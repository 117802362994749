// import { createStore, combineReducers, applyMiddleware } from 'redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
// dev
import thunk from 'redux-thunk';
// import promise from 'redux-promise-middleware';

/*** module-login reducers --- START */
/*** you can import them as whatever name you want */
import authR from 'components/module-login/redux/reducer-auth';
import loginsR from 'components/module-login/redux/reducer-logins';
import loginrolesR from 'components/module-login/redux/reducer-login-roles';
/*** module-login reducers --- END */

import globalsR from './reducers/reducer-globals';

import finalFormR from '../views/Orders-Add-Edit-View/Form-Redux-Requirements/test-redux/finalForm-Reducer'

import dashboardR from './reducers/reducer-dashboards';
import notificationsR from './reducers/notifications-reducer';

import ordersR from './reducers/orders-reducer';

import receiversQCR from './reducers/receiversqc-reducer';
import fltestR from './reducers/fltest-reducer';

/*
    1st is the root reducer of all the reducers
    2nd initial state of the app
    3rd add any enhancers in this case the following 
*/
const initialState = {};
const reducer = combineReducers({
/*** module-login reducers --- START */
  auth: authR,
  logins: loginsR,
  loginroles: loginrolesR,
/*** module-login reducers --- END */

  globals: globalsR,

  finalForm: finalFormR,
  
  dashboard: dashboardR,

  notifications: notificationsR,
  fltests: fltestR,
  orders: ordersR,
  receiversQCs: receiversQCR,
})


// // console.log('0000000000    cur env' , process.env.NODE_ENV);
// // if (process.env.NODE_ENV === 'development') 
// // {
  /*** DEV */
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /*** DEV */
  const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));
// // }else{
//   /*** PROD */
//   const composeEnhancer = compose;
//   /*** PROD */
//   const store = createStore(reducer, initialState);
//   // const store = createStore(reducer, initialState, composeEnhancer);
// // }

export default store;
