/*

const elements1 = ['Fire1', 'Air', '' , 'Water'];
const elements2 = ['Fire2', 'Air', '' , 'Water'];
const elements3 = ['Fire3', 'Air', '' , 'Water'];
const elements4 = ['Fire4', 'Air', '' , 'Water'];

console.log(elements1.join());
// expected output: "Fire,Air,Water"

console.log(elements1.join(''));
// expected output: "FireAirWater"

console.log(elements1.join('-'));
// expected output: "Fire-Air-Water"

console.log(elements1.join(';'));
// expected output: "Fire-Air-Water"

const elements6 = elements1.join('|')
const elements7 = elements2.join('|')
const elements8 = elements3.join('|')
const elements9 = [elements6, elements7, elements8];

console.log('A - ', elements6);

const elements10 = elements9.join('~')
// console.log(elements.join(';'));
console.log('B - ', elements10);


const elements5 = [elements2, elements3, elements4];


// console.log(elements5.join(';'));
// expected output: "Fire,Air,Water"




const myArray1 = elements6.split('|');
console.log('A - length = ', myArray1.length);
console.log('A - length = ', myArray1[0]);

const myArray2 = elements10.split('~');
console.log('B - length = ', myArray2.length);
console.log('B - length = ', myArray2[0]);


*/



export default [
  {
    key: 21,
    bugfix: 1, // 0 or 1
    date: '1-21-2022',
    version: 'vbeta21.1.21.22.1',
    location: 'web - backend',
    title: 'Added Customer Alerts for truck and trailer',
    details: 'Added Customer Alerts for truck and trailer',
  },
  {
    key: 20,
    bugfix: 1, // 0 or 1
    date: '1-4-2022',
    version: 'vbeta20.1.4.22.1',
    location: 'web',
    title: 'Major core component update form core restructure',
    details: 'Major core component update form core restructure',
  },
  {
    key: 19,
    bugfix: 1, // 0 or 1
    date: '12-21-2021',
    version: 'vbeta19.12.21.21.1',
    location: 'web',
    title: 'Went through display programming notes all changes are update to date',
    details: 'Went through display programming notes all changes are update to date',
  },
  {
    key: 18,
    bugfix: 1, // 0 or 1
    date: '12-9-2021',
    version: 'vbeta18.12.17.21.5',
    location: 'web - backend',
    title: 'updated display programming actions section based on new criteria',
    details: 'updated display programming actions section based on new criteria',
  },
  {
    key: 17,
    bugfix: 1, // 0 or 1
    date: '12-9-2021',
    version: 'vbeta17.12.9.21.3',
    location: 'web - backend',
    title: 'fixed add - edit - current user info missing - refactors',
    details: 'fixed add - edit - current user info missing - refactors',
  },
  {
    key: 16,
    bugfix: 1, // 0 or 1
    date: '12-9-2021',
    version: 'vbeta16.12.9.21.1',
    location: 'web - backend',
    title: 'fields added - refactors - major calculation updates - major flow updates',
    details: 'fields added - refactors - major calculation updates - major flow updates',
  },
  {
    key: 15,
    bugfix: 1, // 0 or 1
    date: '12-7-2021',
    version: 'vbeta15.12.7.21.1',
    location: 'web - backend',
    title: 'fields added - refactors - Battery Backup Reporting Schedule added - flow updates',
    details: 'fields added - refactors - Battery Backup Reporting Schedule added - flow updates',
  },
  {
    key: 14,
    bugfix: 1, // 0 or 1
    date: '11-18-2021',
    version: 'v4.2021.11.18.1',
    location: 'web - frontend',
    title: 'telematics settings formula major updates',
    details: 'telematics settings formula major updates',
  },
  {
    key: 13,
    bugfix: 0, // 0 or 1
    date: '11-11-2021',
    version: 'v3.2021.11.11.2',
    location: 'web - frontend',
    title: 'display programming - programming types rules',
    details: 'display programming - programming types rules',
  },
  {
    key: 12,
    bugfix: 0, // 0 or 1
    date: '11-11-2021',
    version: 'v3.2021.11.11.1',
    location: 'web - frontend',
    title: 'display programming - data billing section updates',
    details: 'display programming - data billing section updates',
  },
  {
    key: 11,
    bugfix: 0, // 0 or 1
    date: '9-14-2021',
    version: 'v3.2021.9.14.1 - v3.2021.9.14.1',
    location: 'web - frontend',
    title: 'display programming - data billing section requirements',
    details: 'display programming - data billing section requirements',
  },
  {
    key: 10,
    bugfix: 0, // 0 or 1
    date: '9-13-2021',
    version: 'v3.2021.9.13.1 - v3.2021.9.13.1',
    location: 'web',
    title: 'field renaming completed for sections - display programming',
    details: 'field renaming completed for sections - display programming',
  },
  {
    key: 9,
    bugfix: 1, // 0 or 1
    date: '9-10-2021',
    version: 'v3.2021.9.10.1 - v3.2021.9.10.1',
    location: 'web',
    title: 'field renaming completed for sections - customer details - truck specifications - trailer specifications',
    details: 'field renaming completed for sections - customer details - truck specifications - trailer specifications',
  },
  {
    key: 8,
    bugfix: 1, // 0 or 1
    date: '8-26-2021',
    version: 'v2.2021.8.26.2 - v2.2021.8.26.321',
    location: 'web - frontend',
    title: 'web - frontend - truck spec sheet updates',
    details: 'web - frontend - truck spec sheet updates',
  },
  {
    key: 7,
    bugfix: 1, // 0 or 1
    date: '8-26-2021',
    version: 'v2.2021.8.26.1 - v2.2021.8.26.321',
    location: 'web - core',
    title: 'workspace update',
    details: '--workspace update',
  },
  {
    key: 6,
    bugfix: 1, // 0 or 1
    date: '4-19-2021',
    version: 'v2.2021.4.19.13 - v2.2021.4.19.128',
    location: 'web - backend',
    title: 'Updated dashboard counts',
    details: '--Updated dashboard counts',
  },
  {
    key: 5,
    bugfix: 1, // 0 or 1
    date: '4-7-2021',
    version: 'v2.2021.4.7.52 - v2.2021.4.7.73',
    location: 'web - backend',
    title: 'Fixed orders page having no orders and thowing an error from the backend',
    details: '--Fixed orders page having no orders and thowing an error from the backend',
  },
  {
    key: 4,
    bugfix: 0, // 0 or 1
    date: '4-7-2021',
    version: 'v2.2021.4.7.35',
    location: 'web',
    title: 'Backend updated changed order of results for the receipts page',
    details: '--Backend updated changed order of results for the receipts page',
  },
  {
    key: 3,
    bugfix: 1, // 0 or 1
    date: '4-7-2021',
    version: 'v2.2021.4.7.21',
    location: 'web',
    title: 'Removed FLTest no longer needed for programming',
    details: '--Removed FLTest no longer needed for programming per meeting with team',
  },
  {
    key: 2,
    bugfix: 0, // 0 or 1
    date: '4-6-2021',
    version: 'v2.2021.4.6.276',
    location: 'web',
    title: 'Receiver Receipts page added',
    details: '--Receiver Receipts page added',
  },
  {
    key: 1,
    bugfix: 1, // 0 or 1
    date: '4-5-2021',
    version: 'v2.2021.4.5.397',
    location: 'web',
    title: 'Change Log link added to web experience',
    details: '--Change Log link added to web experience',
  },
  {
    key: 0,
    bugfix: 0, // 0 or 1
    date: '4-5-2021',
    version: '4-5-2021-2',
    location: 'desktop',
    title: 'Programming Counts Experience Updated',
    details:
      '--Added programming count to the programming screen.    --Added notification that programming is completed when receiver counts are equal to total count   --Updated data send meta information  --Verification screen updated added current count of total count.  Staff are taking screen shots of each completed order this will help them with screen shots. --When user finishes all programming for an order a pop up box appears stating programming is complete and user is sent back to the home screen --Programming screen tallys count as programming is being done  --Added time and date stamp to verification screen for screen shots',
  }
];
