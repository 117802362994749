import axios from 'axios';
import {
  SIGN_IN_SET_USER,
  SIGN_OUT_DELETE_USER_N_TOKEN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_IN_REQUEST,
  REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS,
  REGISTER_LOGIN_AUTO_LOGIN_SUCCESS,
  REGISTER_LOGIN_AUTO_LOGIN_FAIL,
  REGISTER_LOGIN_AUTO_LOGIN_PENDING,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
} from './action-constants';

const HEADER_AUTHORIZATION = 'Authorization';
const HEADER_BEARER = 'Bearer ';

const ROUTE_SIGN_IN = 'signin';
const ROUTE_CHANGE_PASSWORD = 'changepassword';
const ROUTE_REGISTER = 'register';

export const signIn = (login, passwordParam) => async (dispatch) => {
  dispatch({ type: SIGN_IN_REQUEST });
  try {
    const JSONURL =
      process.env.REACT_APP_DATABASE_URL + '/' + ROUTE_SIGN_IN + '/' + login;
    await axios
      .post(JSONURL, passwordParam)
      .then((response) => {
        signInNoDispatch(response.data.token);
        dispatch({ type: SIGN_IN_SUCCESS, payload: { data: response.data } });
      })
      .catch((err) => {
        // if (err.response) {
        //     /*** client received an error response (5xx, 4xx) */
        //     // console.log('1919-01 5xx, 4xx ', err);
        //     // console.log('1919-02 5xx, 4xx ', err.response);
        // } else if (err.request) {
        if (err.request) {
          /*** client never received a response, or request never left, DB server offline */
          console.log('2828-01 DB server offline ', err);
          console.log('2828-02 DB server offline ', err.response);
        } else {
          /*** anything else */
          console.log('3838-01 else ', err);
          console.log('3838-02 else ', err.response);
        }

        signOutNoDispatch();
        dispatch({
          type: SIGN_IN_FAIL,
          payload:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.message
        });
      });
  } catch (error) {
    console.log('99-36 error ', error);
    signOutNoDispatch();
    dispatch({
      type: SIGN_IN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const changePassword = (id, passwordParam) => async (dispatch) => {
  const JSONURL =
    process.env.REACT_APP_DATABASE_URL + '/' + ROUTE_CHANGE_PASSWORD + '/' + id;
  dispatch({
    type: CHANGE_PASSWORD_REQUEST
  });
  try {
    const { data } = await axios.post(
      JSONURL,
      passwordParam
      // , HEADERS
    );
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: 'Data Server Appears To Be Offline'
      });
    } else {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: err.message
      });
    }
  }
};

export const RegisterLogin_Auto_Sign_In = (passwordParam) => async (dispatch) => {

  dispatch({ type: REGISTER_LOGIN_AUTO_LOGIN_PENDING });
  try {
    const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + ROUTE_REGISTER;
    await axios
      .post(JSONURL, passwordParam)
      .then((response) => {
        signInNoDispatch(response.data.token);
        dispatch({
          type: REGISTER_LOGIN_AUTO_LOGIN_SUCCESS,
          payload: { data: response.data },
        });
      })
      .catch((err) => {
        if (err.response) {
          /*** client received an error response (5xx, 4xx) */
          // console.log('1818-01 5xx, 4xx ', err);
          // console.log('1818-02 5xx, 4xx ', err.response);
          signOutNoDispatch();
          dispatch({
            type: REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS,
            payload: err.response,
          });
        } else if (err.request) {
          /*** client never received a response, or request never left, DB server offline */
          console.log("2828-01 DB server offline ", err);
          console.log("2828-02 DB server offline ", err.response);

          signOutNoDispatch();
          dispatch({
            type: REGISTER_LOGIN_AUTO_LOGIN_FAIL,
            payload:
              err.response && err.response.data.message
                ? err.response.data.message
                : err.message,
          });
        } else {
          /*** anything else */
          console.log("3838-01 else ", err);
          console.log("3838-02 else ", err.response);

          signOutNoDispatch();
          dispatch({
            type: REGISTER_LOGIN_AUTO_LOGIN_FAIL,
            payload:
              err.response && err.response.data.message
                ? err.response.data.message
                : err.message,
          });
        }
      });
  } catch (error) {
    console.log("99-36 error ", error);
    signOutNoDispatch();
    dispatch({
      type: REGISTER_LOGIN_AUTO_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export function setCurrentUser(user, token) {
  let setUser = {
    id: user.id,
    name: user.name,
    login: user.login,
    role: user.role,
    roleDescription: user.roledescription,
    isAuthenticated: true,
    isAuthenticating: false,
    errors: []
  };

  return {
    type: SIGN_IN_SET_USER,
    payload: {
      user: setUser,
      token: token
    }
  };
}

export function setDefaultAuthorizationTokenForAllHeaders(token) {
  if (token) {
    axios.defaults.headers.common[HEADER_AUTHORIZATION] = HEADER_BEARER + token;
  } else {
    delete axios.defaults.headers.common[HEADER_AUTHORIZATION];
  }
}

export function getLocalStorageToken() {
  return localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
}

export function signOut() {
  signOutNoDispatch();
  return {
    type: SIGN_OUT_DELETE_USER_N_TOKEN,
    payload: 1
  };
}

export function serverDown() {
  signOutNoDispatch();
  return {
    type: SIGN_OUT_DELETE_USER_N_TOKEN,
    payload: 1
  };
}

function signOutNoDispatch() {
  setDefaultAuthorizationTokenForAllHeaders(false);
  localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
}

function signInNoDispatch(token) {
  localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
  axios.defaults.headers.common[HEADER_AUTHORIZATION] = HEADER_BEARER + token;
}
