export const validateFields = async (values) => {
  let errors = {};
  if (!values.login) {
    errors.login = 'Required';
    errors.notifyFormErrors = 'Login Required';
  } else if (!values.password) {
    errors.password = 'Required';
    errors.notifyFormErrors = 'Password Required';
  }
  return errors;
};
export default validateFields;
