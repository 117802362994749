import React from 'react';
import { Card, CardHeader, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    /*** 
     * Make the card width = 100% 
     * Add spacing around the top and bottom of the card
    */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const MessageCard = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          title={props.title}
        />
      </Card>
    </div>
  );
};

export default MessageCard;

/***
 * Required property 
 * title
 * <VehicleNotSelected title="VEHICLE NOT SELECTED" />
 */
