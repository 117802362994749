import moment from 'moment';

// export const GETDROPDOWNSURL = process.env.REACT_APP_DATABASE_URL + "/dropdowns/" ;
// export const GETDROPDOWNURL = process.env.REACT_APP_DATABASE_URL + "/dropdown/" ;

/* */
export const LIST_VIEW_DASH_YES = {
    1: '-',
    2: 'YES',
};
export const LIST_VIEW_NO_YES = {
    1: 'NO',
    2: 'YES',
};
export const LIST_VIEW_VEHICLE_TYPE = {
    1: "TRUCK / MACHINE",
    2: "TRAILER",
    3: "TRUCK & TRAILER",
};
  
/* REPORTS */
export const REPORT_ALLTABLES_COUNT = 'REPORT_ALLTABLESCOUNT';
export const GET_REPORT_ALLTABLES_COUNT = 'GET_REPORT_ALLTABLES_COUNT';
export const GET_REPORT_ALLTABLES_COUNT_PENDING = 'GET_REPORT_ALLTABLES_COUNT_PENDING';
export const GET_REPORT_ALLTABLES_COUNT_FULFILLED = 'GET_REPORT_ALLTABLES_COUNT_FULFILLED';
export const GET_REPORT_ALLTABLES_COUNT_REJECTED = 'GET_REPORT_ALLTABLES_COUNT_REJECTED';



/* STORE */
export const STORE_TABLE = 'stores';
export const GET_STORES_LIST = 'GET_STORES_LIST';
export const GET_STORES_LIST_PENDING = 'GET_STORES_LIST_PENDING';
export const GET_STORES_LIST_FULFILLED = 'GET_STORES_LIST_FULFILLED';
export const GET_STORES_LIST_REJECTED = 'GET_STORES_LIST_REJECTED';

export const GET_STORE = 'GET_STORE';
export const GET_STORE_PENDING = 'GET_STORE_PENDING';
export const GET_STORE_FULFILLED = 'GET_STORE_FULFILLED';
export const GET_STORE_REJECTED = 'GET_STORE_REJECTED';

export const DELETE_STORE = 'DELETE_STORE';
export const DELETE_STORE_PENDING = 'DELETE_STORE_PENDING';
export const DELETE_STORE_FULFILLED = 'DELETE_STORE_FULFILLED';
export const DELETE_STORE_REJECTED = 'DELETE_STORE_REJECTED';

export const ADD_STORE = 'ADD_STORE';
export const ADD_STORE_PENDING = 'ADD_STORE_PENDING';
export const ADD_STORE_FULFILLED = 'ADD_STORE_FULFILLED';
export const ADD_STORE_REJECTED = 'ADD_STORE_REJECTED';

export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_STORE_PENDING = 'UPDATE_STORE_PENDING';
export const UPDATE_STORE_FULFILLED = 'UPDATE_STORE_FULFILLED';
export const UPDATE_STORE_REJECTED = 'UPDATE_STORE_REJECTED';



/* ORDER */
export const ORDER_TABLE = 'orders';

export const CREATE_NEW_ORDERS = 'CREATE_NEW_ORDERS';

export const ADD_TO_TRUCK_CUSTOMER_ALERTS_LIST = 'ADD_TO_TRUCK_CUSTOMER_ALERTS_LIST';
export const REMOVE_TO_TRUCK_CUSTOMER_ALERTS_LIST = 'REMOVE_TO_TRUCK_CUSTOMER_ALERTS_LIST';
export const ADD_TO_TRAILER_CUSTOMER_ALERTS_LIST = 'ADD_TO_TRAILER_CUSTOMER_ALERTS_LIST';
export const REMOVE_TO_TRAILER_CUSTOMER_ALERTS_LIST = 'REMOVE_TO_TRAILER_CUSTOMER_ALERTS_LIST';


                                                                        // export const CHANGE_VEHICLE_TYPE = 'CHANGE_VEHICLE_TYPE';


/*** order form initialValues --- START */

/*** export me to orders-actions or action-constants ---- start */
export const DEFAULTORDERENTRY = {
    psirepresentative: 0,
    
    tempid: moment(new Date()).format('YYYY.MM.DD.HH.mm.ss.SSS'),
    orderdate: moment(new Date()).format('YYYY-MM-DD'),
    jobnamepo: '',
    fleetname: '',
    hardwarebillingtype: 2,
    usage: 1,
    vehicletype: 2,
    quantity: 1,
    ordercomments: '',
  
    trucksensorsonsteeraxle: 1,
    trucksensortypeaxle1: 1,
    trucksensorsondriveaxle: 1,
    trucktireconfiguration234: 1,
    truckaxleconfiguration: 1,
    truckdriveaxlewheelsize: 1,
    truckdriveaxlewheelmaterial: 1,
    truckliftaxle: 1,
    trucksensortypeaxle234: 1,
    truckflowthrukit: 1,
    truckhubtype: 1,
    truckhubtypeother: '',
    truckspecialtysensors: 1,
    trucksparetire: 1,
  
    trailersensorsontraileraxle: 1,
    trailertireconfiguration: 2,
    traileraxleconfiguration: 2,
    trailerdriveaxlewheelsize: 3,
    trailerdriveaxlewheelmaterial: 2,
    trailerliftaxle: 1,
    trailersensortypeaxle: 1,
    traileradapttoatis: 1,
    traileridentifybrand: 1,
    traileridentifybrandotherspecify: '',
    trailerneedtosupplycphoses: 1,
    trailerflowthrukit: 1,
    trailerhubtype: 1,
    trailerhubtypeother: '',
    trailerspecialtysensors: 1,
    trailersparetire: 1,
    trailerdigitalthermalert: 1,
    trailertankpressure: 1,
    trailercontrolboxoutput: 1,
    trailercontrolboxonoff: 1,
  
    dpdisplayselection: 1,
    dptelematics: 1,
    trucktelematicsbrand: 0,
    trailertelematicsbrand: 0,
    dppreprogramming: 1,
    steertirecoldpressure: 158,
    steertirealarmsettingsdefaultlow: true,
    steertirealarmsettingsdefaultlowadjustvalue: 158,
    steertirealarmsettingsdefaulthigh: true,
    steertirealarmsettingsdefaulthighadjustvalue: 158,
    steertirealarmsettingsdefaulthightemp: true,
    steertirealarmsettingsdefaulthightempadjustvalue: 158,
    drivetirecoldpressure: 158,
    drivetirealarmsettingsdefaultlow: 1,
    drivetirealarmsettingsdefaultlowadjustvalue: 158,
    drivetirealarmsettingsdefaulthigh: 1,
    drivetirealarmsettingsdefaulthighadjustvalue: 158,
    drivetirealarmsettingsdefaulthightemp: 1,
    drivetirealarmsettingsdefaulthightempadjustvalue: 158,

    trailertirecoldpressure: 400,
    trailertirealarmsettingsdefaultlow: 1,
    trailertirealarmsettingsdefaultlowadjustvalue: 158,
    trailertirealarmsettingsdefaulthigh: 1,
    trailertirealarmsettingsdefaulthighadjustvalue: 158,
    trailertirealarmsettingsdefaulthightemp: 1,
    trailertirealarmsettingsdefaulthightempadjustvalue: 158,

    trailerliftaxletirecoldpressure: 100,
    trailerliftaxletirealarmsettingsdefaultlow: 1,
    trailerliftaxletirealarmsettingsdefaultlowadjustvalue: 0,
    trailerliftaxletirealarmsettingsdefaulthigh: 1,
    trailerliftaxletirealarmsettingsdefaulthighadjustvalue: 0,

    trailerspindletype: 1,
    trailerspindletypeother: '',


    pressureunits: 1,
    temperatureunits: 1,


    truckprogrammingtype: 0,
    trailerprogrammingtype: 0,

    dpdisplaypowersource: 1,
    
    dptruckdatabilling: 1,
    dptruckdatabillingterm: 1,
    dptruckdatabillingrate: '',
    dptruckcustsatexpire: 1,
    dptruckexpireswhen: moment(new Date()).format('YYYY-MM-DD'),
    dptruckafterexpiration: 1,

    dptrailerdatabilling: 1,
    dptrailerdatabillingterm: 1,
    dptrailerdatabillingrate: '',
    dptrailercustsatexpire: 1,
    dptrailerexpireswhen: moment(new Date()).format('YYYY-MM-DD'),
    dptrailerafterexpiration: 1,


    dptrailerfirstreportafterdisconnected: 2,
    dptrailerongoingreportintervalafterfirstreport: 2,





    

    truckCustomerAlerts: [
        // {firstName: '1-TRK_Red', lastName: '1-TRK_Parker', email: 'r@p.com',realtimeAlert:0,reminderAlert:1,summaryAlert:1,submittedBy:7},
        // {firstName: '1-TRK_Kenya', lastName: '1-TRK_Ozuna', email: 'k@o.com',realtimeAlert:1,reminderAlert:0,summaryAlert:0,submittedBy:7},
        // {firstName: '1-TRK_Shang', lastName: '1-TRK_Tsung', email: 's@ptcom',realtimeAlert:0,reminderAlert:1,summaryAlert:1,submittedBy:7},
        // {firstName: '1-TRK_Erasmo', lastName: '1-TRK_Ruiz', email: 'e@r.com',realtimeAlert:1,reminderAlert:0,summaryAlert:1,submittedBy:7},
        // {firstName: '1-TRK_Singen', lastName: '1-TRK_Otuje', email: 's@o.com',realtimeAlert:0,reminderAlert:0,summaryAlert:0,submittedBy:7},
    ],
    trailerCustomerAlerts: [
        // {firstName: '2-TRLR_Red', lastName: '2-TRLR_Parker', email: 'r@p.com',realtimeAlert:0,reminderAlert:1,summaryAlert:1,submittedBy:7},
        // {firstName: '2-TRLR_Kenya', lastName: '2-TRLR_Ozuna', email: 'k@o.com',realtimeAlert:1,reminderAlert:0,summaryAlert:0,submittedBy:7},
        // {firstName: '2-TRLR_Shang', lastName: '2-TRLR_Tsung', email: 's@ptcom',realtimeAlert:0,reminderAlert:1,summaryAlert:1,submittedBy:7},
        // {firstName: '2-TRLR_Erasmo', lastName: '2-TRLR_Ruiz', email: 'e@r.com',realtimeAlert:1,reminderAlert:0,summaryAlert:1,submittedBy:7},
        // {firstName: '2-TRLR_Singen', lastName: '2-TRLR_Otuje', email: 's@o.com',realtimeAlert:0,reminderAlert:0,summaryAlert:0,submittedBy:7},
    ],
    

    id: 0,
    /* ORDER CREATOR */
    internalorderid: 'internalorderid',
    /* ORDER APPROVER */
    needsapproval: 1,
    needsqc: 0,
    needsprogramming: 0,
    /* ORDER CREATOR */
    /* ORDER APPROVER */
    /* ORDER CREATOR */
    /* ORDER APPROVER */
    lname: '',
    /* ORDER CREATOR */
    ordercreatorcomments: '',
    /* ORDER APPROVER */
    orderapprovercomments: '',
    /* PROGRAMMER */
    programmercomments: '',
    /* QUALITY CONTROL */
    qccomments: '',
    // timestamps: true,
  
    sensorslist: [],
  
    orderid: 'orderid',
    uniqueid: 'uniqueid',
    filename: 'filename',
  
    needsfilename: 1,
    needsorderid: 0,
  
    /*** Item,Order,Vehicle,Axle, */
    item: 1,
    ordernumber: 1,
    vehicle: 1,
    axle: 1,
  
    /*** lo,li,ri,ro,VehType, */
    leftouter: '',
    leftinner: '',
    rightinner: '',
    rightouter: '01=CCTX1',
  
    leftouterax2: '08=CCTX8',
    leftinnerax2: '',
    rightinnerax2: '',
    rightouterax2: '',
  
    leftouterax3: '',
    leftinnerax3: '',
    rightinnerax3: '',
    rightouterax3: '09=CCTX9',
  
    leftouterax4: '16=CCTX16',
    leftinnerax4: '',
    rightinnerax4: '',
    rightouterax4: ''
  };
  /*** export me to orders-actions or action-constants ---- end */


/*** order form initialValues --- END */




/*** FORM CONSTANTS - START */

export const HARDWARE_BILLING_TYPE = [
    {value: 1, label: "N / A - DISPLAY & SENSORS ONLY"},
    {value: 2, label: "BILLABLE"},
    {value: 3, label: "CUSTOMER SAT"},
    {value: 4, label: "R & D"},
];

export const USAGE = [
    {value: 1, label: "COMMERCIAL VEHICLE"},
    {value: 2, label: "OFF HIGHWAY ( OTR )"},
    {value: 3, label: "RV / LIGHT DUTY"},
];

export const VEHICLE_TYPE = [
    {value: 1, label: "TRUCK / MACHINE"},
    {value: 2, label: "TRAILER"},
    {value: 3, label: "TRUCK & TRAILER"},
];

export const TRUCK_DRIVE_AXLE_WHEEL_SIZE = [
    {value: 1, label: "22.5"},
    {value: 2, label: "24.5"}
];

export const TRAILER_DRIVE_AXLE_WHEEL_SIZE = [
    {value: 1, label: "17.5"},
    {value: 2, label: "19.5"},
    {value: 3, label: "22.5"},
    {value: 4, label: "24.5"},
];

export const TRUCK_DRIVE_AXLE_WHEEL_MATERIAL = [
    {value: 1, label: "ALUMINUM"},
    {value: 2, label: "STEEL"}
];

export const TRUCK_AXLE_CONFIGURATION = [
    {value: 1, label: "SINGLE"},
    {value: 2, label: "TANDEM"},
    {value: 3, label: "TRIDEM"},
];

export const TRAILER_AXLE_CONFIGURATION = [
    {value: 1, label: "SINGLE"},
    {value: 2, label: "TANDEM"},
    {value: 3, label: "TRIDEM"},
    {value: 4, label: "QUAD"},
];

export const TRUCK_HUB_TYPE = [
    {value: 1, label: "R"},
    {value: 2, label: "OTHER"},
];

export const TRAILER_HUB_TYPE = [
    {value: 1, label: "N"},
    {value: 2, label: "P"},
    {value: 3, label: "OTHER"},
];

export const TRAILER_SPINDLE_TYPE = [
    {value: 1, label: "P"},
    {value: 2, label: "N"},
    {value: 3, label: "OTHER"},
];

export const TIRE_SIZE_TRAILER_AXLE = [
    {value: 1, label: "22.5"},
    {value: 2, label: "24"}
];

export const AXLE_CONFIGURATION = [
    {value: 1, label: "N / A"},
    {value: 2, label: "SINGLE"},
    {value: 3, label: "TANDEM"},
    {value: 4, label: "TRI"},
    {value: 5, label: "QUAD"}
];

export const CONTROL_BOX = [
    {value: 1, label: "N / A"},
    {value: 2, label: "INTERNAL"},
    {value: 3, label: "CAP"}
];

export const DISPLAY_ARRAY = [
    {value: 1, label: "NONE"},
    {value: 2, label: "MONOCHROME"},
    {value: 3, label: "COLOR"}
];

export const IDENTIFY_BRAND_MODEL = [
    {value: 1, label: "MERITOR / PSI"},
    {value: 2, label: "HENDRICKSON"},
    {value: 3, label: "STEMCO"},
    {value: 4, label: "OTHER"}
];

export const OPTION_NO_YES = [
    {value: 1, label: "NO"},
    {value: 2, label: "YES"},
];

export const TRUCK_SENSOR_TYPE_AXLE_1 = [
    {value: 1, label: "CAP"},
    {value: 2, label: "INTERNAL"},
    {value: 3, label: "FLOW-THRU SENSOR"},
];

export const SENSOR_TYPE = [
    {value: 1, label: "CAP"},
    {value: 2, label: "INTERNAL"},
];

export const SPECIALTY_SENSOR = [
    {value: 1, label: "NO"},
    {value: 2, label: "YES"}
];


export const TRUCK_TELEMATICS_BRAND = [
    {value: 0, label: "SELECT AN OPTION"},
    {value: 4, label: "TireView Live (Standard Gateway)"},
    {value: 6, label: "GeoTab Go Device"},
    {value: 8, label: "GeoTab API (Standard Gateway)"},
];

export const TELEMATICS_BRAND = [
    {value: 0, label: "SELECT AN OPTION"},
    {value: 1, label: "PCT"},
    {value: 2, label: "TRUCK-LITE ROAD READY"},
    {value: 3, label: "SPIREON FL FLEX"},
    {value: 4, label: "TireView Live (Standard Gateway)"},
    {value: 5, label: "TireView Live (Battery Backup Gateway)"},
    // {value: 6, label: "GEOTAB GO DEVICE"},
    {value: 7, label: "DRIVER TECH"},
    {value: 8, label: "GeoTab API (Standard Gateway)"},
    {value: 9, label: "GeoTab API (Battery Backup Gateway)"},
];

export const TRUCK_CONFIGURATION = [
    {value: 1, label: "N / A"},
    {value: 2, label: "WIDE BASE SINGLE"},
    {value: 3, label: "DUALS"}
];

export const DATA_BILLING = [
    {value: 1, label: "BILLABLE"},
    {value: 2, label: "CUSTOMER SAT"},
    {value: 3, label: "R & D"}
];

export const DATA_BILLING_TERM = [
    {value: 1, label: "MONTHLY"},
    {value: 2, label: "YEARLY"},
    {value: 3, label: "5 YEAR"},
    {value: 4, label: "10 YEAR"}
];

export const OPTION_AFTER_EXPIRATION = [
    {value: 1, label: "DISABLE AND REMOVE FROM ACCOUNT"},
    {value: 2, label: "CONVERTS TO BILLING"}
];




export const FIRST_REPORT_AFTER_DISCONNECTED = [
    {value: 1, label: "4 HOURS"},
    {value: 2, label: "6 HOURS"}
];

export const ON_GOING_REPORT_INTERVAL_AFTER_FIRST_REPORT = [
    {value: 1, label: "12 HOURS"},
    {value: 2, label: "24 HOURS"},
    {value: 3, label: "48 HOURS"},
    {value: 4, label: "72 HOURS"}
];



export const PRESSURE_UNITS = [
    {value: 1, label: "PSI"},
    {value: 2, label: "BAR"}
];

export const TEMPERATURE_UNITS = [
    {value: 1, label: "FAHRENHEIT"},
    {value: 2, label: "CELSIUS"}
];





export const POWER_SOURCE = [
    {value: 1, label: "USB"},
    {value: 2, label: "Hardwire"}
];


 



// export const TEST_10 = [
//     {value: 0, label: "00BBBB"},
//     {value: 1, label: "11ZZZZ"},
//     {value: 2, label: "2222SINGLE"},
//     {value: 3, label: "3333TANDEM"},
//     {value: 4, label: "444TRI"},
//     {value: 5, label: "555QUAD"},
//     {value: 6, label: "666ZZIP"},
//     {value: 7, label: "777YYER"},
//     {value: 8, label: "888XXET"},
//     {value: 9, label: "999WYYY"},
//     {value: 10, label: "101010UUVVVV"}
// ];




        // TEST --- start
                                                                                export const DISPLAY_POWER = [
                                                                                    {value: 1, label: "U - USB - U"},
                                                                                    {value: 2, label: "H - Hardwire - H"}
                                                                                ];

                                                                                export const SPINDLE_TYPE_NP = [
                                                                                    {value: 1, label: "N - N-SERIES"},
                                                                                    {value: 2, label: "P - P-SERIES"},
                                                                                ];

                                                                                export const DISPLAY_MOUNT = [
                                                                                    {value: 1, label: "F - FIXED - F"},
                                                                                    {value: 2, label: "C - CRADLE - C"},
                                                                                    {value: 3, label: "S - SUCTION CUP - S"}
                                                                                ];

                                                                                export const SPINDLE_TYPE_RO = [
                                                                                    {value: 1, label: "R - R-SERIES"},
                                                                                    {value: 2, label: "O - SPECIFY"},
                                                                                ];

                                                                                export const TIRE_WHEEL_INFO_SINGLE = [
                                                                                    {value: 2, label: "S - SINGLE"},
                                                                                ];

                                                                                export const TIRE_WHEEL_INFO = [
                                                                                    {value: 1, label: "D - DUAL"},
                                                                                    {value: 2, label: "S - SINGLE"},
                                                                                ];

                                                                                export const TIRE_CONFIGURATION_SINGLE_DUAL = [
                                                                                    {value: 1, label: "S - SINGLE"},
                                                                                    {value: 2, label: "D - DUALS"},
                                                                                ];

        // TEST --- end






/*** FORM CONSTANTS - END */








/*** GLOBAL */
export const GET_DASHBOARD_COUNTS = 'GET_DASHBOARD_COUNTS';
export const GET_DASHBOARD_COUNTS_REQUEST = 'GET_DASHBOARD_COUNTS_REQUEST';
export const GET_DASHBOARD_COUNTS_PENDING = 'GET_DASHBOARD_COUNTS_PENDING';
export const GET_DASHBOARD_COUNTS_SUCCESS = 'GET_DASHBOARD_COUNTS_SUCCESS';
export const GET_DASHBOARD_COUNTS_FULFILLED = 'GET_DASHBOARD_COUNTS_FULFILLED';
export const GET_DASHBOARD_COUNTS_REJECTED = 'GET_DASHBOARD_COUNTS_REJECTED';
export const GET_DASHBOARD_COUNTS_FAIL = 'GET_DASHBOARD_COUNTS_FAIL';




/* ORDERS */
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const GET_ORDERS_LIST_PENDING = 'GET_ORDERS_LIST_PENDING';
export const GET_ORDERS_LIST_FULFILLED = 'GET_ORDERS_LIST_FULFILLED';
export const GET_ORDERS_LIST_REJECTED = 'GET_ORDERS_LIST_REJECTED';

export const GET_ORDERSPENDINGAPPROVAL_LIST = 'GET_ORDERSPENDINGAPPROVAL_LIST';
export const GET_ORDERSPENDINGAPPROVAL_LIST_PENDING = 'GET_ORDERSPENDINGAPPROVAL_LIST_PENDING';
export const GET_ORDERSPENDINGAPPROVAL_LIST_FULFILLED = 'GET_ORDERSPENDINGAPPROVAL_LIST_FULFILLED';
export const GET_ORDERSPENDINGAPPROVAL_LIST_REJECTED = 'GET_ORDERSPENDINGAPPROVAL_LIST_REJECTED';

export const GET_ORDERSAPPROVED_LIST = 'GET_ORDERSAPPROVED_LIST';
export const GET_ORDERSAPPROVED_LIST_PENDING = 'GET_ORDERSAPPROVED_LIST_PENDING';
export const GET_ORDERSAPPROVED_LIST_FULFILLED = 'GET_ORDERSAPPROVED_LIST_FULFILLED';
export const GET_ORDERSAPPROVED_LIST_REJECTED = 'GET_ORDERSAPPROVED_LIST_REJECTED';

export const GET_ORDERSNEEDSPROGRAMMING_LIST = 'GET_ORDERSNEEDSPROGRAMMING_LIST';
export const GET_ORDERSNEEDSPROGRAMMING_LIST_PENDING = 'GET_ORDERSNEEDSPROGRAMMING_LIST_PENDING';
export const GET_ORDERSNEEDSPROGRAMMING_LIST_FULFILLED = 'GET_ORDERSNEEDSPROGRAMMING_LIST_FULFILLED';
export const GET_ORDERSNEEDSPROGRAMMING_LIST_REJECTED = 'GET_ORDERSNEEDSPROGRAMMING_LIST_REJECTED';

export const GET_ORDERSNEEDSQC_LIST = 'GET_ORDERSNEEDSQC_LIST';
export const GET_ORDERSNEEDSQC_LIST_PENDING = 'GET_ORDERSNEEDSQC_LIST_PENDING';
export const GET_ORDERSNEEDSQC_LIST_FULFILLED = 'GET_ORDERSNEEDSQC_LIST_FULFILLED';
export const GET_ORDERSNEEDSQC_LIST_REJECTED = 'GET_ORDERSNEEDSQC_LIST_REJECTED';

export const GET_ORDERSCOMPLETED_LIST = 'GET_ORDERSCOMPLETED_LIST';
export const GET_ORDERSCOMPLETED_LIST_PENDING = 'GET_ORDERSCOMPLETED_LIST_PENDING';
export const GET_ORDERSCOMPLETED_LIST_FULFILLED = 'GET_ORDERSCOMPLETED_LIST_FULFILLED';
export const GET_ORDERSCOMPLETED_LIST_REJECTED = 'GET_ORDERSCOMPLETED_LIST_REJECTED';

export const GET_ORDERS_SENSORS = 'GET_ORDERS_SENSORS';
export const GET_ORDERS_SENSORS_PENDING = 'GET_ORDERS_SENSORS_PENDING';
export const GET_ORDERS_SENSORS_FULFILLED = 'GET_ORDERS_SENSORS_FULFILLED';
export const GET_ORDERS_SENSORS_REJECTED = 'GET_ORDERS_SENSORS_REJECTED';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_PENDING = 'DELETE_ORDER_PENDING';
export const DELETE_ORDER_FULFILLED = 'DELETE_ORDER_FULFILLED';
export const DELETE_ORDER_REJECTED = 'DELETE_ORDER_REJECTED';

/* QUALITYCONTROL1 */
export const QUALITYCONTROL1_TABLE = 'qualitycontrol1s';
export const GET_QUALITYCONTROL1S_LIST = 'GET_QUALITYCONTROL1S_LIST';
export const GET_QUALITYCONTROL1S_LIST_PENDING = 'GET_QUALITYCONTROL1S_LIST_PENDING';
export const GET_QUALITYCONTROL1S_LIST_FULFILLED = 'GET_QUALITYCONTROL1S_LIST_FULFILLED';
export const GET_QUALITYCONTROL1S_LIST_REJECTED = 'GET_QUALITYCONTROL1S_LIST_REJECTED';

export const GET_QUALITYCONTROL1 = 'GET_QUALITYCONTROL1';
export const GET_QUALITYCONTROL1_PENDING = 'GET_QUALITYCONTROL1_PENDING';
export const GET_QUALITYCONTROL1_FULFILLED = 'GET_QUALITYCONTROL1_FULFILLED';
export const GET_QUALITYCONTROL1_REJECTED = 'GET_QUALITYCONTROL1_REJECTED';





/* SAVESENSOR1 */
export const CREATE_NEW_SAVESENSOR1 = 'CREATE_NEW_SAVESENSOR1';

export const SAVESENSOR1_TABLE = 'savesensor1s';
export const GET_SAVESENSOR1S_LIST = 'GET_SAVESENSOR1S_LIST';
export const GET_SAVESENSOR1S_LIST_PENDING = 'GET_SAVESENSOR1S_LIST_PENDING';
export const GET_SAVESENSOR1S_LIST_FULFILLED = 'GET_SAVESENSOR1S_LIST_FULFILLED';
export const GET_SAVESENSOR1S_LIST_REJECTED = 'GET_SAVESENSOR1S_LIST_REJECTED';

export const GET_SAVESENSOR1S_NO_FILENAME_LIST = 'GET_SAVESENSOR1S_NO_FILENAME_LIST';
export const GET_SAVESENSOR1S_NO_FILENAME_LIST_PENDING = 'GET_SAVESENSOR1S_NO_FILENAME_LIST_PENDING';
export const GET_SAVESENSOR1S_NO_FILENAME_LIST_FULFILLED = 'GET_SAVESENSOR1S_NO_FILENAME_LIST_FULFILLED';
export const GET_SAVESENSOR1S_NO_FILENAME_LIST_REJECTED = 'GET_SAVESENSOR1S_NO_FILENAME_LIST_REJECTED';

export const GET_SAVESENSOR1S_NO_ORDER_ID = 'GET_SAVESENSOR1S_NO_ORDER_ID';
export const GET_SAVESENSOR1S_NO_ORDER_ID_PENDING = 'GET_SAVESENSOR1S_NO_ORDER_ID_PENDING';
export const GET_SAVESENSOR1S_NO_ORDER_ID_FULFILLED = 'GET_SAVESENSOR1S_NO_ORDER_ID_FULFILLED';
export const GET_SAVESENSOR1S_NO_ORDER_ID_REJECTED = 'GET_SAVESENSOR1S_NO_ORDER_ID_REJECTED';

export const GET_SAVESENSOR1S_LIST_BY_ORDER_ID = 'GET_SAVESENSOR1S_LIST_BY_ORDER_ID';
export const GET_SAVESENSOR1S_LIST_BY_ORDER_ID_PENDING = 'GET_SAVESENSOR1S_LIST_BY_ORDER_ID_PENDING';
export const GET_SAVESENSOR1S_LIST_BY_ORDER_ID_FULFILLED = 'GET_SAVESENSOR1S_LIST_BY_ORDER_ID_FULFILLED';
export const GET_SAVESENSOR1S_LIST_BY_ORDER_ID_REJECTED = 'GET_SAVESENSOR1S_LIST_BY_ORDER_ID_REJECTED';

export const GET_SAVESENSOR1 = 'GET_SAVESENSOR1';
export const GET_SAVESENSOR1_PENDING = 'GET_SAVESENSOR1_PENDING';
export const GET_SAVESENSOR1_FULFILLED = 'GET_SAVESENSOR1_FULFILLED';
export const GET_SAVESENSOR1_REJECTED = 'GET_SAVESENSOR1_REJECTED';

export const DELETE_SAVESENSOR1 = 'DELETE_SAVESENSOR1';
export const DELETE_SAVESENSOR1_PENDING = 'DELETE_SAVESENSOR1_PENDING';
export const DELETE_SAVESENSOR1_FULFILLED = 'DELETE_SAVESENSOR1_FULFILLED';
export const DELETE_SAVESENSOR1_REJECTED = 'DELETE_SAVESENSOR1_REJECTED';

export const ADD_SAVESENSOR1 = 'ADD_SAVESENSOR1';
export const ADD_SAVESENSOR1_PENDING = 'ADD_SAVESENSOR1_PENDING';
export const ADD_SAVESENSOR1_FULFILLED = 'ADD_SAVESENSOR1_FULFILLED';
export const ADD_SAVESENSOR1_REJECTED = 'ADD_SAVESENSOR1_REJECTED';

export const UPDATE_SAVESENSOR1 = 'UPDATE_SAVESENSOR1';
export const UPDATE_SAVESENSOR1_PENDING = 'UPDATE_SAVESENSOR1_PENDING';
export const UPDATE_SAVESENSOR1_FULFILLED = 'UPDATE_SAVESENSOR1_FULFILLED';
export const UPDATE_SAVESENSOR1_REJECTED = 'UPDATE_SAVESENSOR1_REJECTED';

export const UPDATE_SAVESENSOR1_SAVE = 'UPDATE_SAVESENSOR1_SAVE';
export const UPDATE_SAVESENSOR1_SAVE_PENDING = 'UPDATE_SAVESENSOR1_SAVE_PENDING';
export const UPDATE_SAVESENSOR1_SAVE_FULFILLED = 'UPDATE_SAVESENSOR1_SAVE_FULFILLED';
export const UPDATE_SAVESENSOR1_SAVE_REJECTED = 'UPDATE_SAVESENSOR1_SAVE_REJECTED';

export const UPDATE_SAVESENSOR1_SAVE_AND_CLOSE = 'UPDATE_SAVESENSOR1_SAVE_AND_CLOSE';
export const UPDATE_SAVESENSOR1_SAVE_AND_CLOSE_PENDING = 'UPDATE_SAVESENSOR1_SAVE_AND_CLOSE_PENDING';
export const UPDATE_SAVESENSOR1_SAVE_AND_CLOSE_FULFILLED = 'UPDATE_SAVESENSOR1_SAVE_AND_CLOSE_FULFILLED';
export const UPDATE_SAVESENSOR1_SAVE_AND_CLOSE_REJECTED = 'UPDATE_SAVESENSOR1_SAVE_AND_CLOSE_REJECTED';

export const UPDATE_ORDERID_N_FILENAME = 'UPDATE_ORDERID_N_FILENAME';
export const UPDATE_ORDERID_N_FILENAME_PENDING = 'UPDATE_ORDERID_N_FILENAME_PENDING';
export const UPDATE_ORDERID_N_FILENAME_FULFILLED = 'UPDATE_ORDERID_N_FILENAME_FULFILLED';
export const UPDATE_ORDERID_N_FILENAME_REJECTED = 'UPDATE_ORDERID_N_FILENAME_REJECTED';

export const GET_SAVESENSOR1S_COUNT = 'GET_SAVESENSOR1S_COUNT';
export const GET_SAVESENSOR1S_COUNT_PENDING = 'GET_SAVESENSOR1S_COUNT_PENDING';
export const GET_SAVESENSOR1S_COUNT_FULFILLED = 'GET_SAVESENSOR1S_COUNT_FULFILLED';
export const GET_SAVESENSOR1S_COUNT_REJECTED = 'GET_SAVESENSOR1S_COUNT_REJECTED';


/* DASHBOARD */
export const DASHBOARD_TABLE = 'dashboard';
export const GET_DASHBOARDS_LIST = 'GET_DASHBOARDS_LIST';
export const GET_DASHBOARDS_LIST_PENDING = 'GET_DASHBOARDS_LIST_PENDING';
export const GET_DASHBOARDS_LIST_FULFILLED = 'GET_DASHBOARDS_LIST_FULFILLED';
export const GET_DASHBOARDS_LIST_REJECTED = 'GET_DASHBOARDS_LIST_REJECTED';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_PENDING = 'GET_DASHBOARD_PENDING';
export const GET_DASHBOARD_FULFILLED = 'GET_DASHBOARD_FULFILLED';
export const GET_DASHBOARD_REJECTED = 'GET_DASHBOARD_REJECTED';

export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const DELETE_DASHBOARD_PENDING = 'DELETE_DASHBOARD_PENDING';
export const DELETE_DASHBOARD_FULFILLED = 'DELETE_DASHBOARD_FULFILLED';
export const DELETE_DASHBOARD_REJECTED = 'DELETE_DASHBOARD_REJECTED';

export const ADD_DASHBOARD = 'ADD_DASHBOARD';
export const ADD_DASHBOARD_PENDING = 'ADD_DASHBOARD_PENDING';
export const ADD_DASHBOARD_FULFILLED = 'ADD_DASHBOARD_FULFILLED';
export const ADD_DASHBOARD_REJECTED = 'ADD_DASHBOARD_REJECTED';

export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const UPDATE_DASHBOARD_PENDING = 'UPDATE_DASHBOARD_PENDING';
export const UPDATE_DASHBOARD_FULFILLED = 'UPDATE_DASHBOARD_FULFILLED';
export const UPDATE_DASHBOARD_REJECTED = 'UPDATE_DASHBOARD_REJECTED';




























/*** NEW REDUCERS */
/*** SALES ORDER QC  - QC DATA */
export const GET_RECEIVERS_TABLE = 'RECEIVERS';
export const GET_RECEIVERS = 'GET_RECEIVERS';
export const GET_RECEIVERS_REQUEST = 'GET_RECEIVERS_REQUEST';
export const GET_RECEIVERS_PENDING = 'GET_RECEIVERS_PENDING';
export const GET_RECEIVERS_FAIL = 'GET_RECEIVERS_FAIL';
export const GET_RECEIVERS_REJECTED = 'GET_RECEIVERS_REJECTED';
export const GET_RECEIVERS_SUCCESS = 'GET_RECEIVERS_SUCCESS';
export const GET_RECEIVERS_FULFILLED = 'GET_RECEIVERS_FULFILLED';

export const GET_RECEIVERSQC_TABLE = 'RECEIVERSQC';
export const GET_RECEIVERSQC = 'GET_RECEIVERSQC';
export const GET_RECEIVERSQC_REQUEST = 'GET_RECEIVERSQC_REQUEST';
export const GET_RECEIVERSQC_PENDING = 'GET_RECEIVERSQC_PENDING';
export const GET_RECEIVERSQC_FAIL = 'GET_RECEIVERSQC_FAIL';
export const GET_RECEIVERSQC_REJECTED = 'GET_RECEIVERSQC_REJECTED';
export const GET_RECEIVERSQC_SUCCESS = 'GET_RECEIVERSQC_SUCCESS';
export const GET_RECEIVERSQC_FULFILLED = 'GET_RECEIVERSQC_FULFILLED';


/*** FLTEST  - QC DATA */
export const GET_FLTEST_TABLE = 'FLTEST';
export const GET_FLTEST = 'GET_FLTEST';
export const GET_FLTEST_REQUEST = 'GET_FLTEST_REQUEST';
export const GET_FLTEST_PENDING = 'GET_FLTEST_PENDING';
export const GET_FLTEST_FAIL = 'GET_FLTEST_FAIL';
export const GET_FLTEST_REJECTED = 'GET_FLTEST_REJECTED';
export const GET_FLTEST_SUCCESS = 'GET_FLTEST_SUCCESS';
export const GET_FLTEST_FULFILLED = 'GET_FLTEST_FULFILLED';


/*** ORDERS */
/*** FORM CONSTANTS */
export const ORDER_FORM_ADD = 'ORDER_FORM_ADD';
export const ORDER_FORM_EDIT = 'ORDER_FORM_EDIT';
export const ORDER_FORM_VIEW = 'ORDER_FORM_VIEW';
export const MODAL_CUSTOMER_ACCESS_LIST = 'MODAL_CUSTOMER_ACCESS_LIST';
export const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_PENDING = 'GET_ORDERS_PENDING';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';
export const GET_ORDERS_REJECTED = 'GET_ORDERS_REJECTED';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FULFILLED = 'GET_ORDERS_FULFILLED';

export const GET_ORDERS_NEW_REQUEST = 'GET_ORDERS_NEW_REQUEST';
export const GET_ORDERS_NEW_PENDING = 'GET_ORDERS_NEW_PENDING';
export const GET_ORDERS_NEW_FAIL = 'GET_ORDERS_NEW_FAIL';
export const GET_ORDERS_NEW_REJECTED = 'GET_ORDERS_NEW_REJECTED';
export const GET_ORDERS_NEW_SUCCESS = 'GET_ORDERS_NEW_SUCCESS';
export const GET_ORDERS_NEW_FULFILLED = 'GET_ORDERS_NEW_FULFILLED';

export const GET_ORDERS_VIEW_REQUEST = 'GET_ORDERS_VIEW_REQUEST';
export const GET_ORDERS_VIEW_PENDING = 'GET_ORDERS_VIEW_PENDING';
export const GET_ORDERS_VIEW_FAIL = 'GET_ORDERS_VIEW_FAIL';
export const GET_ORDERS_VIEW_REJECTED = 'GET_ORDERS_VIEW_REJECTED';
export const GET_ORDERS_VIEW_SUCCESS = 'GET_ORDERS_VIEW_SUCCESS';
export const GET_ORDERS_VIEW_FULFILLED = 'GET_ORDERS_VIEW_FULFILLED';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_PENDING = 'GET_ORDER_PENDING';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FULFILLED = 'GET_ORDER_FULFILLED';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';
export const GET_ORDER_REJECTED = 'GET_ORDER_REJECTED';

export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_PENDING = 'ADD_ORDER_PENDING';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';
export const ADD_ORDER_REJECTED = 'ADD_ORDER_REJECTED';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FULFILLED = 'ADD_ORDER_FULFILLED';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_PENDING = 'UPDATE_ORDER_PENDING';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';
export const UPDATE_ORDER_REJECTED = 'UPDATE_ORDER_REJECTED';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FULFILLED = 'UPDATE_ORDER_FULFILLED';

export const GET_ORDERS_COUNT = 'GET_ORDERS_COUNT';
export const GET_ORDERS_COUNT_REQUEST = 'GET_ORDERS_COUNT_REQUEST';
export const GET_ORDERS_COUNT_PENDING = 'GET_ORDERS_COUNT_PENDING';
export const GET_ORDERS_COUNT_FAIL = 'GET_ORDERS_COUNT_FAIL';
export const GET_ORDERS_COUNT_REJECTED = 'GET_ORDERS_COUNT_REJECTED';
export const GET_ORDERS_COUNT_SUCCESS = 'GET_ORDERS_COUNT_SUCCESS';
export const GET_ORDERS_COUNT_FULFILLED = 'GET_ORDERS_COUNT_FULFILLED';
