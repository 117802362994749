export const validateFields = async (values) => {
  let errors = {};
  if (!values.password) {
    errors.password = "Password Required";
    errors.notifyFormErrors = "Password Required";
  } else if (!values.confirmpassword) {
    errors.confirmpassword = "Password Confirmation Required";
    errors.notifyFormErrors = "Password Confirmation Required";
  } else if (values.password.length < 5) {
    errors.password = "Password Must be 6 characters or more";
    errors.notifyFormErrors = "Password Must be 6 characters or more";
  } else if (values.confirmpassword.length < 5) {
    errors.confirmpassword = "Confirm Password Must be 6 characters or more";
    errors.notifyFormErrors = "Confirm Password Must be 6 characters or more";
  } else if (values.password !== values.confirmpassword) {
    errors.confirmpassword = "Passwords do not match.";
    errors.notifyFormErrors = "Passwords do not match";
  }
  return errors;
};
export default validateFields;
