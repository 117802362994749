import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  /*** sign in page --- START */
  root: {
    background: 'linear-gradient(45deg, #4caf50 30%, #66bb6a 90%)', // green
    color: 'white'
  },
  /*** sign in page --- END */
  flexGrow: {
    flexGrow: 1
  },
  rightImg1: {
    maxWidth: '100%',
    height: 50,
    borderRadius: 8
  }
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary" // not sure what this changes may not be needed, may be text color come back to review on sign in page
      position="fixed">
      {/* sign in page top left graphic --- START */}
      <Toolbar />
      {/* sign in page top left graphic --- END */}
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
