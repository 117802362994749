import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  MenuItem
  // NativeSelect,
} from '@material-ui/core';
import { renderSelectField_Conditional_Options } from 'components/form-global-components/react-final-form-global-components';
/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingTruckProgramingType(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-72 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Truck Programming Type" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField_Conditional_Options}
                id="truckprogrammingtype"
                name="truckprogrammingtype"
                objName="truckprogrammingtype"
                label="Truck Programming Type"
                footerMessage=""
                onChangeMethod={props.changeTruckProgrammingTypeSelected}
                locked={props.locked}
              >

                {props.showDPPreProgramming === 2 ? (
                  <MenuItem value={0} disabled>
                    Select an Option
                  </MenuItem>
                ) : (
                  <MenuItem value={0} selected="SELECTED">
                    Select an Option
                  </MenuItem>
                )}



                {
   (             
                // props.showDPPreProgramming === 2 || props.showDPPreProgramming === 3 
                
                
                
                                  // // #5-A
                                  // (
                                  //   props.showDPPreProgramming === 2 && 
                                  //   (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                  //   (
                                  //       props.showDPTruckTelematicsBrand === 4  ||
                                  //       props.showDPTruckTelematicsBrand === 8 
                                  //   )
                                  // )
                                  // ||


                    /*** general rule of thumb - logic if telematics is no and diplay is a type of yes show this option */
                    ( 
                      (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                      props.showDPTelematics === 1 
                  )

              
  )
                ? (
                  <MenuItem value={1} selected="SELECTED">
                    Program Sensors to Display
                  </MenuItem>
                ) : (
                  <MenuItem value={1} disabled>
                    Program Sensors to Display
                  </MenuItem>
                )}



                {
                  (
//                     // #8
//                       (
//                         (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
//                         props.showDPTelematics === 2 &&
//                         props.showDPPreProgramming === 2 &&
//                         props.showDPTruckTelematicsBrand === 6 
//                       )
// ||                      

                      // #23-A - No display its a receiver / if it is a display its display
                      (
                        (props.DPDisplaySelection === 1) &&
                        props.showDPTelematics === 2 &&
                        props.showDPPreProgramming === 2 &&
                        props.showDPTruckTelematicsBrand === 6 
                      )

                  )
                  ? (
                    <MenuItem value={2}>
                      Program Sensors to Receiver
                    </MenuItem>
                  ) : (
                    <MenuItem value={2} disabled>
                      Program Sensors to Receiver
                    </MenuItem>
                  )
                }




                {
                  (
                    // #8
                      (
                        (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                        props.showDPTelematics === 2 &&
                        props.showDPPreProgramming === 2 &&
                        props.showDPTruckTelematicsBrand === 6 
                      )

                  )
                   ? (
                    <MenuItem value={3}>
                      Program Sensors to Display and Receiver
                    </MenuItem>
                  ) : (
                    <MenuItem value={3} disabled>
                      Program Sensors to Display and Receiver
                    </MenuItem>
                  )
                }





                {
                  (

                    // #9
                    (
                      // props.DPDisplaySelection === 1 &&
                      props.showDPPreProgramming === 2 && 
                      (
                          props.showDPTruckTelematicsBrand === 4  ||
                          props.showDPTruckTelematicsBrand === 8 
                      )
                    )
                    // ||


                  ) ? (
                    <MenuItem value={4}>
                      Activate Gateway Only - Customer Will Program Sensors
                    </MenuItem>
                  ) : (
                    <MenuItem value={4} disabled>
                      Activate Gateway Only - Customer Will Program Sensors
                    </MenuItem>
                  )
                }





                {
                  (


                                // #10
                                (
                                  (props.DPDisplaySelection === 1) &&
                                  props.showDPTelematics === 2 &&
                                  props.showDPPreProgramming === 2 &&
                                  (
                                      props.showDPTruckTelematicsBrand === 4 ||
                                      props.showDPTruckTelematicsBrand === 5 ||
                                      props.showDPTruckTelematicsBrand === 8 ||
                                      props.showDPTruckTelematicsBrand === 9 
                                  ) 
                              )
                              // ||
              

                      ) ? (
                        <MenuItem value={5}>
                          Activate Gateway and Program Sensors to Gateway
                        </MenuItem>
                      ) : (
                        <MenuItem value={5} disabled>
                          Activate Gateway and Program Sensors to Gateway
                        </MenuItem>
                      )
                    }




                    {
                      (

                    // props.showDPPreProgramming === 2 
                    // ||

                  //   // #3
                  //   ( 
                  //     // (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                  //     (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                  //     props.showDPPreProgramming === 2 // && 
                  //     // (props.TruckProgrammingTypeSelected === 1 && props.TruckProgrammingTypeSelected === 2)
                  // )
                  // ||


                  // #5-A
                  (
                    props.showDPPreProgramming === 2 && 
                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                    (
                        props.showDPTruckTelematicsBrand === 4  ||
                        props.showDPTruckTelematicsBrand === 8 
                    )
                  )
                  ||


                  // #9
                  (
                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                    props.showDPPreProgramming === 2 && 
                    (
                        props.showDPTruckTelematicsBrand === 4  ||
                        props.showDPTruckTelematicsBrand === 8 
                    )
                  )
                  // ||


                )
                ? (
                  <MenuItem value={6} selected="SELECTED">
                    Activate Gateway and Program Sensors to Gateway and Display
                  </MenuItem>
                ) : (
                  <MenuItem value={6} disabled>
                    Activate Gateway and Program Sensors to Gateway and Display
                  </MenuItem>
                )}

              </Field>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingTruckProgramingType;

/***
 * Required property 
 * locked=bool

 * changeTruckProgrammingTypeSelected=function
 * showDPPreProgramming=int

                                //  * DPDisplaySelection=int
                                //  * showDPTelematics=int
                                //  * showDPTruckTelematicsBrand=int

  <SRFDisplayProgrammingTruckProgramingType
    locked={true / false}
    changeTruckProgrammingTypeSelected=(props.changeTruckProgrammingTypeSelected)
    showDPPreProgramming={1, 2, 3, or 4}

                                // DPDisplaySelection={1, 2, 3, or 4}
                                // showDPTelematics={1, 2, 3, or 4}
                                // showDPTruckTelematicsBrand={1, 2, 3, or 4}

  />
  
*/
