import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useParams
} from 'react-router-dom';
/*** signed in user */
import { CONTEXT_USER } from 'App';
import { getOrder, updateOrder } from 'actions/orders-Actions';
import { ORDER_FORM_EDIT } from 'constants/action-constants';
import OrderForm from 'components/order-form/order-form';
import SpinnerLoading from 'components/spinner-loading/spinner-loading';

// function OrdersEdit(props) {
const OrdersEdit = (props) => {
  /*** SIGNED IN USER DETAILS */
  const CONST_CONTEXT_USER = useContext(CONTEXT_USER);

  /*** this is the old container --- start */
  const dispatch = useDispatch();
  const orderEntry = useSelector((state) => state.orders);
  // const { loading, error, title, order } = orderEntry;
  const { order } = orderEntry;
  /*** this is the old container --- end */
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- start */
  let { id } = useParams();
  useEffect(() => {
    dispatch(getOrder(id));
  }, [dispatch, id]);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */
  /*** replaces history page push --- start  */
  // const history = useHistory()
  // let navigate = useNavigate();
  /*** replaces history page push --- end  */

  const handleSubmitSaveAndClose = (values) => {
    // console.log('4432-11-22 - STEP 3 - handleSubmitSaveAndClose - values = ', values);
    // console.log('4432-11-33 - STEP 3 - handleSubmitSaveAndClose - values.values = ', values.values);
    // console.log('4432-33-44 - STEP 3 - handleSubmitSaveAndClose - props = ', props);

    var params = {};
    params = {
      // psirepresentative: CONST_CONTEXT_USER.id,
      psirepresentative: values.psirepresentative,
      tempid: values.tempid,
      orderdate: values.orderdate,
      jobnamepo: values.jobnamepo,
      fleetname: values.fleetname,
      hardwarebillingtype: values.hardwarebillingtype,
      usage: values.usage,
      vehicletype: values.vehicletype,
      quantity: values.quantity,
      ordercomments: values.ordercomments,

      trucksensorsonsteeraxle: values.trucksensorsonsteeraxle,
      trucksensortypeaxle1: values.trucksensortypeaxle1,
      trucksensorsondriveaxle: values.trucksensorsondriveaxle,
      trucktireconfiguration234: values.trucktireconfiguration234,
      truckaxleconfiguration: values.truckaxleconfiguration,
      truckdriveaxlewheelsize: values.truckdriveaxlewheelsize,
      truckdriveaxlewheelmaterial: values.truckdriveaxlewheelmaterial,
      truckliftaxle: values.truckliftaxle,
      trucksensortypeaxle234: values.trucksensortypeaxle234,
      truckflowthrukit: values.truckflowthrukit,
      truckhubtype: values.truckhubtype,
      truckhubtypeother: values.truckhubtypeother,
      truckspecialtysensors: values.truckspecialtysensors,
      trucksparetire: values.trucksparetire,

      trailersensorsontraileraxle: values.trailersensorsontraileraxle,
      trailertireconfiguration: values.trailertireconfiguration,
      traileraxleconfiguration: values.traileraxleconfiguration,
      trailerdriveaxlewheelsize: values.trailerdriveaxlewheelsize,
      trailerdriveaxlewheelmaterial: values.trailerdriveaxlewheelmaterial,
      trailerliftaxle: values.trailerliftaxle,
      trailersensortypeaxle: values.trailersensortypeaxle,
      traileradapttoatis: values.traileradapttoatis,
      traileridentifybrand: values.traileridentifybrand,
      traileridentifybrandotherspecify: values.traileridentifybrandotherspecify,
      trailerneedtosupplycphoses: values.trailerneedtosupplycphoses,
      trailerflowthrukit: values.trailerflowthrukit,
      trailerhubtype: values.trailerhubtype,
      trailerhubtypeother: values.trailerhubtypeother,
      trailerspecialtysensors: values.trailerspecialtysensors,
      trailersparetire: values.trailersparetire,
      trailerdigitalthermalert: values.trailerdigitalthermalert,
      trailertankpressure: values.trailertankpressure,
      trailercontrolboxoutput: values.trailercontrolboxoutput,
      trailercontrolboxonoff: values.trailercontrolboxonoff,

      dpdisplayselection: values.dpdisplayselection,
      dptelematics: values.dptelematics,
      trucktelematicsbrand: values.trucktelematicsbrand,
      trailertelematicsbrand: values.trailertelematicsbrand,
      dppreprogramming: values.dppreprogramming,
      steertirecoldpressure: values.steertirecoldpressure,
      steertirealarmsettingsdefaultlow: values.steertirealarmsettingsdefaultlow,
      steertirealarmsettingsdefaultlowadjustvalue:
        values.steertirealarmsettingsdefaultlowadjustvalue,
      steertirealarmsettingsdefaulthigh:
        values.steertirealarmsettingsdefaulthigh,
      steertirealarmsettingsdefaulthighadjustvalue:
        values.steertirealarmsettingsdefaulthighadjustvalue,
      steertirealarmsettingsdefaulthightemp:
        values.steertirealarmsettingsdefaulthightemp,
      steertirealarmsettingsdefaulthightempadjustvalue:
        values.steertirealarmsettingsdefaulthightempadjustvalue,
      drivetirecoldpressure: values.drivetirecoldpressure,
      drivetirealarmsettingsdefaultlow: values.drivetirealarmsettingsdefaultlow,
      drivetirealarmsettingsdefaultlowadjustvalue:
        values.drivetirealarmsettingsdefaultlowadjustvalue,
      drivetirealarmsettingsdefaulthigh:
        values.drivetirealarmsettingsdefaulthigh,
      drivetirealarmsettingsdefaulthighadjustvalue:
        values.drivetirealarmsettingsdefaulthighadjustvalue,
      drivetirealarmsettingsdefaulthightemp:
        values.drivetirealarmsettingsdefaulthightemp,
      drivetirealarmsettingsdefaulthightempadjustvalue:
        values.drivetirealarmsettingsdefaulthightempadjustvalue,
      trailertirecoldpressure: values.trailertirecoldpressure,
      trailertirealarmsettingsdefaultlow:
        values.trailertirealarmsettingsdefaultlow,
      trailertirealarmsettingsdefaultlowadjustvalue:
        values.trailertirealarmsettingsdefaultlowadjustvalue,
      trailertirealarmsettingsdefaulthigh:
        values.trailertirealarmsettingsdefaulthigh,
      trailertirealarmsettingsdefaulthighadjustvalue:
        values.trailertirealarmsettingsdefaulthighadjustvalue,
      trailertirealarmsettingsdefaulthightemp:
        values.trailertirealarmsettingsdefaulthightemp,
      trailertirealarmsettingsdefaulthightempadjustvalue:
        values.trailertirealarmsettingsdefaulthightempadjustvalue,

      trailerliftaxletirecoldpressure: values.trailerliftaxletirecoldpressure,
      trailerliftaxletirealarmsettingsdefaultlow:
        values.trailerliftaxletirealarmsettingsdefaultlow,
      trailerliftaxletirealarmsettingsdefaultlowadjustvalue:
        values.trailerliftaxletirealarmsettingsdefaultlowadjustvalue,
      trailerliftaxletirealarmsettingsdefaulthigh:
        values.trailerliftaxletirealarmsettingsdefaulthigh,
      trailerliftaxletirealarmsettingsdefaulthighadjustvalue:
        values.trailerliftaxletirealarmsettingsdefaulthighadjustvalue,

      trailerspindletype: values.trailerspindletype,
      trailerspindletypeother: values.trailerspindletypeother,

      pressureunits: values.pressureunits,
      temperatureunits: values.temperatureunits,

      truckprogrammingtype: values.truckprogrammingtype,
      trailerprogrammingtype: values.trailerprogrammingtype,

      dpdisplaypowersource: values.dpdisplaypowersource,

      dptruckdatabilling: values.dptruckdatabilling,
      dptruckdatabillingterm: values.dptruckdatabillingterm,
      dptruckdatabillingrate: values.dptruckdatabillingrate,
      dptruckcustsatexpire: values.dptruckcustsatexpire,
      dptruckexpireswhen: values.dptruckexpireswhen,
      dptruckafterexpiration: values.dptruckafterexpiration,

      dptrailerdatabilling: values.dptrailerdatabilling,
      dptrailerdatabillingterm: values.dptrailerdatabillingterm,
      dptrailerdatabillingrate: values.dptrailerdatabillingrate,
      dptrailercustsatexpire: values.dptrailercustsatexpire,
      dptrailerexpireswhen: values.dptrailerexpireswhen,
      dptrailerafterexpiration: values.dptrailerafterexpiration,

      dptrailerfirstreportafterdisconnected:
        values.dptrailerfirstreportafterdisconnected,
      dptrailerongoingreportintervalafterfirstreport:
        values.dptrailerongoingreportintervalafterfirstreport,

      truckCustomerAlerts: values.truckCustomerAlerts,
      trailerCustomerAlerts: values.trailerCustomerAlerts,
    };
    console.log('4444-22-22 - EDIT - handleSubmitSaveAndClose - params = ', params);

    dispatch(updateOrder(id, params)).then(() => props.history.push('/orders/'));
    // dispatch(updateOrder(id, params)).then( console.log('done') );
  };

  // console.log('4444-ee-44 - props = ', props);
  // console.log('4444-ee-55 - orderEntry = ', orderEntry);
  // console.log('4444-ee-66 - order = ', order); // use this to see field details

  return (
    <>
    {order ?
        <OrderForm
          formMode={2}
          currentUserID={CONST_CONTEXT_USER.id}
          tempID={order.tempid}
          initialValues={order}
          onSubmit={handleSubmitSaveAndClose}
          FormStateToRedux={ORDER_FORM_EDIT}
        />
      : <SpinnerLoading /> }
    </>
  );
}

export default OrdersEdit;
