import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
  // NativeSelect,
} from '@material-ui/core';
import { renderSelectField } from 'components/form-global-components/react-final-form-global-components';
import {
  FIRST_REPORT_AFTER_DISCONNECTED,
  ON_GOING_REPORT_INTERVAL_AFTER_FIRST_REPORT
} from 'constants/action-constants';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingBatteryBackupReportingSchedule(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-14 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Battery Backup Reporting Schedule" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="dptrailerfirstreportafterdisconnected"
                name="dptrailerfirstreportafterdisconnected"
                objName="dptrailerfirstreportafterdisconnected"
                label="First Report After Disconnected"
                footerMessage=""
                dataArray={FIRST_REPORT_AFTER_DISCONNECTED}
                requireOnChangeMethod={false}
                locked={props.locked}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="dptrailerongoingreportintervalafterfirstreport"
                name="dptrailerongoingreportintervalafterfirstreport"
                objName="dptrailerongoingreportintervalafterfirstreport"
                label="Ongoing Report Interval After First Report"
                footerMessage=""
                dataArray={ON_GOING_REPORT_INTERVAL_AFTER_FIRST_REPORT}
                requireOnChangeMethod={false}
                locked={props.locked}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingBatteryBackupReportingSchedule;

/***
 * Required property 
 * locked=bool

 * DPTrailerTrailerDataBillingSelected=int
 * changeDPTrailerDataBillingSelected=function
 * DPTrailerCustSatExpireSelected=int
 * changeDPTrailerCustSatExpireSelected=function
 * DPTrailerAfterExpirationSelected=int
 * changeDPTrailerAfterExpirationSelected=function

  <SRFDisplayProgrammingBatteryBackupReportingSchedule
    locked={true / false}
    
    DPTrailerDataBillingSelected={1, 2, 3, or 4}
    changeDPTrailerDataBillingSelected={props.changeDPTrailerDataBillingSelected}
    DPTrailerCustSatExpireSelected={1, 2, 3, or 4}
    changeDPTrailerCustSatExpireSelected={props.changeDPTrailerCustSatExpireSelected}
    DPTrailerAfterExpirationSelected={1, 2, 3, or 4}
    changeDPTrailerAfterExpirationSelected={props.changeDPTrailerAfterExpirationSelected}
  />

*/
