import React from 'react';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
function ReceiversQCReceiptEntry(props) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>
                  {props.receiver.receivercount}
                  <b>{' - Sales Order Number : Receiver #: '}</b>
                  {props.receiver.salesorderreceiverid}
                  <b>{' - Config Type: '}</b>
                  {props.receiver.configtype}
                  <b>{' - QC Operator: '}</b>
                  {props.receiver.qcoperator}
                  <b>{' - Date: '}</b>
                  {
                    props.receiver.programmingdate 
                    + '-' +
                    props.receiver.programmingtime
                  }
                  <b>{' - SYSID: '}</b>
                  {props.receiver.id}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Table size="small">
          <TableRow>
            <TableCell>
              <p>
                <Typography>Details</Typography>
              </p>
              <TableRow>
                <TableRow component="th" scope="row">
                  All Vehicles High Temp={props.receiver.allvehicleshightemp}
                </TableRow>

                <TableRow component="th" scope="row">
                  &nbsp;
                </TableRow>

                <TableRow component="th" scope="row">
                  Axle 1 High Pressure={props.receiver.axle1highpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 1 Low Pressure={props.receiver.axle1lowpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 2 High Pressure={props.receiver.axle2highpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 2 Low Pressure={props.receiver.axle2lowpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 3 High Pressure={props.receiver.axle3highpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 3 Low Pressure={props.receiver.axle3lowpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 4 High Pressure={props.receiver.axle4highpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  Axle 4 Low Pressure={props.receiver.axle4lowpressure}
                </TableRow>

                <TableRow component="th" scope="row">
                  &nbsp;
                </TableRow>

                <TableRow component="th" scope="row">
                  All Trailers Axles High Pressure=
                  {props.receiver.alltrailersaxleshighpressure}
                </TableRow>
                <TableRow component="th" scope="row">
                  All Trailers Axles Low Pressure=
                  {props.receiver.alltrailersaxleslowpressure}
                </TableRow>
              </TableRow>
            </TableCell>
            <TableCell>
              <p>
                <Typography>Tractor 1</Typography>
              </p>
              <TableRow>
                <TableRow component="th" scope="row">
                  01={props.receiver.tractor1_01}
                </TableRow>
                <TableRow component="th" scope="row">
                  02={props.receiver.tractor1_02}
                </TableRow>
                <TableRow component="th" scope="row">
                  03={props.receiver.tractor1_03}
                </TableRow>
                <TableRow component="th" scope="row">
                  04={props.receiver.tractor1_04}
                </TableRow>
                <TableRow component="th" scope="row">
                  05={props.receiver.tractor1_05}
                </TableRow>
                <TableRow component="th" scope="row">
                  06={props.receiver.tractor1_06}
                </TableRow>
                <TableRow component="th" scope="row">
                  07={props.receiver.tractor1_07}
                </TableRow>
                <TableRow component="th" scope="row">
                  08={props.receiver.tractor1_08}
                </TableRow>
                <TableRow component="th" scope="row">
                  09={props.receiver.tractor1_09}
                </TableRow>

                <TableRow component="th" scope="row">
                  10={props.receiver.tractor1_10}
                </TableRow>
                <TableRow component="th" scope="row">
                  11={props.receiver.tractor1_11}
                </TableRow>
                <TableRow component="th" scope="row">
                  12={props.receiver.tractor1_12}
                </TableRow>
                <TableRow component="th" scope="row">
                  13={props.receiver.tractor1_13}
                </TableRow>
                <TableRow component="th" scope="row">
                  14={props.receiver.tractor1_14}
                </TableRow>
                <TableRow component="th" scope="row">
                  15={props.receiver.tractor1_15}
                </TableRow>
                <TableRow component="th" scope="row">
                  16={props.receiver.tractor1_16}
                </TableRow>
                <TableRow component="th" scope="row">
                  17={props.receiver.tractor1_17}
                </TableRow>
                <TableRow component="th" scope="row">
                  18={props.receiver.tractor1_18}
                </TableRow>
                <TableRow component="th" scope="row">
                  19={props.receiver.tractor1_19}
                </TableRow>

                <TableRow component="th" scope="row">
                  20={props.receiver.tractor1_20}
                </TableRow>
                <TableRow component="th" scope="row">
                  21={props.receiver.tractor1_21}
                </TableRow>
                <TableRow component="th" scope="row">
                  22={props.receiver.tractor1_22}
                </TableRow>
                <TableRow component="th" scope="row">
                  23={props.receiver.tractor1_23}
                </TableRow>
                <TableRow component="th" scope="row">
                  24={props.receiver.tractor1_24}
                </TableRow>
              </TableRow>
            </TableCell>
            <TableCell>
              <p>
                <Typography>Trailer 1</Typography>
              </p>
              <TableRow>
                <TableRow component="th" scope="row">
                  01={props.receiver.trailer1_01}
                </TableRow>
                <TableRow component="th" scope="row">
                  02={props.receiver.trailer1_02}
                </TableRow>
                <TableRow component="th" scope="row">
                  03={props.receiver.trailer1_03}
                </TableRow>
                <TableRow component="th" scope="row">
                  04={props.receiver.trailer1_04}
                </TableRow>
                <TableRow component="th" scope="row">
                  05={props.receiver.trailer1_05}
                </TableRow>
                <TableRow component="th" scope="row">
                  06={props.receiver.trailer1_06}
                </TableRow>
                <TableRow component="th" scope="row">
                  07={props.receiver.trailer1_07}
                </TableRow>
                <TableRow component="th" scope="row">
                  08={props.receiver.trailer1_08}
                </TableRow>
                <TableRow component="th" scope="row">
                  09={props.receiver.trailer1_09}
                </TableRow>

                <TableRow component="th" scope="row">
                  10={props.receiver.trailer1_10}
                </TableRow>
                <TableRow component="th" scope="row">
                  11={props.receiver.trailer1_11}
                </TableRow>
                <TableRow component="th" scope="row">
                  12={props.receiver.trailer1_12}
                </TableRow>
                <TableRow component="th" scope="row">
                  13={props.receiver.trailer1_13}
                </TableRow>
                <TableRow component="th" scope="row">
                  14={props.receiver.trailer1_14}
                </TableRow>
                <TableRow component="th" scope="row">
                  15={props.receiver.trailer1_15}
                </TableRow>
                <TableRow component="th" scope="row">
                  16={props.receiver.trailer1_16}
                </TableRow>
                <TableRow component="th" scope="row">
                  17={props.receiver.trailer1_17}
                </TableRow>
                <TableRow component="th" scope="row">
                  18={props.receiver.trailer1_18}
                </TableRow>
                <TableRow component="th" scope="row">
                  19={props.receiver.trailer1_19}
                </TableRow>

                <TableRow component="th" scope="row">
                  20={props.receiver.trailer1_20}
                </TableRow>
                <TableRow component="th" scope="row">
                  21={props.receiver.trailer1_21}
                </TableRow>
                <TableRow component="th" scope="row">
                  22={props.receiver.trailer1_22}
                </TableRow>
                <TableRow component="th" scope="row">
                  23={props.receiver.trailer1_23}
                </TableRow>
                <TableRow component="th" scope="row">
                  24={props.receiver.trailer1_24}
                </TableRow>
              </TableRow>
            </TableCell>
            <TableCell>
              <p>
                <Typography>Trailer 2</Typography>
              </p>
              <TableRow>
                <TableRow component="th" scope="row">
                  01={props.receiver.trailer2_01}
                </TableRow>
                <TableRow component="th" scope="row">
                  02={props.receiver.trailer2_02}
                </TableRow>
                <TableRow component="th" scope="row">
                  03={props.receiver.trailer2_03}
                </TableRow>
                <TableRow component="th" scope="row">
                  04={props.receiver.trailer2_04}
                </TableRow>
                <TableRow component="th" scope="row">
                  05={props.receiver.trailer2_05}
                </TableRow>
                <TableRow component="th" scope="row">
                  06={props.receiver.trailer2_06}
                </TableRow>
                <TableRow component="th" scope="row">
                  07={props.receiver.trailer2_07}
                </TableRow>
                <TableRow component="th" scope="row">
                  08={props.receiver.trailer2_08}
                </TableRow>
                <TableRow component="th" scope="row">
                  09={props.receiver.trailer2_09}
                </TableRow>

                <TableRow component="th" scope="row">
                  10={props.receiver.trailer2_10}
                </TableRow>
                <TableRow component="th" scope="row">
                  11={props.receiver.trailer2_11}
                </TableRow>
                <TableRow component="th" scope="row">
                  12={props.receiver.trailer2_12}
                </TableRow>
                <TableRow component="th" scope="row">
                  13={props.receiver.trailer2_13}
                </TableRow>
                <TableRow component="th" scope="row">
                  14={props.receiver.trailer2_14}
                </TableRow>
                <TableRow component="th" scope="row">
                  15={props.receiver.trailer2_15}
                </TableRow>
                <TableRow component="th" scope="row">
                  16={props.receiver.trailer2_16}
                </TableRow>
                <TableRow component="th" scope="row">
                  17={props.receiver.trailer2_17}
                </TableRow>
                <TableRow component="th" scope="row">
                  18={props.receiver.trailer2_18}
                </TableRow>
                <TableRow component="th" scope="row">
                  19={props.receiver.trailer2_19}
                </TableRow>

                <TableRow component="th" scope="row">
                  20={props.receiver.trailer2_20}
                </TableRow>
                <TableRow component="th" scope="row">
                  21={props.receiver.trailer2_21}
                </TableRow>
                <TableRow component="th" scope="row">
                  22={props.receiver.trailer2_22}
                </TableRow>
                <TableRow component="th" scope="row">
                  23={props.receiver.trailer2_23}
                </TableRow>
                <TableRow component="th" scope="row">
                  24={props.receiver.trailer2_24}
                </TableRow>
              </TableRow>
            </TableCell>
            <TableCell>
              <p>
                <Typography>Trailer 3</Typography>
              </p>
              <TableRow>
                <TableRow component="th" scope="row">
                  01={props.receiver.trailer3_01}
                </TableRow>
                <TableRow component="th" scope="row">
                  02={props.receiver.trailer3_02}
                </TableRow>
                <TableRow component="th" scope="row">
                  03={props.receiver.trailer3_03}
                </TableRow>
                <TableRow component="th" scope="row">
                  04={props.receiver.trailer3_04}
                </TableRow>
                <TableRow component="th" scope="row">
                  05={props.receiver.trailer3_05}
                </TableRow>
                <TableRow component="th" scope="row">
                  06={props.receiver.trailer3_06}
                </TableRow>
                <TableRow component="th" scope="row">
                  07={props.receiver.trailer3_07}
                </TableRow>
                <TableRow component="th" scope="row">
                  08={props.receiver.trailer3_08}
                </TableRow>
                <TableRow component="th" scope="row">
                  09={props.receiver.trailer3_09}
                </TableRow>

                <TableRow component="th" scope="row">
                  10={props.receiver.trailer3_10}
                </TableRow>
                <TableRow component="th" scope="row">
                  11={props.receiver.trailer3_11}
                </TableRow>
                <TableRow component="th" scope="row">
                  12={props.receiver.trailer3_12}
                </TableRow>
                <TableRow component="th" scope="row">
                  13={props.receiver.trailer3_13}
                </TableRow>
                <TableRow component="th" scope="row">
                  14={props.receiver.trailer3_14}
                </TableRow>
                <TableRow component="th" scope="row">
                  15={props.receiver.trailer3_15}
                </TableRow>
                <TableRow component="th" scope="row">
                  16={props.receiver.trailer3_16}
                </TableRow>
                <TableRow component="th" scope="row">
                  17={props.receiver.trailer3_17}
                </TableRow>
                <TableRow component="th" scope="row">
                  18={props.receiver.trailer3_18}
                </TableRow>
                <TableRow component="th" scope="row">
                  19={props.receiver.trailer3_19}
                </TableRow>

                <TableRow component="th" scope="row">
                  20={props.receiver.trailer3_20}
                </TableRow>
                <TableRow component="th" scope="row">
                  21={props.receiver.trailer3_21}
                </TableRow>
                <TableRow component="th" scope="row">
                  22={props.receiver.trailer3_22}
                </TableRow>
                <TableRow component="th" scope="row">
                  23={props.receiver.trailer3_23}
                </TableRow>
                <TableRow component="th" scope="row">
                  24={props.receiver.trailer3_24}
                </TableRow>
              </TableRow>
            </TableCell>
            <TableCell>
              <p>
                <Typography>Trailer 4</Typography>
              </p>
              <TableRow>
                <TableRow component="th" scope="row">
                  01={props.receiver.trailer4_01}
                </TableRow>
                <TableRow component="th" scope="row">
                  02={props.receiver.trailer4_02}
                </TableRow>
                <TableRow component="th" scope="row">
                  03={props.receiver.trailer4_03}
                </TableRow>
                <TableRow component="th" scope="row">
                  04={props.receiver.trailer4_04}
                </TableRow>
                <TableRow component="th" scope="row">
                  05={props.receiver.trailer4_05}
                </TableRow>
                <TableRow component="th" scope="row">
                  06={props.receiver.trailer4_06}
                </TableRow>
                <TableRow component="th" scope="row">
                  07={props.receiver.trailer4_07}
                </TableRow>
                <TableRow component="th" scope="row">
                  08={props.receiver.trailer4_08}
                </TableRow>
                <TableRow component="th" scope="row">
                  09={props.receiver.trailer4_09}
                </TableRow>

                <TableRow component="th" scope="row">
                  10={props.receiver.trailer4_10}
                </TableRow>
                <TableRow component="th" scope="row">
                  11={props.receiver.trailer4_11}
                </TableRow>
                <TableRow component="th" scope="row">
                  12={props.receiver.trailer4_12}
                </TableRow>
                <TableRow component="th" scope="row">
                  13={props.receiver.trailer4_13}
                </TableRow>
                <TableRow component="th" scope="row">
                  14={props.receiver.trailer4_14}
                </TableRow>
                <TableRow component="th" scope="row">
                  15={props.receiver.trailer4_15}
                </TableRow>
                <TableRow component="th" scope="row">
                  16={props.receiver.trailer4_16}
                </TableRow>
                <TableRow component="th" scope="row">
                  17={props.receiver.trailer4_17}
                </TableRow>
                <TableRow component="th" scope="row">
                  18={props.receiver.trailer4_18}
                </TableRow>
                <TableRow component="th" scope="row">
                  19={props.receiver.trailer4_19}
                </TableRow>

                <TableRow component="th" scope="row">
                  20={props.receiver.trailer4_20}
                </TableRow>
                <TableRow component="th" scope="row">
                  21={props.receiver.trailer4_21}
                </TableRow>
                <TableRow component="th" scope="row">
                  22={props.receiver.trailer4_22}
                </TableRow>
                <TableRow component="th" scope="row">
                  23={props.receiver.trailer4_23}
                </TableRow>
                <TableRow component="th" scope="row">
                  24={props.receiver.trailer4_24}
                </TableRow>
              </TableRow>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
}
export default ReceiversQCReceiptEntry;
