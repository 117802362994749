import {
  GET_RECEIVERS_REQUEST,
  GET_RECEIVERS_PENDING,
  GET_RECEIVERS_FAIL,
  GET_RECEIVERS_REJECTED,
  GET_RECEIVERS_SUCCESS,
  GET_RECEIVERS_FULFILLED,

  GET_RECEIVERSQC_REQUEST,
  GET_RECEIVERSQC_PENDING,
  GET_RECEIVERSQC_FAIL,
  GET_RECEIVERSQC_REJECTED,
  GET_RECEIVERSQC_SUCCESS,
  GET_RECEIVERSQC_FULFILLED
} from 'constants/action-constants';
const initialState = {
  errors: [],
  loading: true,
  title: 'Receivers QC',
  receiversqcs: [],
  receivers: []
};
const receiversQCReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIVERS_REQUEST: // new
    case GET_RECEIVERS_PENDING: // old
      return {
        loading: true,
        title: 'Querying Receivers...',
        // receiversqcs: [],
        receivers: []
      };
    case GET_RECEIVERSQC_REQUEST: // new
    case GET_RECEIVERSQC_PENDING: // old
      return {
        loading: true,
        title: 'Receivers QC - Querying...',
        receiversqcs: []
        // receivers: [],
      };
    case GET_RECEIVERS_FAIL: // new
    case GET_RECEIVERS_REJECTED: // old
    case GET_RECEIVERSQC_FAIL: // new
    case GET_RECEIVERSQC_REJECTED: // old
      return {
        loading: false,
        error: action.payload,
        receiversqcs: [],
        receivers: [],
        title: 'Receivers QC - Error...',
        errors: [action.payload.response]
      };
    case GET_RECEIVERSQC_SUCCESS: // new
    case GET_RECEIVERSQC_FULFILLED: // old
      var dataEntry =
        typeof action.payload.receiversqc !== 'undefined' &&
        action.payload.receiversqc !== null
          ? action.payload.receiversqc
          : [];
      return {
        loading: false,
        title: 'Receivers QC',
        receiversqcs: dataEntry,
        receivers: []
      };
    case GET_RECEIVERS_SUCCESS: // new
    case GET_RECEIVERS_FULFILLED: // old
      var dataEntry =
        typeof action.payload.receivers !== 'undefined' &&
        action.payload.receivers !== null
          ? action.payload.receivers
          : [];
      return {
        loading: false,
        title: 'Receivers Receipt',
        receivers: dataEntry
      };
    default:
      return state;
  }
};
export default receiversQCReducer;
