import {
  CHANGE_PASSWORD_FULFILLED,
  SIGN_IN_REQUEST,
  SIGN_IN_PENDING,
  SIGN_IN_SUCCESS,
  SIGN_IN_FULFILLED,
  SIGN_IN_FAIL,
  SIGN_IN_REJECTED,
  SIGN_OUT_DELETE_USER_N_TOKEN,
  SIGN_IN_SET_USER,

  // CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  // CHANGE_PASSWORD_FAIL,

  REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS,
  REGISTER_LOGIN_AUTO_LOGIN_FAIL,
  REGISTER_LOGIN_AUTO_LOGIN_PENDING,
  REGISTER_LOGIN_AUTO_LOGIN_FULFILLED,
  REGISTER_LOGIN_AUTO_LOGIN_SUCCESS,
} from '../actions/action-constants'
const initialState = {
  errors: {},

  registerSuccess: false,
  errorInvalidEntry: false,

  isAuthenticating: false,
  isAuthenticated: false,
  creating: false,
  token: '',
  changePassword: '',
  user: {
    id: '',
    name: '',
    login: '',
    role: 0,
    roleDescription: '',
    isAuthenticated: false
  }
}
const reducerUser = (state = initialState, action) => {
  let error_status_code
  let error_message  

  switch (action.type) {
    case SIGN_OUT_DELETE_USER_N_TOKEN:
      state = {
        ...state,
        errors: {},
        isAuthenticating: false,
        isAuthenticated: false,
        user: {
          id: '',
          name: '',
          login: '',
          role: 0,
          roleDescription: '',
          isAuthenticated: false
        },
        token: ''
      }
      break
    case REGISTER_LOGIN_AUTO_LOGIN_PENDING:
      state = {
        ...state,
        isAuthenticating: true,
        creating: true
      }
      break
    case SIGN_IN_REQUEST:
    case SIGN_IN_PENDING:
      state = {
        ...state,
        isAuthenticating: true
      }
      break
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FULFILLED:
      state = {
        ...state,
        changePassword: 'Password Changed'
      }
      break
    case SIGN_IN_SUCCESS:
    case REGISTER_LOGIN_AUTO_LOGIN_SUCCESS:
    case REGISTER_LOGIN_AUTO_LOGIN_FULFILLED:
    case SIGN_IN_FULFILLED:
      // console.log('BBB-9393-01 = ' , action.payload);
      // console.log('BBB-9393-02 = ' , action.payload.data);
      var dataEntry_Token
      var dataEntry_isAuthenticated
      var dataEntry_User
      var dataErrors = {}
      if (
        typeof action.payload.data !== 'undefined' &&
        action.payload.data !== null
      ) {
        if (
          typeof action.payload.data.message !== 'undefined' &&
          action.payload.data.message !== null
        ) {
          dataEntry_isAuthenticated = false
          dataEntry_Token = 0
        } else {
          dataEntry_isAuthenticated = true
          dataEntry_Token = action.payload.data.token
        }
      }
      if (
        typeof action.payload.data !== 'undefined' &&
        action.payload.data !== null
      ) {
        if (
          typeof action.payload.data.message !== 'undefined' &&
          action.payload.data.message !== null
        ) {
          dataErrors = {
            statusCode: 401,
            message: 'Full Filled Undefined Error'
          }
          dataEntry_isAuthenticated = false
          dataEntry_User = {
            id: '',
            name: '',
            login: '',
            role: 0,
            roleDescription: '',
            isAuthenticated: false
          }
        } else {
          // console.log('CCC-83-71 = ' , action.payload.data);
          // console.log('CCC-83-72 = ' , action.payload.data.user);
          dataErrors = {}
          dataEntry_isAuthenticated = true
          dataEntry_User = {
            id: action.payload.data.user.id,
            name: action.payload.data.user.name,
            login: action.payload.data.user.login,
            role: action.payload.data.user.role,
            roleDescription: action.payload.data.user.roledescription,
            isAuthenticated: true
          }
        }
      }
      state = {
        ...state,
        isAuthenticating: false,
        isAuthenticated: dataEntry_isAuthenticated,
        user: dataEntry_User,
        token: dataEntry_Token,
        errors: dataErrors
      }
      break
    case SIGN_IN_SET_USER:
      state = {
        ...state,
        errors: {},
        isAuthenticating: false,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token
      }
      break
    case REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS:
    case REGISTER_LOGIN_AUTO_LOGIN_FAIL:
      if (typeof action.payload.response !== 'undefined') {
        error_status_code = action.payload.status
        error_message = action.payload.data.message
      } else {
        error_status_code = 500
        // error_message = 'Data Server Appears To Be Offline'
        error_message = 'Invalid Login: Login ID Exists'
      }
      state = {
        ...state,
        isAuthenticating: false,
        errorInvalidEntry: true,
        errors: {
          status: error_status_code,
          message: error_message
        }
      };
      break;
    case SIGN_IN_FAIL:
    case SIGN_IN_REJECTED:
      if ( action.payload === 'Invalid Login or Password') {
        error_status_code = 401
        error_message = action.payload
      } else if (typeof action.payload.response !== 'undefined') {
        error_status_code = action.payload.response.status
        error_message = action.payload.response.data.message
      } else {
        error_status_code = 500
        error_message = 'Data Server Appears To Be Offline'
      }
      state = {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        user: {
          id: '',
          name: '',
          login: '',
          role: 0,
          roleDescription: '',
          isAuthenticated: false
        },
        token: '',
        errors: {
          statusCode: error_status_code,
          message: error_message
        }
      }
      break
    // case REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS:
    //   state = {
    //     ...state,
    //     isAuthenticating: false,
    //     isAuthenticated: false,
    //     user: {
    //       id: '',
    //       name: '',
    //       login: '',
    //       role: 0,
    //       roleDescription: '',
    //       isAuthenticated: false
    //     },
    //     token: '',
    //     errors: {
    //       statusCode: action.payload.response.status,
    //       message: action.payload.response.data.message
    //     }
    //   }
    //   break
    default:
      return state
  }

  return state
}

export default reducerUser





/*
                                                                                            // localStorage.removeItem('psitoken');
                                                                                            //     delete axios.defaults.headers.common['Authorization'];

                                                                                            // localStorage.setItem('psitoken', action.payload.data.token)
                                                                                            //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload.data.token;

                                                                                                    // if (token) {
                                                                                                    //     // axios.defaults.baseURL = 'https://api.example.com';
                                                                                                    //     // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                                                                                                    //     // axios.defaults.headers.common['Authorization'] = 'Bearer ${token}';
                                                                                                    //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                                                                                                    //     // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };
                                                                                                    // } else {
                                                                                                    //     delete axios.defaults.headers.common['Authorization'];
                                                                                                    //     // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };
                                                                                                    // }
                                                                                                

                                                                                                    // if (data.message) {
                                                                                                    //     // Here you should have logic to handle invalid creation of a user.
                                                                                                    //     // This assumes your Rails API will return a JSON object with a key of
                                                                                                    //     // 'message' if there is an error with creating the user, i.e. invalid username
                                                                                                    //   } else {
                                                                                                    //     localStorage.setItem("psitoken", data.jwt)
                                                                                                    //     dispatch(loginUser(data.user))
                                                                                                    //   }
*/
