import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Snackbar, SnackbarContent } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
const variantIconError = {
  error: ErrorIcon
};
const useStyleError = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center'
  }
}));
function SnackbarContent8(props) {
  const classes = useStyleError();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIconError[variant];
  const [state] = React.useState({
    open: true,
    vertical: 'bottom',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom', // fixed issues blocking the screen
        horizontal: 'center'
      }}
      open={open}
      className={clsx(classes[variant], className)}
      key={`${vertical},${horizontal}`}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        style={{
          backgroundColor: 'red'
        }}
        aria-describedby="snackbar-error"
        message={
          <span id="snackbar-error" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {props.message}
          </span>
        }
        {...other}
      />
    </Snackbar>
  );
}
SnackbarContent8.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error']).isRequired
};
export default SnackbarContent8;
