import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_PENDING,
  GET_ORDERS_FAIL,
  GET_ORDERS_REJECTED,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FULFILLED,

  GET_ORDERS_COUNT_REQUEST,
  GET_ORDERS_COUNT_PENDING,
  GET_ORDERS_COUNT_FAIL,
  GET_ORDERS_COUNT_REJECTED,
  GET_ORDERS_COUNT_SUCCESS,
  GET_ORDERS_COUNT_FULFILLED,

  GET_ORDERS_VIEW_REQUEST,
  GET_ORDERS_VIEW_PENDING,
  GET_ORDERS_VIEW_FAIL,
  GET_ORDERS_VIEW_REJECTED,
  GET_ORDERS_VIEW_SUCCESS,
  GET_ORDERS_VIEW_FULFILLED,

  GET_ORDER_REQUEST,
  GET_ORDER_PENDING,
  GET_ORDER_FAIL,
  GET_ORDER_REJECTED,
  GET_ORDER_SUCCESS,
  GET_ORDER_FULFILLED,

  // /*** put this back in the new orders form when the edit and view are ready --- start */
  // GET_ORDERS_VIEW_SUCCESS,
  // GET_ORDERS_VIEW_FULFILLED,
  // /*** put this back in the new orders form when the edit and view are ready --- end */

  ADD_ORDER_REQUEST,
  ADD_ORDER_PENDING,
  ADD_ORDER_FAIL,
  ADD_ORDER_REJECTED,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FULFILLED,


  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_PENDING,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REJECTED,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FULFILLED,


} from 'constants/action-constants'
const initialState = {
  error: {},
  errors: [],
  loading: true,
  title: 'Orders',
  orders: [],
  orderCount: 0,
}
const ordersReducer = (state = initialState, action) => {
  var dataEntry;
  switch (action.type) {
    case ADD_ORDER_REQUEST: // new
    case ADD_ORDER_PENDING: // OLD
    case UPDATE_ORDER_REQUEST: // new
    case UPDATE_ORDER_PENDING: // OLD
    case GET_ORDERS_COUNT_REQUEST: // new
    case GET_ORDERS_COUNT_PENDING: // OLD
    case GET_ORDERS_VIEW_REQUEST: // new
    case GET_ORDERS_VIEW_PENDING: // OLD
    case GET_ORDER_REQUEST: // new
    case GET_ORDER_PENDING: // OLD
    case GET_ORDERS_REQUEST: // new
    case GET_ORDERS_PENDING: // old
      return {
        loading: true,
        orderCount: -1,
        title: 'Orders - Querying...',
      }
    case ADD_ORDER_FAIL: // new
    case ADD_ORDER_REJECTED: // old
    case UPDATE_ORDER_FAIL: // new
    case UPDATE_ORDER_REJECTED: // old
    case GET_ORDERS_COUNT_FAIL: // new
    case GET_ORDERS_COUNT_REJECTED: // old
    case GET_ORDERS_VIEW_FAIL: // new
    case GET_ORDERS_VIEW_REJECTED: // old
    case GET_ORDER_FAIL: // new
    case GET_ORDER_REJECTED: // old
    case GET_ORDERS_FAIL: // new
    case GET_ORDERS_REJECTED: // old
      return {
        loading: false,
        error: action.payload,
        orders: [],
        title: 'Orders - Error...',
        errors: [action.payload.response]
      }


    case UPDATE_ORDER_SUCCESS:
    case UPDATE_ORDER_FULFILLED:
    case ADD_ORDER_SUCCESS:
    case ADD_ORDER_FULFILLED:
        // console.log('AAAA - 4432 - 0007 - action payload = ', );
    
        return {
          loading: false,
          // addSuccess: true,
          // dataList: [],
          // orders: [],
      }
  
      
    case GET_ORDERS_VIEW_SUCCESS:
    case GET_ORDERS_VIEW_FULFILLED:
      dataEntry = (typeof(action.payload.orders[0]) !== "undefined" && action.payload.orders[0] !== null ) ? action.payload.orders[0] : {} ;
      return {
        loading: false,
        returnedEntry: dataEntry,
        order: dataEntry,
      }

    case GET_ORDERS_COUNT_SUCCESS:
    case GET_ORDERS_COUNT_FULFILLED:
        let count = (typeof(action.payload.orderscount) !== "undefined" && action.payload.orderscount !== null ) ? action.payload.orderscount : -1 ;
        return {
          loading: false,
          orderCount: count,
          title: 'Orders',
        }
        
    case GET_ORDER_SUCCESS:
    case GET_ORDER_FULFILLED:
        dataEntry = (typeof(action.payload.orders) !== "undefined" && action.payload.orders !== null ) ? action.payload.orders : {} ;
        return {
          loading: false,
          returnedEntry: dataEntry,
          order: dataEntry,
        }
          
    // /*** put this back in the new orders form when the edit and view are ready --- start */
    // case GET_ORDERS_VIEW_SUCCESS: // new
    // case GET_ORDERS_VIEW_FULFILLED: // old
    //   // console.log('AAAA - 4432 - 0004 - action payload = ', action.payload );
    //   // console.log('AAAA - 4432 - 0005 - action payload.orders = ', action.payload.orders );
    //   dataEntry =
    //     typeof action.payload.orders !== 'undefined' &&
    //     action.payload.orders !== null
    //       ? action.payload.orders
    //       : []
    //   //   var dataEntry =
    //   //     typeof action.payload.qcdata !== 'undefined' &&
    //   //     action.payload.qcdata !== null
    //   //       ? action.payload.qcdata
    //   //       : []
    //   return {
    //     loading: false,
    //     title: 'Orders',
    //     orders: dataEntry
    //   }
    // /*** put this back in the new orders form when the edit and view are ready --- end */
    case GET_ORDERS_SUCCESS: // new
    case GET_ORDERS_FULFILLED: // old
      // console.log('4432 - 0002 - action payload = ', action.payload );
      // console.log('4432 - 0003 - action payload.orders = ', action.payload.orders );
      dataEntry =
        typeof action.payload.orders !== 'undefined' &&
        action.payload.orders !== null
          ? action.payload.orders
          : []
      //   var dataEntry =
      //     typeof action.payload.qcdata !== 'undefined' &&
      //     action.payload.qcdata !== null
      //       ? action.payload.qcdata
      //       : []
      return {
        loading: false,
        title: 'Orders',
        orders: dataEntry
      }
    default:
      return state
  }
}
export default ordersReducer
