import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));
const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">404</Typography>
            <Typography variant="subtitle2">
              Page Not Found: The page you are looking for does not exist yet.
            </Typography>
            <img
              alt="Page Not Found: The page you are looking for does not exist yet."
              className={classes.image}
              src={'/images/404-page-not-found/404pagenotfound2.jpg'}
              // src={process.env.PUBLIC_URL + '/images/404-page-not-found/404pagenotfound2.jpg'}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default NotFound;
