export const validateFields = async (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = 'Name Required';
    errors.notifyFormErrors = 'Name Required';
  } else if (!values.login) {
    errors.login = 'Login Required';
    errors.notifyFormErrors = 'Login Required';
  }
  return errors;
};
export default validateFields;

                                                                    // } else if (!values.password) {
                                                                    //   errors.password = 'Password Required';
                                                                    //   errors.notifyFormErrors = 'Password Required';
                                                                    //   throw errors;
                                                                    // } else if (!values.confirmpassword) {
                                                                    //   errors.confirmpassword = 'Password Confirmation Required';
                                                                    //   errors.notifyFormErrors = 'Password Confirmation Required';
                                                                    //   throw errors;
                                                                    // } else if (values.password.length < 5) {
                                                                    //   errors.password = 'Password Must be 6 characters or more';
                                                                    //   errors.notifyFormErrors = 'Password Must be 6 characters or more';
                                                                    //   throw errors;
                                                                    // } else if (values.confirmpassword.length < 5) {
                                                                    //   errors.confirmpassword = 'Confirm Password Must be 6 characters or more';
                                                                    //   errors.notifyFormErrors = 'Confirm Password Must be 6 characters or more';
                                                                    //   throw errors;
                                                                    // } else if (values.password !== values.confirmpassword ) {
                                                                    //   errors.confirmpassword = 'Passwords do not match.';
                                                                    //   errors.notifyFormErrors = 'Passwords do not match';
                                                                    //   throw errors;

                                                                    // if (!values.age) {
                                                                    //   errors.age = 'Required'
                                                                    // } else if (isNaN(values.age)) {
                                                                    //   errors.age = 'Must be a number'
                                                                    // } else if (values.age < 18) {
                                                                    //   errors.age = 'Must be >18'
                                                                    // }

                                                                    // }
                                                                    // return errors;
