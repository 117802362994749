import React from 'react';
import { Form } from 'react-final-form';
import { ButtonSaveAndClose } from 'theme';
import { Save } from '@material-ui/icons';
import { LinearProgress } from '@material-ui/core';
import arrayMutators from 'final-form-arrays'
import FormFields from '../../components/form-fields/form-fields';
import SpinnerLoading from '../../components/spinner-loading/spinner-loading';


/********** form calculations ***** START  ****/
import { calculator } from '../final-form-calculate-decorator/final-form-calculate-decorator';
/********** form calculations ***** END  ****/

import async_validateFields from './components/asyncValidate';
import FormStateToRedux from './Form-Redux-Requirements/FormStateToRedux';



                                  // import { renderSnackbarError } from 'components/form-global-components/react-final-form-global-components';

                                  // /*** NEED THIS FOR REDUX TO WORK --- END */
                                  // import FormStateFromRedux from './Form-Redux-Requirements/FormStateFromRedux'
                                  // /*** */ NEED THIS FOR REDUX TO WORK --- END */

                                  // const voidMethodPlaceholder = async (values) => {
                                  //   // console.log('4444-0091 onSubmit = ');
                                  //   // await sleep(300)
                                  //   // window.alert(JSON.stringify(values, 0, 2))
                                  // };

                                  // const dataChecker = async values => {
                                  //   // await sleep(300)
                                  //   console.log('440112 - ', JSON.stringify(values, 0, 2))
                                  //   window.alert(JSON.stringify(values, 0, 2))
                                  // }





const OrderForm = (props) => (
  <Form
    onSubmit={props.onSubmit}
    // onSubmit={dataChecker}
    /*** this fixes the typing issue using formSpy on the bottom --- START  */
    subscription={{ submitting: true, pristine: true }}
    // subscription={{ submitting: true, pristine: true, values: true }}
    // subscription={{ valid: true, }} // works
    /*** this fixes the typing issue using formSpy on the bottom --- END  */

    /*** intialValues From Reducer --  START */
    initialValues={props.initialValues}
    /*** intialValues From Reducer --  END */

    /********** form calculations ***** START  ****/
    decorators={[calculator]}
    /********** form calculations ***** END  ****/

    /********** validate form file --- START  ****/
    validate={async_validateFields}
    /********** validate form file --- END  ****/

    mutators={{
      ...arrayMutators

                                        /********** TEST - CHANGE A VALUE ON FORM COMPONENT ***** START  ****/
                                        // setAge: ([field, value], state, { changeValue }) => {
                                        //   // changeValue(state, 'age', () => setAge(field))
                                        //   changeValue(state, 'age', () => '9876');
                                        //   changeValue(state, 'dob', () => field);
                                        // }
                                        /********** TEST - CHANGE A VALUE ON FORM COMPONENT ***** END  ****/

    }}
  
    render={({
      /********** need this for mutator set Age --- START  ****/
      form: {
        mutators: { 
          push 
        }
      }, // injected from final-form-arrays above
      /********** need this for mutator set Age --- END  ****/

      error,
      errors,

      form,

      handleSubmit,
      submitting,
      pristine,
      reset,
      valid,
      values
      // ...other
    }) => (
      <form onSubmit={handleSubmit}>
        {/* NEED THIS FOR REDUX TO WORK --- START */}
        <FormStateToRedux form={props.FormStateToRedux} />
        {/* NEED THIS FOR REDUX TO WORK --- END */}

                                        {/* {  console.log('00 - error = ', error) } */}
                                        {/* {  console.log('110 - errors = ', errors) } */}
                                        {/* 
                                        <Field
                                          component={renderSnackbarError}
                                          id='notifyFormErrors'
                                          name='notifyFormErrors'
                                        />
                                        */}

        <FormFields
          locked={props.formMode === 1 || props.formMode === 2 ? false : true}
          custPush={push}
          currentUserID={props.currentUserID}
          tempID={props.tempID}
          
                                          // /* mutator test use this for the default values on sensor check marks  --- START */
                                          // setAge={voidMethodPlaceholder} // old - form.mutators.setAge}
                                          // /* mutator test use this for the default values on sensor check marks  --- END */
        />

        {props.formMode === 1 || props.formMode === 2 ? (
          <>
            <br />
            <hr />
            <br />
            <ButtonSaveAndClose
              type="submit"
              size="large"
              fullWidth
              // disabled={submitting || pristine} >
              disabled={submitting}
            >
              <Save />
              &nbsp; Save &amp; Close
            </ButtonSaveAndClose>

            {submitting && (
              <LinearProgress
                variant="buffer"
                value={5}
                valueBuffer={10}
                color="secondary"
              />
            )}
            {submitting && <SpinnerLoading />}


            {/* {'0012 - props.initialValues = ' + JSON.stringify(props.initialValues) } */}


          </>
        ) : (
          ''
        )}

        {/* NEED THIS FOR REDUX TO WORK --- START */}
        {/* <h3>Form State from Redux</h3> */}
        {/* <FormStateFromRedux form={props.FormStateToRedux} /> */}
        {/* NEED THIS FOR REDUX TO WORK --- END */}
      </form>
    )}
  />
);

export default OrderForm;

/***
 * Required property 
 * locked=bool
 * formMode=int
 * initialValues=array
 * onSubmit=function

  <OrderForm 
    // locked={true / false}
    formMode={1, 2, or 3}
    initialValues={ {id: x,abc: 123} }
    onSubmit={onSubmitSelected}
  />
*/































                                          // updateOrderNFilename={voidMethodPlaceholder} // old - submitUpdateOrderNFilename}
                                          // saveSensor={voidMethodPlaceholder} // old - mySubmit_SaveSensor}
                                          

                                          // addToTruckCustomerAlertsList={voidMethodPlaceholder} // old - props.addToTruckCustomerAlertsList}
                                          // removeFromTruckCustomerAlertsList={voidMethodPlaceholder} // old - props.removeFromTruckCustomerAlertsList}
                                          // addToTrailerCustomerAlertsList={voidMethodPlaceholder} // old - props.addToTrailerCustomerAlertsList}
                                          // removeFromTrailerCustomerAlertsList={voidMethodPlaceholder} // old - props.removeFromTrailerCustomerAlertsList}




















// import { Form, Field } from "react-final-form";

// // RMWC Components
// import { Button, TextField } from "../UI-kit";

// // Styles
// import commonStyles from "../styles";

// const initialValues = { email: "", password: "" };

// const OrderForm2 = () => (

//   <Form
//     initialValues={initialValues}
//     validate={validate}
//     onSubmit={onSubmit}
//   >
//     {({ handleSubmit, submitting }) => (
//       <form onSubmit={handleSubmit} style={commonStyles.form}>
//         <Field
//           name="email"
//           render={({ input, meta }) => (
//             <TextField
//               {...input}
//               type="email"
//               error={meta.error}
//               touched={meta.touched}
//               label={"Email"}
//             />
//           )}
//         />
//         <Field
//           name="password"
//           render={({ input, meta }) => (
//             <TextField
//               {...input}
//               type="password"
//               error={meta.error}
//               touched={meta.touched}
//               label={"Pasword"}
//             />
//           )}
//         />
//         <Button disabled={submitting} label="Submit" type="submit" unelevated />
//       </form>
//     )}
//   </Form>
// );






// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const onSubmit = async values => {
//   await sleep(400);
//   window.alert(JSON.stringify(values, 0, 2));
// };

//   // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

//   /*** SIGNED IN USER DETAILS */
//   const CONST_CONTEXT_USER = useContext(CONTEXT_USER);







//   /*** keep this removed for testing --- start -- original save code */
//   const handleSubmitSaveAndClose = (values) => {
//     // console.log('4432-11-22 - STEP 3 - handleSubmitSaveAndClose - values = ', values);

//     // //  sleep(3000)
//     // // window.alert(JSON.stringify(values, 0, 2))

//     // console.log('4432-11-33 - STEP 3 - handleSubmitSaveAndClose - values.values = ', values.values);
//     // console.log('4432-33-44 - STEP 3 - handleSubmitSaveAndClose - props = ', props);

//     var params = {};
//     params = {
//       psirepresentative: CONST_CONTEXT_USER.id,
//       orderdate: values.orderdate,
//       jobnamepo: values.jobnamepo,
//       fleetname: values.fleetname,
//       hardwarebillingtype: values.hardwarebillingtype,
//       usage: values.usage,
//       vehicletype: values.vehicletype,
//       quantity: values.quantity,
//       ordercomments: values.ordercomments,

//       trucksensorsonsteeraxle: values.trucksensorsonsteeraxle,
//       trucksensortypeaxle1: values.trucksensortypeaxle1,
//       trucksensorsondriveaxle: values.trucksensorsondriveaxle,
//       trucktireconfiguration234: values.trucktireconfiguration234,
//       truckaxleconfiguration: values.truckaxleconfiguration,
//       truckdriveaxlewheelsize: values.truckdriveaxlewheelsize,
//       truckdriveaxlewheelmaterial: values.truckdriveaxlewheelmaterial,
//       truckliftaxle: values.truckliftaxle,
//       trucksensortypeaxle234: values.trucksensortypeaxle234,
//       truckflowthrukit: values.truckflowthrukit,
//       truckhubtype: values.truckhubtype,
//       truckhubtypeother: values.truckhubtypeother,
//       truckspecialtysensors: values.truckspecialtysensors,
//       trucksparetire: values.trucksparetire,

//       trailersensorsontraileraxle: values.trailersensorsontraileraxle,
//       trailertireconfiguration: values.trailertireconfiguration,
//       traileraxleconfiguration: values.traileraxleconfiguration,
//       trailerdriveaxlewheelsize: values.trailerdriveaxlewheelsize,
//       trailerdriveaxlewheelmaterial: values.trailerdriveaxlewheelmaterial,
//       trailerliftaxle: values.trailerliftaxle,
//       trailersensortypeaxle: values.trailersensortypeaxle,
//       traileradapttoatis: values.traileradapttoatis,
//       traileridentifybrand: values.traileridentifybrand,
//       traileridentifybrandotherspecify: values.traileridentifybrandotherspecify,
//       trailerneedtosupplycphoses: values.trailerneedtosupplycphoses,
//       trailerflowthrukit: values.trailerflowthrukit,
//       trailerhubtype: values.trailerhubtype,
//       trailerhubtypeother: values.trailerhubtypeother,
//       trailerspecialtysensors: values.trailerspecialtysensors,
//       trailersparetire: values.trailersparetire,
//       trailerdigitalthermalert: values.trailerdigitalthermalert,
//       trailertankpressure: values.trailertankpressure,
//       trailercontrolboxoutput: values.trailercontrolboxoutput,
//       trailercontrolboxonoff: values.trailercontrolboxonoff,

//       dpdisplayselection: values.dpdisplayselection,
//       dptelematics: values.dptelematics,
//       trucktelematicsbrand: values.trucktelematicsbrand,
//       trailertelematicsbrand: values.trailertelematicsbrand,
//       dppreprogramming: values.dppreprogramming,
//       steertirecoldpressure: values.steertirecoldpressure,
//       steertirealarmsettingsdefaultlow: values.steertirealarmsettingsdefaultlow,
//       steertirealarmsettingsdefaultlowadjustvalue:
//         values.steertirealarmsettingsdefaultlowadjustvalue,
//       steertirealarmsettingsdefaulthigh:
//         values.steertirealarmsettingsdefaulthigh,
//       steertirealarmsettingsdefaulthighadjustvalue:
//         values.steertirealarmsettingsdefaulthighadjustvalue,
//       steertirealarmsettingsdefaulthightemp:
//         values.steertirealarmsettingsdefaulthightemp,
//       steertirealarmsettingsdefaulthightempadjustvalue:
//         values.steertirealarmsettingsdefaulthightempadjustvalue,
//       drivetirecoldpressure: values.drivetirecoldpressure,
//       drivetirealarmsettingsdefaultlow: values.drivetirealarmsettingsdefaultlow,
//       drivetirealarmsettingsdefaultlowadjustvalue:
//         values.drivetirealarmsettingsdefaultlowadjustvalue,
//       drivetirealarmsettingsdefaulthigh:
//         values.drivetirealarmsettingsdefaulthigh,
//       drivetirealarmsettingsdefaulthighadjustvalue:
//         values.drivetirealarmsettingsdefaulthighadjustvalue,
//       drivetirealarmsettingsdefaulthightemp:
//         values.drivetirealarmsettingsdefaulthightemp,
//       drivetirealarmsettingsdefaulthightempadjustvalue:
//         values.drivetirealarmsettingsdefaulthightempadjustvalue,
//       trailertirecoldpressure: values.trailertirecoldpressure,
//       trailertirealarmsettingsdefaultlow:
//         values.trailertirealarmsettingsdefaultlow,
//       trailertirealarmsettingsdefaultlowadjustvalue:
//         values.trailertirealarmsettingsdefaultlowadjustvalue,
//       trailertirealarmsettingsdefaulthigh:
//         values.trailertirealarmsettingsdefaulthigh,
//       trailertirealarmsettingsdefaulthighadjustvalue:
//         values.trailertirealarmsettingsdefaulthighadjustvalue,
//       trailertirealarmsettingsdefaulthightemp:
//         values.trailertirealarmsettingsdefaulthightemp,
//       trailertirealarmsettingsdefaulthightempadjustvalue:
//         values.trailertirealarmsettingsdefaulthightempadjustvalue,

//       trailerliftaxletirecoldpressure: values.trailerliftaxletirecoldpressure,
//       trailerliftaxletirealarmsettingsdefaultlow: values.trailerliftaxletirealarmsettingsdefaultlow,
//       trailerliftaxletirealarmsettingsdefaultlowadjustvalue: values.trailerliftaxletirealarmsettingsdefaultlowadjustvalue,
//       trailerliftaxletirealarmsettingsdefaulthigh: values.trailerliftaxletirealarmsettingsdefaulthigh,
//       trailerliftaxletirealarmsettingsdefaulthighadjustvalue: values.trailerliftaxletirealarmsettingsdefaulthighadjustvalue,

//       trailerspindletype: values.trailerspindletype,
//       trailerspindletypeother: values.trailerspindletypeother,

//       pressureunits: values.pressureunits,
//       temperatureunits: values.temperatureunits,

//       truckprogrammingtype: values.truckprogrammingtype,
//       trailerprogrammingtype: values.trailerprogrammingtype,

//       dpdisplaypowersource: values.dpdisplaypowersource,

//       dptruckdatabilling: values.dptruckdatabilling,
//       dptruckdatabillingterm: values.dptruckdatabillingterm,
//       dptruckdatabillingrate: values.dptruckdatabillingrate,
//       dptruckcustsatexpire: values.dptruckcustsatexpire,
//       dptruckexpireswhen: values.dptruckexpireswhen,
//       dptruckafterexpiration: values.dptruckafterexpiration,

//       dptrailerdatabilling: values.dptrailerdatabilling,
//       dptrailerdatabillingterm: values.dptrailerdatabillingterm,
//       dptrailerdatabillingrate: values.dptrailerdatabillingrate,
//       dptrailercustsatexpire: values.dptrailercustsatexpire,
//       dptrailerexpireswhen: values.dptrailerexpireswhen,
//       dptrailerafterexpiration: values.dptrailerafterexpiration,

//       dptrailerfirstreportafterdisconnected: values.dptrailerfirstreportafterdisconnected,
//       dptrailerongoingreportintervalafterfirstreport: values.dptrailerongoingreportintervalafterfirstreport,

//       // truckCustomerAlerts: values.truckCustomerAlerts,
//       // trailerCustomerAlerts: values.trailerCustomerAlerts,

//     };
//     // console.log('4444-11-11 - ADD - handleSubmitSaveAndClose - params = ', params);

//     dispatch(addOrder(params)).then(() =>
//       props.history.push('/orders/')
//     );
//   };
//   /*** keep this removed for testing --- end -- original save code */









// const validate = values => {
//   let errors = {};
//   if (!values.email) {
//     errors.email = "Email is required";
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
//     errors.email = "Invalid email address";
//   }
//   if (!values.password) {
//     errors.password = "Password is required";
//   }
//   return errors;
// };
