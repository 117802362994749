import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { ButtonSaveAndClose, ButtonClearForm } from 'theme';
import Save from '@material-ui/icons/Save';
import asyncValidate from './asyncValidate';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderSnackbarError,
  renderTextField_PASSWORD
} from 'components/form-global-components/react-final-form-global-components';
import { changePassword } from '../../actions/actions-auth';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));
function PasswordReset(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmitSaveAndClose = (values) => {
    var params = {
      password: values.password
    };
    dispatch(changePassword(props.currentUser.id, params)).then(() =>
      props.history.push('/sign-out/')
    );
  };
  return (
    /*** react final form wrapper */
    <Form
      onSubmit={handleSubmitSaveAndClose}
      initialValues={{
        password: '',
        confirmpassword: ''
      }}
      validate={asyncValidate}
      render={({
        form,
        handleSubmit,
        pristine,
        reset,
        submitting,
        valid,
        values,
        ...other
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Change Password" />
            <Divider />
            <CardContent>
              <Field
                component={renderSnackbarError}
                id="notifyFormErrors"
                name="notifyFormErrors"
              />

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderTextField_PASSWORD}
                    id="password"
                    name="password"
                    // objName="password" // not required for final-form
                    label="Password"
                    placeholder="Password Must be 6 characters or more"
                    footerMessage="Password Must be 6 characters or more"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={renderTextField_PASSWORD}
                    id="confirmpassword"
                    name="confirmpassword"
                    // objName="confirmpassword" // not required for final-form
                    label="Confirm Password"
                    placeholder="Password Must be 6 characters or more"
                    footerMessage="Password Must be 6 characters or more"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <ButtonSaveAndClose
                size="large"
                type="submit"
                disabled={!valid || submitting}>
                <Save />
                &nbsp; Save
              </ButtonSaveAndClose>
              <ButtonClearForm
                size="large"
                type="button"
                disabled={pristine || submitting}
                onClick={form.reset}>
                Clear Values
              </ButtonClearForm>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
}
export default PasswordReset;
