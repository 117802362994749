import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderSelectField,
  renderTextField,
  renderTextField_DATE
} from 'components/form-global-components/react-final-form-global-components';
import {
  DATA_BILLING,
  DATA_BILLING_TERM,
  OPTION_AFTER_EXPIRATION,
  OPTION_NO_YES
} from 'constants/action-constants';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingDataBillingTrailer(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-17 - props = ', props);

  const renderBillingTermRate = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              component={renderSelectField}
              id="dptrailerdatabillingterm"
              name="dptrailerdatabillingterm"
              objName="dptrailerdatabillingterm"
              label="Billing Term"
              footerMessage=""
              dataArray={DATA_BILLING_TERM}
              requireOnChangeMethod={false}
              locked={props.locked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              component={renderTextField}
              id="dptrailerdatabillingrate"
              name="dptrailerdatabillingrate"
              // objName="dptrailerdatabillingrate"
              label="Billing Rate"
              emptyText="Billing Rate"
              footerMessage=""
              // requiredText={true}
              locked={props.locked}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return <>{renderBillingTermRate()}</>;
      case 2:
        return (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={renderSelectField}
                  id="dptrailercustsatexpire"
                  name="dptrailercustsatexpire"
                  objName="dptrailercustsatexpire"
                  label="Does Customer Sat expire"
                  footerMessage=""
                  dataArray={OPTION_NO_YES}
                  onChangeMethod={props.changeDPTrailerCustSatExpireSelected}
                  locked={props.locked}
                />
              </Grid>
            </Grid>

            {props.DPTrailerCustSatExpireSelected === 2 ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={renderTextField_DATE}
                      id="dptrailerexpireswhen"
                      name="dptrailerexpireswhen"
                      label="Expires When"
                      emptyText="Expires When"
                      footerMessage="Invalid Date"
                      // requiredText={true}
                      locked={props.locked}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={renderSelectField}
                      id="dptrailerafterexpiration"
                      name="dptrailerafterexpiration"
                      objName="dptrailerafterexpiration"
                      label="What happens after expiration"
                      footerMessage=""
                      dataArray={OPTION_AFTER_EXPIRATION}
                      onChangeMethod={
                        props.changeDPTrailerAfterExpirationSelected
                      }
                      locked={props.locked}
                    />
                  </Grid>
                </Grid>

                {props.DPTrailerAfterExpirationSelected === 2 ? (
                  <>{renderBillingTermRate()}</>
                ) : null}
              </>
            ) : null}
          </>
        );
      case 3:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Trailer Data Billing" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="dptrailerdatabilling"
                name="dptrailerdatabilling"
                objName="dptrailerdatabilling"
                label="Trailer Data Billing"
                footerMessage=""
                dataArray={DATA_BILLING}
                onChangeMethod={props.changeDPTrailerDataBillingSelected}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          {renderSwitch(props.DPTrailerDataBillingSelected)}
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingDataBillingTrailer;

/***
 * Required property 
 * locked=bool

 * DPTrailerTrailerDataBillingSelected=int
 * changeDPTrailerDataBillingSelected=function
 * DPTrailerCustSatExpireSelected=int
 * changeDPTrailerCustSatExpireSelected=function
 * DPTrailerAfterExpirationSelected=int
 * changeDPTrailerAfterExpirationSelected=function

  <SRFDisplayProgrammingDataBillingTrailer
    locked={true / false}
    
    DPTrailerDataBillingSelected={1, 2, 3, or 4}
    changeDPTrailerDataBillingSelected={props.changeDPTrailerDataBillingSelected}
    DPTrailerCustSatExpireSelected={1, 2, 3, or 4}
    changeDPTrailerCustSatExpireSelected={props.changeDPTrailerCustSatExpireSelected}
    DPTrailerAfterExpirationSelected={1, 2, 3, or 4}
    changeDPTrailerAfterExpirationSelected={props.changeDPTrailerAfterExpirationSelected}
  />

*/
