import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import { DEFAULT_VIEW_LOGINROLES, DEFAULT_VIEW_LOGIN_ENABLED } from '../../actions/action-constants';
import LoginsToolbar from './Logins-Toolbar/logins-toolbar';
/*** this is the old container --- start */
import { getLoginsList } from 'components/module-login/actions/actions-logins';
// import { getLoginsList, deleteLogin } from 'components/module-login/actions/actions-logins';
import { changePassword } from 'components/module-login/actions/actions-auth'
/*** this is the old container --- end */
import SpinnerLoading from 'components/spinner-loading/spinner-loading';
import MessageBox from 'components/MessageBox/MessageBox';
function Logins(props) {
  const tableColumns = [
    { title: 'Enabled', field: 'loginenabled',
      lookup: DEFAULT_VIEW_LOGIN_ENABLED,
    },
    { title: 'Name', field: 'name' },
    { title: 'Login', field: 'login' },
    { title: 'Role', field: 'role',
      lookup: DEFAULT_VIEW_LOGINROLES,
    },
  ];
  /*** this is the old container --- start */
  const dispatch = useDispatch();
  const loginsList = useSelector(state => state.logins);
  const { loading, error, title, logins } = loginsList;
  /*** this is the old container --- end */
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- start */
  useEffect(() => {
    dispatch(getLoginsList());
  }, [dispatch]);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */
  return (
    <div>
      {loading ? (
        <SpinnerLoading />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ maxWidth: '100%' }}>
          <LoginsToolbar />
          <MaterialTable
            title={title}
            columns={tableColumns}
            data={logins}
            options={{
              // search
              search: true,
              // header grouping
              // grouping: true,
              // or              
              // column filtering
              // filtering: true,
              
              // pagination settings
              // show paging buttons
              // paging:false,
              // default items per page
              pageSize:10,
              // paging options
              pageSizeOptions:[10,25,50,100],

              // ???
              // columnsButton:false,
              // move buttons to right side of list
              // actionsColumnIndex: -1, 

              // styling otions
              rowStyle:{height:"20px"},
              maxBodyHeight: 700,
              
              // CSV export
              exportButton: true,
              // default filename
              exportFileName: 'Logins',
              // comma delimited
              // exportDelimiter: ',', 
            }}
            /*** Custom Toolbar */
            components={{
              Toolbar: props => (
                  <div style={{
                    background: 'linear-gradient(45deg, #fff176 10%, #fff 70%)', // blue / fade light blue
                    color: 'white',
                  }}>
                      <MTableToolbar {...props} />
                  </div>
              ),
            }}

            editable={{
              // onRowDelete: oldData =>
              //   new Promise((resolve, reject) => {
              //     dispatch(deleteLogin(oldData.id)).then(() => dispatch(getLoginsList()));
              //         const dataUpdate = [...data];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setData([...dataUpdate]);
              //         resolve();
              // }),
            }}

            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit Login',
                onClick: (event, rowData) => {
                  props.history.push('/logins/' + rowData.loginid)
                },
              },
              {
                icon: 'clear',
                tooltip: 'Reset Password',
                onClick: (event, rowData) => {
                  var params = {
                    'password': process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD
                  };
                  dispatch(changePassword(rowData.id, params));
                  alert('Password Reset to default password - ' + process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD );
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}
export default Logins;
