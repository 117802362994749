/***
 * App theme settings

  app typography theme
  mui default theme
  button theme
*/
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
// import overrides from './overrides';

/*** v4 */
import { withStyles } from '@material-ui/core/styles'; // v4
import { 
  createMuiTheme,
  Button,
  // colors,
} from '@material-ui/core'; // v4
/*** v4 */


// /*** v5 */
// import { Button, createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles'; // v5
// /*** v5 */

const theme = createMuiTheme({
  palette,
  shadows,
  typography,
  // overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});


// /*** v5 */
// const useStyles = makeStyles((theme) => {
//   root: {
//     // some CSS that access to theme
//   }
// });
// /*** v5 */


export const ButtonAddLogin = withStyles(theme => ({
  root: {
    color: '#8bc34a',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#7684ff',
      backgroundColor: '#fffad5',
    },
  },
}))(Button);

export const ButtonSave = withStyles(theme => ({
  root: {
    color: '#2196f3',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#90caf9',
    },
  },
}))(Button);

export const ButtonSignIn = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: '#8bc34a',
    '&:hover': {
      color: '#c5e1a5',
      backgroundColor: '#fff',
    },
  },
}))(Button);

export const ButtonSaveAndClose = withStyles(theme => ({
  root: {
    color: '#8bc34a',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#c5e1a5',
    },
  },
}))(Button);

export const ButtonRegister = withStyles(theme => ({
  root: {
    color: '#8bc34a',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#c5e1a5',
    },
  },
}))(Button);

export const ButtonListEdit = withStyles(theme => ({
  root: {
    color: '#8bc34a',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#c5e1a5',
    },
  },
}))(Button);

export const ButtonClearForm = withStyles(theme => ({
  root: {
    color: '#ff5722',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff5722',
    },
  },
}))(Button);

export const ButtonChangeProgram = withStyles(theme => ({
  root: {
    color: '#ff5722',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff5722',
    },
  },
}))(Button);

export default theme;