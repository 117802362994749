import {
  GET_FLTEST_REQUEST,
  GET_FLTEST_PENDING,
  GET_FLTEST_FAIL,
  GET_FLTEST_REJECTED,
  GET_FLTEST_SUCCESS,
  GET_FLTEST_FULFILLED
} from '../../constants/action-constants'
const initialState = {
  errors: [],
  error: {},
  loading: true,
  title: 'FLTest',
  fltests: []
}
const fltestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLTEST_REQUEST: // new
    case GET_FLTEST_PENDING: // old
      return {
        loading: true,
        title: 'FLTest - Querying...'
      }
    case GET_FLTEST_FAIL: // new
    case GET_FLTEST_REJECTED: // old
      console.log('4432 - 0074 - action payload = ', action );
      console.log('4432 - 0075 - action payload = ', action.payload );
      return {
        loading: false,
        // error: " - dd - ",
        error: action.payload,
        fltests: [],
        title: 'FLTest - Error...',
        // errors: []
        // errors: [action.payload]
        // errors: [action.payload.response]
      }
    case GET_FLTEST_SUCCESS: // new
    case GET_FLTEST_FULFILLED: // old
      console.log('4432 - 0072 - action payload = ', action.payload );
      // console.log('4432 - 0073 - action payload.fltest = ', action.payload.fltest );
      // var dataEntry =
      //   typeof action.payload.fltest !== 'undefined' &&
      //   action.payload.fltest !== null
      //     ? action.payload.fltest
      //     : []
      // //   var dataEntry =
      // //     typeof action.payload.qcdata !== 'undefined' &&
      // //     action.payload.qcdata !== null
      // //       ? action.payload.qcdata
      // //       : []
      return {
        loading: false,
        title: 'FLTest',
        // fltests: dataEntry
        fltests: [],

        error: action.payload,
        errors: []

      }
    default:
      return state
  }
}
export default fltestReducer
