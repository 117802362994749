import React from 'react';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import { ButtonSaveAndClose } from 'theme';
import asyncValidate from './asyncValidate';
import Save from '@material-ui/icons/Save';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import {
  renderCheckbox,
  renderSelectField,
  renderSnackbarError,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';
const useStyles = makeStyles((theme) => ({
  error500TextColor: {
    color: '#F44336',
    padding: theme.spacing(1, 2)
  }
}));

/*** SPECIFIC TO THIS APP --- START */
/*** SPECIFIC TO THIS APP --- END */

function LoginForm(props) {
  const classes = useStyles();

  const handleSubmitSaveAndClose = (values) => {
    let loginName = values.login.replace(/\s/g, '');
    loginName = loginName.toLowerCase();
    if (props.componentMode === 0) {
      var params = {};
      params = {
        name: values.name,
        login: loginName,
        role: values.role,
        loginenabled: 1,
        password: process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD,
      };
      props.saveEntry(params);
    } else {
      params = {
        name: values.name,
        login: loginName,
        role: values.role,
        loginenabled: values.loginenabled ? 1 : 0
      };
      props.saveEntry(values.id, params);
    }
  };
  return (
    /*** react final form wrapper */
    <Form
      subscription={{ initialValues: true }}
      onSubmit={handleSubmitSaveAndClose}
      initialValues={props.initialValues}
      validate={asyncValidate}
      render={({
        handleSubmit,
        submitting,
        pristine,
        reset,
        valid,
        values,
        ...other
      }) => (
        <form
          onSubmit={handleSubmit}
                                              // onSubmit={event => {
                                              //   handleSubmit(event).then(reset);
                                              // }}
        >
          <Card>
            <CardHeader title="Login Form" />
            <Divider />
            <CardContent>
              <Field
                component={renderSnackbarError}
                id="notifyFormErrors"
                name="notifyFormErrors"
              />

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderTextField}
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="First and Last Name"
                    footerMessage="First and Last Name"
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    locked={props.componentMode === 1 ? true : false}
                    component={renderTextField}
                    id="login"
                    name="login"
                    label="Login ID"
                    placeholder="Login ID"
                    footerMessage="Email address preferred"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="role"
                    name="role"
                    objName="role"
                    label="Login Role"
                    footerMessage=""
                    requireOnChangeMethod={false}
                    dataArray={props.loginroles.map((loginrole) => ({
                      value: loginrole.id,
                      label: loginrole.description
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {props.componentMode === 1 ? (
                    <>
                      <Field
                        component={renderCheckbox}
                        locked={props.locked}
                        id="loginenabled"
                        name="loginenabled"
                        objName="loginenabled"
                        label="Login Enabled"
                        footerMessage=""
                      />
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </CardContent>
            {props.errors.length > 0 ? (
              <>
                {props.errors[0].status === 501 ? (
                  <Typography
                    component="h1"
                    variant="h4"
                    className={classes.error500TextColor}>
                    {props.errors[0].data.message}
                    <br />
                  </Typography>
                ) : null}
              </>
            ) : null}
            <Divider />
            <CardActions>
              <ButtonSaveAndClose
                size="large"
                type="submit"
                disabled={submitting}
                                                      // valid doesnt work not needed as if asyncValidate errors the form automatically
                                                      // stops the handleSubmit from happening
                                                      // pristine has an issue since initial values loads before the data appears so the form
                                                      // will never bee pristine
                                                      // disabled={
                                                      //   props.componentMode === 0
                                                      //     ? !valid || submitting
                                                      //     : !valid || submitting || pristine
                                                      // }
              >
                <Save />
                &nbsp; Save
              </ButtonSaveAndClose>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
}

export default LoginForm;
