import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import { renderSelectField } from 'components/form-global-components/react-final-form-global-components';
import { PRESSURE_UNITS, TEMPERATURE_UNITS } from 'constants/action-constants';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDPProgrammingUnits(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-18 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Programming Units" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="pressureunits"
                name="pressureunits"
                objName="pressureunits"
                label="Pressure Units"
                footerMessage=""
                dataArray={PRESSURE_UNITS}
                requireOnChangeMethod={false}
                locked={props.locked}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="temperatureunits"
                name="temperatureunits"
                objName="temperatureunits"
                label="Temperature Units"
                footerMessage=""
                dataArray={TEMPERATURE_UNITS}
                requireOnChangeMethod={false}
                locked={props.locked}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDPProgrammingUnits;

/***
 * Required property 
 * locked=bool

  <SRFDPProgrammingUnits
    locked={true / false}

  />

*/
