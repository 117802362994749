import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import LoginForm from './components/login-form';
import { getLoginRolesList } from '../../actions/actions-loginroles';
import { addLogin, createNewLogin } from '../../actions/actions-logins';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));
const LoginAdd = (props) => {
  const classes = useStyles();
  /*** this is the old container --- start */
  const dispatch = useDispatch();
  const loginsList = useSelector((state) => state.logins);
  const loginrolesList = useSelector((state) => state.loginroles);
  const { errors, errorInvalidEntry, login } = loginsList;
  const { loginroles } = loginrolesList;
  /*** this is the old container --- end */
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- start */
  useEffect(() => {
    dispatch(getLoginRolesList());
    dispatch(createNewLogin());
  }, [dispatch]);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */

  const saveEntry = (params) => {
    dispatch(addLogin(params)).then(() => props.history.push('/logins/'));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LoginForm
            {...props}
            componentMode={0}
            initialValues={login}
            loginroles={loginroles}
            errors={errors}
            errorInvalidEntry={errorInvalidEntry}
            saveEntry={saveEntry}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default LoginAdd;
