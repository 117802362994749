import React from 'react';
function SpinnerLoading() {
  return (
    <div>
      {/* <CustomSpinner text={"aabbcc"} />   */}
      {/* <i className="fa fa-cog fa-spin" /> */}
      {/* <i className="fa fa-spinner fa-spin" /> */}
      <i className="fa fa-spinner fa-pulse" />
      &nbsp;&nbsp; Loading...
    </div>
  );
}
export default SpinnerLoading;
