import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import ChangeLogEntry from './components/change-log-entry';
import data from './data';
const ChangeLog = () => {
  const [changelog] = useState(data);
  return (
    <Card>
      <CardHeader title="Change Log" />
      <CardContent>
        {changelog.map((entry) => (
          <ChangeLogEntry
            bugfix={entry.bugfix}
            date={entry.date}
            version={entry.version}
            location={entry.location}
            title={entry.title}
            details={entry.details}
          />
        ))}
      </CardContent>
    </Card>
  );
};
export default ChangeLog;
