import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Button,
  colors,
  Divider,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <div key={page.key}>
          {page.title ? (
            <ListItem className={classes.item} disableGutters key={page.key}>
              {page.href ? (
                <Button
                  key={page.key}
                  // original --- start
                  // activeClassName={classes.active}
                  // className={classes.button}
                  // component={CustomRouterLink}
                  // to={page.href}
                  // original --- end

                  // activeClassName={page.externalLink ? undefined : classes.active}
                  activeclassname={
                    page.externalLink ? undefined : classes.active
                  }
                  className={classes.button}
                  component={page.externalLink ? undefined : CustomRouterLink}
                  target={page.externalLink ? "_blank" : ""}
                  href={page.externalLink ? page.href : ""}
                  to={page.externalLink ? "" : page.href}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              ) : (
                <>
                  <br />
                  {page.title}
                  <br />
                </>
              )}
            </ListItem>
          ) : (
            <Divider key={page.key} />
          )}
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
