import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: theme.spacing(1.5)
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 40,
    width: 40
  },
  icon: {
    height: 24,
    width: 24
  },
  difference: {
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));
const ChangeLogEntry = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={props.title} />
      <CardContent>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar className={props.bugfix === 1 ? classes.avatar : null}>
              {(() => {
                if (props.bugfix === 1) {
                  return <PlaylistAddCheck className={classes.icon} />;
                } else {
                  return <FormatListNumbered className={classes.icon} />;
                }
              })()}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="overline">{props.date}</Typography>
            <Typography variant="caption">
              {' - ' + props.location + ' - ' + props.version}
            </Typography>
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  {props.details}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ChangeLogEntry;
