import React from 'react';
import './receiversqc-toolbar.css';
          // import AddIcon from '@material-ui/icons/Add';
          // import { ButtonSaveAndClose } from 'theme';
const ReceiversQCToolbar = () => {
  return (
    <div className="toolbarRow">
      <span className="spacer" />
{/*
          <ButtonSaveAndClose 
            size='large'
            fullWidth
            href='/qualitycontrol1/add'
          >
            <AddIcon />
            Add QC
          </ButtonSaveAndClose> 
*/}
    </div>
  );
};
export default ReceiversQCToolbar;
