import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderSelectField,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';
import {
  OPTION_NO_YES,
  SENSOR_TYPE,
  TIRE_CONFIGURATION_SINGLE_DUAL,
  TRUCK_AXLE_CONFIGURATION,
  TRUCK_DRIVE_AXLE_WHEEL_MATERIAL,
  TRUCK_DRIVE_AXLE_WHEEL_SIZE,
  TRUCK_HUB_TYPE,
  TRUCK_SENSOR_TYPE_AXLE_1
} from 'constants/action-constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const SRFTruckSpecifications = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Truck Specifications" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="trucksensorsonsteeraxle"
                name="trucksensorsonsteeraxle"
                objName="trucksensorsonsteeraxle"
                label="SENSORS ON STEER AXLE (AXLE POSITION 1)"
                footerMessage=""
                dataArray={OPTION_NO_YES}
                onChangeMethod={props.changeTruckSensorsOnSteerAxle}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          {props.truckSensorsOnSteerAxleSelected === 2 ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trucksensortypeaxle1"
                    name="trucksensortypeaxle1"
                    objName="trucksensortypeaxle1"
                    label="SENSOR TYPE"
                    footerMessage=""
                    dataArray={TRUCK_SENSOR_TYPE_AXLE_1}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            ''
          )}
        </CardContent>
      </Card>

      <br />

      <Card>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="trucksensorsondriveaxle"
                name="trucksensorsondriveaxle"
                objName="trucksensorsondriveaxle"
                label="SENSORS ON DRIVE AXLE(s) (AXLE POSITIONS 2, 3, &amp; 4)"
                footerMessage=""
                dataArray={OPTION_NO_YES}
                onChangeMethod={props.changeTruckSensorsOnDriveAxle}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          {props.truckSensorsOnDriveAxleSelected === 2 ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trucktireconfiguration234"
                    name="trucktireconfiguration234"
                    objName="trucktireconfiguration234"
                    label="TIRE CONFIGURATION"
                    footerMessage=""
                    dataArray={TIRE_CONFIGURATION_SINGLE_DUAL}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={renderSelectField}
                    id="truckaxleconfiguration"
                    name="truckaxleconfiguration"
                    objName="truckaxleconfiguration"
                    label="Axle Configuration Truck"
                    footerMessage=""
                    dataArray={TRUCK_AXLE_CONFIGURATION}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="truckdriveaxlewheelsize"
                    name="truckdriveaxlewheelsize"
                    objName="truckdriveaxlewheelsize"
                    label="Wheel Size"
                    footerMessage=""
                    dataArray={TRUCK_DRIVE_AXLE_WHEEL_SIZE}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="truckdriveaxlewheelmaterial"
                    name="truckdriveaxlewheelmaterial"
                    objName="truckdriveaxlewheelmaterial"
                    label="Wheel Material"
                    footerMessage=""
                    dataArray={TRUCK_DRIVE_AXLE_WHEEL_MATERIAL}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="truckliftaxle"
                    name="truckliftaxle"
                    objName="truckliftaxle"
                    label="Lift Axle"
                    footerMessage=""
                    dataArray={OPTION_NO_YES}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trucksensortypeaxle234"
                    name="trucksensortypeaxle234"
                    objName="trucksensortypeaxle234"
                    label="SENSOR TYPE"
                    footerMessage=""
                    dataArray={SENSOR_TYPE}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="truckflowthrukit"
                    name="truckflowthrukit"
                    objName="truckflowthrukit"
                    label="Truck Flow - Thru Kit"
                    footerMessage=""
                    dataArray={OPTION_NO_YES}
                    onChangeMethod={props.changeTruckFlowThruKitOnSteerAxle234}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              {props.truckFlowThruKitOnSteerAxle234Selected === 2 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="truckhubtype"
                        name="truckhubtype"
                        objName="truckhubtype"
                        label="Truck Drive Axle Hub Type"
                        footerMessage=""
                        dataArray={TRUCK_HUB_TYPE}
                        onChangeMethod={props.changeTruckHubType}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>
                  {props.truckHubTypeSelected === 2 ? (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={renderTextField}
                            id="truckhubtypeother"
                            name="truckhubtypeother"
                            // objName='truckhubtypeother'
                            label="Truck Drive Axle Hub Type Other"
                            emptyText="Truck Drive Axle Hub Type Other"
                            footerMessage=""
                            locked={props.locked}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="truckspecialtysensors"
                    name="truckspecialtysensors"
                    objName="truckspecialtysensors"
                    label="SPECIALTY SENSORS"
                    footerMessage=""
                    dataArray={OPTION_NO_YES}
                    onChangeMethod={
                      props.changeTruckSpareTireSpecialtySensorsOnSteerAxle234
                    }
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              {props.truckSpareTireSpecialtySensorsOnSteerAxle234Selected ===
              2 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trucksparetire"
                        name="trucksparetire"
                        objName="trucksparetire"
                        label="SPARE TIRE"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SRFTruckSpecifications;

/***
 * Required property 
 * locked=bool
 * vehicleTypeSelected=int
 * 
 * truckSensorsOnSteerAxleSelected=int
 * changeTruckSensorsOnSteerAxle=function
 * 
 * truckSensorsOnDriveAxleSelected=int
 * changeTruckSensorsOnDriveAxle=function
 * 
 * truckFlowThruKitOnSteerAxle234Selected=int
 * changeTruckFlowThruKitOnSteerAxle234=function
 * 
 * truckSpareTireSpecialtySensorsOnSteerAxle234Selected=int
 * changeTruckSpareTireSpecialtySensorsOnSteerAxle234=function
 * 
 * truckHubTypeSelected=int
 * changeTruckHubType=function

  <SRFTruckSpecifications
    locked={true / false}
    vehicleTypeSelected={1, 2, or 3}

    truckSensorsOnSteerAxleSelected={1, 2}
    changeTruckSensorsOnSteerAxle={changeTruckSensorsOnSteerAxleSelected}

    truckSensorsOnDriveAxleSelected={1, 2}
    changeTruckSensorsOnDriveAxle={changeTruckSensorsOnDriveAxleSelected}

    truckFlowThruKitOnSteerAxle234Selected={1, 2}
    changeTruckFlowThruKitOnSteerAxle234={changeTruckFlowThruKitOnSteerAxle234Selected}

    truckSpareTireSpecialtySensorsOnSteerAxle234Selected={1, 2}
    changeTruckSpareTireSpecialtySensorsOnSteerAxle234={changeTruckSpareTireSpecialtySensorsOnSteerAxle234Selected}
                  
    truckHubTypeSelected={truckHubTypeSelected}
    changeTruckHubType={changeTruckHubTypeSelected}

  />

*/
