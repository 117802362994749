import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
const loginStyle = makeStyles(theme => ({
  errorTextColor: {
    color: '#66bb6a',
    padding: theme.spacing(1, 2)
  }
}));
function MessageBox(props) {
  const classes = loginStyle();
  return (
    <div className={`alert alert-${props.variant || 'info'}`}>
      <Typography
        component="h1"
        variant="h4"
        className={classes.errorTextColor}>
        {props.children}
      </Typography>
    </div>
  );
}
export default MessageBox;

// import React from 'react';
// function MessageBox(props) {
//   return (
//     <div className={`alert alert-${props.variant || 'info'}`}>
//       {props.children}
//     </div>
//   );
// }
// export default MessageBox;
