import {
  LOGINROLES_TABLE,
  GET_LOGINROLES_LIST_PENDING,
  GET_LOGINROLES_LIST_FULFILLED,
  GET_LOGINROLES_LIST_REJECTED,
  GET_LOGINROLE_PENDING,
  GET_LOGINROLE_FULFILLED,
  GET_LOGINROLE_REJECTED,
  GET_LOGINROLES_LIST_REQUEST,
  GET_LOGINROLES_LIST_SUCCESS,
  GET_LOGINROLES_LIST_FAIL,
  GET_LOGINROLE_REQUEST,
  GET_LOGINROLE_SUCCESS,
  GET_LOGINROLE_FAIL,
  ADD_LOGINROLE_REQUEST,
  ADD_LOGINROLE_SUCCESS,
  ADD_LOGINROLE_FAIL,
  UPDATE_LOGINROLE_REQUEST,
  UPDATE_LOGINROLE_SUCCESS,
  UPDATE_LOGINROLE_FAIL,
  DELETE_LOGINROLE_REQUEST,
  DELETE_LOGINROLE_SUCCESS,
  DELETE_LOGINROLE_FAIL,
  DELETE_LOGINROLE_FULFILLED,
  DELETE_LOGINROLE_PENDING,
  ADD_LOGINROLE_FULFILLED,
  UPDATE_LOGINROLE_FULFILLED
} from '../actions/action-constants';
const initialState = {
  title: 'Login Roles',
  tablename: LOGINROLES_TABLE,
  loginroles: [],
  loadingList: false,
  errors: []
};
const reducerLoginRoles = (state = initialState, action) => {
  var dataEntry;
  var dataList;
  switch (action.type) {
    case GET_LOGINROLES_LIST_REQUEST:
    case GET_LOGINROLE_REQUEST:
    case GET_LOGINROLE_PENDING:
    case GET_LOGINROLES_LIST_PENDING:
    case ADD_LOGINROLE_REQUEST:
    case UPDATE_LOGINROLE_REQUEST:
    case DELETE_LOGINROLE_REQUEST:
    case DELETE_LOGINROLE_PENDING:
      state = {
        ...state,
        loadingList: true
      };
      break;
    case GET_LOGINROLES_LIST_SUCCESS:
    case GET_LOGINROLES_LIST_FULFILLED:
      if (
        typeof action.payload.loginroles !== 'undefined' &&
        action.payload.loginroles !== null
      ) {
        dataList = action.payload.loginroles;
        dataList.sort(function (a, b) {
          return a.id - b.id;
        });
      } else {
        dataList = [];
      }
      state = {
        ...state,
        loadingList: false,
        addSuccess: false,
        updateSuccess: false,
        deleteSuccess: false,
        loginroles: dataList
      };
      break;
    case GET_LOGINROLE_SUCCESS:
    case GET_LOGINROLE_FULFILLED:
      dataEntry =
        typeof action.payload.dropdowns !== 'undefined' &&
        action.payload.dropdowns !== null
          ? action.payload.dropdowns
          : [];
      state = {
        ...state,
        loadingList: false,
        loginroles: dataEntry
      };
      break;
    case DELETE_LOGINROLE_SUCCESS:
    case DELETE_LOGINROLE_FULFILLED:
      state = {
        ...state,
        loadingList: false,
        deleteSuccess: true
      };
      break;
    case ADD_LOGINROLE_SUCCESS:
    case ADD_LOGINROLE_FULFILLED:
      state = {
        ...state,
        loadingList: false,
        addSuccess: true
      };
      break;
    case UPDATE_LOGINROLE_SUCCESS:
    case UPDATE_LOGINROLE_FULFILLED:
      state = {
        ...state,
        loadingList: false,
        updateSuccess: true
      };
      break;
    case ADD_LOGINROLE_FAIL:
    case UPDATE_LOGINROLE_FAIL:
    case DELETE_LOGINROLE_FAIL:
    case GET_LOGINROLES_LIST_FAIL:
    case GET_LOGINROLES_LIST_REJECTED:
    case GET_LOGINROLE_FAIL:
    case GET_LOGINROLE_REJECTED:
      state = {
        ...state,
        errors: [action.payload.response]
      };
      break;
    default:
      return state;
  }
  return state;
};
export default reducerLoginRoles;
