import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderTextField_DATE,
  renderTextField_MULTILINE,
  renderSelectField,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';
import {
  HARDWARE_BILLING_TYPE,
  USAGE,
  VEHICLE_TYPE
} from 'constants/action-constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const SRFCustomerDetails = (props) => {
  const classes = useStyles();
  const setVehicleType = (e) => {
    props.changeVehicleType(e);
  };
  const setHardwareBillingType = (e) => {
    props.changeHardwareBillingType(e);
  };







  /*

1
  lets unplug app
  screen unlock counter
  react web app

2

reducer makes the array from the db return

start by just making a generic array
with 2 entries
show those entries
then add more
then delete more
without errors
then attach to db

  form-fields parent component
    pass this down
    custList.array()
    addItem( custItem[] )
    deleteItem( custItem_id )

  component-1
    props.custList.map()
    props.addItem( custItem[] )
    props.deleteItem( custItem.id )

  component-2-show-item
    props.custItem.id
    props.custItem.Email
    props.custItem.First Name
    props.custItem.Last Name
    props.custItem.Real Time Alert
    props.custItem.Reminder Alert
    props.custItem.Summary Alert
    props.custItem.Submitted By




const elements1 = ['Fire1', 'Air', '' , 'Water'];
const elements2 = ['Fire2', 'Air', '' , 'Water'];
const elements3 = ['Fire3', 'Air', '' , 'Water'];
const elements4 = ['Fire4', 'Air', '' , 'Water'];

console.log(elements1.join());
// expected output: "Fire,Air,Water"

console.log(elements1.join(''));
// expected output: "FireAirWater"

console.log(elements1.join('-'));
// expected output: "Fire-Air-Water"

console.log(elements1.join(';'));
// expected output: "Fire-Air-Water"

const elements6 = elements1.join('|')
const elements7 = elements2.join('|')
const elements8 = elements3.join('|')
const elements9 = [elements6, elements7, elements8];

console.log('A - ', elements6);

const elements10 = elements9.join('~')
// console.log(elements.join(';'));
console.log('B - ', elements10);


const elements5 = [elements2, elements3, elements4];


// console.log(elements5.join(';'));
// expected output: "Fire,Air,Water"




const myArray1 = elements6.split('|');
console.log('A - length = ', myArray1.length);
console.log('A - length = ', myArray1[0]);

const myArray2 = elements10.split('~');
console.log('B - length = ', myArray2.length);
console.log('B - length = ', myArray2[0]);


*/



  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Customer Details" />
        <Divider />
        <CardContent>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField_DATE}
                id="orderdate"
                name="orderdate"
                label="Order Date"
                emptyText="Order Date"
                footerMessage="Invalid Date"
                // requiredText={true}
                locked={props.locked}
                autoFocus
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="jobnamepo"
                name="jobnamepo"
                // objName='jobnamepo'
                label="Job Name / PO"
                emptyText="Job Name / PO"
                footerMessage="Job Name / PO"
                // requiredText={true}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="fleetname"
                name="fleetname"
                // objName='fleetname'
                label="Fleet Name"
                emptyText="Fleet Name"
                footerMessage=""
                // requiredText={true}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="hardwarebillingtype"
                name="hardwarebillingtype"
                objName="hardwarebillingtype"
                label="Hardware Billing Type"
                footerMessage=""
                dataArray={HARDWARE_BILLING_TYPE}
                onChangeMethod={setHardwareBillingType}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="usage"
                name="usage"
                objName="usage"
                label="Usage"
                footerMessage=""
                dataArray={USAGE}
                requireOnChangeMethod={false}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="vehicletype"
                name="vehicletype"
                objName="vehicletype"
                label="VEHICLE TYPE"
                footerMessage=""
                dataArray={VEHICLE_TYPE}
                onChangeMethod={setVehicleType}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="quantity"
                name="quantity"
                // objName='quantity'
                label="Quantity"
                emptyText="Quantity"
                footerMessage=""
                // requiredText={true}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField_MULTILINE}
                id="ordercomments"
                name="ordercomments"
                // objName='ordercomments'
                label="Comments"
                emptyText="Comments"
                footerMessage=""
                rows="6"
                locked={props.locked}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SRFCustomerDetails;

/***
 * Required property 
 * locked=bool
 * vehicleTypeSelected=int
 * changeVehicleType=function

  <SRFCustomerDetails 
    locked={true / false}
    vehicleTypeSelected={1, 2, or 3}
    changeVehicleType={changeVehicleTypeSelected}
  />

*/
