import React from 'react';
import './logins-toolbar.css';
import AddIcon from '@material-ui/icons/Add';
import { ButtonAddLogin } from 'theme';
const LoginsToolbar = () => {
  return (
    <div className="toolbarRow">
      <span className="spacer" />
      <ButtonAddLogin size="large" fullWidth 
        href="/logins/add"
      >
        <AddIcon />
        Add Login
      </ButtonAddLogin>
    </div>
  );
};
export default LoginsToolbar;
