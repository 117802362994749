import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { Grid, Typography, Link, makeStyles } from '@material-ui/core';
import { Grid, Typography, makeStyles } from '@material-ui/core';
const styleFooter = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#eeeeee',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));
const Footer = (props) => {
  const { className, ...rest } = props;
  const classes = styleFooter();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant="caption">{process.env.REACT_APP_APPVERSION}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="caption">
            {process.env.REACT_APP_REPORTSVERSION}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="caption">{process.env.REACT_APP_DBCOREVERSION}</Typography>
        </Grid>
      </Grid>
{/* 
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="caption">
            {process.env.REACT_APP_PLATFORMVERSION}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="caption">
            <Link href={process.env.REACT_APP_COMPANYURL} target="_blank">
              {process.env.REACT_APP_COMPANYURLDESCRIPTION}
            </Link>
          </Typography>
        </Grid>
      </Grid>
*/}
    </div>
  );
};
Footer.propTypes = {
  className: PropTypes.string
};
export default Footer;
