import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { ButtonSaveAndClose } from 'theme';

import {
  renderCheckbox,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';

import SRFDisplayProgrammingCustomerLoginNAlerts from './display-programming-customer-logins-n-alerts';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const SRFDisplayProgrammingCustomersList = (props) => {
  const classes = useStyles();
  const addCuutomer = (e) => {
                                                /* console.log('00331- ', JSON.stringify(e, 0, 2)); */
    props.custPush(props.arrayTitle, e);
  };
  const changeWindowStatus = (e) => {
    setOpen(e);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [open, setOpen] = useState(false);

                                                      // console.log('97-81 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title={props.title} />
        <Divider />
        <CardContent>
          <SRFDisplayProgrammingCustomerLoginNAlerts
            open={open}
            windowStatus={changeWindowStatus}
            dialogTitle={props.dialogTitle}
            custPush={addCuutomer}
            currentUserID={props.currentUserID}
            tempID={props.tempID}
          />

          <FieldArray name={props.arrayTitle}>
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={name}>
                  <Grid container spacing={1}>
                    {!props.locked ? (
                      <>
                        <Grid item xs={12} sm={2}>
                          <span
                            onClick={() => fields.remove(index)}

                                                /*
                                                  // onClick={() => props.custPop(index)}
                                                  // onClick={() => props.custPop('truckCustomerAlerts2') }
                                                  // onClick={() => props.custPop('truckCustomerAlerts2',`${name}.email`) }
                                                  // onClick={() => props.custPop(props.arrayTitle,`${name}.email`) }
                                                  // onClick={() => props.custPop(props.arrayTitle,`${name}..email`) }
                                                  // onClick={() => props.custPop(`${name}.email`) }
                                                */

                            style={{ cursor: 'pointer' }}
                          >
                            {index + 1 + ' - '}
                            <Delete />
                            {' - ' + (index + 1)}
                          </span>
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={props.locked}
                        // component="input"
                        component={renderTextField}
                        name={`${name}.email`}
                        label="Email"
                        placeholder="Email"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={props.locked}
                        // component="input"
                        component={renderTextField}
                        name={`${name}.firstname`}
                        label="First Name"
                        placeholder="First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={props.locked}
                        // component="input"
                        component={renderTextField}
                        name={`${name}.lastname`}
                        label="Last Name"
                        placeholder="Last Name"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    {!props.locked ? (
                      <>
                        <Grid item xs={12} sm={2}></Grid>
                      </>
                    ) : null}

                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={props.locked}
                        component={renderCheckbox}
                        id={`${name}.realtimealert`}
                        name={`${name}.realtimealert`}
                        objName={`${name}.realtimealert`}
                        label="Realtime Alert"
                      />

                                                  {/* 
                                                    <label className="custom-control-label" htmlFor={`${name}.realtimealert`}>
                                                      Realtime Alert
                                                    </label> 
                                                    <Field
                                                      locked={props.locked}
                                                      component="input"
                                                      id={`${name}.realtimealert`}
                                                      name={`${name}.realtimealert`}
                                                      type="checkbox"
                                                      placeholder="Realtime Alert"
                                                    />
                                                  */}

                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={props.locked}
                        component={renderCheckbox}
                        id={`${name}.reminderalert`}
                        name={`${name}.reminderalert`}
                        objName={`${name}.reminderalert`}
                        label="Reminder Alert"
                      />

                                                  {/* 
                                                    <label className="custom-control-label" htmlFor={`${name}.reminderalert`}>
                                                      Reminder Alert
                                                    </label>
                                                    <Field
                                                      locked={props.locked}
                                                      component="input"
                                                      id={`${name}.reminderalert`}
                                                      name={`${name}.reminderalert`}
                                                      type="checkbox"
                                                      placeholder="Reminder Alert"
                                                    /> 
                                                  */}

                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={props.locked}
                        component={renderCheckbox}
                        id={`${name}.summaryalert`}
                        name={`${name}.summaryalert`}
                        objName={`${name}.summaryalert`}
                        label="Summary Alert"
                      />

                                                  {/*                         
                                                    <label className="custom-control-label" htmlFor={`${name}.summaryalert`}>
                                                      Summary Alert
                                                    </label>
                                                    <Field
                                                      locked={props.locked}
                                                      component="input"
                                                      id={`${name}.summaryalert`}
                                                      name={`${name}.summaryalert`}
                                                      type="checkbox"
                                                      placeholder="Summary Alert"
                                                    />
                                                  */}
                    </Grid>

{/* 
                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={true}
                        component={renderTextField}
                        name={`${name}.submittedby`}
                        label="Submitted By"
                        placeholder="Submitted By"
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Field
                        locked={true}
                        component={renderTextField}
                        name={`${name}.tempid`}
                        label="Temp ID"
                        placeholder="Temp ID"
                      />

                    </Grid>
 */}

                  </Grid>
                </div>
              ))
            }
          </FieldArray>

          <ButtonSaveAndClose
            disabled={props.locked}
            size="large"
            onClick={handleClickOpen}>
            {props.buttonText}
          </ButtonSaveAndClose>
        </CardContent>
      </Card>
    </div>
  );
};

export default SRFDisplayProgrammingCustomersList;

/***
 * Required property 
 * locked=bool
 * title=string
 * buttonText=string
 * dialogTitle=string
 * custList.array
 * custPush=function

  <SRFDisplayProgrammingCustomersList 
    locked={true / false}
    title={string}
    buttonText={string}
    dialogTitle={string}
    custList.array
    custPush={props.custPush}                                  
  />

*/

























/*


https://www.google.com/search?q=react+modal+form+pass+data+back+to+parent&oq=react+modal+form+pass+data+back+to+pa&aqs=chrome.1.69i57j33i22i29i30.5975j0j7&sourceid=chrome&ie=UTF-8
https://stackoverflow.com/questions/40722382/how-to-pass-state-back-to-parent-in-react
https://www.google.com/search?q=react+open+modal+close+send+data+back+to+parent&oq=react+open+modal+close+send+data+ba&aqs=chrome.1.69i57j33i160l2j33i299l3.9928j0j7&sourceid=chrome&ie=UTF-8
x - https://www.freakyjolly.com/pass-data-open-react-modal-from-parent-functional-component/

https://www.freecodecamp.org/news/pass-data-between-components-in-react/
https://pretagteam.com/question/how-to-pass-data-to-a-react-modal
https://www.freakyjolly.com/react-bootstrap-4-modals-passing-data-from-component-tutorial/#Open_Modal_with_Animation
https://stackoverflow.com/questions/43335452/pass-item-data-to-a-react-modal/43335626
https://pretagteam.com/question/how-to-set-the-props-data-in-the-state-of-materialui-dialog-when-the-dialog-open-in-react-js

https://medium.com/@willdjerry/passing-props-using-react-modal-25bcf721780c
https://github.com/jwilld/react-modal-dynamic



Make a new form called customer access list model



MODAL_CUSTOMER_ACCESS_LIST



1
  lets unplug app
  screen unlock counter
  react web app

2

reducer makes the array from the db return

start by just making a generic array
with 2 entries
show those entries
then add more
then delete more
without errors
then attach to db

  form-fields parent component
    pass this down
    custList.array()
    addItem( custItem[] )
    deleteItem( custItem_id )

  component-1
    props.custList.map()
    props.addItem( custItem[] )
    props.deleteItem( custItem.id )

  component-2-show-item
    props.custItem.id
    props.custItem.Email
    props.custItem.First Name
    props.custItem.Last Name
    props.custItem.Real Time Alert
    props.custItem.Reminder Alert
    props.custItem.Summary Alert
    props.custItem.Submitted By




const elements1 = ['Fire1', 'Air', '' , 'Water'];
const elements2 = ['Fire2', 'Air', '' , 'Water'];
const elements3 = ['Fire3', 'Air', '' , 'Water'];
const elements4 = ['Fire4', 'Air', '' , 'Water'];

console.log(elements1.join());
// expected output: "Fire,Air,Water"

console.log(elements1.join(''));
// expected output: "FireAirWater"

console.log(elements1.join('-'));
// expected output: "Fire-Air-Water"

console.log(elements1.join(';'));
// expected output: "Fire-Air-Water"

const elements6 = elements1.join('|')
const elements7 = elements2.join('|')
const elements8 = elements3.join('|')
const elements9 = [elements6, elements7, elements8];

console.log('A - ', elements6);

const elements10 = elements9.join('~')
// console.log(elements.join(';'));
console.log('B - ', elements10);


const elements5 = [elements2, elements3, elements4];


// console.log(elements5.join(';'));
// expected output: "Fire,Air,Water"




const myArray1 = elements6.split('|');
console.log('A - length = ', myArray1.length);
console.log('A - length = ', myArray1[0]);

const myArray2 = elements10.split('~');
console.log('B - length = ', myArray2.length);
console.log('B - length = ', myArray2[0]);



add temp ID to this entry
save each cust account with the temp id

when save is made update all id=temp id
set to id

*/
