import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper
} from '@material-ui/core';
import { getReceiversQCReceipt } from 'actions/receiversqc-Actions';
import SpinnerLoading from 'components/spinner-loading/spinner-loading';
import MessageBox from 'components/MessageBox/MessageBox';
import ReceiversQCReceiptEntry from './components/receiversQC-receipt-entry';

function ReceiversQCReceipt() {
  let history = useHistory();
  const dispatch = useDispatch();
  const receiversQCList = useSelector((state) => state.receiversQCs);
  const { loading, error, title, receivers } = receiversQCList;
  let { salesordernumber } = useParams();
  useEffect(() => {
    dispatch(getReceiversQCReceipt(salesordernumber));
  }, [dispatch, salesordernumber]);
  return (
    <div>
      {loading ? (
        <SpinnerLoading />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ maxWidth: '100%' }}>
          <Button onClick={() => history.goBack()}>Back</Button>
          <Paper>
            <CardHeader
              title={title + ' - Sales Order #: ' + salesordernumber}
            />
            <Divider />
            <Card>
              <CardContent>
                {receivers.map((receiver) => (
                  <ReceiversQCReceiptEntry
                    key={receiver.id}
                    receiver={receiver}
                  />
                ))}
              </CardContent>
            </Card>
          </Paper>
          <Button onClick={() => history.goBack()}>Back</Button>
        </div>
      )}
    </div>
  );
}

export default ReceiversQCReceipt;
