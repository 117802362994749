import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Profile, SidebarFooter, SidebarNav } from './components';
/*** signed in user */
import {CONTEXT_USER} from 'App';
import {
  AccountTree,
  Assignment,
  Dashboard,
  People,
  Print,
  // Reply,
  SettingsApplications,
  ViewList
} from '@material-ui/icons';
import {
  Box,
  Divider,
  Drawer,
  makeStyles
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  footerBox: {
    backgroundColor: theme.palette.background.dark
  }
}));

const profileStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const profileClass = profileStyles();
  /*** SIGNED IN USER DETAILS */
  const CONST_CONTEXT_USER = useContext(CONTEXT_USER);

  const createSideBarOptions = () => {
    const pageList = [];


    /*** SPECIFIC TO THIS APP --- START */

    /*** All users */
    pageList.push(
      {
        section: '8',
        key: '81',
        title: '',
        href: '',
        icon: null
      },
      {
        section: '8',
        key: '82',
        title: 'Change Password',
        href: '/settings',
        icon: <SettingsApplications />
      },
      
      // {
      //   section: '8',
      //   key: '83',
      //   title: 'Sign Out',
      //   href: '/sign-out',
      //   icon: <Reply />
      // }

    );

    /*** Order Creator */
    // if (CONST_CONTEXT_USER.role === 1 || CONST_CONTEXT_USER.role === 5) {
    if (CONST_CONTEXT_USER.role > 0) {
        pageList.push({
        section: '1',
        key: '12',
        title: 'Create Order',
        href: '/orders/add',
        icon: <Assignment />
      });
    }

    // if (
    //   CONST_CONTEXT_USER.role === 1 ||
    //   CONST_CONTEXT_USER.role === 2 ||
    //   CONST_CONTEXT_USER.role === 5
    // ) {
    if (CONST_CONTEXT_USER.role > 0) {      
      pageList.push(
        {
          section: '1',
          key: '11',
          title: 'Orders',
          href: '',
          icon: <Dashboard />
        },

        {
          section: '1',
          key: '13',
          title: 'All Orders',
          href: '/orders',
          icon: <Dashboard />
        },

        // {
        //   section: '1',
        //   key: '14',
        //   title: 'Pending Approval',
        //   href: '/orders/pendingapproval',
        //   icon: <Dashboard />
        // },

        // {
        //   section: '1',
        //   key: '15',
        //   title: 'Approved',
        //   href: '/orders/approved',
        //   icon: <Dashboard />
        // },
        
      );
    }

    /*** QC */
    // if (CONST_CONTEXT_USER.role === 4 || CONST_CONTEXT_USER.role === 5) {
    if (CONST_CONTEXT_USER.role > 0) {
      pageList.push(
        {
          section: '2',
          key: '21',
          title: 'Programming Receipts',
          href: '',
          icon: null
        },

        {
          section: '2',
          key: '22',
          title: 'Receiver QCs',
          href: '/receiversqc',
          icon: <Print />
        },

        // {
        //   section: '2',
        //   key: '23',
        //   title: 'Receivers',
        //   href: '',
        //   icon: null
        // },

        // {
        //   section: '2',
        //   key: '24',
        //   title: 'Receipts - Receiver QCs',
        //   href: '/receiversqc',
        //   icon: <Print />
        // },

        // {
        //   section: '2',
        //   key: '25',
        //   title: 'TESTing',
        //   href: '',
        //   icon: null
        // },

        // {
        //   section: '2',
        //   key: '26',
        //   title: 'FL Test Call',
        //   href: '/fltest',
        //   icon: <AccountTree />
        // },

      );
    }

    // /*** > 1 Reports  - Admin */
    //   // if (CONST_CONTEXT_USER.role > 2) {
    //   // if (CONST_CONTEXT_USER.role === 3) {
    //   if (CONST_CONTEXT_USER.role > 0) {
    //     pageList.push(
    //       {
    //         section: "0",
    //         key: "01",
    //         title: "",
    //         href: "",
    //         icon: null,
    //       },
  
    //       {
    //         section: "0",
    //         key: "03",
    //         title: "Reports",
    //         href: process.env.REACT_APP_REPORTS_URL,
    //         icon: <Print />,
    //         externalLink: true,
    //       },
  
    //       {
    //         section: "0",
    //         key: "05",
    //         title: "",
    //         href: "",
    //         icon: null,
    //       }
  
    //       // {
    //       //   section: '0',
    //       //   key: '02',
    //       //   title: 'Reports',
    //       //   href: '/reports',
    //       //   icon: <Print />,
    //       // },
  
    //       // {
    //       //   section: '0',
    //       //   key: '04',
    //       //   title: 'Reports Samples',
    //       //   href: '/reports1',
    //       //   icon: <Print />,
    //       // },
    //     );
    //   }
  
    /*** Admin */
    // if (CONST_CONTEXT_USER.role === 5) {
    if (CONST_CONTEXT_USER.role > 0) {
      pageList.push(
        {
          section: '5',
          key: '51',
          title: 'Login Settings',
          href: '',
          icon: null
        },
        {
          section: '5',
          key: '52',
          title: 'Logins',
          href: '/logins',
          icon: <People />
        },
        // {
        //   section: '5',
        //   key: '53',
        //   title: 'Login Roles',
        //   href: '/loginroles',
        //   icon: <ViewList />
        // }
      );
    }

    /*** SPECIFIC TO THIS APP --- END */

    
    pageList.sort(function (a, b) {
      return a['section'] - b['section'] || a['key'] - b['key'];
    });
    return pageList;
  };

  function getRandomImage() {
    const max = 10;
    const rand = Math.floor(Math.random() * max);
    let imageFile;
    switch (rand) {
      case 0:
        imageFile = '/images/avatars/avatar-1.jpg';
        break;
      case 1:
        imageFile = '/images/avatars/avatar-2.jpg';
        break;
      case 2:
        imageFile = '/images/avatars/avatar-3.png';
        break;
      case 3:
        imageFile = '/images/avatars/avatar-4.png';
        break;
      case 4:
        imageFile = '/images/avatars/avatar-5.png';
        break;
      case 5:
        imageFile = '/images/avatars/avatar-6.png';
        break;
      default:
        imageFile = '/images/avatars/avatar-7.png';
    }

    return imageFile;
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile
          classes={profileClass}
          currentuser={CONST_CONTEXT_USER}
          avatar={getRandomImage()}
        />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={createSideBarOptions()} />

        {/* this is required to make the footer box float on the bottom of the sidebar */}
        <Box height="100%" display="flex" flexDirection="column" flexGrow={1} />
        <SidebarFooter />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
