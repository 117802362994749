import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  renderSnackbarError,
  renderTextField,
} from "components/form-global-components/react-final-form-global-components";
import Send from "@material-ui/icons/Send";
import { ButtonSaveAndClose, ButtonRegister } from "theme";
import asyncValidate from "./asyncValidate";
import SpinnerLoading from "components/spinner-loading/spinner-loading";
import MessageBox from "components/MessageBox/MessageBox";
import { RegisterLogin_Auto_Sign_In } from "../../actions/actions-auth";

const loginStyle = makeStyles((theme) => ({
  error500TextColor: {
    color: "#F44336",
    padding: theme.spacing(1, 2),
  },
}));

const formINITIALVALUES = {
  name: "",
  login: "",
};

function Register() {
  const classes = loginStyle();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { errors, isAuthenticated, isAuthenticating } = auth;

  const handleSubmitRegister = async (values) => {
    let loginName = values.login.replace(/\s/g, "");
    loginName = loginName.toLowerCase();
    let params = {
      name: values.name,
      login: loginName,
      role: 1,
      loginenabled: 1,
      password: process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD,
    };
    await dispatch(RegisterLogin_Auto_Sign_In(params));
  };

                                    /*** redirect and send to home screen if already logged in */
                                    // if (isAuthenticated) {
                                    //   history.push(process.env.PUBLIC_URL + '/dashboard', {replace: true})
                                    //   return ()
                                    // } else {

  return (
    <div>
      {isAuthenticated ? (
        <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
      ) : (
        <Form
          onSubmit={handleSubmitRegister}
          initialValues={formINITIALVALUES}
          validate={asyncValidate}
          subscription={{ initialValues: true }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Card>
                  <CardHeader title="Register" />
                  <Divider />
                  <CardContent>
                    {errors.message ? (
                      <MessageBox variant="danger">{errors.message}</MessageBox>
                    ) : null}

                    <Field
                      component={renderSnackbarError}
                      id="notifyFormErrors"
                      name="notifyFormErrors"
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          component={renderTextField}
                          id="name"
                          name="name"
                          label="Name"
                          placeholder="First and Last Name"
                          footerMessage="First and Last Name"
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          component={renderTextField}
                          id="login"
                          name="login"
                          label="Login ID"
                          placeholder="Login ID"
                          footerMessage="Email address preferred"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  {errors.length > 0 ? (
                    <>
                      {/* {errors[0].status === 501 ? ( */}
                      <Typography
                        component="h1"
                        variant="h4"
                        className={classes.error500TextColor}
                      >
                        {errors[0].data.message}
                        <br />
                      </Typography>
                      {/* ) : null} */}
                    </>
                  ) : null}

                  <Divider />
                  <CardActions>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ButtonSaveAndClose
                          size="large"
                          type="submit"
                          disabled={submitting}
                        >
                          <Send />
                          &nbsp; Register
                        </ButtonSaveAndClose>

                        {isAuthenticating && <SpinnerLoading />}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ButtonRegister
                          size="large"
                          type="button"
                          href={process.env.PUBLIC_URL + "/sign-in"}
                        >
                          Back to Sign In
                        </ButtonRegister>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default Register;
