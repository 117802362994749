import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Link,
  makeStyles,
  Typography,
  Avatar
} from '@material-ui/core';
// import ArrowUpward from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Assignment from '@material-ui/icons/Assignment';
import Folder from '@material-ui/icons/Folder';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import MoneyIcon from '@material-ui/icons/Money';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import RecentActors from '@material-ui/icons/RecentActors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 24,
    width: 24
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const DashboardWidget = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2">
              <Link
                href={props.link}
                variant="body1"
                // component="button"
                // onClick={() => {
                //   // alert("I'm a button. - " + props.link + ' - bbbbb ');
                //   // let lnk = props.link;
                //   props.history.push(props.link);
                // }}
              >
                {props.title}
              </Link>
            </Typography>
            <Typography variant="h3">
              {props.count}
            </Typography>
          </Grid>
          <Grid item>
            {/* gives a red background */}
            {/* <Avatar className={classes.avatar}> */}
            <Avatar>
              {(() => {
                if (props.icon === 0) {
                  return <Assignment className={classes.icon} />;
                } else if (props.icon === 1) {
                  return <RecentActors className={classes.icon} />;
                } else if (props.icon === 2) {
                  return <Folder className={classes.icon} />;
                } else if (props.icon === 3) {
                  return <PlaylistAddCheck className={classes.icon} />;
                } else if (props.icon === 4) {
                  return <FormatListNumbered className={classes.icon} />;
                } else {
                  return <MoneyIcon className={this.classes.icon} />;
                }
              })()}
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
{/*
          <ArrowUpward className={classes.differenceIcon} />
          <ArrowDownwardIcon className={classes.differenceIcon} />
*/}
{/*
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            12%
          </Typography>
*/}
          <Typography className={classes.caption} variant="caption">
            to date
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

DashboardWidget.propTypes = {
  className: PropTypes.string
};

export default DashboardWidget;
