import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteWithLayout } from '..';

/***
 * LOGIN REQUIRED
 * ROLE ACCESS LEVEL REQUIRED
 */
/*** signed in user details */
import { CONTEXT_USER } from 'App';

const PrivateRoute = ({ component, layout, path, requiredRole, ...rest }) => {
  /*** SIGNED IN USER DETAILS */
  const CONST_CONTEXT_USER = useContext(CONTEXT_USER);
  return (
    <Route
      {...rest}
      render={props =>
        CONST_CONTEXT_USER.isAuthenticated === true &&
        CONST_CONTEXT_USER.role >= requiredRole ? 
        (
          <RouteWithLayout
            {...props}
            component={component}
            layout={layout}
            exact
          />
        )
        :
        (
          <Redirect
            from=''
            noThromw
            to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
