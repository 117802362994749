import React from 'react';
import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core'; // v4
// import { ThemeProvider } from '@material-ui/core/styles'; // v5

import theme from 'theme';
/*** date picker on forms */
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import 'react-perfect-scrollbar/dist/css/styles.css';  // prior - why here IDK
// import 'react-perfect-scrollbar';
import 'assets/scss/index.scss';

import Routes from 'Routes';

/*** PASS THE CURRENT USER TO THE REST OF THE APP WHEN NEEDED */
export const CONTEXT_USER = React.createContext();

export const browserHistory = createBrowserHistory();

function App() {
  /*** this is the old container --- start */
  const auth = useSelector(state => state.auth);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */

  return (
    /*** CONTEXT_USER.Provider passes the signed in users details to all components to have access */
    /*** MuiThemeProvider & ThemeProvider theme providers this may pose an issue look at the material-ui get started guide to fix this */
    <CONTEXT_USER.Provider value={auth.user}>
      <ThemeProvider theme={theme}>
        {/* <Router history={browserHistory} basename={'/psi'}> */}
        <Router history={browserHistory} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Routes />
          </MuiPickersUtilsProvider>
        </Router>
      </ThemeProvider>
    </CONTEXT_USER.Provider>
  );
}
export default App;

/*
    <CONTEXT_USER.Provider value={auth.user}>

    how to use this to get the data while in a component
    // / *** signed in user * /
    import {CONTEXT_USER} from 'App';
    
    // / *** SIGNED IN USER DETAILS * /
    const CONST_CONTEXT_USER = useContext(CONTEXT_USER);
    
    {CONST_CONTEXT_USER.role}
*/
