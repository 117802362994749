import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from 'components';

import DesktopSideMenu_Layout from 'layouts/DesktopSideMenu-Layout-v5/DesktopSideMenuLayout'
import MobileNoSideMenu_Layout from 'layouts/MobileNoSideMenu-Layout-v5/MobileNoSideMenuLayout';

import NotFound from 'components/module-login/views/NotFound/NotFound';
import Homepage from 'components/module-login/views/Homepage/homepage';

import PrivateRoute from 'components/private-route/private-route';

import Dashboard from 'views/Dashboard/Dashboard';

import ChangeLog from 'views/Change-Log/change-log';

// import FLTest from 'views/x-FLTest/fltest'

import ReceiversQC from 'views/ReceiversQC/receiversqc'

import Orders from "views/Orders/orders";
import OrdersPendingApproval from "views/Orders/orders-pending-approval";
import OrdersApproved from "views/Orders/orders-approved";
import OrdersAdd from "views/Orders-Add-Edit-View/orders-add";
import OrderEdit from "views/Orders-Add-Edit-View/orders-edit";
import OrderView from "views/Orders-Add-Edit-View/orders-view";



/*** components/module-login */
import LoginsAdd from 'components/module-login/views/Logins-Add-Edit/login-add'
import LoginsEdit from 'components/module-login/views/Logins-Add-Edit/login-edit';
import LoginsList from "components/module-login/views/Logins/logins";

// import LoginRolesList from "components/module-login/views/LoginRoles/loginroles";

import Settings from 'components/module-login/views/PasswordReset/password-reset'
import Register from 'components/module-login/views/Register/Register';
import SignIn from 'components/module-login/views/SignIn/SignIn';
import SignOut from 'components/module-login/views/SignOut/SignOut';
import ReceiversQCReceipt from 'views/ReceiversQC-Receipt/receiversQC-receipt';
/*** components/module-login */




                                                                // /*** Order Creator */
                                                                // if (props.currentuser.role === 1 || props.currentuser.role === 5) {
                                                                // /*** Order Approver */
                                                                // if (props.currentuser.role === 2 || props.currentuser.role === 5) {
                                                                // /*** PROGRAMMER */
                                                                // if (props.currentuser.role === 3 || props.currentuser.role === 5) {
                                                                // /*** QC */
                                                                // if (props.currentuser.role === 4 || props.currentuser.role === 5) {
                                                                // /*** Admin */
                                                                // if (props.currentuser.role === 5) {

                                                            
const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={'/'}
        to={'/dashboard'}
                        // to={'/home'}
      />



{/* 
      <RouteWithLayout
        // requiredRole='2'
        // requiredRole='5'
        requiredRole='0'
        component={Dashboard}
        path={'/'}
        layout={DesktopSideMenu_Layout}
        exact

        // component={Register}
        // path={'/register'}
        // layout={MobileNoSideMenu_Layout}
        // exact
      /> 
*/}


      <PrivateRoute 
        
                                  // requiredRole='2'
                                  // requiredRole='5'
        requiredRole='0'
        component={Dashboard}
        path={'/dashboard'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                    // requiredRole='2'
        requiredRole='0'
        component={Dashboard}
        path={'/ownersummary'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                    // requiredRole='2'
        requiredRole='0'
        component={ChangeLog}
        path={'/changelog'}
        layout={DesktopSideMenu_Layout}
        exact
      />



      <PrivateRoute 
        
                                    // requiredRole='2'
        requiredRole='0'
        component={Orders}
        path={'/orders'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                  // requiredRole='1'
        requiredRole='0'
        component={OrdersPendingApproval}
        path={'/orders/pendingapproval'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                  // requiredRole='2'
        requiredRole='0'
        component={OrdersApproved}
        path={'/orders/approved'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                  // requiredRole='1'
                                  // requiredRole='5'
                                  // requiredRole='2'
        requiredRole='0'
        component={OrdersAdd}
        path={'/orders/add'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                   // requiredRole='2'
        requiredRole='0'
        component={OrderEdit}
        path={'/orders/:id'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                    // requiredRole='2'
        requiredRole='0'
        component={OrderView}
        path={'/orders/view/:id'}
        layout={DesktopSideMenu_Layout}
        exact
      />

{/* 
      <PrivateRoute 
        
                                    // requiredRole='2'
        requiredRole='0'
        component={LoginRolesList}
        path={'/loginroles'}
        layout={DesktopSideMenu_Layout}
        exact
      /> 
*/}

      <PrivateRoute 
        
                                            // requiredRole='2'
        requiredRole='0'
        component={ReceiversQC}
        path={'/receiversqc'}
        layout={DesktopSideMenu_Layout}
        exact
      />

      <PrivateRoute 
        
                                            // requiredRole='2'
        requiredRole='0'
        component={ReceiversQCReceipt}
        path={'/receiversqc/:salesordernumber'}
        layout={MobileNoSideMenu_Layout}
        exact
      />

{/* 
      <PrivateRoute 
        
                                            // requiredRole='2'
        requiredRole='0'
        component={FLTest}
        path={'/fltest'}
        layout={DesktopSideMenu_Layout}
        exact
      /> 
*/}


      <PrivateRoute 
        
                                    // requiredRole='3'
        requiredRole='0'
        component={LoginsList}
        path={'/logins'}
        layout={DesktopSideMenu_Layout}
        exact
      />
      <PrivateRoute 
        
                                    // requiredRole='3'
        requiredRole='0'
        component={LoginsAdd}
        // component={LoginsAdd(componentMode=0)}
        path={'/logins/add'}
        layout={DesktopSideMenu_Layout}
        exact
      />
      <PrivateRoute 
        
                                    // requiredRole='3'
        requiredRole='0'
        component={LoginsEdit}
        path={'/logins/:id'}
        layout={DesktopSideMenu_Layout}
        exact
      />




      <PrivateRoute
        
        requiredRole='0'
        component={Settings}
        path={'/settings'}
        layout={DesktopSideMenu_Layout}
        exact
      />
      <RouteWithLayout
        component={SignOut}
        path={'/sign-out'}
        layout={MobileNoSideMenu_Layout}
        exact
      />
      <RouteWithLayout
        component={SignIn}
        path={'/sign-in'}
        layout={MobileNoSideMenu_Layout}
        exact
      />
      <RouteWithLayout
        component={Register}
        path={'/register'}
        layout={MobileNoSideMenu_Layout}
        exact
      />
      <PrivateRoute 
        
        requiredRole='0'         
        component={Homepage}
        path={'/home'}
        layout={DesktopSideMenu_Layout}
        exact
      />
      <PrivateRoute 
        
        requiredRole='0'         
        component={NotFound}
        path={'/not-found'}
        /*** with toolbar */
        layout={DesktopSideMenu_Layout}
                                                                        /*** no toolbar */
                                                                        // layout={MobileNoSideMenu_Layout}
        exact
      />
      <Redirect to='/not-found' />
    </Switch>
  );
};
export default Routes;
