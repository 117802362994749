import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signOut } from '../../actions/actions-auth';
function SignOut(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signOut());
  }, [dispatch]);
  return <Redirect to="/sign-in" />;
}
export default SignOut;
