import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderCheckbox,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingSteerTireAlarmSettings(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-61 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Steer Tire Alarm Settings" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="steertirecoldpressure"
                name="steertirecoldpressure"
                // objName='steertirecoldpressure'
                label="Steer Tire Cold Pressure - Number"
                emptyText="Steer Tire Cold Pressure - Number"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPSteerTireAlarmLow}
                checked={props.defaultDPSteerTireAlarmLow}
                id="steertirealarmsettingsdefaultlow"
                name="steertirealarmsettingsdefaultlow"
                objName="steertirealarmsettingsdefaultlow"
                label="Default Low (-10% under cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPSteerTireAlarmLow}
                id="steertirealarmsettingsdefaultlowadjustvalue"
                name="steertirealarmsettingsdefaultlowadjustvalue"
                // objName='steertirealarmsettingsdefaultlowadjustvalue'
                label="Low Adjust Value"
                emptyText="Low Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPSteerTireAlarmHigh}
                checked={props.defaultDPSteerTireAlarmHigh}
                id="steertirealarmsettingsdefaulthigh"
                name="steertirealarmsettingsdefaulthigh"
                objName="steertirealarmsettingsdefaulthigh"
                label="Default High (DEFAULT +25% over cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPSteerTireAlarmHigh}
                id="steertirealarmsettingsdefaulthighadjustvalue"
                name="steertirealarmsettingsdefaulthighadjustvalue"
                // objName='steertirealarmsettingsdefaulthighadjustvalue'
                label="High Adjust Value"
                emptyText="High Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPSteerTireAlarmHighTemp}
                checked={props.defaultDPSteerTireAlarmHighTemp}
                id="steertirealarmsettingsdefaulthightemp"
                name="steertirealarmsettingsdefaulthightemp"
                objName="steertirealarmsettingsdefaulthightemp"
                label="Default High Temp (DEFAULT 158 degrees F)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPSteerTireAlarmHighTemp}
                id="steertirealarmsettingsdefaulthightempadjustvalue"
                name="steertirealarmsettingsdefaulthightempadjustvalue"
                // objName='steertirealarmsettingsdefaulthightempadjustvalue'
                label="High Temp (DEFAULT 158 degrees F)"
                emptyText="High Temp (DEFAULT 158 degrees F)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingSteerTireAlarmSettings;

/***
 * Required property 
 * locked=bool
 * 
 * changeDPSteerTireAlarmLow=function
 * defaultDPSteerTireAlarmLow=int
 * changeDPSteerTireAlarmHigh=function
 * defaultDPSteerTireAlarmHigh=int
 * changeDPSteerTireAlarmHighTemp=function
 * defaultDPSteerTireAlarmHighTemp=int

  <SRFTrailerSpecifications
    locked={true / false}

    changeDPSteerTireAlarmLow={props.changeDPSteerTireAlarmLow}                        
    defaultDPSteerTireAlarmLow={props.defaultDPSteerTireAlarmLow}
    changeDPSteerTireAlarmHigh={props.changeDPSteerTireAlarmHigh}                        
    defaultDPSteerTireAlarmHigh={props.defaultDPSteerTireAlarmHigh}
    changeDPSteerTireAlarmHighTemp={props.changeDPSteerTireAlarmHighTemp}                        
    defaultDPSteerTireAlarmHighTemp={props.defaultDPSteerTireAlarmHighTemp}

  />

*/
