import axios from 'axios';
import {
  CREATE_NEW_LOGIN,
  
  // REGISTER_LOGIN_REQUEST,
  ADD_LOGIN_REQUEST,
  UPDATE_LOGIN_REQUEST,
  DELETE_LOGIN_REQUEST,
  GET_LOGIN_REQUEST,
  GET_LOGINS_LIST_REQUEST,
  VALIDATE_PASSWORD_REQUEST,


  GET_LOGIN_SUCCESS,
  GET_LOGINS_LIST_SUCCESS,
  DELETE_LOGIN_SUCCESS,
  ADD_LOGIN_SUCCESS,
  // REGISTER_LOGIN_SUCCESS,
  UPDATE_LOGIN_SUCCESS,
  VALIDATE_PASSWORD_SUCCESS,

  GET_LOGINS_LIST_FAIL,
  GET_LOGIN_FAIL,
  ADD_LOGIN_FAIL,
  UPDATE_LOGIN_FAIL,
  // REGISTER_LOGIN_FAIL,
  VALIDATE_PASSWORD_FAIL,

  
  DEFAULTLOGINENTRY,

  // DELETE_LOGIN,
  // GET_LOGIN,
  // GET_LOGINS_LIST,
  // UPDATE_LOGIN,
  // VALIDATE_PASSWORD,

  /*** SPECIFIC TO THIS APP --- START */
  /*** SPECIFIC TO THIS APP --- END */
} from './action-constants';

const TABLENAME = 'logins';


/*** SPECIFIC TO THIS APP --- START */
/*** SPECIFIC TO THIS APP --- END */

export const createNewLogin = () => async dispatch => {
  try {
    dispatch({
      type: CREATE_NEW_LOGIN,
      payload: { login: DEFAULTLOGINENTRY }
    })
  } catch (err) {
    console.log('4409 - Error : ', err);
  }
}

export const getLoginsList = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/';
  dispatch({
    type: GET_LOGINS_LIST_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_LOGINS_LIST_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_LOGINS_LIST_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_LOGINS_LIST_FAIL,
        payload: err.message
      })
    }
  }
}

export const getLogin = (id) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/' + id;
  dispatch({
    type: GET_LOGIN_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_LOGIN_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_LOGIN_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_LOGIN_FAIL,
        payload: err.message
      })
    }
  }
}

export const addLogin = (params) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/';
  dispatch({
    type: ADD_LOGIN_REQUEST
  })
  try {
    const { data } = await axios.post(
      JSONURL, 
      params
      // , HEADERS
    )
    dispatch({
      type: ADD_LOGIN_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: ADD_LOGIN_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: ADD_LOGIN_FAIL,
        payload: err.message
      })
    }
  }
}

export const updateLogin = (id, params) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/' + id;
  dispatch({
    type: UPDATE_LOGIN_REQUEST
  })
  try {
    const { data } = await axios.put(
      JSONURL, 
      params
      // , HEADERS
    )
    dispatch({
      type: UPDATE_LOGIN_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: UPDATE_LOGIN_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: UPDATE_LOGIN_FAIL,
        payload: err.message
      })
    }
  }
}

export const deleteLogin = (id) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/' + id;
  dispatch({
    type: DELETE_LOGIN_REQUEST
  })
  try {
    const { data } = await axios.delete(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: DELETE_LOGIN_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: DELETE_LOGIN_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: DELETE_LOGIN_FAIL,
        payload: err.message
      })
    }
  }
}

export const validatePassword = (id) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/password/';
  dispatch({
    type: VALIDATE_PASSWORD_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL + id
      // , HEADERS
    )
    dispatch({
      type: VALIDATE_PASSWORD_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: VALIDATE_PASSWORD_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: VALIDATE_PASSWORD_FAIL,
        payload: err.message
      })
    }
  }
}

/*** SPECIFIC TO THIS APP --- START */
/*** SPECIFIC TO THIS APP --- END */
