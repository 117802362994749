import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import {
  AppBar,
  Badge,
  Toolbar,
  Hidden,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  /*** https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=2196F3 */
  root: {
    background: 'linear-gradient(45deg, #fff 30%, red 90%)', // blue / fade light blue
    color: 'white'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  rightImg1: {
    maxWidth: '100%',
    height: 50,
    borderRadius: 8
  },

  /** top bar title link font settings */
  topbarTitle: {
    color: 'black',
    fontSize: 16
  },

}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>

        {/* Topbar Left Logo */}
{/* 
        <RouterLink to={'/'}>
          <img
            className={classes.rightImg1}
            alt="Logo"
            src={'/images/logos/logo.png'}
          />
        </RouterLink> 
*/}

        {/* Topbar Left Title Text */}
{/* 
        <Typography variant="caption"> 
*/}

        <Typography variant="body1" >
          <RouterLink to={'/'} className={classes.topbarTitle}>
            {process.env.REACT_APP_WEBSITE_NAME}
          </RouterLink> 
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
{/* 
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            href={'/sign-out'}
          >
            <InputIcon /> 
          </IconButton>
*/}
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
