import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useParams
} from 'react-router-dom';
import { CONTEXT_USER } from 'App';
import { getOrder } from 'actions/orders-Actions';
import { ORDER_FORM_VIEW } from 'constants/action-constants';
import OrderForm from 'components/order-form/order-form';

// function OrdersView(props) {
const OrdersView = (props) => {
  /*** SIGNED IN USER DETAILS */
  const CONST_CONTEXT_USER = useContext(CONTEXT_USER);

    /*** this is the old container --- start */
  const dispatch = useDispatch();
  const orderEntry = useSelector((state) => state.orders);
  // const { loading, error, title, order } = orderEntry;
  const { order } = orderEntry;
  /*** this is the old container --- end */
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- start */
  let { id } = useParams();
  useEffect(() => {
    dispatch(getOrder(id));
  }, [dispatch, id]);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */
  /*** replaces history page push --- start  */
  // const history = useHistory()
  // let navigate = useNavigate();
  /*** replaces history page push --- end  */

  const voidMethodPlaceholder = async (values) => {
    // console.log('4444-0011 onSubmit = ');
    // await sleep(300)
    // window.alert(JSON.stringify(values, 0, 2))
  };

  // console.log('4444-vv-33 - props = ', props);
  // console.log('4444-vv-44 - orderEntry = ', orderEntry);
  // console.log('4444-vv-55 - order = ', order);

  return (
    <OrderForm
      formMode={3}
      currentUserID={CONST_CONTEXT_USER.id}
      initialValues={order}
      onSubmit={voidMethodPlaceholder}
      FormStateToRedux={ORDER_FORM_VIEW}
    />
  );
}

export default OrdersView;
