import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderSelectField,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';
import {
  IDENTIFY_BRAND_MODEL,
  OPTION_NO_YES,
  SENSOR_TYPE,
  TIRE_CONFIGURATION_SINGLE_DUAL,
  TRAILER_DRIVE_AXLE_WHEEL_SIZE,
  TRAILER_AXLE_CONFIGURATION,
  TRUCK_DRIVE_AXLE_WHEEL_MATERIAL,
  TRAILER_HUB_TYPE,
  TRAILER_SPINDLE_TYPE
} from 'constants/action-constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const SRFTrailerSpecifications = (props) => {
  const classes = useStyles();

  // console.log('4444-34 - props', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Trailer Specifications" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="trailersensorsontraileraxle"
                name="trailersensorsontraileraxle"
                objName="trailersensorsontraileraxle"
                label="SENSORS ON TRAILER AXLE(s)"
                footerMessage=""
                dataArray={OPTION_NO_YES}
                onChangeMethod={props.changeTrailerSensorsOnAxle}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          {props.trailerSensorsOnAxleSelected === 2 ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trailertireconfiguration"
                    name="trailertireconfiguration"
                    objName="trailertireconfiguration"
                    label="TIRE CONFIGURATION"
                    footerMessage=""
                    dataArray={TIRE_CONFIGURATION_SINGLE_DUAL}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={renderSelectField}
                    id="traileraxleconfiguration"
                    name="traileraxleconfiguration"
                    objName="traileraxleconfiguration"
                    label="Axle Configuration Trailer"
                    footerMessage=""
                    dataArray={TRAILER_AXLE_CONFIGURATION}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trailerdriveaxlewheelsize"
                    name="trailerdriveaxlewheelsize"
                    objName="trailerdriveaxlewheelsize"
                    label="Wheel Size"
                    footerMessage=""
                    dataArray={TRAILER_DRIVE_AXLE_WHEEL_SIZE}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trailerdriveaxlewheelmaterial"
                    name="trailerdriveaxlewheelmaterial"
                    objName="trailerdriveaxlewheelmaterial"
                    label="Wheel Material"
                    footerMessage=""
                    dataArray={TRUCK_DRIVE_AXLE_WHEEL_MATERIAL}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trailerliftaxle"
                    name="trailerliftaxle"
                    objName="trailerliftaxle"
                    label="Lift Axle"
                    footerMessage=""
                    dataArray={OPTION_NO_YES}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trailersensortypeaxle"
                    name="trailersensortypeaxle"
                    objName="trailersensortypeaxle"
                    label="SENSOR TYPE"
                    footerMessage=""
                    dataArray={SENSOR_TYPE}
                    requireOnChangeMethod={false}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="traileradapttoatis"
                    name="traileradapttoatis"
                    objName="traileradapttoatis"
                    label="ADAPT TO ATIS"
                    footerMessage=""
                    dataArray={OPTION_NO_YES}
                    onChangeMethod={props.changeTrailerAdaptToAtis}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              {props.trailerAdaptToAtisSelected === 2 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="traileridentifybrand"
                        name="traileridentifybrand"
                        objName="traileridentifybrand"
                        label="Identify Brand"
                        footerMessage=""
                        dataArray={IDENTIFY_BRAND_MODEL}
                        onChangeMethod={
                          props.changeTrailerAdaptToAtisIdentifyBrand
                        }
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  {props.trailerAdaptToAtisIdentifyBrandSelected === 4 ? (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={renderTextField}
                            id="traileridentifybrandotherspecify"
                            name="traileridentifybrandotherspecify"
                            // objName='traileridentifybrandotherspecify'
                            label="Specify"
                            emptyText="Specify"
                            footerMessage=""
                            locked={props.locked}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailerneedtosupplycphoses"
                        name="trailerneedtosupplycphoses"
                        objName="trailerneedtosupplycphoses"
                        label="Need to Supply CP Hoses"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )}

              {props.trailerAdaptToAtisSelected === 2 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailerflowthrukit"
                        name="trailerflowthrukit"
                        objName="trailerflowthrukit"
                        label="Trailer Flow - Thru Kit"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        onChangeMethod={props.changeTrailerFlowThruKit}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  {props.trailerFlowThruKitSelected === 2 ? (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={renderSelectField}
                            id="trailerhubtype"
                            name="trailerhubtype"
                            objName="trailerhubtype"
                            label="Trailer Axle Hub Type"
                            footerMessage=""
                            dataArray={TRAILER_HUB_TYPE}
                            onChangeMethod={props.changeTrailerHubType}
                            locked={props.locked}
                          />
                        </Grid>
                      </Grid>
                      {props.trailerHubTypeSelected === 3 ? (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Field
                                component={renderTextField}
                                id="trailerhubtypeother"
                                name="trailerhubtypeother"
                                // objName='trailerhubtypeother'
                                label="Trailer Axle Hub Type Other"
                                emptyText="Trailer Axle Hub Type Other"
                                footerMessage=""
                                locked={props.locked}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={renderSelectField}
                    id="trailerspecialtysensors"
                    name="trailerspecialtysensors"
                    objName="trailerspecialtysensors"
                    label="SPECIALTY SENSORS"
                    footerMessage=""
                    dataArray={OPTION_NO_YES}
                    onChangeMethod={props.changeTrailerSpecialtySensors}
                    locked={props.locked}
                  />
                </Grid>
              </Grid>

              {props.trailerSpecialtySensorsSelected === 2 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailersparetire"
                        name="trailersparetire"
                        objName="trailersparetire"
                        label="SPARE TIRE"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailerdigitalthermalert"
                        name="trailerdigitalthermalert"
                        objName="trailerdigitalthermalert"
                        label="Digital ThermALERT"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailerspindletype"
                        name="trailerspindletype"
                        objName="trailerspindletype"
                        label="Spindle Type - Flow Thru Kit"
                        footerMessage=""
                        dataArray={TRAILER_SPINDLE_TYPE}
                        onChangeMethod={props.changeTrailerSpindleType}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  {props.trailerSpindleTypeSelected === 3 ? (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={renderTextField}
                            id="trailerspindletypeother"
                            name="trailerspindletypeother"
                            // objName='trailerspindletypeother'
                            label="Spindle Type - Flow Thru Kit Other"
                            emptyText="Spindle Type - Flow Thru Kit Other"
                            footerMessage=""
                            locked={props.locked}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailertankpressure"
                        name="trailertankpressure"
                        objName="trailertankpressure"
                        label="Tank Pressure"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailercontrolboxoutput"
                        name="trailercontrolboxoutput"
                        objName="trailercontrolboxoutput"
                        label="Control Box Output"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={renderSelectField}
                        id="trailercontrolboxonoff"
                        name="trailercontrolboxonoff"
                        objName="trailercontrolboxonoff"
                        label="Control Box On / Off"
                        footerMessage=""
                        dataArray={OPTION_NO_YES}
                        requireOnChangeMethod={false}
                        locked={props.locked}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SRFTrailerSpecifications;

/***
 * Required property 
 * locked=bool
 * vehicleTypeSelected=int
 * 
 * trailerSensorsOnAxleSelected=int
 * changeTrailerSensorsOnAxle=function
 * 
 * trailerAdaptToAtisSelected=int
 * changeTrailerAdaptToAtis=function
 * 
 * trailerAdaptToAtisIdentifyBrandSelected=int
 * changeTrailerAdaptToAtisIdentifyBrand=function
 * 
 * trailerFlowThruKitSelected=int
 * changeTrailerFlowThruKit=function
 * 
 * trailerSpecialtySensorsSelected=int
 * changeTrailerSpecialtySensors=function
 * 
 * trailerHubTypeSelected=int
 * changeTrailerHubType=function

 * trailerSpindleTypeSelected=int
 * changeTrailerSpindleType=function


  <SRFTrailerSpecifications
    locked={true / false}
    vehicleTypeSelected={1, 2, or 3}

    trailerSensorsOnAxleSelected={1, 2}
    changeTrailerSensorsOnAxle={changeTrailerSensorsOnAxleSelected}

    trailerAdaptToAtisSelected={1, 2}
    changeTrailerAdaptToAtis={changeTrailerAdaptToAtisSelected}

    trailerAdaptToAtisIdentifyBrandSelected={1, 2}
    changeTrailerAdaptToAtisIdentifyBrand={changeTrailerAdaptToAtisIdentifyBrandSelected}

    trailerFlowThruKitSelected={1, 2}
    changeTrailerFlowThruKit={changeTrailerFlowThruKitSelected}

    trailerSpecialtySensorsSelected={1, 2}
    changeTrailerSpecialtySensors={setTrailerSpecialtySensors}

    trailerHubTypeSelected={trailerHubTypeSelected}
    changeTrailerHubType={changeTrailerHubTypeSelected}

    trailerSpindleTypeSelected={trailerSpindleTypeSelected}
    changeTrailerSpindleType={changeTrailerSpindleType}

  />
*/
