import {
    GET_DASHBOARD_COUNTS_PENDING,
    GET_DASHBOARD_COUNTS_FULFILLED,
    GET_DASHBOARD_COUNTS_REJECTED,

    GET_DASHBOARD_COUNTS_REQUEST,
    GET_DASHBOARD_COUNTS_SUCCESS,
    GET_DASHBOARD_COUNTS_FAIL,
} from 'constants/action-constants';

const initialState = {
    loading: true,
    errors: [],
    error: '',

    orderCount: -1,
    receiversqcdCount: -1,
    salesorderCount: -1,
};

const reducerGlobals = (state = initialState, action) => {
  var dataEntry;
  switch (action.type) {
    case GET_DASHBOARD_COUNTS_REQUEST:
    case GET_DASHBOARD_COUNTS_PENDING:
        state = {
            ...state,
            loading: true
        };
        break;
    case GET_DASHBOARD_COUNTS_SUCCESS:
    case GET_DASHBOARD_COUNTS_FULFILLED:
        var orderCount = -1;
        var receiversqcdCount = -1;
        var salesorderCount = -1;

        dataEntry = (typeof(action.payload.counts) !== "undefined" && action.payload.counts !== null ) ? action.payload.counts[0] : {} ;

        if (typeof(dataEntry) !== "undefined" && dataEntry !== null ) {                        
            orderCount = dataEntry.orders;
            receiversqcdCount = dataEntry.receiversqcd;
            salesorderCount = dataEntry.salesordernumbers;
        }
        state = {
            ...state,
            loading: false,

            orderCount: orderCount,
            receiversqcdCount: receiversqcdCount,
            salesorderCount: salesorderCount,
        };
        break;
    case GET_DASHBOARD_COUNTS_FAIL:
    case GET_DASHBOARD_COUNTS_REJECTED:
        state = {
            ...state,
            loading: false,
            errors: [action.payload.response],
        };
        break;

    default:
        return state;
  }

  return state;
};
export default reducerGlobals;
