import { 
    DASHBOARD_TABLE
    , GET_DASHBOARDS_LIST_PENDING 
    , GET_DASHBOARDS_LIST_FULFILLED 
    , GET_DASHBOARDS_LIST_REJECTED 

    , GET_DASHBOARD_PENDING
    , GET_DASHBOARD_FULFILLED
    , GET_DASHBOARD_REJECTED

    , DELETE_DASHBOARD_FULFILLED
    , DELETE_DASHBOARD_PENDING,
    ADD_DASHBOARD_FULFILLED,
    UPDATE_DASHBOARD_FULFILLED
} from '../../constants/action-constants';

const initialState = {
    title: 'Dashboard'
    , tablename: DASHBOARD_TABLE
    // , dashboards: []
    , dashboard: []
    , loadingList: false
    , errors: []
};

const reducerDashboards = (state = initialState, action) => {
  var dataUndefinedTestReturn;

  switch (action.type) {
    case GET_DASHBOARD_PENDING:
        state = {
            ...state
            , loadingList: true
        };
        break;
    case GET_DASHBOARDS_LIST_PENDING:
        state = {
            ...state
            , loadingList: true
        };
        break;    
    case DELETE_DASHBOARD_PENDING:
        state = {
            ...state
            , loadingList: true
        };
        break;

    case GET_DASHBOARDS_LIST_FULFILLED:
        // console.log('4432-101 - action.payload = ', action.payload);
        // console.log('4432-102 - action.payload.data = ', action.payload.data);
        // dataUndefinedTestReturn = (typeof(action.payload.data) !== "undefined" && action.payload.data !== null ) ? action.payload.data.dashboard : [] ;
        if (typeof(action.payload.data) !== "undefined" && action.payload.data !== null ) {
            dataUndefinedTestReturn = action.payload.data.dashboard  ;
            // dataUndefinedTestReturn.sort('id', 'description');
            // dataUndefinedTestReturn.sort((a, b) =&gt; a.localeCompare(b) );
            dataUndefinedTestReturn.sort(function (a, b) {
                return a.id - b.id;
              });

        } else {
            dataUndefinedTestReturn = [];
        }
        
        state = {
            ...state
            , loadingList: false
            , addSuccess: false
            , updateSuccess: false
            , deleteSuccess: false
            , dashboard: dataUndefinedTestReturn
            // , dashboards: dataUndefinedTestReturn
            // , dashboards: [...state.dashboards, ...action.payload.data.dashboard]
        };
        break;
    case GET_DASHBOARD_FULFILLED:
        dataUndefinedTestReturn = (typeof(action.payload.data) !== "undefined" && action.payload.data !== null ) ? action.payload.data.dashboard : [] ;

        state = {
            ...state
            , loadingList: false
            , dashboard: dataUndefinedTestReturn
            // , dashboards: dataUndefinedTestReturn
        };
        break;
    case DELETE_DASHBOARD_FULFILLED:
            // console.log ( 'KOT 4432-101 referrals.affectedRows : ' , action.payload.data);
            // console.log ( 'KOT 4432-102 referrals.affectedRows : ' , action.payload.data.referrals);
            // dataUndefinedTestReturn = (typeof(action.payload.data) !== "undefined" && action.payload.data !== null ) ? action.payload.data.dashboard : [] ;

            state = {
                ...state
                , loadingList: false
                , deleteSuccess: true
                // , dashboards: dataUndefinedTestReturn
        };
        break;
    case ADD_DASHBOARD_FULFILLED:
        // console.log ( 'KOT 4432-101 referrals.affectedRows : ' , action.payload.data);
        // console.log ( 'KOT 4432-102 referrals.affectedRows : ' , action.payload.data.referrals);
        // dataUndefinedTestReturn = (typeof(action.payload.data) !== "undefined" && action.payload.data !== null ) ? action.payload.data.dashboard : [] ;


        state = {
            ...state
            , loadingList: false
            , addSuccess: true
            // , referrals: action.payload.data.referrals
            // , referrals: [...state.referrals, ...action.payload.data.referrals]
        };
        break;
    case UPDATE_DASHBOARD_FULFILLED:
        // console.log ( 'KOT 4432-101 referrals.affectedRows : ' , action.payload.data);
        // console.log ( 'KOT 4432-102 referrals.affectedRows : ' , action.payload.data.referrals);

        state = {
            ...state
            , loadingList: false
            , updateSuccess: true
            // , referrals: action.payload.data.referrals
            // , referrals: [...state.referrals, ...action.payload.data.referrals]
        };
        break;
                
    case GET_DASHBOARDS_LIST_REJECTED:
        console.log('KOT - 22019 - reducerDashboardEntry : FETCH_DASHBOARD_REJECTED : error set = ');
        state = {
            ...state
            , errors: [action.payload.response]
        };
        break;
    case GET_DASHBOARD_REJECTED:
        console.log('KOT - 22019 - reducerDashboardEntry : FETCH_DASHBOARD_REJECTED : error set = ');
        state = {
            ...state
            , errors: [action.payload.response]
        };
        break;
                
    default:
        return state;
  }

  return state;
};

export default reducerDashboards;