import axios from 'axios';
import {
  GET_LOGINROLES_LIST_REQUEST,
  GET_LOGINROLES_LIST_SUCCESS,
  GET_LOGINROLES_LIST_FAIL,
  GET_LOGINROLE_REQUEST,
  GET_LOGINROLE_SUCCESS,
  GET_LOGINROLE_FAIL,
  ADD_LOGINROLE_REQUEST,
  ADD_LOGINROLE_SUCCESS,
  ADD_LOGINROLE_FAIL,
  UPDATE_LOGINROLE_REQUEST,
  UPDATE_LOGINROLE_SUCCESS,
  UPDATE_LOGINROLE_FAIL,
  DELETE_LOGINROLE_REQUEST,
  DELETE_LOGINROLE_SUCCESS,
  DELETE_LOGINROLE_FAIL
} from './action-constants';

const TABLENAME = 'loginroles';

export const getLoginRolesList = () => async (dispatch) => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/';
  dispatch({
    type: GET_LOGINROLES_LIST_REQUEST
  });
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    );
    dispatch({
      type: GET_LOGINROLES_LIST_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_LOGINROLES_LIST_FAIL,
        payload: 'Data Server Appears To Be Offline'
      });
    } else {
      dispatch({
        type: GET_LOGINROLES_LIST_FAIL,
        payload: err.message
      });
    }
  }
};

export const getLoginRole = (id) => async (dispatch) => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/' + id;
  dispatch({
    type: GET_LOGINROLE_REQUEST
  });
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    );
    dispatch({
      type: GET_LOGINROLE_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_LOGINROLE_FAIL,
        payload: 'Data Server Appears To Be Offline'
      });
    } else {
      dispatch({
        type: GET_LOGINROLE_FAIL,
        payload: err.message
      });
    }
  }
};

export const addLoginRole = (params) => async (dispatch) => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/';
  dispatch({
    type: ADD_LOGINROLE_REQUEST
  });
  try {
    const { data } = await axios.post(
      JSONURL,
      params
      // , HEADERS
    );
    dispatch({
      type: ADD_LOGINROLE_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: ADD_LOGINROLE_FAIL,
        payload: 'Data Server Appears To Be Offline'
      });
    } else {
      dispatch({
        type: ADD_LOGINROLE_FAIL,
        payload: err.message
      });
    }
  }
};

export const updateLoginRole = (id, params) => async (dispatch) => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/' + id;
  dispatch({
    type: UPDATE_LOGINROLE_REQUEST
  });
  try {
    const { data } = await axios.put(
      JSONURL,
      params
      // , HEADERS
    );
    dispatch({
      type: UPDATE_LOGINROLE_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: UPDATE_LOGINROLE_FAIL,
        payload: 'Data Server Appears To Be Offline'
      });
    } else {
      dispatch({
        type: UPDATE_LOGINROLE_FAIL,
        payload: err.message
      });
    }
  }
};

export const deleteLoginRole = (id) => async (dispatch) => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME + '/' + id;
  dispatch({
    type: DELETE_LOGINROLE_REQUEST
  });
  try {
    const { data } = await axios.delete(
      JSONURL
      // , HEADERS
    );
    dispatch({
      type: DELETE_LOGINROLE_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: DELETE_LOGINROLE_FAIL,
        payload: 'Data Server Appears To Be Offline'
      });
    } else {
      dispatch({
        type: DELETE_LOGINROLE_FAIL,
        payload: err.message
      });
    }
  }
};
