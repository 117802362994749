
/*** SPECIFIC TO THIS APP */
/*** LOGIN ROLEs */
export const DEFAULT_VIEW_LOGINROLES = {
  1: 'Order Creator',
  2: 'Order Approver',
  3: 'Programmer',
  4: 'Quality Control Specialist',
  5: 'Admin',
};
/*** SPECIFIC TO THIS APP */


/*** LOGINs */
export const DEFAULTLOGINENTRY = {
  id: 0,
  name: '',
  login: '',
  password: process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD,
  role: 1,
  loginenabled: 1,
};

/*** LOGIN ENABLED */
export const DEFAULT_VIEW_LOGIN_ENABLED = {
  0: 'DISABLED',
  1: 'ENABLED',
};

/* SIGN_IN */
export const SIGN_OUT_DELETE_USER_N_TOKEN = 'SIGN_OUT_DELETE_USER_N_TOKEN';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SET_USER = 'SIGN_IN_SET_USER';

export const SIGN_IN_PENDING = 'SIGN_IN_PENDING';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_FULFILLED = 'SIGN_IN_FULFILLED';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_REJECTED = 'SIGN_IN_REJECTED';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_IN_FAIL_REQUEST = 'SIGN_IN_FAIL_REQUEST';
export const SIGN_IN_FAIL_PENDING = 'SIGN_IN_FAIL_PENDING';
export const SIGN_IN_FAIL_FULFILLED = 'SIGN_IN_FAIL_FULFILLED';
export const SIGN_IN_FAIL_REJECTED = 'SIGN_IN_FAIL_REJECTED';

export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_LOGIN_EXISTS';

export const REGISTER_LOGIN_AUTO_LOGIN = 'REGISTER_LOGIN_AUTO_LOGIN';
export const REGISTER_LOGIN_AUTO_LOGIN_PENDING = 'REGISTER_LOGIN_AUTO_LOGIN_PENDING';
export const REGISTER_LOGIN_AUTO_LOGIN_REQUEST = 'REGISTER_LOGIN_AUTO_LOGIN_REQUEST';
export const REGISTER_LOGIN_AUTO_LOGIN_FULFILLED = 'REGISTER_LOGIN_AUTO_LOGIN_FULFILLED';
export const REGISTER_LOGIN_AUTO_LOGIN_SUCCESS = 'REGISTER_LOGIN_AUTO_LOGIN_SUCCESS';
export const REGISTER_LOGIN_AUTO_LOGIN_REJECTED = 'REGISTER_LOGIN_AUTO_LOGIN_REJECTED';
export const REGISTER_LOGIN_AUTO_LOGIN_FAIL = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL';

// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL';
// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_PENDING = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_PENDING';
// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_REQUEST = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_REQUEST';
// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_FULFILLED = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_FULFILLED';
// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_SUCCESS = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_SUCCESS';
// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_REJECTED = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_REJECTED';
// export const REGISTER_LOGIN_AUTO_LOGIN_FAIL_FAIL = 'REGISTER_LOGIN_AUTO_LOGIN_FAIL_FAIL';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

/*** LOGINs */
export const CREATE_NEW_LOGIN = 'CREATE_NEW_LOGIN';

export const GET_LOGIN = 'GET_LOGIN';
export const GET_LOGIN_PENDING = 'GET_LOGIN_PENDING';
export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_FULFILLED = 'GET_LOGIN_FULFILLED';
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_REJECTED = 'GET_LOGIN_REJECTED';
export const GET_LOGIN_FAIL = 'GET_LOGIN_FAIL';

export const DELETE_LOGIN = 'DELETE_LOGIN';
export const DELETE_LOGIN_PENDING = 'DELETE_LOGIN_PENDING';
export const DELETE_LOGIN_REQUEST = 'DELETE_LOGIN_REQUEST';
export const DELETE_LOGIN_FULFILLED = 'DELETE_LOGIN_FULFILLED';
export const DELETE_LOGIN_SUCCESS = 'DELETE_LOGIN_SUCCESS';
export const DELETE_LOGIN_REJECTED = 'DELETE_LOGIN_REJECTED';
export const DELETE_LOGIN_FAIL = 'DELETE_LOGIN_FAIL';

export const DEACTIVATE_LOGIN = 'DEACTIVATE_LOGIN';
export const DEACTIVATE_LOGIN_PENDING = 'DEACTIVATE_LOGIN_PENDING';
export const DEACTIVATE_LOGIN_REQUEST = 'DEACTIVATE_LOGIN_REQUEST';
export const DEACTIVATE_LOGIN_FULFILLED = 'DEACTIVATE_LOGIN_FULFILLED';
export const DEACTIVATE_LOGIN_SUCCESS = 'DEACTIVATE_LOGIN_SUCCESS';
export const DEACTIVATE_LOGIN_REJECTED = 'DEACTIVATE_LOGIN_REJECTED';
export const DEACTIVATE_LOGIN_FAIL = 'DEACTIVATE_LOGIN_FAIL';

export const REGISTER_LOGIN = 'REGISTER_LOGIN';
export const REGISTER_LOGIN_PENDING = 'REGISTER_LOGIN_PENDING';
export const REGISTER_LOGIN_REQUEST = 'REGISTER_LOGIN_REQUEST';
export const REGISTER_LOGIN_FULFILLED = 'REGISTER_LOGIN_FULFILLED';
export const REGISTER_LOGIN_SUCCESS = 'REGISTER_LOGIN_SUCCESS';
export const REGISTER_LOGIN_REJECTED = 'REGISTER_LOGIN_REJECTED';
export const REGISTER_LOGIN_FAIL = 'REGISTER_LOGIN_FAIL';

export const ADD_LOGIN = 'ADD_LOGIN';
export const ADD_LOGIN_PENDING = 'ADD_LOGIN_PENDING';
export const ADD_LOGIN_REQUEST = 'ADD_LOGIN_REQUEST';
export const ADD_LOGIN_FULFILLED = 'ADD_LOGIN_FULFILLED';
export const ADD_LOGIN_SUCCESS = 'ADD_LOGIN_SUCCESS';
export const ADD_LOGIN_REJECTED = 'ADD_LOGIN_REJECTED';
export const ADD_LOGIN_FAIL = 'ADD_LOGIN_FAIL';

export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const UPDATE_LOGIN_PENDING = 'UPDATE_LOGIN_PENDING';
export const UPDATE_LOGIN_REQUEST = 'UPDATE_LOGIN_REQUEST';
export const UPDATE_LOGIN_FULFILLED = 'UPDATE_LOGIN_FULFILLED';
export const UPDATE_LOGIN_SUCCESS = 'UPDATE_LOGIN_SUCCESS';
export const UPDATE_LOGIN_REJECTED = 'UPDATE_LOGIN_REJECTED';
export const UPDATE_LOGIN_FAIL = 'UPDATE_LOGIN_FAIL';

export const GET_LOGINS_LIST = 'GET_LOGINS_LIST';
export const GET_LOGINS_LIST_PENDING = 'GET_LOGINS_LIST_PENDING';
export const GET_LOGINS_LIST_REQUEST = 'GET_LOGINS_LIST_REQUEST';
export const GET_LOGINS_LIST_FULFILLED = 'GET_LOGINS_LIST_FULFILLED';
export const GET_LOGINS_LIST_SUCCESS = 'GET_LOGINS_LIST_SUCCESS';
export const GET_LOGINS_LIST_REJECTED = 'GET_LOGINS_LIST_REJECTED';
export const GET_LOGINS_LIST_FAIL = 'GET_LOGINS_LIST_FAIL';

export const GET_LOGINS_LIST_PAGINATION = 'GET_LOGINS_LIST_PAGINATION';
export const GET_LOGINS_LIST_PAGINATION_PENDING = 'GET_LOGINS_LIST_PAGINATION_PENDING';
export const GET_LOGINS_LIST_PAGINATION_FULFILLED = 'GET_LOGINS_LIST_PAGINATION_FULFILLED';
export const GET_LOGINS_LIST_PAGINATION_REJECTED = 'GET_LOGINS_LIST_PAGINATION_REJECTED';

export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const VALIDATE_PASSWORD_PENDING = 'VALIDATE_PASSWORD_PENDING';
export const VALIDATE_PASSWORD_REQUEST = 'VALIDATE_PASSWORD_REQUEST';
export const VALIDATE_PASSWORD_FULFILLED = 'VALIDATE_PASSWORD_FULFILLED';
export const VALIDATE_PASSWORD_SUCCESS = 'VALIDATE_PASSWORD_SUCCESS';
export const VALIDATE_PASSWORD_REJECTED = 'VALIDATE_PASSWORD_REJECTED';
export const VALIDATE_PASSWORD_FAIL = 'VALIDATE_PASSWORD_FAIL';

/* Login Roles */
export const LOGINROLES_TABLE = 'loginroles';

export const GET_LOGINROLES_LIST = 'GET_LOGINROLES_LIST';
export const GET_LOGINROLES_LIST_PENDING = 'GET_LOGINROLES_LIST_PENDING';
export const GET_LOGINROLES_LIST_REQUEST = 'GET_LOGINROLES_LIST_REQUEST';
export const GET_LOGINROLES_LIST_FULFILLED = 'GET_LOGINROLES_LIST_FULFILLED';
export const GET_LOGINROLES_LIST_SUCCESS = 'GET_LOGINROLES_LIST_SUCCESS';
export const GET_LOGINROLES_LIST_REJECTED = 'GET_LOGINROLES_LIST_REJECTED';
export const GET_LOGINROLES_LIST_FAIL = 'GET_LOGINROLES_LIST_FAIL';

export const GET_LOGINROLE = 'GET_LOGINROLE';
export const GET_LOGINROLE_PENDING = 'GET_LOGINROLE_PENDING';
export const GET_LOGINROLE_REQUEST = 'GET_LOGINROLE_REQUEST';
export const GET_LOGINROLE_FULFILLED = 'GET_LOGINROLE_FULFILLED';
export const GET_LOGINROLE_SUCCESS = 'GET_LOGINROLE_SUCCESS';
export const GET_LOGINROLE_REJECTED = 'GET_LOGINROLE_REJECTED';
export const GET_LOGINROLE_FAIL = 'GET_LOGINROLE_FAIL';

export const DELETE_LOGINROLE = 'DELETE_LOGINROLE';
export const DELETE_LOGINROLE_PENDING = 'DELETE_LOGINROLE_PENDING';
export const DELETE_LOGINROLE_REQUEST = 'DELETE_LOGINROLE_REQUEST';
export const DELETE_LOGINROLE_FULFILLED = 'DELETE_LOGINROLE_FULFILLED';
export const DELETE_LOGINROLE_SUCCESS = 'DELETE_LOGINROLE_SUCCESS';
export const DELETE_LOGINROLE_REJECTED = 'DELETE_LOGINROLE_REJECTED';
export const DELETE_LOGINROLE_FAIL = 'DELETE_LOGINROLE_FAIL';

export const ADD_LOGINROLE = 'ADD_LOGINROLE';
export const ADD_LOGINROLE_PENDING = 'ADD_LOGINROLE_PENDING';
export const ADD_LOGINROLE_REQUEST = 'ADD_LOGINROLE_REQUEST';
export const ADD_LOGINROLE_FULFILLED = 'ADD_LOGINROLE_FULFILLED';
export const ADD_LOGINROLE_SUCCESS = 'ADD_LOGINROLE_SUCCESS';
export const ADD_LOGINROLE_REJECTED = 'ADD_LOGINROLE_REJECTED';
export const ADD_LOGINROLE_FAIL = 'ADD_LOGINROLE_FAIL';

export const UPDATE_LOGINROLE = 'UPDATE_LOGINROLE';
export const UPDATE_LOGINROLE_PENDING = 'UPDATE_LOGINROLE_PENDING';
export const UPDATE_LOGINROLE_REQUEST = 'UPDATE_LOGINROLE_REQUEST';
export const UPDATE_LOGINROLE_FULFILLED = 'UPDATE_LOGINROLE_FULFILLED';
export const UPDATE_LOGINROLE_SUCCESS = 'UPDATE_LOGINROLE_SUCCESS';
export const UPDATE_LOGINROLE_REJECTED = 'UPDATE_LOGINROLE_REJECTED';
export const UPDATE_LOGINROLE_FAIL = 'UPDATE_LOGINROLE_FAIL';

/*** SPECIFIC TO THIS APP --- START */
export const GET_LOGIN_PROGRAMSLIST_LIST = 'GET_LOGIN_PROGRAMSLIST_LIST';
export const GET_LOGIN_PROGRAMSLIST_LIST_PENDING = 'GET_LOGIN_PROGRAMSLIST_LIST_PENDING';
export const GET_LOGIN_PROGRAMSLIST_LIST_REQUEST = 'GET_LOGIN_PROGRAMSLIST_LIST_REQUEST';
export const GET_LOGIN_PROGRAMSLIST_LIST_FULFILLED = 'GET_LOGIN_PROGRAMSLIST_LIST_FULFILLED';
export const GET_LOGIN_PROGRAMSLIST_LIST_SUCCESS = 'GET_LOGIN_PROGRAMSLIST_LIST_SUCCESS';
export const GET_LOGIN_PROGRAMSLIST_LIST_REJECTED = 'GET_LOGIN_PROGRAMSLIST_LIST_REJECTED';
export const GET_LOGIN_PROGRAMSLIST_LIST_FAIL = 'GET_LOGIN_PROGRAMSLIST_LIST_FAIL';

export const GET_LOGIN_PROGRAMSNOTLIST_LIST = 'GET_LOGIN_PROGRAMSNOTLIST_LIST';
export const GET_LOGIN_PROGRAMSNOTLIST_LIST_PENDING = 'GET_LOGIN_PROGRAMSNOTLIST_LIST_PENDING';
export const GET_LOGIN_PROGRAMSNOTLIST_LIST_REQUEST = 'GET_LOGIN_PROGRAMSNOTLIST_LIST_REQUEST';
export const GET_LOGIN_PROGRAMSNOTLIST_LIST_FULFILLED = 'GET_LOGIN_PROGRAMSNOTLIST_LIST_FULFILLED';
export const GET_LOGIN_PROGRAMSNOTLIST_LIST_SUCCESS = 'GET_LOGIN_PROGRAMSNOTLIST_LIST_SUCCESS';
export const GET_LOGIN_PROGRAMSNOTLIST_LIST_REJECTED = 'GET_LOGIN_PROGRAMSNOTLIST_LIST_REJECTED';
export const GET_LOGIN_PROGRAMSNOTLIST_LIST_FAIL = 'GET_LOGIN_PROGRAMSNOTLIST_LIST_FAIL';

export const ADD_LOGIN_PROGRAM = 'ADD_LOGIN_PROGRAM';
export const ADD_LOGIN_PROGRAM_PENDING = 'ADD_LOGIN_PROGRAM_PENDING';
export const ADD_LOGIN_PROGRAM_REQUEST = 'ADD_LOGIN_PROGRAM_REQUEST';
export const ADD_LOGIN_PROGRAM_FULFILLED = 'ADD_LOGIN_PROGRAM_FULFILLED';
export const ADD_LOGIN_PROGRAM_SUCCESS = 'ADD_LOGIN_PROGRAM_SUCCESS';
export const ADD_LOGIN_PROGRAM_REJECTED = 'ADD_LOGIN_PROGRAM_REJECTED';
export const ADD_LOGIN_PROGRAM_FAIL = 'ADD_LOGIN_PROGRAM_FAIL';

export const DELETE_LOGIN_PROGRAM = 'DELETE_LOGIN_PROGRAM';
export const DELETE_LOGIN_PROGRAM_PENDING = 'DELETE_LOGIN_PROGRAM_PENDING';
export const DELETE_LOGIN_PROGRAM_REQUEST = 'DELETE_LOGIN_PROGRAM_REQUEST';
export const DELETE_LOGIN_PROGRAM_FULFILLED = 'DELETE_LOGIN_PROGRAM_FULFILLED';
export const DELETE_LOGIN_PROGRAM_SUCCESS = 'DELETE_LOGIN_PROGRAM_SUCCESS';
export const DELETE_LOGIN_PROGRAM_REJECTED = 'DELETE_LOGIN_PROGRAM_REJECTED';
export const DELETE_LOGIN_PROGRAM_FAIL = 'DELETE_LOGIN_PROGRAM_FAIL';
/*** SPECIFIC TO THIS APP --- END */
