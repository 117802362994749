import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { ButtonRegister, ButtonSignIn } from 'theme';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  makeStyles,
  Avatar,
  CssBaseline,
  Paper,
  Box,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { renderTextField } from 'components/form-global-components/react-final-form-global-components';
import SpinnerLoading from 'components/spinner-loading/spinner-loading';
import MessageBox from 'components/MessageBox/MessageBox';
import { signIn } from '../../actions/actions-auth';
import asyncValidate from './asyncValidate';

function getRandomImage() {
  /*** SPECIFIC TO THIS APP --- START */
  const max = 21; //  0 - 20 .jpeg files
  /*** SPECIFIC TO THIS APP --- END */
  const rand = Math.floor(Math.random() * max);
  /*** SPECIFIC TO THIS APP --- START */
  let imageFile =
    process.env.PUBLIC_URL + '/images/sign-images/' + rand + '.jpeg';
  /*** SPECIFIC TO THIS APP --- END */
  return imageFile;
}

const loginStyle = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  /*** https://stackoverflow.com/questions/52911169/how-to-change-the-border-color-of-material-ui-textfield */
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#76d275 !important'
  },
  image: {
    /*** backgroundImage: 'url(https://source.unsplash.com/random)', // sample images site */
    backgroundImage: 'url(' + getRandomImage() + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    background: '#66bb6a' // green
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorTextColor: {
    color: '#66bb6a',
    padding: theme.spacing(1, 2)
  },
  error500TextColor: {
    color: '#F44336',
    padding: theme.spacing(1, 2)
  }
}));

const formINITIALVALUES = {
  login: '',
  password: ''
};

function SignIn() {
  const classes = loginStyle();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { errors, isAuthenticating, isAuthenticated } = auth;

  const handleSubmitSignIn = async (values) => {
    var params = {
      password: values.password
    };
    await dispatch(signIn(values.login, params));
  };

  return (
    <div>
      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <Form
          onSubmit={handleSubmitSignIn}
          initialValues={formINITIALVALUES}
          validate={asyncValidate}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                {/* 
                <button>
                  Do an Auth Check and change the button color template and - Your Signed In - click here to proceed
                  <br />
                  / dashboard is the default route (count of orders)
                  <br />
                  / dashboard is the default route (count of past 6 months of orders)
                  <br />        
                  before every web call
                  token yes / no = if no token is set
                  has token expired yes / no = if token has expired
                  sign out - remove token
                  and
                  send to sign
                </button>
*/}

                <Grid container component="main" className={classes.root}>
                  <CssBaseline />
                  <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    className={classes.image}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square>
                    <div className={classes.paper}>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sign in
                      </Typography>

                      {errors.message && (
                        <MessageBox variant="danger">
                          {errors.message}
                        </MessageBox>
                      )}

                      {/* 
                          {errors.statusCode === 500 ?
                            <Typography 
                              component="h1" 
                              variant="h4"
                              className={classes.error500TextColor}
                            >                              
                              {errors.message}
                            </Typography>
                          : 
                          null
                          }
                          
                          {errors.statusCode === 401 || errors.statusCode === 403 ?
                            <Typography 
                              component="h1" 
                              variant="h4"
                              className={classes.errorTextColor}
                            >
                              Invalid Login Credentials
                            </Typography>
                          : 
                          null
                          } 
*/}

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={renderTextField}
                            id="login"
                            name="login"
                            label="Login"
                            // objName='login'
                            emptyText="Login"
                            footerMessage=""
                            autoComplete="login"
                            autoFocus
                            required
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={renderTextField}
                            id="password"
                            name="password"
                            label="Password"
                            emptyText="Password"
                            footerMessage=""
                            autoComplete="enter-password"
                            type="password"
                            required
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline
                              }
                            }}
                          />
                        </Grid>
                      </Grid>

                      <ButtonSignIn
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        disabled={submitting || pristine}>
                        Sign In
                      </ButtonSignIn>
                      <hr width="100%" />
                      <ButtonRegister
                        type="button"
                        size="large"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        href={process.env.PUBLIC_URL + '/register'}>
                        Register
                      </ButtonRegister>

                      {isAuthenticating && <LinearProgress variant="buffer"  value={5} valueBuffer={10} color="secondary" />}
                      {isAuthenticating && <SpinnerLoading />}

                      <Box mt={5}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="center">
                          {process.env.REACT_APP_APPVERSION}
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default SignIn;
