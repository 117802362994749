import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { withRouter } from 'react-router-dom'; // old
import MaterialTable, { MTableToolbar } from 'material-table';
import ReceiversQCToolbar from './ReceiversQC-Toolbar/receiversqc-toolbar';
/*** this is the old container --- start */
import { getReceiversQCList } from 'actions/receiversqc-Actions';
/*** this is the old container --- end */
import SpinnerLoading from 'components/spinner-loading/spinner-loading';
import MessageBox from 'components/MessageBox/MessageBox';

function ReceiversQC(props) {
  const tableColumns = [
    { title: 'Sales Order Number', field: 'salesordernumber' },
    { title: 'Config Type', field: 'configtype' },
    { title: 'QC Count', field: 'qccount' }
  ];
  const dispatch = useDispatch();
  const receiversQCList = useSelector(state => state.receiversQCs);
  const { loading, error, title, receiversqcs } = receiversQCList;
  useEffect(() => {
    dispatch(getReceiversQCList());
  }, [dispatch]);
  return (
    <div>
      {loading ? (
        <SpinnerLoading />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ maxWidth: '100%' }}>
          <ReceiversQCToolbar />
          <MaterialTable
            title={title}
            columns={tableColumns}
            data={receiversqcs}
            options={{
              // search
              search: true,
              // header grouping
              // grouping: true,
              // or
              // column filtering
              // filtering: true,

              // pagination settings
              // show paging buttons
              // paging:false,
              // default items per page
              // pageSize:10,
              pageSize: 10,
              // paging options
              pageSizeOptions: [10, 25, 50, 100],

              // ???
              // columnsButton:false,
              // move buttons to right side of list
              // actionsColumnIndex: -1,

              // styling otions
              rowStyle: { height: '20px' },
              maxBodyHeight: 700,

              // CSV export
              exportButton: true,
              // default filename
              exportFileName: 'PSIOTexport-ReceiversQC'
              // comma delimited
              // exportDelimiter: ',',
            }}
            /*** Custom Toolbar */
            components={{
              Toolbar: props => (
                <div style={{
                  background: 'linear-gradient(45deg, #f48fb1 10%, #fff 70%)', // blue / fade light blue
                  color: 'white',
                }}>
                    <MTableToolbar {...props} />
                </div>
              ),
            }}
            actions={[
              {
                icon: 'pageview',
                tooltip: 'View Sales Order',
                onClick: (event, rowData) => {
                  props.history.push('/orders/view/' + rowData.salesordernumber);
                }
              },
              {
                icon: 'receipt',
                tooltip: 'Receipt',
                onClick: (event, rowData) => {
                  props.history.push('/receiversqc/' + rowData.salesordernumber);
                }
              }
            ]}

          />
        </div>
      )}
    </div>
  );
}
export default ReceiversQC; // new
// export default withRouter(ReceiversQC); // old
