import React from 'react';
import './orders-toolbar.css';
import AddIcon from '@material-ui/icons/Add';
import { ButtonSaveAndClose } from 'theme';
const OrdersToolbar = () => {
  return (
    <div className="toolbarRow">
      <span className="spacer" />
      <ButtonSaveAndClose
        size="large"
        fullWidth
        href={'/orders/add'}
        // href={`${process.env.PUBLIC_URL}/orders/add`}
      >
        <AddIcon />
        Add Order
      </ButtonSaveAndClose>
    </div>
  );
};
export default OrdersToolbar;
