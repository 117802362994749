import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderCheckbox,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingDriveTireAlarmSettings(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  //   console.log('77-62 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Drive Tire Alarm Settings" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="drivetirecoldpressure"
                name="drivetirecoldpressure"
                // objName='drivetirecoldpressure'
                label="Drive Tire Cold Pressure - Number"
                emptyText="Drive Tire Cold Pressure - Number"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPDriveTireAlarmLow}
                checked={props.defaultDPDriveTireAlarmLow}
                id="drivetirealarmsettingsdefaultlow"
                name="drivetirealarmsettingsdefaultlow"
                objName="drivetirealarmsettingsdefaultlow"
                label="Low (DEFAULT -10% under cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPDriveTireAlarmLow}
                id="drivetirealarmsettingsdefaultlowadjustvalue"
                name="drivetirealarmsettingsdefaultlowadjustvalue"
                // objName='drivetirealarmsettingsdefaultlowadjustvalue'
                label="Low Adjust Value"
                emptyText="Low Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPDriveTireAlarmHigh}
                checked={props.defaultDPDriveTireAlarmHigh}
                id="drivetirealarmsettingsdefaulthigh"
                name="drivetirealarmsettingsdefaulthigh"
                objName="drivetirealarmsettingsdefaulthigh"
                label="High (DEFAULT +25% over cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPDriveTireAlarmHigh}
                id="drivetirealarmsettingsdefaulthighadjustvalue"
                name="drivetirealarmsettingsdefaulthighadjustvalue"
                // objName='drivetirealarmsettingsdefaulthighadjustvalue'
                label="High Adjust Value"
                emptyText="High Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPDriveTireAlarmHighTemp}
                checked={props.defaultDPDriveTireAlarmHighTemp}
                id="drivetirealarmsettingsdefaulthightemp"
                name="drivetirealarmsettingsdefaulthightemp"
                objName="drivetirealarmsettingsdefaulthightemp"
                label="High Temp (DEFAULT 158 degrees F)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPDriveTireAlarmHighTemp}
                id="drivetirealarmsettingsdefaulthightempadjustvalue"
                name="drivetirealarmsettingsdefaulthightempadjustvalue"
                // objName='drivetirealarmsettingsdefaulthightempadjustvalue'
                label="High Temp Adjust Value"
                emptyText="High Temp Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingDriveTireAlarmSettings;

/***
 * Required property 
 * locked=bool
 * 
 * changeDPDriveTireAlarmLow=function
 * defaultDPDriveTireAlarmLow=int
 * changeDPDriveTireAlarmHigh=function
 * defaultDPDriveTireAlarmHigh=int
 * changeDPDriveTireAlarmHighTemp=function
 * defaultDPDriveTireAlarmHighTemp=int


  <SRFTrailerSpecifications
    locked={true / false}

    changeDPDriveTireAlarmLow={props.changeDPDriveTireAlarmLow}                        
    defaultDPDriveTireAlarmLow={props.defaultDPDriveTireAlarmLow}
    changeDPDriveTireAlarmHigh={props.changeDPDriveTireAlarmHigh}                        
    defaultDPDriveTireAlarmHigh={props.defaultDPDriveTireAlarmHigh}
    changeDPDriveTireAlarmHighTemp={props.changeDPDriveTireAlarmHighTemp}                        
    defaultDPDriveTireAlarmHighTemp={props.defaultDPDriveTireAlarmHighTemp}

  />

*/
