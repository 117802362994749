// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
// const UPDATE_FORM_STATE = 'final-form-redux-example/finalForm/UPDATE_FORM_STATE'

import { 
                        // ORDER_FORM_ADD,
                        // ORDER_FORM_EDIT,
    UPDATE_FORM_STATE,
} from '../../../../constants/action-constants'


// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_FORM_STATE:
      // console.log('4432-002 - action payload = ', action.payload);
      return {
        ...state,
        [action.form]: action.payload
      }
    default:
      return state
  }
}

// Action Creators
export const updateFormState = (form, state) => ({
  type: UPDATE_FORM_STATE,
  form,
  payload: state
})

// Selectors
export const getFormState = (state, form) =>
  (state && state.finalForm && state.finalForm[form]) || {}
