import axios from 'axios'
import {

  GET_ORDERS_REQUEST,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,

  GET_ORDERS_VIEW_REQUEST,
  GET_ORDERS_VIEW_FAIL,
  GET_ORDERS_VIEW_SUCCESS,

  GET_ORDER_REQUEST,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,


  ADD_ORDER_REQUEST,
  ADD_ORDER_FAIL,
  ADD_ORDER_SUCCESS,

  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,

  GET_ORDERS_COUNT_REQUEST,
  GET_ORDERS_COUNT_FAIL,
  GET_ORDERS_COUNT_SUCCESS,

} from '../constants/action-constants'
const TABLENAME = 'orders'
const TABLENAME_PENDING = 'orders/pendingapproval'
const TABLENAME_APPROVED = 'orders/approved'
const TABLENAME_VIEW = 'orders/view'
const TABLENAME_COUNT = 'orders/count'


export const getOrdersCount = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME_COUNT
  dispatch({
    type: GET_ORDERS_COUNT_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_ORDERS_COUNT_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_ORDERS_COUNT_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_ORDERS_COUNT_FAIL,
        payload: err.message
      })
    }
  }
}

export const getOrdersList = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME
  dispatch({
    type: GET_ORDERS_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_ORDERS_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: err.message
      })
    }
  }
}

export const getOrdersPendingList = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME_PENDING
  dispatch({
    type: GET_ORDERS_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_ORDERS_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: err.message
      })
    }
  }
}



export const getOrdersApprovedList = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME_APPROVED
  dispatch({
    type: GET_ORDERS_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_ORDERS_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: err.message
      })
    }
  }
}

export const getOrder_View = (orderid, token) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME_VIEW + "/" + orderid;
  // console.log('BBBB - 4432-0054 get view = ', JSONURL);
  dispatch({
    type: GET_ORDERS_VIEW_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_ORDERS_VIEW_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_ORDERS_VIEW_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_ORDERS_VIEW_FAIL,
        payload: err.message
      })
    }
  }
}

export const getOrder = (id, token) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME + "/" + id;
  // console.log('RRRRRR - 4432-00522 get view = ', JSONURL);
  dispatch({
    type: GET_ORDER_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_ORDER_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_ORDER_FAIL,
        payload: err.message
      })
    }
  }
}

export const addOrder = (params, token) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME + "/" ;
  // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };
  // console.log('CCCCCC - 4432-0056 get view = ', JSONURL);
  dispatch({
    type: ADD_ORDER_REQUEST
  })
  try {
    const { data } = await axios.post(
      JSONURL 
      , params
      // , HEADERS
    )
    dispatch({
      type: ADD_ORDER_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: ADD_ORDER_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: ADD_ORDER_FAIL,
        payload: err.message
      })
    }
  }
}

 
export const updateOrder = (id, params, token) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME + "/" + id;
  // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };
  // console.log('JJJJJJ - 4432-0058 get view = ', JSONURL);
  dispatch({
    type: UPDATE_ORDER_REQUEST
  })
  try {
    const { data } = await axios.put(
      JSONURL 
      , params
      // , HEADERS
    )
    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        payload: err.message
      })
    }
  }
}







// export function updateOrderSaveAndClose(id, params, token) {
//   const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME + "/" + id;
//   // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };
//   console.log('GGGGG - 4432-0059 get view = ', JSONURL);
//   dispatch({
//     type: UPDATE_ORDER_REQUEST
//   })
//   try {
//     const { data } = await axios.put(
//       JSONURL 
//       , params
//       // , HEADERS
//     )
//     dispatch({
//       type: UPDATE_ORDER_SUCCESS,
//       payload: data
//     })
//   } catch (err) {
//     if (err.message === 'Network Error') {
//       dispatch({
//         type: UPDATE_ORDER_FAIL,
//         payload: 'Data Server Appears To Be Offline'
//       })
//     } else {
//       dispatch({
//         type: UPDATE_ORDER_FAIL,
//         payload: err.message
//       })
//     }
//   }
// }

// export function updateOrderSaveAndClose(id, params, token) {
//   const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME + "/" + id;
//   // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };

//   return {
//       type: UPDATE_ORDER
//       , payload: new Promise(( resolve, reject) => {
//           axios.put(
//               JSONURL
//               , params
//               // , HEADERS
//           )
//           .then(function (response) {
//               resolve(response);
//           })
//           .catch(function (error) {
//               reject(error);
//           });
//       })
//   };
// }


// export function addOrder(params, token) {
//   const JSONURL = process.env.REACT_APP_DATABASE_URL + "/" + TABLENAME + "/" ;
//   // const HEADERS = { headers: { 'Authorization': 'Bearer ' + token, } };

//   return {
//       type: ADD_ORDER
//       , payload: new Promise(( resolve, reject) => {
//           axios.post(
//               JSONURL 
//               , params
//               // , HEADERS
//           )
//           .then(function (response) {
//               resolve(response);
//           })
//           .catch(function (error) {
//               reject(error);
//           });
//       })
//   };
// }






// return {
//     type: GET_ORDERS,
//     payload: new Promise(( resolve, reject) => {
//         axios.get(
//             JSONURL
//             // , HEADERS
//         )
//         .then(function (response) {
//             resolve(response);
//         })
//         .catch(function (error) {
//             reject(error);
//         });
//     })
// };

// export const listProducts = () => async (dispatch) => {
//     try {
//         // const {data} = await axios.get('/api/products');
//         const {data} = await axios.get('http://localhost:5000/api/products');
//         dispatch({
//             type: PRODUCT_LIST_SUCCESS,
//             payload: data
//         })
//     } catch (err) {
//         dispatch({
//             type: PRODUCT_LIST_FAIL,
//             payload: err.message
//         })
//     }
// }
