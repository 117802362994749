import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));
const Homepage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper>
        <CardHeader title="Welcome to Order Creator" />
        <Divider />
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={6} sm={6} xl={3} xs={12}>
                <Button color="primary" variant="contained" href="/orders/add">
                  Create Order
                </Button>
              </Grid>

              <Grid item lg={6} sm={6} xl={3} xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  href="/orders/pendingapproval">
                  Orders Pending Approval
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
};
export default Homepage;
