import React from 'react';
import { Field } from 'react-final-form';
/*** required for SELECT field onChange event capture or value chance capture in calculations --- START */
import { OnChange } from 'react-final-form-listeners';
/*** required for SELECT field onChange event capture or value chance capture in calculations --- END */
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { Checkbox, Radio, Select } from 'final-form-material-ui';
import { ToggleButton } from '@material-ui/lab';

import SnackbarError from 'components/snackbar/snackbar-error';


/////////

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
        <OnChange name={field}>
          {value => {
            if (value === becomes) {
              onChange(to)
            }
          }}
        </OnChange>
      )}
  </Field>
)

////////////////



export const renderTextField = ({
  requiredText,
  locked,
  label,
  id,
  emptyText,
  footerMessage,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <div>
    <TextField
      required={requiredText ? true : false}
      disabled={locked}
      label={label}
      placeholder={emptyText}
      helperText={footerMessage} // same as FormHelperText but not bold like FormHelperText
      error={error ? true : false}
      hinttext={footerMessage}
      fullWidth
      variant="outlined"
      {...input}
      {...custom}
    />
  </div>
);

/*** EMAIL Textboxes works */
export const renderTextField_EMAIL = ({
  requiredText,
  locked,
  label,
  emptyText,
  footerMessage,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControl fullWidth error={touched}>
    <TextField
      type="email"
      required={requiredText ? true : false}
      disabled={locked}
      label={label}
      placeholder={emptyText}
      helperText={footerMessage} // same as FormHelperText but not bold like FormHelperText
      error={error ? true : false}
      hinttext={footerMessage}
      fullWidth
      variant="outlined"
      {...input}
      {...custom}
    />
    <FormHelperText>
      {error ? (
        <span>
          {' '}
          <b> {footerMessage} </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** PASSWORD Textboxes works */
export const renderTextField_PASSWORD = ({
  requiredText,
  locked,
  label,
  emptyText,
  footerMessage,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControl fullWidth error={touched}>
    <TextField
      type="password"
      required={requiredText ? true : false}
      disabled={locked}
      label={label}
      placeholder={emptyText}
      helperText={footerMessage} // same as FormHelperText but not bold like FormHelperText
      error={error ? true : false}
      hinttext={footerMessage}
      fullWidth
      variant="outlined"
      {...input}
      {...custom}
    />
    <FormHelperText>
      {error ? (
        <span>
          {' '}
          <b> {footerMessage} </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** DATE Textboxes works */
export const renderTextField_DATE = ({
  requiredText,
  locked,
  label,
  defaultValue,
  emptyText,
  footerMessage,
  rows,
  input,
  input: { value },
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControl fullWidth error={touched}>
    <TextField
      type="date"
      InputLabelProps={{
        shrink: true
      }}
      label={label}
      defaultValue={defaultValue}
      disabled={locked}
      value={value}
      {...input}
      {...custom}
    />

    <FormHelperText>
      {error ? (
        <span>
          {' '}
          <b> {footerMessage} </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** TIME Textboxes works */
export const renderTextField_TIME = ({
  requiredText,
  locked,
  label,
  emptyText,
  footerMessage,
  rows,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControl fullWidth error={touched}>
    <TextField
      type="time"
      required={requiredText ? true : false}
      disabled={locked}
      label={label}
      placeholder={emptyText}
      error={error ? true : false}
      helperText={touched && error}
      fullWidth
      variant="outlined"
      {...input}
      {...custom}
    />
    <FormHelperText>
      {error ? (
        <span>
          {' '}
          <b> {footerMessage} </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** Multi Line Textbox */
export const renderTextField_MULTILINE = ({
  requiredText,
  locked,
  label,
  emptyText,
  footerMessage,
  defaultValue,
  value,
  rows,
  meta: { touched, invalid, error },
  input,
  ...custom
}) => (
  <FormControl fullWidth error={touched}>
    <TextField
      required={requiredText ? true : false}
      disabled={locked}
      label={label}
      placeholder={emptyText}
      helperText={footerMessage} // same as FormHelperText but not bold like FormHelperText
      multiline
      rows={rows}
      error={error ? true : false}
      hinttext={footerMessage}
      fullWidth
      variant="outlined"
      {...input}
      {...custom}
    />
    <FormHelperText>
      {error ? (
        <span>
          {' '}
          <b> {footerMessage} </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** checkbox WORKS */
export const renderCheckbox = ({
  objName,
  label,
  locked,
  footerMessage,
  input: { checked },
  meta: { touched, invalid, error },
  ...rest
}) => (
  <FormControl fullWidth error={touched}>
    <FormControlLabel
      label={label}
      control={
        <Field
          type="checkbox"
          component={Checkbox}
          checked={checked}
          name={objName}
          label={label}
          disabled={locked ? true : false}
          {...rest}
        />
      }
    />
    <FormHelperText>
      {footerMessage}
      {error ? (
        <span>
          <b>
            {' '}
            <br /> {error}{' '}
          </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** Toggle Switch aka Checkbox */
export const renderToggle = ({
  label,
  locked,
  checked,
  footerMessage,
  input: { onChange, value },
  meta: { touched, invalid, error },
  ...rest
}) => (
  <FormControl fullWidth error={touched}>
    {/* <Toggle */}
    <ToggleButton
      disabled={locked}
      label={label}
      toggled={!!value}
      onToggle={(event, isInputChecked) => onChange(isInputChecked)}
      {...rest}
    />
    <FormHelperText>
      {footerMessage}
      {error ? (
        <span>
          <b>
            {' '}
            <br /> {error}{' '}
          </b>
        </span>
      ) : (
        ''
      )}
    </FormHelperText>
  </FormControl>
);

/*** Radio Group */
/*** NOTE REQUIRES objName to set the name !!! */
/*** dataArray = [ { value: 1, label: 'label' }] */
export const renderRadioGroupField = ({
  footerMessage,
  id,
  input,
  label,
  locked,
  children,
  dataArray,
  meta: { onChange, touched, invalid, error },
  ...custom
}) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">{label}</FormLabel>
    <RadioGroup {...input} {...custom} disabled={locked} native>
      {dataArray.map((optionItem) => (
        <React.Fragment key={optionItem.value}>
          <FormControlLabel
            control={<Radio />}
            value={optionItem.value}
            label={optionItem.label}
          />
        </React.Fragment>
      ))}
    </RadioGroup>

    <FormHelperText>{footerMessage}</FormHelperText>
  </FormControl>
);

/*** Drop Down Selection with changing options based on conditions*/
/*** children displayed as a child object */
export const renderSelectField_Conditional_Options = ({
  id,
  objName,
  footerMessage,
  label,
  locked,
  children,
  onChangeMethod,
  requireOnChangeMethod = true,
  input: { onChange, onToggle },
  meta: { touched, error },
  ...rest
}) => (
  <FormControl fullWidth error={touched}>
    <Field
      component={Select}
      label={label}
      name={objName}
      disabled={locked}
      formControlProps={{
        fullWidth: true,
        margin: 'none'
      }}
      {...rest}>
      {children}
    </Field>
    <OnChange name={objName}>
      {(value, previous) => {
        if (requireOnChangeMethod) {
          onChangeMethod(value);
        }
      }}
    </OnChange>
    <FormHelperText>{footerMessage}</FormHelperText>
  </FormControl>
);

/*** Drop Down Selection */
/*** NOTE REQUIRES objName to set the name !!! */
/*** dataArray = [ { value: 1, label: 'label' }] */
/*** children displayed as a child object */
export const renderSelectField = ({
  requireOnChangeMethod = true,
  onChangeMethod,
  objName,
  footerMessage,
  label,
  locked,
  dataArray,
  children,
  input: { onChange, onToggle },
  meta: { touched, error },
  ...rest
}) => (
  <FormControl variant="outlined" fullWidth error={touched}>
    <Field
      component={Select}
      label={label}
      name={objName}
      disabled={locked}
      formControlProps={{
        fullWidth: true,
        margin: 'none'
      }}
      {...rest}>
      {dataArray.map((optionItem) => (
        <MenuItem key={optionItem.value} value={optionItem.value}>
          {optionItem.label}
        </MenuItem>
      ))}
    </Field>
    <OnChange name={objName}>
      {(value, previous) => {
        if (requireOnChangeMethod) {
          onChangeMethod(value);
        }
      }}
    </OnChange>

    <FormHelperText>{footerMessage}</FormHelperText>
  </FormControl>
);

/*** Drop Down Selection */
/*** NOTE REQUIRES objName to set the name !!! */
/*** dataArray = [ { value: 1, label: 'label' }] */
/*** children displayed as a child object */
export const renderSelectFieldWhereChange = ({

  WhereChange,
  WhereChangeValue,


  requireOnChangeMethod = true,
  onChangeMethod,
  objName,
  footerMessage,
  label,
  locked,
  dataArray,
  children,
  input: { onChange, onToggle },
  meta: { touched, error },
  ...rest
}) => (
  <FormControl variant="outlined" fullWidth error={touched}>
    <Field
      component={Select}
      label={label}
      name={objName}
      disabled={locked}
      formControlProps={{
        fullWidth: true,
        margin: 'none'
      }}
      {...rest}>
      {dataArray.map((optionItem) => (
        <MenuItem key={optionItem.value} value={optionItem.value}>
          {optionItem.label}
        </MenuItem>
      ))}
    </Field>
    <OnChange name={objName}>
      {(value, previous) => {
        if (requireOnChangeMethod) {
          onChangeMethod(value);
        }
      }}
    </OnChange>




{/* //////// */}


        <div>
          <label>
            <Field
              // name="condition"
              name={WhereChange}
              component="input"
              type="radio"
              value="show"
              // value={WhereChangeValue}
            />{" "}
            Show
          </label>
          <label>
            <Field
              // name="condition"
              name={WhereChange}
              component="input"
              type="radio"
              value="hide"
              // value={WhereChangeValue}
            />{" "}
            Hide
          </label>
        </div>

        {/* <Condition when="condition" is="show"> */}
        <Condition when={WhereChange} is={WhereChangeValue}>
        {/* <Condition when={WhereChange} is="show"> */}
        {/* <Condition when={WhereChange} is={WhereChange}> */}
        {/* <Condition when="condition" is="hide"> */}
        {/* <Condition when={objName} is="hide"> */}
          <div>
            <Field
              name="range"
              // name={objName}
              value="0"
              component="input"
              type="range"
              min="0"
              max="3000"
            />
            <Field name="range" value="0" component="input" type="text" />
          </div>
        </Condition>

        <WhenFieldChanges
          field="condition"
          // field={WhereChange}
          becomes={"show"}
          set="range"
          to={"0"}
        />

{/* ////// */}


    <FormHelperText>{footerMessage}</FormHelperText>
  </FormControl>
);

/*** SNACK BAR Custom Error*/
export const renderSnackbarErrorCustom = ({ input, error }) => (
  <FormControl fullWidth>
    {error ? <SnackbarError variant="error" message={error} /> : ''}
  </FormControl>
);

/*** SNACK BAR ERROR */
export const renderSnackbarError = ({
  input,
  meta: { touched, invalid, error },
  ...rest
}) => (
  <FormControl fullWidth error={touched}>
    {error ? <SnackbarError variant="error" message={error} /> : ''}
  </FormControl>
);
