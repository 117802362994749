import axios from 'axios'
import {
  GET_RECEIVERS_REQUEST,
  GET_RECEIVERS_FAIL,
  GET_RECEIVERS_SUCCESS,

  GET_RECEIVERSQC_REQUEST,
  GET_RECEIVERSQC_FAIL,
  GET_RECEIVERSQC_SUCCESS,
} from 'constants/action-constants'

const TABLENAME_RECEIVERS = 'receivers'
const TABLENAME_RECEIVERSQC = 'receiversqc'
    // const TABLENAME_RECEIVERSQC = 'qc'
                                                // const TABLENAME_RECEIVERSQC = 'receiversqc'
    // const TABLENAME_RECEIVERSQC = 'qcdata'
                                                // const TABLENAME_RECEIVERSQC = 'sensorsqc'

export const getReceiversQCList = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME_RECEIVERSQC
  dispatch({
    type: GET_RECEIVERSQC_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_RECEIVERSQC_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_RECEIVERSQC_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_RECEIVERSQC_FAIL,
        payload: err.message
      })
    }
  }
}


export const getReceiversQCReceipt = (salesordernumber) => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + TABLENAME_RECEIVERS + "/" + salesordernumber
  dispatch({
    type: GET_RECEIVERS_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_RECEIVERS_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_RECEIVERS_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_RECEIVERS_FAIL,
        payload: err.message
      })
    }
  }
}













// return {
//     type: GET_RECEIVERSQC,
//     payload: new Promise(( resolve, reject) => {
//         axios.get(
//             JSONURL
//             // , HEADERS
//         )
//         .then(function (response) {
//             resolve(response);
//         })
//         .catch(function (error) {
//             reject(error);
//         });
//     })
// };

// export const listProducts = () => async (dispatch) => {
//     try {
//         // const {data} = await axios.get('/api/products');
//         const {data} = await axios.get('http://localhost:5000/api/products');
//         dispatch({
//             type: PRODUCT_LIST_SUCCESS,
//             payload: data
//         })
//     } catch (err) {
//         dispatch({
//             type: PRODUCT_LIST_FAIL,
//             payload: err.message
//         })
//     }
// }
