import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import DashboardWidget from './components/Dashboard-Widget/Dashboard-Widget';
import SpinnerLoading from 'components/spinner-loading/spinner-loading';
import MessageBox from 'components/MessageBox/MessageBox';
import { getDashboardCounts } from 'actions/global-actions';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));
const Dashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalsList = useSelector((state) => state.globals);
  const {
    loading,
    error,
    orderCount,
    receiversqcdCount,
    salesorderCount
  } = globalsList;
  useEffect(() => {
    dispatch(getDashboardCounts());
  }, [dispatch]);
  return (
    <div>
      {loading ? (
        <SpinnerLoading />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <DashboardWidget
                count={receiversqcdCount}
                icon={3}
                link={'/receiversqc'}
                title={'Receivers QC.d'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <DashboardWidget
                count={salesorderCount}
                icon={4}
                link={'/receiversqc'}
                title={'Sales Orders'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <DashboardWidget
                count={orderCount}
                icon={2}
                link={'/orders'}
                title={'Orders'}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
