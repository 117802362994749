/***
 * most changes occur here
 * add the change to each field


final-form-calculate-decorator.js - conditions to default value

display-programming.js - conditions to show
display-programming-trailer-programing-type.js - conditions to show
display-programming-truck-programing-type.js - conditions to show

*/


/********** form calculations ***** START  ****/
import createDecorator from 'final-form-calculate';
/********** form calculations ***** END  ****/

/********** form calculations ***** START  ****/
/*** if field value changes do something here --- START */
export const calculator = createDecorator(
    {
      field: 'steertirecoldpressure', // when the value of field changes...
      updates: (value, name, allValues) => {
        if (allValues) {
          let Vsteertirealarmsettingsdefaultlowadjustvalue =
            allValues.steertirealarmsettingsdefaultlowadjustvalue;
          let Vsteertirealarmsettingsdefaulthighadjustvalue =
            allValues.steertirealarmsettingsdefaulthighadjustvalue;
          if (allValues.steertirealarmsettingsdefaultlow) {
            Vsteertirealarmsettingsdefaultlowadjustvalue =
              parseInt(allValues.steertirecoldpressure) -
              parseInt(allValues.steertirecoldpressure * 0.1);
          }
          if (allValues.steertirealarmsettingsdefaulthigh) {
            Vsteertirealarmsettingsdefaulthighadjustvalue =
              parseInt(allValues.steertirecoldpressure) +
              parseInt(allValues.steertirecoldpressure * 0.25);
          }
          return {
            steertirealarmsettingsdefaultlowadjustvalue:
              Vsteertirealarmsettingsdefaultlowadjustvalue,
            steertirealarmsettingsdefaulthighadjustvalue:
              Vsteertirealarmsettingsdefaulthighadjustvalue
          };
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'steertirealarmsettingsdefaultlow', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.steertirealarmsettingsdefaultlow) {
            return {
              steertirealarmsettingsdefaultlowadjustvalue:
                parseInt(allValues.steertirecoldpressure) -
                parseInt(allValues.steertirecoldpressure * 0.1)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'steertirealarmsettingsdefaulthigh', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.steertirealarmsettingsdefaulthigh) {
            return {
              steertirealarmsettingsdefaulthighadjustvalue:
                parseInt(allValues.steertirecoldpressure) +
                parseInt(allValues.steertirecoldpressure * 0.25)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'steertirealarmsettingsdefaulthightemp', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.steertirealarmsettingsdefaulthightemp) {
            return { steertirealarmsettingsdefaulthightempadjustvalue: 158 };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
  
  
  
  
  
  
  

    



    /*** TireView™ – Display / Programming --- start */
    {
      field: 'dpdisplayselection', // when this field value changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (
            allValues.dpdisplayselection === 1 &&
            allValues.dptelematics === 1
          ) {
            return { 
              dppreprogramming: 1 
            };
















            // #10
            } else if (
              (
                  (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                  (allValues.dpdisplayselection === 1) &&
                  // allValues.dptelematics === 2 &&
                  allValues.dppreprogramming === 2 &&
                  (
                      allValues.trailertelematicsbrand === 4 ||
                      allValues.trailertelematicsbrand === 5 ||
                      allValues.trailertelematicsbrand === 8 ||
                      allValues.trailertelematicsbrand === 9 
                  ) 
              )
            ) {
              return { 
                trailerprogrammingtype: 5,            
               };


            // #11
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 4 ||
                    allValues.trailertelematicsbrand === 5 ||
                    allValues.trailertelematicsbrand === 8 ||
                    allValues.trailertelematicsbrand === 9 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 5,            
          };


            // #12
            } else if (
              (
                  (allValues.vehicletype === 3) &&      
                  (allValues.dpdisplayselection === 1) &&
                  // (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                  allValues.dptelematics === 2 &&
                  allValues.dppreprogramming === 2 &&
                  (
                      allValues.trailertelematicsbrand === 4 ||
                      allValues.trailertelematicsbrand === 8 
                  ) 
              )
            ) {
              return { 
                trailerprogrammingtype: 5,            
               };


            // #13
            } else if (
              (
                  (allValues.vehicletype === 3) &&      
                  (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                  allValues.dptelematics === 2 &&
                  allValues.dppreprogramming === 2 &&
                  (
                      allValues.trailertelematicsbrand === 4 ||
                      allValues.trailertelematicsbrand === 8 
                  ) 
              )
            ) {
              return { 
                trailerprogrammingtype: 6,            
               };










            // #14
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 1) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 5,            
              trailerprogrammingtype: 5,            
             };


          // #15
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 6,            
              trailerprogrammingtype: 6,            
             };





            // #8
        } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 3,            
             };


            // #3
          } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) && 
                // allValues.dptelematics === 1 &&
                allValues.dppreprogramming === 2 
            )
          ) {
            return { 
                truckprogrammingtype: 1,            
             };


             // #5
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                allValues.dpdisplayselection === 1 &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 1 ||
                    allValues.trailertelematicsbrand === 2 ||
                    allValues.trailertelematicsbrand === 3 ||
                    allValues.trailertelematicsbrand === 7 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 2,            
             };

             // #6
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 1 ||
                    allValues.trailertelematicsbrand === 2 ||
                    allValues.trailertelematicsbrand === 3 ||
                    allValues.trailertelematicsbrand === 7 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 3,            
             };

            // #4
        } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) && 
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) && 
                // allValues.dptelematics === 1 &&
                allValues.dppreprogramming === 2 
            )
          ) {
            return { 
                trailerprogrammingtype: 1,            
             };
    






          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'dptelematics', // when this field value changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          /*** telematics is set to NO */
          if ( allValues.dptelematics === 1 ) {
            return {
              dppreprogramming: 1,
              /*** set the truck / trailer programming type = 0 - Select option */
              truckprogrammingtype: 0,
              trailerprogrammingtype: 0,
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'dppreprogramming', //  when this field value changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          /*** pre-programming is set to NO */
          if ( allValues.dppreprogramming === 1 ) {
            return {
              /*** set the truck / trailer programming type = 0 - Select option */
              truckprogrammingtype: 0,
              trailerprogrammingtype: 0,
            };
  


    
            // #10
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 1) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 4 ||
                    allValues.trailertelematicsbrand === 5 ||
                    allValues.trailertelematicsbrand === 8 ||
                    allValues.trailertelematicsbrand === 9 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 5,            
             };


            // #11
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 4 ||
                    allValues.trailertelematicsbrand === 5 ||
                    allValues.trailertelematicsbrand === 8 ||
                    allValues.trailertelematicsbrand === 9 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 5,            
             };


            // #12
            } else if (
              (
                  (allValues.vehicletype === 3) &&      
                  (allValues.dpdisplayselection === 1) &&
                  // (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                  allValues.dptelematics === 2 &&
                  allValues.dppreprogramming === 2 &&
                  (
                      allValues.trailertelematicsbrand === 4 ||
                      allValues.trailertelematicsbrand === 8 
                  ) 
              )
            ) {
              return { 
                trailerprogrammingtype: 5,            
               };


            // #13
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 4 ||
                    allValues.trailertelematicsbrand === 8 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 6,            
             };







            // #14
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 1) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 5,            
              trailerprogrammingtype: 5,            
             };


          // #15
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 6,            
              trailerprogrammingtype: 6,            
             };





            // #8
        } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 3,            
             };


            // #3
          } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) && 
                // allValues.dptelematics === 1 &&
                allValues.dppreprogramming === 2 
            )
          ) {
            return { 
                truckprogrammingtype: 1,            
             };

             // #5
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                allValues.dpdisplayselection === 1 &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 1 ||
                    allValues.trailertelematicsbrand === 2 ||
                    allValues.trailertelematicsbrand === 3 ||
                    allValues.trailertelematicsbrand === 7 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 2,            
             };

             // #6
            } else if (
                (
                    (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                    (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                    // allValues.dptelematics === 2 &&
                    allValues.dppreprogramming === 2 &&
                    (
                        allValues.trailertelematicsbrand === 1 ||
                        allValues.trailertelematicsbrand === 2 ||
                        allValues.trailertelematicsbrand === 3 ||
                        allValues.trailertelematicsbrand === 7 
                    ) 
                )
              ) {
                return { 
                  trailerprogrammingtype: 3,            
                 };

            // #4
        } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) && 
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) && 
                // allValues.dptelematics === 1 &&
                allValues.dppreprogramming === 2 
            )
          ) {
            return { 
                trailerprogrammingtype: 1,            
             };


            // #9
        } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                // allValues.dpdisplayselection === 1 &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trucktelematicsbrand === 4 ||
                    allValues.trucktelematicsbrand === 8
                )
            )
          ) {
            return { 
              truckprogrammingtype: 6,            
             };









          /*** if preprogramming is YES - vehicle type = truck or truck & trailer programming type default value */
          } else if (
            allValues.dppreprogramming === 2 &&
            (
              allValues.vehicletype === 1 ||
              allValues.vehicletype === 3
            ) 
          ) {
            return { 
              truckprogrammingtype: 6,
            };
  
          } else {
            return {};
          }
  
  
                                        /*** if preprogramming is YES - vehicle type = trailer programming type has a default value */
                                        // } else if (
                                          // allValues.dppreprogramming === 2 && allValues.vehicletype === 2
                                          // ) {
                                          // return { 
                                          //   truckprogrammingtype: 0,
                                          // };
                                        // /*** if preprogramming is NO - truck & trailer programming type resets to 0 select an option */
                                        // } else {
                                        //   console.log('4455-65 allValues.dppreprogramming = ', allValues.dppreprogramming);
                                        //   return { 
                                        //     // truckprogrammingtype: 0,
                                        //     // trailerprogrammingtype: 0,
                                        //    };
                                        // }
  
                                        
        } else {
          return {};
        }
      }
    },











    {
      field: 'trucktelematicsbrand', // when this field value changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {

                        // #9
          if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                // allValues.dpdisplayselection === 1 &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trucktelematicsbrand === 4 ||
                    allValues.trucktelematicsbrand === 8
                )
            )
          ) {
            return { 
              truckprogrammingtype: 6,            
             };
 




            // #14
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 1) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 5,            
              trailerprogrammingtype: 5,            
             };


          // #15
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 6,            
              trailerprogrammingtype: 6,            
             };



             
             
        // } else if (
        //     (
        //         allValues.dppreprogramming === 2 &&
        //     )
        //   ) {
        //     return { 
        //       truckprogrammingtype: 3,            
        //      };


 
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'trailertelematicsbrand', // when this field value changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {




            
          if (
            (
              allValues.dpdisplayselection === 1 &&
              allValues.dppreprogramming === 2 &&
              allValues.dptelematics === 2 &&
              (
                allValues.trailertelematicsbrand === 1 ||
                allValues.trailertelematicsbrand === 2 ||
                allValues.trailertelematicsbrand === 3 ||
                allValues.trailertelematicsbrand === 7 
              ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 2,            
             };







            // #10
            } else if (
              (
                  (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                  (allValues.dpdisplayselection === 1) &&
                  // allValues.dptelematics === 2 &&
                  allValues.dppreprogramming === 2 &&
                  (
                      allValues.trailertelematicsbrand === 4 ||
                      allValues.trailertelematicsbrand === 5 ||
                      allValues.trailertelematicsbrand === 8 ||
                      allValues.trailertelematicsbrand === 9 
                  ) 
              )
            ) {
              return { 
                trailerprogrammingtype: 5,            
               };


            // #11
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 4 ||
                    allValues.trailertelematicsbrand === 5 ||
                    allValues.trailertelematicsbrand === 8 ||
                    allValues.trailertelematicsbrand === 9 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 5,            
             };


            // #12
          } else if (
            (
                (allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 1) &&
                // (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 4 ||
                    allValues.trailertelematicsbrand === 8 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 5,            
             };


            // #13
            } else if (
              (
                  (allValues.vehicletype === 3) &&      
                  (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                  allValues.dptelematics === 2 &&
                  allValues.dppreprogramming === 2 &&
                  (
                      allValues.trailertelematicsbrand === 4 ||
                      allValues.trailertelematicsbrand === 8 
                  ) 
              )
            ) {
              return { 
                trailerprogrammingtype: 6,            
               };





          //   // #14
          // } else if (
          //   (
          //       (allValues.vehicletype === 3) &&      
          //       (allValues.dpdisplayselection === 1) &&
          //       allValues.dptelematics === 2 &&
          //       allValues.dppreprogramming === 2 &&
          //       allValues.trucktelematicsbrand === 6 
          //   )
          // ) {
          //   return { 
          //     truckprogrammingtype: 5,            
          //     trailerprogrammingtype: 5,            
          //    };


          // // #15
          // } else if (
          //   (
          //       (allValues.vehicletype === 3) &&      
          //       (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
          //       allValues.dptelematics === 2 &&
          //       allValues.dppreprogramming === 2 &&
          //       allValues.trucktelematicsbrand === 6 
          //   )
          // ) {
          //   return { 
          //     truckprogrammingtype: 6,            
          //     trailerprogrammingtype: 6,            
          //    };






             // #5
          } else if (
            (
                (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                allValues.dpdisplayselection === 1 &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trailertelematicsbrand === 1 ||
                    allValues.trailertelematicsbrand === 2 ||
                    allValues.trailertelematicsbrand === 3 ||
                    allValues.trailertelematicsbrand === 7 
                ) 
            )
          ) {
            return { 
              trailerprogrammingtype: 2,            
             };

             // #6
            } else if (
                (
                    (allValues.vehicletype === 2 || allValues.vehicletype === 3) &&      
                    (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                    // allValues.dptelematics === 2 &&
                    allValues.dppreprogramming === 2 &&
                    (
                        allValues.trailertelematicsbrand === 1 ||
                        allValues.trailertelematicsbrand === 2 ||
                        allValues.trailertelematicsbrand === 3 ||
                        allValues.trailertelematicsbrand === 7 
                    ) 
                )
              ) {
                return { 
                  trailerprogrammingtype: 3,            
                 };
    
            // #8
        } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                (allValues.dpdisplayselection === 2 || allValues.dpdisplayselection === 3) &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                allValues.trucktelematicsbrand === 6 
            )
          ) {
            return { 
              truckprogrammingtype: 3,            
             };


            // #9
        } else if (
            (
                (allValues.vehicletype === 1 || allValues.vehicletype === 3) &&      
                // allValues.dpdisplayselection === 1 &&
                // allValues.dptelematics === 2 &&
                allValues.dppreprogramming === 2 &&
                (
                    allValues.trucktelematicsbrand === 4 ||
                    allValues.trucktelematicsbrand === 8
                )
            )
          ) {
            return { 
              truckprogrammingtype: 6,            
             };







  
          } else if ( allValues.trailertelematicsbrand === 4 ) {
            return { 
              trailerprogrammingtype: 5,            
             };
          } else if ( allValues.trailertelematicsbrand === 5 ) {
            return {
              trailerprogrammingtype: 0,
             };
  
          } else {

            //   console.log('33333 else ');
            //   console.log('33333 else - vehicletype ', allValues.vehicletype);
            //   console.log('33333 else - dpdisplayselection ', allValues.dpdisplayselection);
            //   console.log('33333 else - dptelematics ', allValues.dptelematics);
            //   console.log('33333 else - dppreprogramming ', allValues.dppreprogramming);
            //   console.log('33333 else - trailertelematicsbrand ', allValues.trailertelematicsbrand);

            return {};
          }
        } else {
          return {};
        }
      }
    },
  
      /*** TireView™ – Display / Programming --- end */

  
  
  
  
  
    {
      field: 'drivetirecoldpressure', // when the value of field changes...
      updates: (value, name, allValues) => {
        if (allValues) {
          let Vdrivetirealarmsettingsdefaultlowadjustvalue =
            allValues.drivetirealarmsettingsdefaultlowadjustvalue;
          let Vdrivetirealarmsettingsdefaulthighadjustvalue =
            allValues.drivetirealarmsettingsdefaulthighadjustvalue;
          if (allValues.drivetirealarmsettingsdefaultlow) {
            Vdrivetirealarmsettingsdefaultlowadjustvalue =
              parseInt(allValues.drivetirecoldpressure) -
              parseInt(allValues.drivetirecoldpressure * 0.1);
          }
          if (allValues.drivetirealarmsettingsdefaulthigh) {
            Vdrivetirealarmsettingsdefaulthighadjustvalue =
              parseInt(allValues.drivetirecoldpressure) +
              parseInt(allValues.drivetirecoldpressure * 0.25);
          }
          return {
            drivetirealarmsettingsdefaultlowadjustvalue:
              Vdrivetirealarmsettingsdefaultlowadjustvalue,
            drivetirealarmsettingsdefaulthighadjustvalue:
              Vdrivetirealarmsettingsdefaulthighadjustvalue
          };
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'drivetirealarmsettingsdefaultlow', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.drivetirealarmsettingsdefaultlow) {
            return {
              drivetirealarmsettingsdefaultlowadjustvalue:
                parseInt(allValues.drivetirecoldpressure) -
                parseInt(allValues.drivetirecoldpressure * 0.1)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'drivetirealarmsettingsdefaulthigh', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.drivetirealarmsettingsdefaulthigh) {
            return {
              drivetirealarmsettingsdefaulthighadjustvalue:
                parseInt(allValues.drivetirecoldpressure) +
                parseInt(allValues.drivetirecoldpressure * 0.25)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'drivetirealarmsettingsdefaulthightemp', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.drivetirealarmsettingsdefaulthightemp) {
            return { drivetirealarmsettingsdefaulthightempadjustvalue: 158 };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'trailertirecoldpressure', // when the value of field changes...
      updates: (value, name, allValues) => {
        if (allValues) {
          let Vtrailertirealarmsettingsdefaultlowadjustvalue =
            allValues.trailertirealarmsettingsdefaultlowadjustvalue;
          let Vtrailertirealarmsettingsdefaulthighadjustvalue =
            allValues.trailertirealarmsettingsdefaulthighadjustvalue;
          if (allValues.trailertirealarmsettingsdefaultlow) {
            Vtrailertirealarmsettingsdefaultlowadjustvalue =
              parseInt(allValues.trailertirecoldpressure) -
              parseInt(allValues.trailertirecoldpressure * 0.1);
          }
          if (allValues.trailertirealarmsettingsdefaulthigh) {
            Vtrailertirealarmsettingsdefaulthighadjustvalue =
              parseInt(allValues.trailertirecoldpressure) +
              parseInt(allValues.trailertirecoldpressure * 0.25);
          }
          return {
            trailertirealarmsettingsdefaultlowadjustvalue:
              Vtrailertirealarmsettingsdefaultlowadjustvalue,
            trailertirealarmsettingsdefaulthighadjustvalue:
              Vtrailertirealarmsettingsdefaulthighadjustvalue
          };
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'trailerliftaxletirecoldpressure', // when the value of field changes...
      updates: (value, name, allValues) => {
        if (allValues) {
          let Vtrailerliftaxletirealarmsettingsdefaultlowadjustvalue =
            allValues.trailerliftaxletirealarmsettingsdefaultlowadjustvalue;
          let Vtrailerliftaxletirealarmsettingsdefaulthighadjustvalue =
            allValues.trailerliftaxletirealarmsettingsdefaulthighadjustvalue;
          if (allValues.trailerliftaxletirealarmsettingsdefaultlow) {
            Vtrailerliftaxletirealarmsettingsdefaultlowadjustvalue =
              parseInt(allValues.trailerliftaxletirecoldpressure) -
              parseInt(allValues.trailerliftaxletirecoldpressure * 0.1);
          }
          if (allValues.trailerliftaxletirealarmsettingsdefaulthigh) {
            Vtrailerliftaxletirealarmsettingsdefaulthighadjustvalue =
              parseInt(allValues.trailerliftaxletirecoldpressure) +
              parseInt(allValues.trailerliftaxletirecoldpressure * 0.25);
          }
          return {
            trailerliftaxletirealarmsettingsdefaultlowadjustvalue:
              Vtrailerliftaxletirealarmsettingsdefaultlowadjustvalue,
            trailerliftaxletirealarmsettingsdefaulthighadjustvalue:
              Vtrailerliftaxletirealarmsettingsdefaulthighadjustvalue
          };
        } else {
          return {};
        }
      }
    },
    {
      field: 'trailerliftaxletirealarmsettingsdefaultlow', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.trailerliftaxletirealarmsettingsdefaultlow) {
            return {
              trailerliftaxletirealarmsettingsdefaultlowadjustvalue:
                parseInt(allValues.trailerliftaxletirecoldpressure) -
                parseInt(allValues.trailerliftaxletirecoldpressure * 0.1)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'trailerliftaxletirealarmsettingsdefaulthigh', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.trailerliftaxletirealarmsettingsdefaulthigh) {
            return {
              trailerliftaxletirealarmsettingsdefaulthighadjustvalue:
                parseInt(allValues.trailerliftaxletirecoldpressure) +
                parseInt(allValues.trailerliftaxletirecoldpressure * 0.25)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'trailertirealarmsettingsdefaultlow', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.trailertirealarmsettingsdefaultlow) {
            return {
              trailertirealarmsettingsdefaultlowadjustvalue:
                parseInt(allValues.trailertirecoldpressure) -
                parseInt(allValues.trailertirecoldpressure * 0.1)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
    {
      field: 'trailertirealarmsettingsdefaulthigh', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.trailertirealarmsettingsdefaulthigh) {
            return {
              trailertirealarmsettingsdefaulthighadjustvalue:
                parseInt(allValues.trailertirecoldpressure) +
                parseInt(allValues.trailertirecoldpressure * 0.25)
            };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    },
  
    {
      field: 'trailertirealarmsettingsdefaulthightemp', // when the value of field changes...
      updates: (value, name, allValues, prevValues) => {
        if (allValues) {
          if (allValues.trailertirealarmsettingsdefaulthightemp) {
            return { trailertirealarmsettingsdefaulthightempadjustvalue: 158 };
          } else {
            return {};
          }
        } else {
          return {};
        }
      }
    }
  
  
  );
  /*** if field value changes do something here --- END */
  /********** form calculations ***** END  ****/
  