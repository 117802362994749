import React, {useState} from 'react'
import { FormSpy } from 'react-final-form'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  /*** Wizard Stepper --- START */
    Button,
    makeStyles,
    Paper,
    Stepper,
    Step,
    StepLabel,
    StepContent,
  /*** Wizard Stepper --- END */
} from '@material-ui/core'
import SRFCustomerDetails from './components/customer-details';
import SRFTruckSpecifications from './components/truck-specifications';
import SRFTrailerSpecifications from './components/trailer-specifications';
import SRFDisplayProgramming from './components/display-programming';
import VehicleNotSelected from 'components/messagecard/messagecard';

  /*** Wizard Stepper --- START */
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));
  /*** Wizard Stepper --- END */



function FormFields(props) {


  // console.log('0012 - props - ', props);
  // console.log('0013 - props.ccvalues - ', props.ccvalues);


  /*** Wizard Stepper --- START */
    function getSteps() {
      return ['Customer Details', 'Truck Specifications', 'Trailer Specifications', 'TireView™ – Display / Programming'];
    }

    function getStepContent(step) {
      switch (step) {
        case 0:
        // case 3:
          return <SRFCustomerDetails 
              locked={props.locked}
              vehicleTypeSelected={vehicleTypeSelected}
              changeVehicleType={changeVehicleTypeSelected}
              hardwareBillingTypeSelected={hardwareBillingTypeSelected}
              changeHardwareBillingType={changeHardwareBillingTypeSelected}
            />
          ;
        case 1:
          return <>
              {(vehicleTypeSelected === 1 || vehicleTypeSelected === 3 ) ? <>
                <SRFTruckSpecifications
                  locked={props.locked}
                  vehicleTypeSelected={vehicleTypeSelected}
                  
                  truckSensorsOnSteerAxleSelected={truckSensorsOnSteerAxleSelected}
                  changeTruckSensorsOnSteerAxle={changeTruckSensorsOnSteerAxleSelected}

                  truckSensorsOnDriveAxleSelected={truckSensorsOnDriveAxleSelected}
                  changeTruckSensorsOnDriveAxle={changeTruckSensorsOnDriveAxleSelected}
                  
                  truckFlowThruKitOnSteerAxle234Selected={truckFlowThruKitOnSteerAxle234Selected}
                  changeTruckFlowThruKitOnSteerAxle234={changeTruckFlowThruKitOnSteerAxle234Selected}

                  truckSpareTireSpecialtySensorsOnSteerAxle234Selected={truckSpareTireSpecialtySensorsOnSteerAxle234Selected}
                  changeTruckSpareTireSpecialtySensorsOnSteerAxle234={changeTruckSpareTireSpecialtySensorsOnSteerAxle234Selected}
                                    
                  truckHubTypeSelected={truckHubTypeSelected}
                  changeTruckHubType={changeTruckHubTypeSelected}

                />
              </> : <VehicleNotSelected title="VEHICLE NOT SELECTED" /> }
            </>
          ;
        case 2:
          return <>
            {(vehicleTypeSelected === 2 || vehicleTypeSelected === 3 ) ? <>
              <SRFTrailerSpecifications 
                locked={props.locked}
                vehicleTypeSelected={vehicleTypeSelected}

                trailerSensorsOnAxleSelected={trailerSensorsOnAxleSelected}
                changeTrailerSensorsOnAxle={changeTrailerSensorsOnAxleSelected}

                trailerAdaptToAtisSelected={trailerAdaptToAtisSelected}
                changeTrailerAdaptToAtis={changeTrailerAdaptToAtisSelected}

                trailerAdaptToAtisIdentifyBrandSelected={trailerAdaptToAtisIdentifyBrandSelected}
                changeTrailerAdaptToAtisIdentifyBrand={changeTrailerAdaptToAtisIdentifyBrandSelected}

                trailerFlowThruKitSelected={trailerFlowThruKitSelected}
                changeTrailerFlowThruKit={changeTrailerFlowThruKitSelected}

                trailerSpecialtySensorsSelected={trailerSpecialtySensorsSelected}
                changeTrailerSpecialtySensors={changeTrailerSpecialtySensorsSelected}

                trailerHubTypeSelected={trailerHubTypeSelected}
                changeTrailerHubType={changeTrailerHubTypeSelected}

                trailerSpindleTypeSelected={trailerSpindleTypeSelected}
                changeTrailerSpindleType={changeTrailerSpindleTypeSelected}
              />
            </> : <VehicleNotSelected title="VEHICLE NOT SELECTED" /> }
          </>
          ;
                                                       // return 'An ad group contains one or more ads which target a shared set of keywords.';
        case 3:
        // case 0:
          return <SRFDisplayProgramming
          custPush={props.custPush}
          
          currentUserID={props.currentUserID}
          tempID={props.tempID}

          locked={props.locked}
          vehicleTypeSelected={vehicleTypeSelected}

          DPDisplaySelection={DPDisplaySelection}
          changeDPDisplaySelectionSelected={changeDPDisplaySelectionSelected}
          
          showDPTelematics={showDPTelematics}
          changeDPTelematicsSelected={changeDPTelematicsSelected}

          showDPPreProgramming={showDPPreProgramming}
          changeDPPreProgrammingSelected={changeDPPreProgrammingSelected}


          showDPTruckTelematicsBrand={showDPTruckTelematicsBrand}
          changeDPTruckTelematicsBrand={changeDPTruckTelematicsBrand}

          showDPTrailerTelematicsBrand={showDPTrailerTelematicsBrand}
          changeDPTrailerTelematicsBrand={changeDPTrailerTelematicsBrand}



          defaultDPSteerTireAlarmLow={defaultDPSteerTireAlarmLow}
          changeDPSteerTireAlarmLow={changeDefaultDPSteerTireAlarmLow}

          defaultDPSteerTireAlarmHigh={defaultDPSteerTireAlarmHigh}
          changeDPSteerTireAlarmHigh={changeDefaultDPSteerTireAlarmHigh}

          defaultDPSteerTireAlarmHighTemp={defaultDPSteerTireAlarmHighTemp}
          changeDPSteerTireAlarmHighTemp={changeDefaultDPSteerTireAlarmHighTemp}


          defaultDPDriveTireAlarmLow={defaultDPDriveTireAlarmLow}
          changeDPDriveTireAlarmLow={changeDefaultDPDriveTireAlarmLow}

          defaultDPDriveTireAlarmHigh={defaultDPDriveTireAlarmHigh}
          changeDPDriveTireAlarmHigh={changeDefaultDPDriveTireAlarmHigh}

          defaultDPDriveTireAlarmHighTemp={defaultDPDriveTireAlarmHighTemp}
          changeDPDriveTireAlarmHighTemp={changeDefaultDPDriveTireAlarmHighTemp}


          defaultDPTrailerTireAlarmLow={defaultDPTrailerTireAlarmLow}
          changeDPTrailerTireAlarmLow={changeDPTrailerTireAlarmLow}

          defaultDPTrailerTireAlarmHigh={defaultDPTrailerTireAlarmHigh}
          changeDPTrailerTireAlarmHigh={changeDefaultDPTrailerTireAlarmHigh}

          defaultDPTrailerTireAlarmHighTemp={defaultDPTrailerTireAlarmHighTemp}
          changeDPTrailerTireAlarmHighTemp={changeDefaultDPTrailerTireAlarmHighTemp}



          defaultDPTrailerLiftAxleTireAlarmLow={defaultDPTrailerLiftAxleTireAlarmLow}
          changeDPTrailerLiftAxleTireAlarmLow={changeDPTrailerLiftAxleTireAlarmLow}                        

          defaultDPTrailerLiftAxleTireAlarmHigh={defaultDPTrailerLiftAxleTireAlarmHigh}
          changeDPTrailerLiftAxleTireAlarmHigh={changeDPTrailerLiftAxleTireAlarmHigh}                        





          DPTruckDataBillingSelected={DPTruckDataBillingSelected}
          changeDPTruckDataBillingSelected={changeDPTruckDataBillingSelected}

          DPTruckCustSatExpireSelected={DPTruckCustSatExpireSelected}
          changeDPTruckCustSatExpireSelected={changeDPTruckCustSatExpireSelected}

          DPTruckAfterExpirationSelected={DPTruckAfterExpirationSelected}
          changeDPTruckAfterExpirationSelected={changeDPTruckAfterExpirationSelected}

          
          DPTrailerDataBillingSelected={DPTrailerDataBillingSelected}
          changeDPTrailerDataBillingSelected={changeDPTrailerDataBillingSelected}

          DPTrailerCustSatExpireSelected={DPTrailerCustSatExpireSelected}
          changeDPTrailerCustSatExpireSelected={changeDPTrailerCustSatExpireSelected}

          DPTrailerAfterExpirationSelected={DPTrailerAfterExpirationSelected}
          changeDPTrailerAfterExpirationSelected={changeDPTrailerAfterExpirationSelected}





          TrailerProgrammingTypeSelected={TrailerProgrammingTypeSelected}
          changeTrailerProgrammingTypeSelected={changeTrailerProgrammingTypeSelected}
          
          TruckProgrammingTypeSelected={TruckProgrammingTypeSelected}
          changeTruckProgrammingTypeSelected={changeTruckProgrammingTypeSelected}
          


            />
          ;
        default:
          return 'UNKNOWN STEP';
      }
    }
  /*** Wizard Stepper --- END */


  /*** Wizard Stepper --- START */
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
    };
  /*** Wizard Stepper --- END */


  /*** SRFCustomerDetails --- START */
    const [vehicleTypeSelected, setVehicleTypeSelected] = useState(2) // same default as action-constant
    const [hardwareBillingTypeSelected, setHardwareBillingTypeSelected] = useState(3) // same default as action-constant
  
    const changeVehicleTypeSelected = (e) => {
        if (e !== 0) {
          setVehicleTypeSelected(e)
        }                                                        
    }
    const changeHardwareBillingTypeSelected = (e) => {
        if (e !== 0) {
          setHardwareBillingTypeSelected(e)
        }                                                        
    }
  /*** SRFCustomerDetails --- END */

  /*** SRFTruckSpecifications --- START  */
    const [truckSensorsOnSteerAxleSelected, setTruckSensorsOnSteerAxleSelected] = useState(1)
    const [truckSensorsOnDriveAxleSelected, setTruckSensorsOnDriveAxleSelected] = useState(1)
    const [truckFlowThruKitOnSteerAxle234Selected, setTruckFlowThruKitOnSteerAxle234Selected] = useState(1)

    const [truckHubTypeSelected, setTruckHubTypeSelected] = useState(1)
    const [trailerHubTypeSelected, setTrailerHubTypeSelected] = useState(1)
    const [trailerSpindleTypeSelected, setTrailerSpindleTypeSelected] = useState(1)

    const [truckSpareTireSpecialtySensorsOnSteerAxle234Selected, setTruckSpareTireSpecialtySensorsOnSteerAxle234Selected] = useState(1)

    const changeTruckSensorsOnSteerAxleSelected = (e) => {
      if (e !== 0) {
        setTruckSensorsOnSteerAxleSelected(e)
      }                                                        
    }
    const changeTruckSensorsOnDriveAxleSelected = (e) => {
        if (e !== 0) {
          setTruckSensorsOnDriveAxleSelected(e)
        }                                                        
    }
    const changeTruckFlowThruKitOnSteerAxle234Selected = (e) => {
        if (e !== 0) {
          setTruckFlowThruKitOnSteerAxle234Selected(e)
        }
    }

    const changeTruckHubTypeSelected = (e) => {
        if (e !== 0) {
          setTruckHubTypeSelected(e)
        }
    }
    const changeTrailerHubTypeSelected = (e) => {
        if (e !== 0) {
          setTrailerHubTypeSelected(e)
        }
    }
    const changeTrailerSpindleTypeSelected = (e) => {
        if (e !== 0) {
          setTrailerSpindleTypeSelected(e)
        }
    }


    const changeTruckSpareTireSpecialtySensorsOnSteerAxle234Selected = (e) => {
        if (e !== 0) {
          setTruckSpareTireSpecialtySensorsOnSteerAxle234Selected(e)
        }
    }   
  /*** SRFTruckSpecifications --- END  */

  /*** SRFTrailerSpecifications --- START  */
    const [trailerSensorsOnAxleSelected, setTrailerSensorsOnAxleSelected] = useState(1)
    const [trailerAdaptToAtisSelected, setTrailerAdaptToAtisSelected] = useState(1)
    const [trailerAdaptToAtisIdentifyBrandSelected, setTrailerAdaptToAtisIdentifyBrandSelected] = useState(1)
    const [trailerFlowThruKitSelected, setShowTrailerFlowThruKit] = useState(1)
    const [trailerSpecialtySensorsSelected, setTrailerSpecialtySensorsSelected] = useState(1)

    const changeTrailerSensorsOnAxleSelected = (e) => {
      if (e !== 0) {
        setTrailerSensorsOnAxleSelected(e)
      }
    }
    const changeTrailerAdaptToAtisSelected = (e) => {
      if (e !== 0) {
        setTrailerAdaptToAtisSelected(e)
      }
    }
    const changeTrailerAdaptToAtisIdentifyBrandSelected = (e) => {
      if (e !== 0) {
        setTrailerAdaptToAtisIdentifyBrandSelected(e)
      }
    }
    const changeTrailerFlowThruKitSelected = (e) => {
      if (e !== 0) {
        setShowTrailerFlowThruKit(e)
      }
    }
    const changeTrailerSpecialtySensorsSelected = (e) => {
      if (e !== 0) {
        setTrailerSpecialtySensorsSelected(e)
      }
    }
  /*** SRFTrailerSpecifications --- END  */

  /*** SRFDisplayProgramming --- START  */
    const [DPDisplaySelection, setDPDisplaySelectionSelected] = useState(1)
    const [showDPTelematics, setDPTelematicsSelected] = useState(1)
    const [showDPPreProgramming, setDPPreProgrammingSelected] = useState(1)


    const [showDPTruckTelematicsBrand, setDPTruckTelematicsBrand] = useState(0)
    const [showDPTrailerTelematicsBrand, setDPTrailerTelematicsBrand] = useState(0)


    const [defaultDPSteerTireAlarmLow, setDefaultDPSteerTireAlarmLow] = useState(true)
    const [defaultDPSteerTireAlarmHigh, setDefaultDPSteerTireAlarmHigh] = useState(true)
    const [defaultDPSteerTireAlarmHighTemp, setDefaultDPSteerTireAlarmHighTemp] = useState(true)

    const [defaultDPDriveTireAlarmLow, setDefaultDPDriveTireAlarmLow] = useState(true)
    const [defaultDPDriveTireAlarmHigh, setDefaultDPDriveTireAlarmHigh] = useState(true)
    const [defaultDPDriveTireAlarmHighTemp, setDefaultDPDriveTireAlarmHighTemp] = useState(true)

    const [defaultDPTrailerTireAlarmLow, setDefaultDPTrailerTireAlarmLow] = useState(true)
    const [defaultDPTrailerTireAlarmHigh, setDefaultDPTrailerTireAlarmHigh] = useState(true)
    const [defaultDPTrailerTireAlarmHighTemp, setDefaultDPTrailerTireAlarmHighTemp] = useState(true)

    const [defaultDPTrailerLiftAxleTireAlarmLow, setDefaultDPTrailerLiftAxleTireAlarmLow] = useState(true)
    const [defaultDPTrailerLiftAxleTireAlarmHigh, setDefaultDPTrailerLiftAxleTireAlarmHigh] = useState(true)


    
    const [DPTruckDataBillingSelected, setDPTruckDataBillingSelected] = useState(1)
    const [DPTruckCustSatExpireSelected, setDPTruckCustSatExpireSelected] = useState(1)
    const [DPTruckAfterExpirationSelected, setDPTruckAfterExpirationSelected] = useState(1)

    const [DPTrailerDataBillingSelected, setDPTrailerDataBillingSelected] = useState(1)
    const [DPTrailerCustSatExpireSelected, setDPTrailerCustSatExpireSelected] = useState(1)
    const [DPTrailerAfterExpirationSelected, setDPTrailerAfterExpirationSelected] = useState(1)


    const [TrailerProgrammingTypeSelected, setTrailerProgrammingTypeSelected] = useState(1)
    const [TruckProgrammingTypeSelected, setTruckProgrammingTypeSelected] = useState(1)
    



    
    const changeDPDisplaySelectionSelected = (e) => {
      if (e !== 0) {
        setDPDisplaySelectionSelected(e)
      }
    }
    const changeDPTelematicsSelected = (e) => {
      if (e !== 0) {
        setDPTelematicsSelected(e)
      }
    }
    const changeDPPreProgrammingSelected = (e) => {
      if (e !== 0) {
        setDPPreProgrammingSelected(e)
      }
    }



    const changeDPTruckDataBillingSelected = (e) => {
      if (e !== 0) {
        setDPTruckDataBillingSelected(e)
      }                                                        
    }
    const changeDPTruckCustSatExpireSelected = (e) => {
      if (e !== 0) {
        setDPTruckCustSatExpireSelected(e)
      }                                                        
    }
    const changeDPTruckAfterExpirationSelected = (e) => {
      if (e !== 0) {
        setDPTruckAfterExpirationSelected(e)
      }                                                        
    }

    const changeDPTrailerDataBillingSelected = (e) => {
      if (e !== 0) {
        setDPTrailerDataBillingSelected(e)
      }                                                        
    }
    const changeDPTrailerCustSatExpireSelected = (e) => {
      if (e !== 0) {
        setDPTrailerCustSatExpireSelected(e)
      }                                                        
    }
    const changeDPTrailerAfterExpirationSelected = (e) => {
      if (e !== 0) {
        setDPTrailerAfterExpirationSelected(e)
      }                                                        
    }



    const changeDPTruckTelematicsBrand = (e) => {
      if (e !== 0) {
        setDPTruckTelematicsBrand(e)
      }
    }
    const changeDPTrailerTelematicsBrand = (e) => {
      if (e !== 0) {
        setDPTrailerTelematicsBrand(e)
      }
    }



    const changeDefaultDPSteerTireAlarmLow = (e) => {
        setDefaultDPSteerTireAlarmLow(e.target.checked)
    }
    const changeDefaultDPSteerTireAlarmHigh = (e) => {
        setDefaultDPSteerTireAlarmHigh(e.target.checked)
    }
    const changeDefaultDPSteerTireAlarmHighTemp = (e) => {
        setDefaultDPSteerTireAlarmHighTemp(e.target.checked)
    }

    const changeDefaultDPDriveTireAlarmLow = (e) => {
        setDefaultDPDriveTireAlarmLow(e.target.checked)
    }
    const changeDefaultDPDriveTireAlarmHigh = (e) => {
        setDefaultDPDriveTireAlarmHigh(e.target.checked)
    }
    const changeDefaultDPDriveTireAlarmHighTemp = (e) => {
        setDefaultDPDriveTireAlarmHighTemp(e.target.checked)
    }

    const changeDPTrailerTireAlarmLow = (e) => {
        setDefaultDPTrailerTireAlarmLow(e.target.checked)
    }
    const changeDefaultDPTrailerTireAlarmHigh = (e) => {
        setDefaultDPTrailerTireAlarmHigh(e.target.checked)
    }
    const changeDefaultDPTrailerTireAlarmHighTemp = (e) => {
        setDefaultDPTrailerTireAlarmHighTemp(e.target.checked)
    }


    const changeDPTrailerLiftAxleTireAlarmLow = (e) => {
        setDefaultDPTrailerLiftAxleTireAlarmLow(e.target.checked)
    }
    const changeDPTrailerLiftAxleTireAlarmHigh = (e) => {
        setDefaultDPTrailerLiftAxleTireAlarmHigh(e.target.checked)
    }

    const changeTrailerProgrammingTypeSelected = (e) => {
        setTrailerProgrammingTypeSelected(e)
    }
    const changeTruckProgrammingTypeSelected = (e) => {
          setTruckProgrammingTypeSelected(e)
    }

  /*** SRFDisplayProgramming --- END  */











  

                                                // console.log('4432-66-67 - STEP 4 - FormFields - props = ', props);


  return (
    <div>
      <Card>
        <CardHeader title="TireView™ TPMS by P.S.I. - Specification Request Form" />
        <Divider />
        <CardContent>
















{/* //////////////////////////////////////// */}
{/* //////////////////////////////////////// */}
{/* //////////////////////////////////////// */}

{/* 

<SRFDisplayProgramming
custPush={props.custPush}

          
locked={props.locked}
vehicleTypeSelected={vehicleTypeSelected}

DPDisplaySelection={DPDisplaySelection}
changeDPDisplaySelectionSelected={changeDPDisplaySelectionSelected}

showDPTelematics={showDPTelematics}
changeDPTelematicsSelected={changeDPTelematicsSelected}

showDPPreProgramming={showDPPreProgramming}
changeDPPreProgrammingSelected={changeDPPreProgrammingSelected}


showDPTruckTelematicsBrand={showDPTruckTelematicsBrand}
changeDPTruckTelematicsBrand={changeDPTruckTelematicsBrand}

showDPTrailerTelematicsBrand={showDPTrailerTelematicsBrand}
changeDPTrailerTelematicsBrand={changeDPTrailerTelematicsBrand}



defaultDPSteerTireAlarmLow={defaultDPSteerTireAlarmLow}
changeDPSteerTireAlarmLow={changeDefaultDPSteerTireAlarmLow}

defaultDPSteerTireAlarmHigh={defaultDPSteerTireAlarmHigh}
changeDPSteerTireAlarmHigh={changeDefaultDPSteerTireAlarmHigh}

defaultDPSteerTireAlarmHighTemp={defaultDPSteerTireAlarmHighTemp}
changeDPSteerTireAlarmHighTemp={changeDefaultDPSteerTireAlarmHighTemp}


defaultDPDriveTireAlarmLow={defaultDPDriveTireAlarmLow}
changeDPDriveTireAlarmLow={changeDefaultDPDriveTireAlarmLow}

defaultDPDriveTireAlarmHigh={defaultDPDriveTireAlarmHigh}
changeDPDriveTireAlarmHigh={changeDefaultDPDriveTireAlarmHigh}

defaultDPDriveTireAlarmHighTemp={defaultDPDriveTireAlarmHighTemp}
changeDPDriveTireAlarmHighTemp={changeDefaultDPDriveTireAlarmHighTemp}


defaultDPTrailerTireAlarmLow={defaultDPTrailerTireAlarmLow}
changeDPTrailerTireAlarmLow={changeDPTrailerTireAlarmLow}

defaultDPTrailerTireAlarmHigh={defaultDPTrailerTireAlarmHigh}
changeDPTrailerTireAlarmHigh={changeDefaultDPTrailerTireAlarmHigh}

defaultDPTrailerTireAlarmHighTemp={defaultDPTrailerTireAlarmHighTemp}
changeDPTrailerTireAlarmHighTemp={changeDefaultDPTrailerTireAlarmHighTemp}



defaultDPTrailerLiftAxleTireAlarmLow={defaultDPTrailerLiftAxleTireAlarmLow}
changeDPTrailerLiftAxleTireAlarmLow={changeDPTrailerLiftAxleTireAlarmLow}                        

defaultDPTrailerLiftAxleTireAlarmHigh={defaultDPTrailerLiftAxleTireAlarmHigh}
changeDPTrailerLiftAxleTireAlarmHigh={changeDPTrailerLiftAxleTireAlarmHigh}                        





DPTruckDataBillingSelected={DPTruckDataBillingSelected}
changeDPTruckDataBillingSelected={changeDPTruckDataBillingSelected}

DPTruckCustSatExpireSelected={DPTruckCustSatExpireSelected}
changeDPTruckCustSatExpireSelected={changeDPTruckCustSatExpireSelected}

DPTruckAfterExpirationSelected={DPTruckAfterExpirationSelected}
changeDPTruckAfterExpirationSelected={changeDPTruckAfterExpirationSelected}


DPTrailerDataBillingSelected={DPTrailerDataBillingSelected}
changeDPTrailerDataBillingSelected={changeDPTrailerDataBillingSelected}

DPTrailerCustSatExpireSelected={DPTrailerCustSatExpireSelected}
changeDPTrailerCustSatExpireSelected={changeDPTrailerCustSatExpireSelected}

DPTrailerAfterExpirationSelected={DPTrailerAfterExpirationSelected}
changeDPTrailerAfterExpirationSelected={changeDPTrailerAfterExpirationSelected}





TrailerProgrammingTypeSelected={TrailerProgrammingTypeSelected}
changeTrailerProgrammingTypeSelected={changeTrailerProgrammingTypeSelected}

TruckProgrammingTypeSelected={TruckProgrammingTypeSelected}
changeTruckProgrammingTypeSelected={changeTruckProgrammingTypeSelected}


            />

  */}

{/* //////////////////////////////////////// */}
{/* //////////////////////////////////////// */}
{/* //////////////////////////////////////// */}





















        {/* /*** Wizard Stepper --- START * / */}
          <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                                                                          {/* <Typography>{getStepContent(index)}</Typography> */}
                      {getStepContent(index)}
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>All steps complete, Form is ready to Save and Submit</Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Return to Step 1
                  </Button>
                </Paper>
              )}
          </div>
        {/* /*** Wizard Stepper --- END * / */}
























































{/***
 * On Load Update Form
 * 
 * initial Load set all drop downs
 * check vehicle type value passed to the form and call onChange event for all drop downs on the form
 * 
 * onChange for drop down item in list selected
 * 
*/}
<FormSpy
  subscription={ props.values }
  onChange={props => {
    /*** 
     * initial Load set all drop downs
     * check vehicle type value passed to the form and call onChange event for all drop downs on the form
    */

    /*** SRFCustomerDetails --- START  */
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeHardwareBillingTypeSelected(props.values ? props.values.hardwarebillingtype : 1) : null ;
    /*** SRFCustomerDetails --- END  */

    /*** SRFTruckSpecifications --- START  */
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTruckSensorsOnSteerAxleSelected(props.values ? props.values.trucksensorsonsteeraxle : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTruckSensorsOnDriveAxleSelected(props.values ? props.values.trucksensorsondriveaxle : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTruckFlowThruKitOnSteerAxle234Selected(props.values ? props.values.truckflowthrukit : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTruckHubTypeSelected(props.values ? props.values.truckhubtype : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerHubTypeSelected(props.values ? props.values.trailerhubtype : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerSpindleTypeSelected(props.values ? props.values.trailerspindletype : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTruckSpareTireSpecialtySensorsOnSteerAxle234Selected(props.values ? props.values.truckspecialtysensors : 1) : null ;
    /*** SRFTruckSpecifications --- END  */

    /*** SRFTrailerSpecifications --- START  */
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerSensorsOnAxleSelected(props.values ? props.values.trailersensorsontraileraxle : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerAdaptToAtisSelected(props.values ? props.values.traileradapttoatis : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerAdaptToAtisIdentifyBrandSelected(props.values ? props.values.traileridentifybrand : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerFlowThruKitSelected(props.values ? props.values.trailerflowthrukit : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerSpecialtySensorsSelected(props.values ? props.values.trailerspecialtysensors : 1) : null ;
    /*** SRFTrailerSpecifications --- END  */

    /*** SRFDisplayProgramming --- START  */
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPDisplaySelectionSelected(props.values ? props.values.dpdisplayselection : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTelematicsSelected(props.values ? props.values.dptelematics : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTruckTelematicsBrand(props.values ? props.values.trucktelematicsbrand : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTrailerTelematicsBrand(props.values ? props.values.trailertelematicsbrand : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPPreProgrammingSelected(props.values ? props.values.dppreprogramming : 1) : null ;

    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTruckDataBillingSelected(props.values ? props.values.dptruckdatabilling : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTruckCustSatExpireSelected(props.values ? props.values.dptruckcustsatexpire : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTruckAfterExpirationSelected(props.values ? props.values.dptruckafterexpiration : 1) : null ;

    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTrailerDataBillingSelected(props.values ? props.values.dptrailerdatabilling : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTrailerCustSatExpireSelected(props.values ? props.values.dptrailercustsatexpire : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeDPTrailerAfterExpirationSelected(props.values ? props.values.dptrailerafterexpiration : 1) : null ;

    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTrailerProgrammingTypeSelected(props.values ? props.values.trailerprogrammingtype : 1) : null ;
    props.dirtyFieldsSinceLastSubmit.vehicletype ? changeTruckProgrammingTypeSelected(props.values ? props.values.truckprogrammingtype : 1) : null ;
    /*** SRFDisplayProgramming --- END  */




    /***
     * onChange for drop down item in list selected
    */

    /*** SRFCustomerDetails --- START  */
    props.modified.hardwarebillingtype ? changeHardwareBillingTypeSelected(props.values ? props.values.hardwarebillingtype : 1) : null ;
    /*** SRFCustomerDetails --- END  */

    /*** SRFTruckSpecifications --- END  */
    props.modified.trucksensorsonsteeraxle ? changeTruckSensorsOnSteerAxleSelected(props.values ? props.values.trucksensorsonsteeraxle : 1) : null ;
    props.modified.trucksensorsondriveaxle ? changeTruckSensorsOnDriveAxleSelected(props.values ? props.values.trucksensorsondriveaxle : 1) : null ;
    props.modified.truckflowthrukit ? changeTruckFlowThruKitOnSteerAxle234Selected(props.values ? props.values.truckflowthrukit : 1) : null ;
    props.modified.truckhubtype ? changeTruckHubTypeSelected(props.values ? props.values.truckhubtype : 1) : null ;
    props.modified.trailerhubtype ? changeTrailerHubTypeSelected(props.values ? props.values.trailerhubtype : 1) : null ;
    props.modified.trailerspindletype ? changeTrailerSpindleTypeSelected(props.values ? props.values.trailerspindletype : 1) : null ;
    props.modified.truckspecialtysensors ? changeTruckSpareTireSpecialtySensorsOnSteerAxle234Selected(props.values ? props.values.truckspecialtysensors : 1) : null ;
    /*** SRFTruckSpecifications --- END  */

    /*** SRFTrailerSpecifications --- START  */
    props.modified.trailersensorsontraileraxle ? changeTrailerSensorsOnAxleSelected(props.values ? props.values.trailersensorsontraileraxle : 1) : null ;
    props.modified.traileradapttoatis ? changeTrailerAdaptToAtisSelected(props.values ? props.values.traileradapttoatis : 1) : null ;
    props.modified.traileridentifybrand ? changeTrailerAdaptToAtisIdentifyBrandSelected(props.values ? props.values.traileridentifybrand : 1) : null ;
    props.modified.trailerflowthrukit ? changeTrailerFlowThruKitSelected(props.values ? props.values.trailerflowthrukit : 1) : null ;
    props.modified.trailerspecialtysensors ? changeTrailerSpecialtySensorsSelected(props.values ? props.values.trailerspecialtysensors : 1) : null ;
    /*** SRFTrailerSpecifications --- END  */

    /*** SRFDisplayProgramming --- START  */
    props.modified.dpdisplayselection ? changeDPDisplaySelectionSelected(props.values ? props.values.dpdisplayselection : 1) : null ;
    props.modified.dptelematics ? changeDPTelematicsSelected(props.values ? props.values.dptelematics : 1) : null ;
    props.modified.trucktelematicsbrand ? changeDPTruckTelematicsBrand(props.values ? props.values.trucktelematicsbrand : 1) : null ;
    props.modified.trailertelematicsbrand ? changeDPTrailerTelematicsBrand(props.values ? props.values.trailertelematicsbrand : 1) : null ;
    props.modified.dppreprogramming ? changeDPPreProgrammingSelected(props.values ? props.values.dppreprogramming : 1) : null ;
    
    props.modified.dptruckdatabilling ? changeDPTruckDataBillingSelected(props.values ? props.values.dptruckdatabilling : 1) : null ;
    props.modified.dptruckcustsatexpire ? changeDPTruckCustSatExpireSelected(props.values ? props.values.dptruckcustsatexpire : 1) : null ;
    props.modified.dptruckafterexpiration ? changeDPTruckAfterExpirationSelected(props.values ? props.values.dptruckafterexpiration : 1) : null ;    
    
    props.modified.dptrailerdatabilling ? changeDPTrailerDataBillingSelected(props.values ? props.values.dptrailerdatabilling : 1) : null ;
    props.modified.dptrailercustsatexpire ? changeDPTrailerCustSatExpireSelected(props.values ? props.values.dptrailercustsatexpire : 1) : null ;
    props.modified.dptrailerafterexpiration ? changeDPTrailerAfterExpirationSelected(props.values ? props.values.dptrailerafterexpiration : 1) : null ;    
    
    props.modified.trailerprogrammingtype ? changeTrailerProgrammingTypeSelected(props.values ? props.values.trailerprogrammingtype : 1) : null ;
    props.modified.truckprogrammingtype ? changeTruckProgrammingTypeSelected(props.values ? props.values.truckprogrammingtype : 1) : null ;
    /*** SRFDisplayProgramming --- END  */

    
  }}
/>
{/* On Load Update Form */}




        </CardContent>
      </Card>

    </div>
  )
}
export default FormFields

/***
 * Required property 
 * locked=bool
 * 

  <FormFields
    locked={true / false}

  />

*/
