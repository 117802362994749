import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import Save from '@material-ui/icons/Save';

import { ButtonSaveAndClose } from 'theme';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  /*** Make the card width = 100% */
  root: {
    flexGrow: 1
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingCustomerLoginNAlerts(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

                                                  /* 
                                                    const [open, setOpen] = useState(false); 
                                                  */

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [realtimeAlert, setRealtimeAlert] = useState(false);
  const [reminderAlert, setReminderAlert] = useState(false);
  const [summaryAlert, setSummaryAlert] = useState(false);
  const [submittedBy, setSubmittedBy] = useState(props.currentUserID);
  const [tempID, setTempID] = useState(props.tempID);

                                                  /*
                                                    const [submittedBy, setSubmittedBy] = useState(0);
                                                  */

                                                  /*
                                                    const handleClickOpen = () => {
                                                      // setOpen(true);
                                                      props.windowStatus(true);
                                                    };
                                                  */

  const handleCancel = () => {
    
                                                  /*
                                                    setOpen(false);
                                                  */

    props.windowStatus(false);
  };
  const handleSave = () => {
    const formParams = {
      tempid: tempID,
      firstname: firstName,
      lastname: lastName,
      email: email,
      realtimealert: realtimeAlert,
      reminderalert: reminderAlert,
      summaryalert: summaryAlert,
      submittedby: submittedBy
    };
    props.custPush(formParams);

                                                  /*
                                                    setOpen(false);
                                                  */

    props.windowStatus(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <Dialog
          /*
                                                    open={open}
                                                  */

          open={props.open}
          onClose={handleCancel}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown>
          <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Email"
                      hinttext="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      fullWidth
                      variant="outlined"
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      hinttext="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      required={true}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      hinttext="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                      required={true}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          id="realtimeAlert"
                          name="realtimeAlert"
                          onChange={(e) => setRealtimeAlert(e.target.checked)}
                        />
                      }
                      value="start"
                      labelPlacement="start"
                      label="Real Time Alert"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          id="reminderAlert"
                          name="reminderAlert"
                          onChange={(e) => setReminderAlert(e.target.checked)}
                        />
                      }
                      value="start"
                      labelPlacement="start"
                      label="Reminder Alert"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          id="summaryAlert"
                          name="summaryAlert"
                          onChange={(e) => setSummaryAlert(e.target.checked)}
                        />
                      }
                      value="start"
                      labelPlacement="start"
                      label="Summary Alert"
                    />
                  </Grid>
                </Grid>

{/* 
                <Grid item xs={12} sm={6}>
                  <span>
                    submitted by: {submittedBy}
                  </span>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <span>
                    Temp ID: {tempID}
                  </span>
                </Grid>
 */}

              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <ButtonSaveAndClose
              size="large"
              type="submit"
              onClick={handleCancel}>
              Cancel
            </ButtonSaveAndClose>
            <ButtonSaveAndClose size="large" onClick={handleSave}>
              <Save />
              &nbsp; Save
            </ButtonSaveAndClose>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default SRFDisplayProgrammingCustomerLoginNAlerts;

/***
 * Required property
 * open=bool
 * windowStatus=bool
 * dialogTitle=string
 * custPush=function

  <SRFDisplayProgrammingCustomerLoginNAlerts 
    open={open}
    windowStatus={changeWindowStatus}
    dialogTitle={props.dialogTitle}
    custPush={addCuutomer}
  />

*/
