import {
  REGISTER_LOGIN_REQUEST,
  ADD_LOGIN_REQUEST,
  UPDATE_LOGIN_REQUEST,
  DELETE_LOGIN_REQUEST,
  GET_LOGIN_REQUEST,
  GET_LOGINS_LIST_REQUEST,
  VALIDATE_PASSWORD_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_LOGINS_LIST_SUCCESS,
  DELETE_LOGIN_SUCCESS,
  ADD_LOGIN_SUCCESS,
  REGISTER_LOGIN_SUCCESS,
  UPDATE_LOGIN_SUCCESS,
  VALIDATE_PASSWORD_SUCCESS,
  GET_LOGINS_LIST_FAIL,
  GET_LOGIN_FAIL,
  ADD_LOGIN_FAIL,
  UPDATE_LOGIN_FAIL,
  REGISTER_LOGIN_FAIL,
  VALIDATE_PASSWORD_FAIL,
  ADD_LOGIN_PENDING,
  ADD_LOGIN_FULFILLED,
  ADD_LOGIN_REJECTED,
  CREATE_NEW_LOGIN,
  DELETE_LOGIN_PENDING,
  DELETE_LOGIN_FULFILLED,
  GET_LOGIN_PENDING,
  GET_LOGIN_FULFILLED,
  GET_LOGIN_REJECTED,
  GET_LOGINS_LIST_PENDING,
  GET_LOGINS_LIST_FULFILLED,
  GET_LOGINS_LIST_REJECTED,
  REGISTER_LOGIN_PENDING,
  REGISTER_LOGIN_FULFILLED,
  REGISTER_LOGIN_REJECTED,
  UPDATE_LOGIN_PENDING,
  UPDATE_LOGIN_FULFILLED,
  UPDATE_LOGIN_REJECTED,
  VALIDATE_PASSWORD_PENDING,
  VALIDATE_PASSWORD_FULFILLED,
  VALIDATE_PASSWORD_REJECTED
} from '../actions/action-constants';
const initialState = {
  addSuccess: false,
  deleteSuccess: false,
  registerSuccess: false,
  updateSuccess: false,

  isAuthenticated: false,
  isAuthenticating: true,

  loading: false,
  loadingList: false,
  validatingPassword: false,

  logins: [],
  loginroles: [],

  dataEntry: {},
  dataList: [],
  referralEntry: [],

  errors: [],
  errorInvalidEntry: false

  /*** SPECIFIC TO THIS APP --- START */
  /*** SPECIFIC TO THIS APP --- END */
};
const reducerLogins = (state = initialState, action) => {
  var dataEntry;
  var dataList;
  switch (action.type) {
    case CREATE_NEW_LOGIN:
      dataEntry =
        typeof action.payload.login !== 'undefined' &&
        action.payload.login !== null
          ? action.payload.login
          : [];
      state = {
        ...state,
        loadingList: false,
        errorInvalidEntry: false,
        // returnedEntry: dataEntry,
        login: dataEntry
      };
      break;
    case REGISTER_LOGIN_REQUEST:
    case ADD_LOGIN_REQUEST:
    case UPDATE_LOGIN_REQUEST:
    case DELETE_LOGIN_REQUEST:
    case GET_LOGIN_REQUEST:
    case REGISTER_LOGIN_PENDING:
    case ADD_LOGIN_PENDING:
    case UPDATE_LOGIN_PENDING:
    case DELETE_LOGIN_PENDING:
    case GET_LOGIN_PENDING:
      state = {
        ...state,
        loading: true
      };
      break;
    case GET_LOGINS_LIST_REQUEST:
    case GET_LOGINS_LIST_PENDING:
      state = {
        ...state,
        loadingList: true
      };
      break;
    case VALIDATE_PASSWORD_REQUEST:
    case VALIDATE_PASSWORD_PENDING:
      state = {
        ...state,
        validatingPassword: true
      };
      break;
    case GET_LOGINS_LIST_SUCCESS:
    case GET_LOGINS_LIST_FULFILLED:
      dataList =
        typeof action.payload.logins !== 'undefined' &&
        action.payload.logins !== null
          ? action.payload.logins
          : [];
      state = {
        ...state,
        loading: false,
        loadingList: false,
        addSuccess: false,
        registerSuccess: false,
        updateSuccess: false,
        deleteSuccess: false,
        errorInvalidEntry: false,
        logins: dataList
      };
      break;
    case GET_LOGIN_SUCCESS:
    case GET_LOGIN_FULFILLED:
      var invalidEntry = false;
      if (Object.entries(action.payload.logins).length > 0) {
        dataEntry =
          typeof action.payload.logins !== 'undefined' &&
          action.payload.logins !== null
            ? action.payload.logins
            : [];
      } else {
        dataEntry = {};
        invalidEntry = true;
      }
      state = {
        ...state,
        loading: false,
        loadingList: false,
        addSuccess: false,
        registerSuccess: false,
        updateSuccess: false,
        deleteSuccess: false,
        errorInvalidEntry: invalidEntry,
        // returnedEntry: dataEntry,
        login: dataEntry
      };
      break;
    case DELETE_LOGIN_SUCCESS:
    case DELETE_LOGIN_FULFILLED:
      state = {
        ...state,
        loading: false,
        loadingList: false,
        deleteSuccess: true,
        errorInvalidEntry: false
      };
      break;
    case ADD_LOGIN_SUCCESS:
    case ADD_LOGIN_FULFILLED:
      state = {
        ...state,
        loading: false,
        loadingList: false,
        addSuccess: true,
        errorInvalidEntry: false
      };
      break;
    case REGISTER_LOGIN_SUCCESS:
    case REGISTER_LOGIN_FULFILLED:
      state = {
        ...state,
        loading: false,
        loadingList: false,
        registerSuccess: true,
        errorInvalidEntry: false
      };
      break;
    case UPDATE_LOGIN_SUCCESS:
    case UPDATE_LOGIN_FULFILLED:
      state = {
        ...state,
        loading: false,
        loadingList: false,
        updateSuccess: true,
        errorInvalidEntry: false
      };
      break;
    case VALIDATE_PASSWORD_SUCCESS:
    case VALIDATE_PASSWORD_FULFILLED:
      state = {
        ...state,
        loading: false,
        loadingList: false,
        validatingPassword: false,
        errorInvalidEntry: false
      };
      break;
    case GET_LOGINS_LIST_FAIL:
    case GET_LOGIN_FAIL:
    case ADD_LOGIN_FAIL:
    case UPDATE_LOGIN_FAIL:
    case REGISTER_LOGIN_FAIL:
    case VALIDATE_PASSWORD_FAIL:
    case GET_LOGINS_LIST_REJECTED:
    case GET_LOGIN_REJECTED:
    case ADD_LOGIN_REJECTED:
    case UPDATE_LOGIN_REJECTED:
    case REGISTER_LOGIN_REJECTED:
    case VALIDATE_PASSWORD_REJECTED:
      state = {
        ...state,
        errorInvalidEntry: true,
        errors: [action.payload.response]
      };
      break;
    default:
      return state;
  }
  return state;
};
export default reducerLogins;
