export const validateFields = async (values) => {
  let errors = {};

  // console.log('1501-222- values = ', values);
  // console.log('7505- values.quantity = ', values.quantity);

  /*** this has a major issue thatwhen you move through the steps the component that owns these values is not in focus and is therefore undefined */
  /*** Customer Details Componenet */
  if (!values.orderdate) {
    errors.orderdate = 'Invalid / Required';
    errors.notifyFormErrors = 'Valid Order Date Required';
  } else if (!values.jobnamepo) {
    errors.jobnamepo = 'Required';
    errors.notifyFormErrors = 'Job Name / PO Required';
  } else if (!values.fleetname) {
    errors.fleetname = 'Required';
    errors.notifyFormErrors = 'Fleet Name Required';
  } else if (!values.quantity || values.quantity < 0) {
    errors.quantity = 'Required';
    errors.notifyFormErrors = 'Quantity Required';

    /***  Componenet */

  // }else{
  //   console.log('AZ - xx - Referral form validated');
  }

  return errors;
};
export default validateFields;



// const validate = values => {
//   let errors = {};
//   if (!values.email) {
//     errors.email = "Email is required";
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
//     errors.email = "Invalid email address";
//   }
//   if (!values.password) {
//     errors.password = "Password is required";
//   }
//   return errors;
// };

