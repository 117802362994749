import React from 'react';
import ReactDOM from 'react-dom'; // new footer
// import { render } from 'react-dom'; // old footer
import { Provider } from 'react-redux';
import axios from 'axios';
// import * as serviceWorker from './serviceWorker'; // old footer
import reportWebVitals from './reportWebVitals'; // new footer
import store from './redux/reduxStore';
import App from './App';

/* this allows you to jwt.decode without have to make a webpack.config.js */
import jwtDecode from 'jwt-decode';
// import jwt from 'jsonwebtoken';
import {
  setDefaultAuthorizationTokenForAllHeaders,
  setCurrentUser,
  getLocalStorageToken,
  signOut,
  serverDown
} from './components/module-login/actions/actions-auth';

/* start - if user refreshes the page log them in from local storage - start */
const localToken = getLocalStorageToken();
if (localToken) {
  setDefaultAuthorizationTokenForAllHeaders(localToken);
  store.dispatch(
    // setCurrentUser(jwt.decode(localToken), localToken )
    setCurrentUser(jwtDecode(localToken), localToken)
  );
}
/* end - if user refreshes the page log them in from local storage - end */

/* start - once you log in if you get a 401 or 403 request sign the user out - start */
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const SERVERERROR = 500;
const { dispatch } = store; // direct access to redux store.
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    var status;
    if (error.response) {
      status = error.response.status;
    } else {
      status = 500;
    }
    if (status === UNAUTHORIZED || status === FORBIDDEN) {
      dispatch(signOut());
    } else if (status === SERVERERROR) {
      console.log(
        '4701 - 55555555 - eeeeee - remove me for fltest ing - FLTest = '
      );
      dispatch(serverDown());
    }
    return Promise.reject(error);
  }
);
/* end - once you log in if you get a 401 or 403 request sign the user out - end */

// // // old footer
// render(
// /*
//     you need a provider to make react and redux talk to each other
//     redux doesnt require react you can use it on its own
//     holds the whole state treee of your application
// */
//     <Provider store={store}>
//       <App />
//     </Provider>
//     , document.getElementById("root")
// );

// /*
//   If you want your app to work offline and load faster, you can change
//   unregister() to register() below. Note this comes with some pitfalls.
//   Learn more about service workers: https://bit.ly/CRA-PWA
// */
// serviceWorker.unregister();
// // // old footer

// new footer
ReactDOM.render(
  /*
  you need a provider to make react and redux talk to each other
  redux doesnt require react you can use it on its own
  holds the whole state treee of your application
*/
  /*
  React.StrictMode causes issues major with comboboxes...
  https://stackoverflow.com/questions/60903335/warning-finddomnode-is-deprecated-in-strictmode-finddomnode-was-passed-an-inst
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
*/
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// new footer
