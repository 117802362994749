import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import SpinnerLoading from 'components/spinner-loading/spinner-loading';
import MessageBox from 'components/MessageBox/MessageBox';
import OrderToolBar from './Orders-Toolbar/orders-toolbar';
/*** this is the old container --- start */
import { 
  getOrdersApprovedList,
  // getOrder,
  // addOrder,
  // updateOrder,
  // deleteOrder,
} from 'actions/orders-Actions';
function Orders(props) {
  const tableColumns = [
    { title: 'ID', field: 'id' },

    { title: 'Needs Approval', field: 'needsapproval' },
    { title: 'Needs Programming', field: 'needsprogramming' },
    { title: 'Needs QC', field: 'needsqc' },

    { title: 'Internal Order ID', field: 'internalorderid' },
    { title: 'Fleet Name', field: 'fleetname' },
    { title: 'Job Name PO', field: 'jobnamepo' },

    { title: 'Creator Comments', field: 'ordercreatorcomments' },
    { title: 'Approver Comments', field: 'orderapprovercomments' },
    { title: 'Programmer Comments', field: 'programmercomments' },
    { title: 'QC Comments', field: 'qccomments' },
];
  /*** this is the old container --- start */
  const dispatch = useDispatch();
  const ordersList = useSelector(state => state.orders);
  const { loading, error, title, orders } = ordersList;
  /*** this is the old container --- end */
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- start */
  useEffect(() => {
    dispatch(getOrdersApprovedList());
  }, [dispatch]);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */
  return (
    <div>
      {loading ? (
        <SpinnerLoading />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ maxWidth: '100%' }}>
        <OrderToolBar />
          <MaterialTable
            title={title}
            columns={tableColumns}
            data={orders}
            options={{
              // search
              search: true,
              // header grouping
              grouping: true,
              // or
              // column filtering
              // filtering: true,
              
              // pagination settings
              // show paging buttons
              // paging:false,
              // default items per page
              pageSize:10,
              // paging options
              pageSizeOptions:[10,25,50,100],

              // ???
              // columnsButton:false,
              // move buttons to right side of list
              // actionsColumnIndex: -1, 

              // styling otions
              rowStyle:{height:"20px"},
              maxBodyHeight: 700,
              
              // CSV export
              exportButton: true,
              // default filename
              exportFileName: 'PSIOTexport-Orders',
              // comma delimited
              // exportDelimiter: ',', 

            }}

            /*** Custom Toolbar */
            components={{
              Toolbar: props => (
                <div style={{
                  background: 'linear-gradient(45deg, #fce8ef 10%, #fff 70%)', // blue / fade light blue
                  color: 'white',
                }}>
                    <MTableToolbar {...props} />
                </div>
              ),
            }}

            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit Order',
                onClick: (event, rowData) => {
                  props.history.push('/orders/' + rowData.id);
                },
              },
              {
                icon: 'search',
                tooltip: 'View Order',
                onClick: (event, rowData) => {
                  // props.history.push('/orders/view/' + rowData.jobnamepo);
                  props.history.push('/orders/view/' + rowData.id);
                },
              }
            ]}

          />
        </div>
      )}
    </div>
  );
}
export default Orders;
