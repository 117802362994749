import axios from 'axios'
import {
  GET_DASHBOARD_COUNTS_REQUEST,
  GET_DASHBOARD_COUNTS_SUCCESS,
  GET_DASHBOARD_COUNTS_FAIL,
} from 'constants/action-constants'

const DASHBOARD_COUNTS = 'dashboard/counts'

export const getDashboardCounts = () => async dispatch => {
  const JSONURL = process.env.REACT_APP_DATABASE_URL + '/' + DASHBOARD_COUNTS
  dispatch({
    type: GET_DASHBOARD_COUNTS_REQUEST
  })
  try {
    const { data } = await axios.get(
      JSONURL
      // , HEADERS
    )
    dispatch({
      type: GET_DASHBOARD_COUNTS_SUCCESS,
      payload: data
    })
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch({
        type: GET_DASHBOARD_COUNTS_FAIL,
        payload: 'Data Server Appears To Be Offline'
      })
    } else {
      dispatch({
        type: GET_DASHBOARD_COUNTS_FAIL,
        payload: err.message
      })
    }
  }
}
