import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import LoginForm from './components/login-form';
import { getLoginRolesList } from '../../actions/actions-loginroles';
import {
  getLogin,
  updateLogin
  // deleteLogin,
} from '../../actions/actions-logins';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));
const LoginEdit = (props) => {
  /*** redirect and send to list screen if id doesnt exist */
  if (props.errorInvalidEntry) {
    console.log('fix the /logins redirect here ...');
    return <Redirect to="/logins" />;
  }
  const classes = useStyles();
  /*** this is the old container --- start */
  const dispatch = useDispatch();
  const loginsList = useSelector((state) => state.logins);
  const loginrolesList = useSelector((state) => state.loginroles);
  const { errors, errorInvalidEntry, login } = loginsList;
  const { loginroles } = loginrolesList;
  /*** this is the old container --- end */
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- start */
  useEffect(() => {
    dispatch(getLoginRolesList());
    dispatch(getLogin(props.match.params.id));
  }, [dispatch]);
  /*** this is the old componentDidMount - aka onLoad - you can have multiple useEffects --- end */

  const saveEntry = (id, params) => {
    console.log('edit', id);
    console.log('edit', params);

    dispatch(updateLogin(id, params)).then(() =>
      props.history.push('/logins/')
    );
  };

  // const deleteEntry = (id, description) => {
  //   console.log('edit', id);
  //   console.log('edit', params);

  //   dispatch(deleteLogin(id, description)).then(
  //     () => console.log('delete done')
  //     // ownProps.history.push('/logins')
  //   );
  // };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LoginForm
            {...props}
            componentMode={1}
            initialValues={login}
            loginroles={loginroles}
            errors={errors}
            errorInvalidEntry={errorInvalidEntry}
            saveEntry={saveEntry}
            // deleteEntry={deleteEntry}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default LoginEdit;
