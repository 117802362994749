const initialState = {
  notifications:[
    {key: '01',message: "AAAA"},
    {key: '02',message: "BBBB"},
    {key: '03',message: "CCCC"},
    {key: '04',message: "DDDD"},
    {key: '05',message: "EEEE"},
    {key: '06',message: "FFFF"},
  ],
  errors: [],
  loading: true,
  title: 'Notifications',
}
const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
export default notificationsReducer
