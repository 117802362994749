import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderCheckbox,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingTrailerLiftAxleAlarmSettings(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-64 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Trailer Lift Axle Alarm Settings" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="trailerliftaxletirecoldpressure"
                name="trailerliftaxletirecoldpressure"
                // objName='trailerliftaxletirecoldpressure'
                label="Trailer Lift Axle Tire Cold Pressure - Number"
                emptyText="Trailer Lift Axle Tire Cold Pressure - Number"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPTrailerLiftAxleTireAlarmLow}
                checked={props.defaultDPTrailerLiftAxleTireAlarmLow}
                id="trailerliftaxletirealarmsettingsdefaultlow"
                name="trailerliftaxletirealarmsettingsdefaultlow"
                objName="trailerliftaxletirealarmsettingsdefaultlow"
                label="Low (DEFAULT -10% under cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPTrailerLiftAxleTireAlarmLow}
                id="trailerliftaxletirealarmsettingsdefaultlowadjustvalue"
                name="trailerliftaxletirealarmsettingsdefaultlowadjustvalue"
                // objName='trailerliftaxletirealarmsettingsdefaultlowadjustvalue'
                label="Low Adjust Value"
                emptyText="Low Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPTrailerLiftAxleTireAlarmHigh}
                checked={props.defaultDPTrailerLiftAxleTireAlarmHigh}
                id="trailerliftaxletirealarmsettingsdefaulthigh"
                name="trailerliftaxletirealarmsettingsdefaulthigh"
                objName="trailerliftaxletirealarmsettingsdefaulthigh"
                label="High (DEFAULT +25% over cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPTrailerLiftAxleTireAlarmHigh}
                id="trailerliftaxletirealarmsettingsdefaulthighadjustvalue"
                name="trailerliftaxletirealarmsettingsdefaulthighadjustvalue"
                // objName='trailerliftaxletirealarmsettingsdefaulthighadjustvalue'
                label="High Adjust Value"
                emptyText="High Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingTrailerLiftAxleAlarmSettings;

/***
 * Required property 
 * locked=bool

 * locked={props.locked}
 * defaultDPTrailerLiftAxleTireAlarmLow=int
 * changeDPTrailerLiftAxleTireAlarmLow=function
 * defaultDPTrailerLiftAxleTireAlarmHigh=int
 * changeDPTrailerLiftAxleTireAlarmHigh=function

  <SRFDisplayProgrammingTrailerLiftAxleAlarmSettings
    locked={true / false}

    locked={props.locked}
    defaultDPTrailerLiftAxleTireAlarmLow={props.defaultDPTrailerLiftAxleTireAlarmLow}
    changeDPTrailerLiftAxleTireAlarmLow={props.changeDPTrailerLiftAxleTireAlarmLow}                        
    defaultDPTrailerLiftAxleTireAlarmHigh={props.defaultDPTrailerLiftAxleTireAlarmHigh}
    changeDPTrailerLiftAxleTireAlarmHigh={props.changeDPTrailerLiftAxleTireAlarmHigh}                        

  />

*/
