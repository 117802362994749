import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import { renderSelectField_Conditional_Options } from 'components/form-global-components/react-final-form-global-components';
/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingTrailerProgramingType(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-73 - props = ', props);




          // onChange={e => onChange(id, e.target.value)}
          // onChange={e => onChange(id, props.changeTrailerProgrammingTypeSelected(e.target.value))}


          // const onChangeInput = useCallback(
          //   (e) => {
          //     props.changeTrailerProgrammingTypeSelected(e.target.value);
          //   },
          //   [selectInput]
          // );



  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Trailer Programming Type" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField_Conditional_Options}
                id="trailerprogrammingtype"
                name="trailerprogrammingtype"
                objName="trailerprogrammingtype"
                label="Trailer Programming Type"
                footerMessage=""
                onChangeMethod={props.changeTrailerProgrammingTypeSelected}
                // onChangeMethod={onChangeInput}
                locked={props.locked}
              >

                <MenuItem value={0}>Select an Option</MenuItem>

{/* 
                                            // (props.DPDisplaySelection === false && props.showDPPreProgramming === false )
                                            (props.DPDisplaySelection === 1 && props.showDPPreProgramming === 1 )
                                            ? 
                                            <MenuItem value='7' >DISPLAY = FALSE N PREPROGRAM = FALSE - OPTION EQUALS NO </MenuItem> 
                                            :
                                            null 
*/}

                {

                  (

                    

                    /*** general rule of thumb - logic if telematics is no and diplay is a type of yes show this option */
                    ( 
                      (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                      props.showDPTelematics === 1 
                  )

                    // ||


                  )

                  
                  ? (
                    <MenuItem value={1}>
                      Program Sensors to Display
                    </MenuItem>
                  ) : (
                    <MenuItem value={1} disabled>
                      Program Sensors to Display
                    </MenuItem>
                  )
                }






                {

                  (


                    // #32-B - General rule of thumb - IDK how to say 

                    props.DPDisplaySelection === 1 &&
                    props.showDPTelematics === 2 &&
                    props.showDPPreProgramming === 2 &&
                    (
                      props.showDPTrailerTelematicsBrand === 1 ||
                      props.showDPTrailerTelematicsBrand === 2 ||
                      props.showDPTrailerTelematicsBrand === 3 ||
                      props.showDPTrailerTelematicsBrand === 7 
                    )                

                  )
                  ? (
                    <MenuItem value={2}>
                      Program Sensors to Receiver
                    </MenuItem>
                  ) : (
                    <MenuItem value={2} disabled>
                      Program Sensors to Receiver
                    </MenuItem>
                  )
                }

                {
                  (


                    (
                      props.DPDisplaySelection === 2 &&
                      props.showDPPreProgramming === 2 &&
                      props.showDPTelematics === 2 &&
                      (
                        props.showDPTrailerTelematicsBrand === 1 ||
                        props.showDPTrailerTelematicsBrand === 2 ||
                        props.showDPTrailerTelematicsBrand === 3
                      )
                    )
                    ||



                    // #6
                    (
                      (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                      props.showDPTelematics === 2 &&
                      props.showDPPreProgramming === 2 &&
                      (
                        props.showDPTrailerTelematicsBrand === 1 ||
                        props.showDPTrailerTelematicsBrand === 2 ||
                        props.showDPTrailerTelematicsBrand === 3 ||
                        props.showDPTrailerTelematicsBrand === 7 
                      ) 
                    )

                  )
                   ? (
                    <MenuItem value={3}>
                      Program Sensors to Display and Receiver
                    </MenuItem>
                  ) : (
                    <MenuItem value={3} disabled>
                      Program Sensors to Display and Receiver
                    </MenuItem>
                  )
                }

                {
                  (
                    (
                      props.DPDisplaySelection === 1 &&
                      props.showDPPreProgramming === 2 &&
                      props.showDPTelematics === 2 &&
                      props.showDPTrailerTelematicsBrand === 4
                    ) 
                    ||

                    (
                      props.DPDisplaySelection === 2 &&
                      props.showDPPreProgramming === 2 &&
                      props.showDPTelematics === 2 &&
                      props.showDPTrailerTelematicsBrand === 4
                    ) 
                    ||


                                // #10
                                (
                                  (props.DPDisplaySelection === 1) &&
                                  props.showDPTelematics === 2 &&
                                  props.showDPPreProgramming === 2 &&
                                  (
                                      props.showDPTrailerTelematicsBrand === 4 ||
                                      props.showDPTrailerTelematicsBrand === 5 ||
                                      props.showDPTrailerTelematicsBrand === 8 ||
                                      props.showDPTrailerTelematicsBrand === 9 
                                  ) 
                              )
                              ||
              

                                // #11
                                (
                                  (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                  props.showDPTelematics === 2 &&
                                  props.showDPPreProgramming === 2 &&
                                  (
                                      props.showDPTrailerTelematicsBrand === 4 ||
                                      props.showDPTrailerTelematicsBrand === 5 ||
                                      props.showDPTrailerTelematicsBrand === 8 ||
                                      props.showDPTrailerTelematicsBrand === 9 
                                  ) 
                              )
                              ||
              

                                                // #9
                  (
                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                    props.showDPPreProgramming === 2 && 
                    (
                      props.showDPTrailerTelematicsBrand === 4 ||
                      props.showDPTrailerTelematicsBrand === 5 ||
                      props.showDPTrailerTelematicsBrand === 8 ||
                      props.showDPTrailerTelematicsBrand === 9 
  )
                  )
                  // ||


                  )
                  ? (
                    <MenuItem value={4}>
                      Activate Gateway Only - Customer Will Program Sensors
                    </MenuItem>
                  ) : (
                    <MenuItem value={4} disabled>
                      Activate Gateway Only - Customer Will Program Sensors
                    </MenuItem>
                  )
                }











                {
                  (



                    // (
                    //   props.DPDisplaySelection === 1 &&
                    //   props.showDPPreProgramming === 2 &&
                    //   props.showDPTelematics === 2 &&
                    //   props.showDPTrailerTelematicsBrand === 4
                    // ) 
                    // ||

                    // (
                    //   props.DPDisplaySelection === 2 &&
                    //   props.showDPPreProgramming === 2 &&
                    //   props.showDPTelematics === 2 &&
                    //   props.showDPTrailerTelematicsBrand === 4
                    // ) 
                    // ||


                                // #10
                                (
                                  (props.DPDisplaySelection === 1) &&
                                  props.showDPTelematics === 2 &&
                                  props.showDPPreProgramming === 2 &&
                                  (
                                      props.showDPTrailerTelematicsBrand === 4 ||
                                      props.showDPTrailerTelematicsBrand === 5 ||
                                      props.showDPTrailerTelematicsBrand === 8 ||
                                      props.showDPTrailerTelematicsBrand === 9 
                                  ) 
                              )
                              // ||
              

                              //   // #11 -- changed for display
                              //   (
                              //     // (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                              //     props.showDPTelematics === 2 &&
                              //     props.showDPPreProgramming === 2 &&
                              //     (
                              //         props.showDPTrailerTelematicsBrand === 4 ||
                              //         props.showDPTrailerTelematicsBrand === 5 ||
                              //         props.showDPTrailerTelematicsBrand === 8 ||
                              //         props.showDPTrailerTelematicsBrand === 9 
                              //     ) 
                              // )
                              // // ||
              
                  )
                  ? (
                    <MenuItem value={5}>
                      Activate Gateway and Program Sensors to Gateway
                    </MenuItem>
                  ) : (
                    <MenuItem value={5} disabled>
                        Activate Gateway and Program Sensors to Gateway
                    </MenuItem>
                  )
                }












                {
  (
  


                  // #5-A
                  (
                    props.showDPPreProgramming === 2 && 
                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                    (
                      props.showDPTrailerTelematicsBrand === 4 ||
                      props.showDPTrailerTelematicsBrand === 5 ||
                      props.showDPTrailerTelematicsBrand === 8 ||
                      props.showDPTrailerTelematicsBrand === 9 
  )
                  )
                  ||


                  // #9
                  (
                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                    props.showDPPreProgramming === 2 && 
                    (
                      props.showDPTrailerTelematicsBrand === 4 ||
                      props.showDPTrailerTelematicsBrand === 5 ||
                      props.showDPTrailerTelematicsBrand === 8 ||
                      props.showDPTrailerTelematicsBrand === 9 
  )
                  )
                  // ||



                  
  )
                  ? (
                    <MenuItem value={6}>
                      Activate Gateway and Program Sensors to Gateway and Display
                    </MenuItem>
                  ) : (
                    <MenuItem value={6} disabled>
                      Activate Gateway and Program Sensors to Gateway and Display
                    </MenuItem>
                  )
                }
              </Field>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingTrailerProgramingType;

/***
 * Required property 
 * locked=bool
 
 * changeTrailerProgrammingTypeSelected=function

 * DPDisplaySelection=int
 * showDPTelematics=int
 * showDPPreProgramming=int
 * showDPTrailerTelematicsBrand=int

  <SRFDisplayProgrammingTrailerProgramingType
    locked={true / false}

    changeTrailerProgrammingTypeSelected={props.changeTrailerProgrammingTypeSelected}

    DPDisplaySelection={1, 2, 3, or 4}
    showDPTelematics={1, 2, 3, or 4}
    showDPPreProgramming={1, 2, 3, or 4}
    showDPTrailerTelematicsBrand={1, 2, 3, or 4}
  />

*/
