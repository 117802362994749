import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderCheckbox,
  renderTextField
} from 'components/form-global-components/react-final-form-global-components';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingTrailerTireAlarmSettings(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  //   console.log('77-67 - props = ', props);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Trailer Tire Alarm Settings" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                id="trailertirecoldpressure"
                name="trailertirecoldpressure"
                // objName='trailertirecoldpressure'
                label="Trailer Tire Cold Pressure - Number"
                emptyText="Trailer Tire Cold Pressure - Number"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPTrailerTireAlarmLow}
                checked={props.defaultDPTrailerTireAlarmLow}
                id="trailertirealarmsettingsdefaultlow"
                name="trailertirealarmsettingsdefaultlow"
                objName="trailertirealarmsettingsdefaultlow"
                label="Low (DEFAULT -10% under cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPTrailerTireAlarmLow}
                id="trailertirealarmsettingsdefaultlowadjustvalue"
                name="trailertirealarmsettingsdefaultlowadjustvalue"
                // objName='trailertirealarmsettingsdefaultlowadjustvalue'
                label="Low Adjust Value"
                emptyText="Low Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPTrailerTireAlarmHigh}
                checked={props.defaultDPTrailerTireAlarmHigh}
                id="trailertirealarmsettingsdefaulthigh"
                name="trailertirealarmsettingsdefaulthigh"
                objName="trailertirealarmsettingsdefaulthigh"
                label="High (DEFAULT +25% over cold pressure)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPTrailerTireAlarmHigh}
                id="trailertirealarmsettingsdefaulthighadjustvalue"
                name="trailertirealarmsettingsdefaulthighadjustvalue"
                // objName='trailertirealarmsettingsdefaulthighadjustvalue'
                label="High Adjust Value"
                emptyText="High Adjust Value"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderCheckbox}
                onClick={props.changeDPTrailerTireAlarmHighTemp}
                checked={props.defaultDPTrailerTireAlarmHighTemp}
                id="trailertirealarmsettingsdefaulthightemp"
                name="trailertirealarmsettingsdefaulthightemp"
                objName="trailertirealarmsettingsdefaulthightemp"
                label="High Temp (DEFAULT 158 degrees F)"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderTextField}
                disabled={props.defaultDPTrailerTireAlarmHighTemp}
                id="trailertirealarmsettingsdefaulthightempadjustvalue"
                name="trailertirealarmsettingsdefaulthightempadjustvalue"
                // objName='trailertirealarmsettingsdefaulthightempadjustvalue'
                label="High Temp"
                emptyText="High Temp"
                footerMessage=""
                locked={props.locked}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingTrailerTireAlarmSettings;

/***
 * Required property 
 * locked=bool
 * 
 * defaultDPTrailerTireAlarmLow=int
 * changeDPTrailerTireAlarmLow=function
 * defaultDPTrailerTireAlarmHigh=int
 * changeDPTrailerTireAlarmHigh=function
 * defaultDPTrailerTireAlarmHighTemp=int
 * changeDPTrailerTireAlarmHighTemp=function

  <SRFDisplayProgrammingTrailerTireAlarmSettings
    locked={true / false}

    defaultDPTrailerTireAlarmLow={props.defaultDPTrailerTireAlarmLow}
    changeDPTrailerTireAlarmLow={props.changeDPTrailerTireAlarmLow}                        
    defaultDPTrailerTireAlarmHigh={props.defaultDPTrailerTireAlarmHigh}
    changeDPTrailerTireAlarmHigh={props.changeDPTrailerTireAlarmHigh}                        
    defaultDPTrailerTireAlarmHighTemp={props.defaultDPTrailerTireAlarmHighTemp}
    changeDPTrailerTireAlarmHighTemp={props.changeDPTrailerTireAlarmHighTemp }                        
  />

*/
