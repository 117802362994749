import React from 'react'
import {Field} from 'react-final-form'
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    makeStyles,
    MenuItem,
} from '@material-ui/core';
import {
    renderSelectField,
    renderSelectField_Conditional_Options,
} from 'components/form-global-components/react-final-form-global-components';
import {
    DISPLAY_ARRAY,
    OPTION_NO_YES,
    POWER_SOURCE,
    TELEMATICS_BRAND,
    TRUCK_TELEMATICS_BRAND,
    
                                                // TEST_10,

} from 'constants/action-constants';

import SRFDisplayProgrammingCustomersList from './display-programming-customers-list';
import SRFDisplayProgrammingDataBillingTruck from './display-programming-data-billing-truck';
import SRFDisplayProgrammingBatteryBackupReportingSchedule from './display-programming-battery-backup-reporting-schedule';
import SRFDisplayProgrammingDataBillingTrailer from './display-programming-data-billing-trailer';
import SRFDisplayProgrammingSteerTireAlarmSettings from './display-programming-steer-tire-alarm-settings';
import SRFDisplayProgrammingDriveTireAlarmSettings from './display-programming-drive-tire-alarm-settings';
import SRFDisplayProgrammingTrailerLiftAxleAlarmSettings from './display-programming-trailer-lift-axle-alarm-settings';
import SRFDisplayProgrammingTrailerTireAlarmSettings from './display-programming-trailer-tire-alarm-settings';
import SRFDisplayProgrammingTruckProgramingType from './display-programming-truck-programing-type';
import SRFDisplayProgrammingTrailerProgramingType from './display-programming-trailer-programing-type';
import SRFDPProgrammingUnits from './display-programming-programming-units';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
      , paddingTop: theme.spacing(3)
      , paddingBottom: theme.spacing(3)
      // , padding: theme.spacing(2)
    }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgramming(props) {

    /*** Make the card width = 100% --- START */
        const classes = useStyles();
    /*** Make the card width = 100% --- END */

    const setDPTelematics = (e) => {
        props.changeDPTelematicsSelected(e);

        // /*** if telematics brand changes to NO set the truck / trailer programming type = 0 */
        // props.changeTruckProgrammingTypeSelected(0);
        // props.changeTrailerProgrammingTypeSelected(0);
    };

    // console.log('77-23 - STEP 8 - SRFDisplayProgramming - props = ', props);

    return (
        <div className={classes.root}>

            <Card>

                <CardHeader
                    title='TireView™ – Display / Programming'
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>  
                        <Grid item xs={12} >
                            <Field
                                component={renderSelectField}
                                id='dpdisplayselection' 
                                name='dpdisplayselection'
                                objName='dpdisplayselection'
                                label="Display Selection"
                                footerMessage=''
                                dataArray={DISPLAY_ARRAY}
                                onChangeMethod={props.changeDPDisplaySelectionSelected}
                                locked={props.locked}                
                            />                                      
                        </Grid>
                    </Grid>
                    
                    {(props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) ? <>
                        <Grid container spacing={3}>  
                            <Grid item xs={12} >
                                <Field
                                    component={renderSelectField}
                                    id='dpdisplaypowersource' 
                                    name='dpdisplaypowersource'
                                    objName='dpdisplaypowersource'
                                    label="Power Source"
                                    footerMessage=''
                                    dataArray={POWER_SOURCE}
                                    requireOnChangeMethod={false}
                                    locked={props.locked}                
                                />                                      
                            </Grid>
                        </Grid>
                    </>
                    :
                    null 
                    }

                    <Grid container spacing={3}>  
                        <Grid item xs={12} >
                            <Field
                                component={renderSelectField}
                                id='dptelematics' 
                                name='dptelematics'
                                objName='dptelematics'
                                label="Telematics"
                                footerMessage=''
                                dataArray={OPTION_NO_YES}
                                onChangeMethod={setDPTelematics}
                                locked={props.locked}
                            />
                        </Grid>
                    </Grid> 

                    {(props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) ? <>

                        {props.showDPTelematics === 2 ? <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Field
                                        component={renderSelectField}
                                        id='trucktelematicsbrand'
                                        name='trucktelematicsbrand'
                                        objName='trucktelematicsbrand'
                                        label='Truck Telematics Brand'
                                        footerMessage=''
                                        dataArray={TRUCK_TELEMATICS_BRAND}
                                        onChangeMethod={props.changeDPTruckTelematicsBrand}
                                        locked={props.locked}
                                    />
                                </Grid>
                            </Grid>
                        
                        </> : '' }

                    </> : '' }


                            {(props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3) && props.showDPTruckTelematicsBrand === 4 ? <>

                                <Grid container spacing={3}>  
                                    <Grid item xs={12} >
                                        <SRFDisplayProgrammingCustomersList
                                                                                locked={props.locked}

                                                                                currentUserID={props.currentUserID}
                                                                                tempID={props.tempID}

                                                                                title='Truck Customer Access List'

                                            buttonText='ADD to Truck List'
                                            dialogTitle='Truck - Customer Access Section - Customer Logins &#38; Alerts (Who needs access)'

                                            arrayTitle={'truckCustomerAlerts'}
                                            custPush={props.custPush}
                                        />
                                    </Grid>
                                </Grid>

                            </> : '' }



                    {(props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) ? <>

                        {props.showDPTelematics === 2 ? <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Field
                                        component={renderSelectField}
                                        id='trailertelematicsbrand'
                                        name='trailertelematicsbrand'
                                        objName='trailertelematicsbrand'
                                        label='Trailer Telematics Brand'
                                        footerMessage=''
                                        dataArray={TELEMATICS_BRAND}
                                        onChangeMethod={props.changeDPTrailerTelematicsBrand}
                                        locked={props.locked}
                                    />
                                </Grid>
                            </Grid>

                        </> : '' }

                    </> : '' }



                            {(props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3) && props.showDPTrailerTelematicsBrand === 4 ? <>
                                <Grid container spacing={3}>  
                                    <Grid item xs={12} >
                                        <SRFDisplayProgrammingCustomersList
                                        locked={props.locked}

                                        currentUserID={props.currentUserID}
                                        tempID={props.tempID}

                                            title='Trailer - Customer Access List'

                                            buttonText='ADD to Trailer List'
                                            dialogTitle='Trailer - Customer Access Section - Customer Logins &#38; Alerts (Who needs access)'
 
                                            arrayTitle={'trailerCustomerAlerts'}
                                            custPush={props.custPush}                                  
                                        />
                                    </Grid>
                                </Grid>

                            </> : '' }



                    {/* #2 */}
                    {( props.DPDisplaySelection !== 1 || props.showDPTelematics !== 1  ) ? <>
                    
                        <Grid container spacing={3}>  
                            <Grid item xs={12} >
                                <Field
                                    component={renderSelectField_Conditional_Options}
                                    id='dppreprogramming' 
                                    name='dppreprogramming'
                                    objName='dppreprogramming'
                                    label="PRE-PROGRAMMING"
                                    footerMessage=''
                                    onChangeMethod={props.changeDPPreProgrammingSelected}
                                    locked={props.locked}
                                >
                                    <MenuItem value={1} >NO</MenuItem>


                                    <MenuItem value={2} >YES</MenuItem> 

{/* 
                                    {
                                        (
                                            props.DPDisplaySelection === 1 
                                            && props.showDPTelematics === 1 
                                        )
                                        ? 
                                        <MenuItem value={2} disabled >YES</MenuItem> 
                                        :
                                        <MenuItem value={2} >YES</MenuItem> 
                                    } 
 */}

                                </Field>
                            </Grid>
                        </Grid>
                    </>
                    :
                    null 
                    }




                    {props.showDPPreProgramming === 2 ? <>
                        
                                                
                        {
                              
                                (
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3) &&
                                    (
                                        props.showDPTruckTelematicsBrand === 4 || 
                                        props.showDPTruckTelematicsBrand === 8  
                                    )
                                )
                        
                         ? <> 

                        <SRFDisplayProgrammingDataBillingTruck
                            locked={props.locked}
                            DPTruckDataBillingSelected={props.DPTruckDataBillingSelected}
                            changeDPTruckDataBillingSelected={props.changeDPTruckDataBillingSelected}
                            DPTruckCustSatExpireSelected={props.DPTruckCustSatExpireSelected}
                            changeDPTruckCustSatExpireSelected={props.changeDPTruckCustSatExpireSelected}
                            DPTruckAfterExpirationSelected={props.DPTruckAfterExpirationSelected}
                            changeDPTruckAfterExpirationSelected={props.changeDPTruckAfterExpirationSelected}
                        />
                    </>
                    :
                    null 
                    }




                            {
                                  
                                    (
                                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3) &&
                                        (
                                            props.showDPTrailerTelematicsBrand === 4 || 
                                            props.showDPTrailerTelematicsBrand === 5 ||
                                            props.showDPTrailerTelematicsBrand === 8 || 
                                            props.showDPTrailerTelematicsBrand === 9  
                                        )
                                    )
                            
                         ? <> 
                            <SRFDisplayProgrammingDataBillingTrailer
                                locked={props.locked}
                                DPTrailerDataBillingSelected={props.DPTrailerDataBillingSelected}
                                changeDPTrailerDataBillingSelected={props.changeDPTrailerDataBillingSelected}
                                DPTrailerCustSatExpireSelected={props.DPTrailerCustSatExpireSelected}
                                changeDPTrailerCustSatExpireSelected={props.changeDPTrailerCustSatExpireSelected}
                                DPTrailerAfterExpirationSelected={props.DPTrailerAfterExpirationSelected}
                                changeDPTrailerAfterExpirationSelected={props.changeDPTrailerAfterExpirationSelected}
                            />
                        </>
                        :
                        null 
                        }








                        {
                        (

                            // (
                            //     props.showDPTrailerTelematicsBrand === 5 && 
                            //     props.showDPPreProgramming === 2 && 
                            //     (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3) 
                            // ) 
                            // ||


                            // #10 - changes are for the battery backup to show not show here only
                            (
                                (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                (props.DPDisplaySelection === 1) &&
                                props.showDPTelematics === 2 &&
                                props.showDPPreProgramming === 2 &&
                                (
                                    // props.showDPTrailerTelematicsBrand === 4 ||
                                    props.showDPTrailerTelematicsBrand === 5 ||
                                    // props.showDPTrailerTelematicsBrand === 8 ||
                                    props.showDPTrailerTelematicsBrand === 9 
                                ) 
                            )
                            ||


                            // #11 - changes are for the battery backup to show not show here only
                            (
                                (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                props.showDPTelematics === 2 &&
                                props.showDPPreProgramming === 2 &&
                                (
                                    // props.showDPTrailerTelematicsBrand === 4 ||
                                    props.showDPTrailerTelematicsBrand === 5 ||
                                    // props.showDPTrailerTelematicsBrand === 8 ||
                                    props.showDPTrailerTelematicsBrand === 9 
                                ) 
                            )
                            // ||


                    ) 
                        
                        ? <> 
                            <SRFDisplayProgrammingBatteryBackupReportingSchedule
                                locked={props.locked}
                                DPTrailerDataBillingSelected={props.DPTrailerDataBillingSelected}
                                changeDPTrailerDataBillingSelected={props.changeDPTrailerDataBillingSelected}
                                DPTrailerCustSatExpireSelected={props.DPTrailerCustSatExpireSelected}
                                changeDPTrailerCustSatExpireSelected={props.changeDPTrailerCustSatExpireSelected}
                                DPTrailerAfterExpirationSelected={props.DPTrailerAfterExpirationSelected}
                                changeDPTrailerAfterExpirationSelected={props.changeDPTrailerAfterExpirationSelected}
                            />
                        </>
                        :
                        null 
                        }


                        {/* {( props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) ? <> */}
                        {/* {( (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {/* {(props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) ? <> */}
                        {





                            // #3
                        ( 
                            (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                            (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                            // props.showDPTelematics === 1 &&  // added this
                            props.showDPPreProgramming === 2 
                        ) 
                        || 
                        ( 
                            (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) && 
                            (props.DPDisplaySelection === 1) && 
                            (props.showDPTelematics === 2) && 
                            (props.showDPTruckTelematicsBrand === 6) && 
                            (props.showDPPreProgramming === 2) 
                        )
                        ||
                                                        // #8
                                                        (
                                                            (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                                            (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                                            props.showDPPreProgramming === 2 && 
                                                            props.showDPTruckTelematicsBrand === 6 
                                                        )
                                                        ||



            // #9
            (
                (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                // props.DPDisplaySelection === 1 &&
                props.showDPPreProgramming === 2 && 
                (
                    props.showDPTruckTelematicsBrand === 4  ||
                    props.showDPTruckTelematicsBrand === 8 
                )
            )
            // ||




                        // (props.showDPTruckTelematicsBrand === 6 && props.showDPPreProgramming === 2 && (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3) ) 

                        ? <>
                            <SRFDisplayProgrammingTruckProgramingType
                                locked={props.locked}
                                changeTruckProgrammingTypeSelected={props.changeTruckProgrammingTypeSelected}
                                DPDisplaySelection={props.DPDisplaySelection}
                                showDPTelematics={props.showDPTelematics}
                                showDPPreProgramming={props.showDPPreProgramming}
                                showDPTruckTelematicsBrand={props.showDPTruckTelematicsBrand}
                            />
                        </>
                        : null 
                        }

                        {/* {( props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) ? <> */}
                        {/* {( (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {/* {( props.showDPPreProgramming === 2 && (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {/* {( props.showDPPreProgramming === 2 && (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {
                            (
                                        // #4
                                    ( 
                                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                        (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                        props.showDPPreProgramming === 2 
                                    )
                                    || 


                                                        // #5
                                                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                                        (props.DPDisplaySelection === 1) &&
                                                        // props.showDPTelematics === 2 &&
                                                        props.showDPPreProgramming === 2 &&
                                                        (
                                                            props.showDPTrailerTelematicsBrand === 1 ||
                                                            props.showDPTrailerTelematicsBrand === 2 ||
                                                            props.showDPTrailerTelematicsBrand === 3 ||
                                                            props.showDPTrailerTelematicsBrand === 7 
                                                        ) 
                                                        ||        
                                    
                                                    
                                // #6
                                (
                                    (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                        props.showDPTrailerTelematicsBrand === 1 ||
                                        props.showDPTrailerTelematicsBrand === 2 ||
                                        props.showDPTrailerTelematicsBrand === 3 ||
                                        props.showDPTrailerTelematicsBrand === 7 
                                    ) 
                                )
                                ||



                                // #10
                                    (
                                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                        (props.DPDisplaySelection === 1) &&
                                        props.showDPTelematics === 2 &&
                                        props.showDPPreProgramming === 2 &&
                                        (
                                            props.showDPTrailerTelematicsBrand === 4 ||
                                            props.showDPTrailerTelematicsBrand === 5 ||
                                            props.showDPTrailerTelematicsBrand === 8 ||
                                            props.showDPTrailerTelematicsBrand === 9 
                                        ) 
                                    )
                                    ||
                
                                    
                                // #11
                                (
                                    (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                        props.showDPTrailerTelematicsBrand === 4 ||
                                        props.showDPTrailerTelematicsBrand === 5 ||
                                        props.showDPTrailerTelematicsBrand === 8 ||
                                        props.showDPTrailerTelematicsBrand === 9 
                                    ) 
                                )
                                // ||
                
  

                        )
                        ? <>
                            <SRFDisplayProgrammingTrailerProgramingType
                                locked={props.locked}
                                changeTrailerProgrammingTypeSelected={props.changeTrailerProgrammingTypeSelected}
                                DPDisplaySelection={props.DPDisplaySelection}
                                showDPTelematics={props.showDPTelematics}
                                showDPPreProgramming={props.showDPPreProgramming}
                                showDPTrailerTelematicsBrand={props.showDPTrailerTelematicsBrand}
                            />
                        </>
                        :
                        null 
                        }













{/* 
                        {
                        (
                            
                            props.TruckProgrammingTypeSelected === 1 
                            || 
                            props.TrailerProgrammingTypeSelected === 5
                        
                        ) ? <>

                            <SRFDPProgrammingUnits
                                locked={props.locked}
                            />
                        </>
                        :
                        null 
                        }


 */}

























                        {/*** Show Trailer Tire Alarm Settings */}
                        {/* {(props.TrailerProgrammingTypeSelected === 5 && (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) ) ? <> */}
                        {/* {( props.TrailerProgrammingTypeSelected === 5 &&  props.showDPPreProgramming === 2 && (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {( 




                            
                                // #3
                                ( 
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    // props.showDPTelematics === 1 &&  // added this
                                    props.showDPPreProgramming === 2 // && 
                                    // (props.TruckProgrammingTypeSelected === 1 && props.TruckProgrammingTypeSelected === 2)
                                )
                                ||
                                   
                                

                                    // #4
                                    ( 
                                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                        (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                        props.showDPPreProgramming === 2 
                                    )
                                    ||


                                                    // #5
                                                    (
                                                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                                        (props.DPDisplaySelection === 1) &&
                                                        // props.showDPTelematics === 2 &&
                                                        props.showDPPreProgramming === 2 &&
                                                        (
                                                            props.showDPTrailerTelematicsBrand === 1 ||
                                                            props.showDPTrailerTelematicsBrand === 2 ||
                                                            props.showDPTrailerTelematicsBrand === 3 ||
                                                            props.showDPTrailerTelematicsBrand === 7 
                                                        )
                                                    ) 
                                                    ||        




                                // #6
                                (
                                    (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                    props.showDPTrailerTelematicsBrand === 1 ||
                                    props.showDPTrailerTelematicsBrand === 2 ||
                                    props.showDPTrailerTelematicsBrand === 3 ||
                                    props.showDPTrailerTelematicsBrand === 7 
                                    ) 
                                )
                                ||
                                    
                                // #8
                                (
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPPreProgramming === 2 && 
                                    props.showDPTruckTelematicsBrand === 6 
                                )
                                ||

                                // #9
                                (
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                    // props.DPDisplaySelection === 1 &&
                                    props.showDPPreProgramming === 2 && 
                                    (
                                        props.showDPTruckTelematicsBrand === 4  ||
                                        props.showDPTruckTelematicsBrand === 8 
                                    )
                                )
                                ||


                                // #10
                                (
                                    (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 1) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                        props.showDPTrailerTelematicsBrand === 4 ||
                                        props.showDPTrailerTelematicsBrand === 5 ||
                                        props.showDPTrailerTelematicsBrand === 8 ||
                                        props.showDPTrailerTelematicsBrand === 9 
                                    ) 
                                )
                                ||
                

                                // #11
                                (
                                    (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                        props.showDPTrailerTelematicsBrand === 4 ||
                                        props.showDPTrailerTelematicsBrand === 5 ||
                                        props.showDPTrailerTelematicsBrand === 8 ||
                                        props.showDPTrailerTelematicsBrand === 9 
                                    ) 
                                )
                                ||
                




                                    // #12
                                (
                                    (props.vehicleTypeSelected === 3 ) && 
                                    // (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    (props.DPDisplaySelection === 1) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                        props.showDPTrailerTelematicsBrand === 4 ||
                                        props.showDPTrailerTelematicsBrand === 8 
                                    ) 
                                )
                                ||

                                // #13
                                (
                                    (props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    (
                                        props.showDPTrailerTelematicsBrand === 4 ||
                                        props.showDPTrailerTelematicsBrand === 8 
                                    ) 
                                )
                                ||
  
  

                                




                                  // #14
                                (
                                    (props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 1) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    props.trucktelematicsbrand === 6
                                )
                                ||

                                // #15
                                (
                                    (props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    props.trucktelematicsbrand === 6
                                )
                                // ||

  




                                    // (props.TrailerProgrammingTypeSelected === 2 || props.TrailerProgrammingTypeSelected === 5) // && 
                                    // // (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3) && 
                                    // // (props.showDPPreProgramming === 2) 




                                    ) 
                                    ? <>



                                            <SRFDPProgrammingUnits
                                                locked={props.locked}
                                            />

                                    </>
                                    :
                                    null 
                                    }





























                        {/* {(props.TruckProgrammingTypeSelected === 1 && (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) ) ? <> */}
                        {/* {(props.TruckProgrammingTypeSelected === 1 && props.showDPPreProgramming === 2 && (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {                        
                        (

                                // #3
                                ( 
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    // props.showDPTelematics === 1 &&  // added this
                                    props.showDPPreProgramming === 2 // && 
                                    // (props.TruckProgrammingTypeSelected === 1 && props.TruckProgrammingTypeSelected === 2)
                                )
                                ||
                                    
                                // #8
                                (
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPPreProgramming === 2 && 
                                    props.showDPTruckTelematicsBrand === 6 
                                )
                                ||

                                // #9
                                (
                                    (props.vehicleTypeSelected === 1 || props.vehicleTypeSelected === 3 ) &&
                                    // props.DPDisplaySelection === 1 &&
                                    props.showDPPreProgramming === 2 && 
                                    (
                                        props.showDPTruckTelematicsBrand === 4  ||
                                        props.showDPTruckTelematicsBrand === 8 
                                    )
                                )
                                ||





                                // // #10
                                // (
                                //     (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                //     (props.DPDisplaySelection === 1) &&
                                //     props.showDPTelematics === 2 &&
                                //     props.showDPPreProgramming === 2 &&
                                //     (
                                //         props.showDPTrailerTelematicsBrand === 4 ||
                                //         props.showDPTrailerTelematicsBrand === 5 ||
                                //         props.showDPTrailerTelematicsBrand === 8 ||
                                //         props.showDPTrailerTelematicsBrand === 9 
                                //     ) 
                                // )
                                // ||

                                



                                // // #11
                                // (
                                //     (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                //     (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                //     props.showDPTelematics === 2 &&
                                //     props.showDPPreProgramming === 2 &&
                                //     (
                                //         props.showDPTrailerTelematicsBrand === 4 ||
                                //         props.showDPTrailerTelematicsBrand === 5 ||
                                //         props.showDPTrailerTelematicsBrand === 8 ||
                                //         props.showDPTrailerTelematicsBrand === 9 
                                //     ) 
                                // )
                                // ||
                




                                //     // #12
                                // (
                                //     (props.vehicleTypeSelected === 3 ) && 
                                //     // (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                //     (props.DPDisplaySelection === 1) &&
                                //     props.showDPTelematics === 2 &&
                                //     props.showDPPreProgramming === 2 &&
                                //     (
                                //         props.showDPTrailerTelematicsBrand === 4 ||
                                //         props.showDPTrailerTelematicsBrand === 8 
                                //     ) 
                                // )
                                // ||

                                // // #13
                                // (
                                //     (props.vehicleTypeSelected === 3 ) && 
                                //     (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                //     props.showDPTelematics === 2 &&
                                //     props.showDPPreProgramming === 2 &&
                                //     (
                                //         props.showDPTrailerTelematicsBrand === 4 ||
                                //         props.showDPTrailerTelematicsBrand === 8 
                                //     ) 
                                // )
                                // ||
  
  
  

                                  // #14
                                (
                                    (props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 1) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    props.trucktelematicsbrand === 6
                                )
                                ||

                                // #15
                                (
                                    (props.vehicleTypeSelected === 3 ) && 
                                    (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                    props.showDPTelematics === 2 &&
                                    props.showDPPreProgramming === 2 &&
                                    props.trucktelematicsbrand === 6
                                )
                                // ||

  


                        )
                        ? <>

                            <SRFDisplayProgrammingSteerTireAlarmSettings 
                                locked={props.locked}
                                changeDPSteerTireAlarmLow={props.changeDPSteerTireAlarmLow}                        
                                defaultDPSteerTireAlarmLow={props.defaultDPSteerTireAlarmLow}
                                changeDPSteerTireAlarmHigh={props.changeDPSteerTireAlarmHigh}                        
                                defaultDPSteerTireAlarmHigh={props.defaultDPSteerTireAlarmHigh}
                                changeDPSteerTireAlarmHighTemp={props.changeDPSteerTireAlarmHighTemp}                        
                                defaultDPSteerTireAlarmHighTemp={props.defaultDPSteerTireAlarmHighTemp}
                            />
                            
                            <SRFDisplayProgrammingDriveTireAlarmSettings 
                                locked={props.locked}
                                changeDPDriveTireAlarmLow={props.changeDPDriveTireAlarmLow}                        
                                defaultDPDriveTireAlarmLow={props.defaultDPDriveTireAlarmLow}
                                changeDPDriveTireAlarmHigh={props.changeDPDriveTireAlarmHigh}                        
                                defaultDPDriveTireAlarmHigh={props.defaultDPDriveTireAlarmHigh}
                                changeDPDriveTireAlarmHighTemp={props.changeDPDriveTireAlarmHighTemp}                        
                                defaultDPDriveTireAlarmHighTemp={props.defaultDPDriveTireAlarmHighTemp}
                            />
                        </>
                        :
                        null 
                        }

                        {/*** Show Trailer Tire Alarm Settings */}
                        {/* {(props.TrailerProgrammingTypeSelected === 5 && (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) ) ? <> */}
                        {/* {( props.TrailerProgrammingTypeSelected === 5 &&  props.showDPPreProgramming === 2 && (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&  (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) )? <> */}
                        {( 



                            // #4
                            ( 
                                (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                props.showDPPreProgramming === 2 
                            )
                        ||
                            


                                            // #5
                                            (
                                                (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                                                (props.DPDisplaySelection === 1) &&
                                                // props.showDPTelematics === 2 &&
                                                props.showDPPreProgramming === 2 &&
                                                (
                                                    props.showDPTrailerTelematicsBrand === 1 ||
                                                    props.showDPTrailerTelematicsBrand === 2 ||
                                                    props.showDPTrailerTelematicsBrand === 3 ||
                                                    props.showDPTrailerTelematicsBrand === 7 
                                                )
                                            ) 
                                            ||        
                        

                    // #6
                    (
                        (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3 ) && 
                        (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                        props.showDPTelematics === 2 &&
                        props.showDPPreProgramming === 2 &&
                        (
                            props.showDPTrailerTelematicsBrand === 1 ||
                            props.showDPTrailerTelematicsBrand === 2 ||
                            props.showDPTrailerTelematicsBrand === 3 ||
                            props.showDPTrailerTelematicsBrand === 7 
                        ) 
                    )
                    ||




                                    // #12
                                    (
                                        (props.vehicleTypeSelected === 3 ) && 
                                        // (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                        (props.DPDisplaySelection === 1) &&
                                        props.showDPTelematics === 2 &&
                                        props.showDPPreProgramming === 2 &&
                                        (
                                            props.showDPTrailerTelematicsBrand === 4 ||
                                            props.showDPTrailerTelematicsBrand === 8 
                                        ) 
                                    )
                                    ||
    
                                    // #13
                                    (
                                        (props.vehicleTypeSelected === 3 ) && 
                                        (props.DPDisplaySelection === 2 || props.DPDisplaySelection === 3) &&
                                        props.showDPTelematics === 2 &&
                                        props.showDPPreProgramming === 2 &&
                                        (
                                            props.showDPTrailerTelematicsBrand === 4 ||
                                            props.showDPTrailerTelematicsBrand === 8 
                                        ) 
                                    )
                                    ||
      



                            (props.TrailerProgrammingTypeSelected === 2 || props.TrailerProgrammingTypeSelected === 5) // && 
                            // (props.vehicleTypeSelected === 2 || props.vehicleTypeSelected === 3) && 
                            // (props.showDPPreProgramming === 2) 




                        ) 
                        ? <>


                            <SRFDisplayProgrammingTrailerTireAlarmSettings
                                locked={props.locked}
                                defaultDPTrailerTireAlarmLow={props.defaultDPTrailerTireAlarmLow}
                                changeDPTrailerTireAlarmLow={props.changeDPTrailerTireAlarmLow}                        
                                defaultDPTrailerTireAlarmHigh={props.defaultDPTrailerTireAlarmHigh}
                                changeDPTrailerTireAlarmHigh={props.changeDPTrailerTireAlarmHigh}                        
                                defaultDPTrailerTireAlarmHighTemp={props.defaultDPTrailerTireAlarmHighTemp}
                                changeDPTrailerTireAlarmHighTemp={props.changeDPTrailerTireAlarmHighTemp }                        
                            />

                            <SRFDisplayProgrammingTrailerLiftAxleAlarmSettings
                                locked={props.locked}
                                defaultDPTrailerLiftAxleTireAlarmLow={props.defaultDPTrailerLiftAxleTireAlarmLow}
                                changeDPTrailerLiftAxleTireAlarmLow={props.changeDPTrailerLiftAxleTireAlarmLow}                        
                                defaultDPTrailerLiftAxleTireAlarmHigh={props.defaultDPTrailerLiftAxleTireAlarmHigh}
                                changeDPTrailerLiftAxleTireAlarmHigh={props.changeDPTrailerLiftAxleTireAlarmHigh}                        
                            />

                        </>
                        :
                        null 
                        }







                    </>
                    :
                    null
                    }

                </CardContent>
            </Card>

        </div>
    )
};

export default SRFDisplayProgramming


/***
 * Required property 
 * locked=bool
 * vehicleTypeSelected=int
 * 
 * TrailerProgrammingTypeSelected=int
 * changeTrailerProgrammingTypeSelected=function
 * 
 * TruckProgrammingTypeSelected=int
 * changeTruckProgrammingTypeSelected=function
 * 
 * showTrailerSensorsOnAxle=int
 * changeTrailerSensorsOnAxle=function
 * 
 * showTrailerAdaptToAtis=int
 * changeTrailerAdaptToAtis=function
 * 
 * showTrailerAdaptToAtisIdentifyBrand=int
 * changeTrailerAdaptToAtisIdentifyBrand=function
 * 
 * showTrailerFlowThruKit=int
 * changeTrailerFlowThruKit=function
 * 
 * showTrailerSpecialtySensors=int
 * changeTrailerSpecialtySensors=function

  <SRFTrailerSpecifications
    locked={true / false}
    vehicleTypeSelected={1, 2, or 3}

    TrailerProgrammingTypeSelected=={'1', '2', or '3'}
    changeTrailerProgrammingTypeSelected={changeTrailerProgrammingTypeSelected}

    TruckProgrammingTypeSelected=={'1', '2', or '3'}
    changeTruckProgrammingTypeSelected={changeTruckProgrammingTypeSelected}

    showTrailerSensorsOnAxle={1, 2}
    changeTrailerSensorsOnAxle={setTrailerSensorsOnAxle}

    showTrailerAdaptToAtis={1, 2}
    changeTrailerAdaptToAtis={setTrailerAdaptToAtis}

    showTrailerAdaptToAtisIdentifyBrand={1, 2}
    changeTrailerAdaptToAtisIdentifyBrand={setTrailerAdaptToAtisIdentifyBrand}

    showTrailerFlowThruKit={1, 2}
    changeTrailerFlowThruKit={setTrailerFlowThruKit}

    showTrailerSpecialtySensors={1, 2}
    changeTrailerSpecialtySensors={setTrailerSpecialtySensors}
  />

*/





























































// {/* a1 */}
//     {/*
//                             <br />
//                             <br />
//                             <br />
//                             <h5>
//                                 Steer Tire Alarm Settings
//                             </h5> 
//                             <br />
//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderTextField}
//                                         id='steertirecoldpressure'
//                                         name='steertirecoldpressure'
//                                                                                             // objName='steertirecoldpressure'
//                                         label='Steer Tire Cold Pressure - Number'
//                                         emptyText='Steer Tire Cold Pressure - Number'
//                                         footerMessage=''
//                                         locked={props.locked}
//                                     />
//                                 </Grid>
//                             </Grid>

//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderCheckbox}
//                                         onClick={props.changeDPSteerTireAlarmLow}                        
//                                         checked={props.defaultDPSteerTireAlarmLow}
//                                         id='steertirealarmsettingsdefaultlow'
//                                         name='steertirealarmsettingsdefaultlow'
//                                         objName='steertirealarmsettingsdefaultlow'
//                                         label='Default Low (-10% under cold pressure)'
//                                         footerMessage=''
//                                     />
//                                 </Grid>
//                             </Grid>

//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderTextField}
//                                         disabled={props.defaultDPSteerTireAlarmLow}
//                                         id='steertirealarmsettingsdefaultlowadjustvalue'
//                                         name='steertirealarmsettingsdefaultlowadjustvalue'
//                                                 // objName='steertirealarmsettingsdefaultlowadjustvalue'
//                                         label='Low Adjust Value'
//                                         emptyText='Low Adjust Value'
//                                         footerMessage=''
//                                     />
//                                 </Grid>
//                             </Grid>

//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderCheckbox}
//                                         onClick={props.changeDPSteerTireAlarmHigh}                        
//                                         checked={props.defaultDPSteerTireAlarmHigh}
//                                         id='steertirealarmsettingsdefaulthigh'
//                                         name='steertirealarmsettingsdefaulthigh'
//                                         objName='steertirealarmsettingsdefaulthigh'
//                                         label='Default High (DEFAULT +25% over cold pressure)'
//                                         footerMessage=''
//                                     />
//                                 </Grid>
//                             </Grid>
                                                                                                                                
//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderTextField}
//                                         disabled={props.defaultDPSteerTireAlarmHigh}
//                                         id='steertirealarmsettingsdefaulthighadjustvalue'
//                                         name='steertirealarmsettingsdefaulthighadjustvalue'
//                                         // objName='steertirealarmsettingsdefaulthighadjustvalue'
//                                         label='High Adjust Value'
//                                         emptyText='High Adjust Value'
//                                         footerMessage=''
//                                         locked={props.locked}
//                                     />
//                                 </Grid>
//                             </Grid>

//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderCheckbox}
//                                         onClick={props.changeDPSteerTireAlarmHighTemp}                        
//                                         checked={props.defaultDPSteerTireAlarmHighTemp}
//                                         id='steertirealarmsettingsdefaulthightemp'
//                                         name='steertirealarmsettingsdefaulthightemp'
//                                         objName='steertirealarmsettingsdefaulthightemp'
//                                         label='Default High Temp (DEFAULT 158 degrees F)'
//                                         footerMessage=''
//                                     />
//                                 </Grid>
//                             </Grid>
                                                                                                                                            
//                             <Grid container spacing={3}>
//                                 <Grid item xs={12}>
//                                     <Field
//                                         component={renderTextField}
//                                         disabled={props.defaultDPSteerTireAlarmHighTemp}
//                                         id='steertirealarmsettingsdefaulthightempadjustvalue'
//                                         name='steertirealarmsettingsdefaulthightempadjustvalue'
//                                         // objName='steertirealarmsettingsdefaulthightempadjustvalue'
//                                         label='High Temp (DEFAULT 158 degrees F)'
//                                         emptyText='High Temp (DEFAULT 158 degrees F)'
//                                         footerMessage=''
//                                         locked={props.locked}
//                                     />
//                                 </Grid>
//                             </Grid> 
//     */}


// {/* 

//                         <br />
//                         <br />
//                         <br />
//                         <h5>
//                             Drive Tire Alarm Settings
//                         </h5>
//                         <br />
//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderTextField}
//                                     id='drivetirecoldpressure'
//                                     name='drivetirecoldpressure'
//                                                                                         // objName='drivetirecoldpressure'
//                                     label='Drive Tire Cold Pressure - Number'
//                                     emptyText='Drive Tire Cold Pressure - Number'
//                                     footerMessage=''
//                                     locked={props.locked}
//                                 />
//                             </Grid>
//                         </Grid>

//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderCheckbox}
//                                     onClick={props.changeDPDriveTireAlarmLow}                        
//                                     checked={props.defaultDPDriveTireAlarmLow}
//                                     id='drivetirealarmsettingsdefaultlow'
//                                     name='drivetirealarmsettingsdefaultlow'
//                                     objName='drivetirealarmsettingsdefaultlow'
//                                     label='Low (DEFAULT -10% under cold pressure)'
//                                     footerMessage=''
//                                 />
//                             </Grid>
//                         </Grid>

//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                         <Field
//                                             component={renderTextField}
//                                             disabled={props.defaultDPDriveTireAlarmLow}
//                                             id='drivetirealarmsettingsdefaultlowadjustvalue'
//                                             name='drivetirealarmsettingsdefaultlowadjustvalue'
//                                             // objName='drivetirealarmsettingsdefaultlowadjustvalue'
//                                             label='Low Adjust Value'
//                                             emptyText='Low Adjust Value'
//                                             footerMessage=''
//                                             locked={props.locked}
//                                         />
//                                         </Grid>
//                                     </Grid>

//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderCheckbox}
//                                     onClick={props.changeDPDriveTireAlarmHigh}                        
//                                     checked={props.defaultDPDriveTireAlarmHigh}
//                                     id='drivetirealarmsettingsdefaulthigh'
//                                     name='drivetirealarmsettingsdefaulthigh'
//                                     objName='drivetirealarmsettingsdefaulthigh'
//                                     label='High (DEFAULT +25% over cold pressure)'
//                                     footerMessage=''
//                                 />
//                             </Grid>
//                         </Grid>
                                                                                                                                        
//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                         <Field
//                                             component={renderTextField}
//                                             disabled={props.defaultDPDriveTireAlarmHigh}
//                                             id='drivetirealarmsettingsdefaulthighadjustvalue'
//                                             name='drivetirealarmsettingsdefaulthighadjustvalue'
//                                             // objName='drivetirealarmsettingsdefaulthighadjustvalue'
//                                             label='High Adjust Value'
//                                             emptyText='High Adjust Value'
//                                             footerMessage=''
//                                             locked={props.locked}
//                                         />
//                                         </Grid>
//                                     </Grid>

//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderCheckbox}
//                                     onClick={props.changeDPDriveTireAlarmHighTemp}                        
//                                     checked={props.defaultDPDriveTireAlarmHighTemp}
//                                     id='drivetirealarmsettingsdefaulthightemp'
//                                     name='drivetirealarmsettingsdefaulthightemp'
//                                     objName='drivetirealarmsettingsdefaulthightemp'
//                                     label='High Temp (DEFAULT 158 degrees F)'
//                                     footerMessage=''
//                                 />
//                             </Grid>
//                         </Grid>
                                                                                                                                        
//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                         <Field
//                                             component={renderTextField}
//                                             disabled={props.defaultDPDriveTireAlarmHighTemp}
//                                             id='drivetirealarmsettingsdefaulthightempadjustvalue'
//                                             name='drivetirealarmsettingsdefaulthightempadjustvalue'
//                                             // objName='drivetirealarmsettingsdefaulthightempadjustvalue'
//                                             label='High Temp Adjust Value'
//                                             emptyText='High Temp Adjust Value'
//                                             footerMessage=''
//                                             locked={props.locked}
//                                         />
//                                         </Grid>
//                                     </Grid>
//  */}




// {/* 
//                         <br />
//                         <br />
//                         <br />
//                         <h5>
//                             Trailer Tire Alarm Settings
//                         </h5>
//                         <br />

                         
//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderTextField}
//                                     id='trailertirecoldpressure'
//                                     name='trailertirecoldpressure'
//                                                                                         // objName='trailertirecoldpressure'
//                                     label='Trailer Tire Cold Pressure - Number'
//                                     emptyText='Trailer Tire Cold Pressure - Number'
//                                     footerMessage=''
//                                     locked={props.locked}
//                                 />
//                             </Grid>
//                         </Grid>

//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderCheckbox}
//                                     onClick={props.changeDPTrailerTireAlarmLow}                        
//                                     checked={props.defaultDPTrailerTireAlarmLow}
//                                     id='trailertirealarmsettingsdefaultlow'
//                                     name='trailertirealarmsettingsdefaultlow'
//                                     objName='trailertirealarmsettingsdefaultlow'
//                                     label='Low (DEFAULT -10% under cold pressure)'
//                                     footerMessage=''
//                                 />
//                             </Grid>
//                         </Grid>

//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                         <Field
//                                             component={renderTextField}
//                                             disabled={props.defaultDPTrailerTireAlarmLow}
//                                             id='trailertirealarmsettingsdefaultlowadjustvalue'
//                                             name='trailertirealarmsettingsdefaultlowadjustvalue'
//                                             // objName='trailertirealarmsettingsdefaultlowadjustvalue'
//                                             label='Low Adjust Value'
//                                             emptyText='Low Adjust Value'
//                                             footerMessage=''
//                                             locked={props.locked}
//                                         />
//                                         </Grid>
//                                     </Grid>

//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderCheckbox}
//                                     onClick={props.changeDPTrailerTireAlarmHigh}                        
//                                     checked={props.defaultDPTrailerTireAlarmHigh}
//                                     id='trailertirealarmsettingsdefaulthigh'
//                                     name='trailertirealarmsettingsdefaulthigh'
//                                     objName='trailertirealarmsettingsdefaulthigh'
//                                     label='High (DEFAULT +25% over cold pressure)'
//                                     footerMessage=''
//                                 />
//                             </Grid>
//                         </Grid>
                                                                                                                                        
//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                         <Field
//                                             component={renderTextField}
//                                             disabled={props.defaultDPTrailerTireAlarmHigh}
//                                             id='trailertirealarmsettingsdefaulthighadjustvalue'
//                                             name='trailertirealarmsettingsdefaulthighadjustvalue'
//                                             // objName='trailertirealarmsettingsdefaulthighadjustvalue'
//                                             label='High Adjust Value'
//                                             emptyText='High Adjust Value'
//                                             footerMessage=''
//                                             locked={props.locked}
//                                         />
//                                         </Grid>
//                                     </Grid>

//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <Field
//                                     component={renderCheckbox}
//                                     onClick={props.changeDPTrailerTireAlarmHighTemp }                        
//                                     checked={props.defaultDPTrailerTireAlarmHighTemp}
//                                     id='trailertirealarmsettingsdefaulthightemp'
//                                     name='trailertirealarmsettingsdefaulthightemp'
//                                     objName='trailertirealarmsettingsdefaulthightemp'
//                                     label='High Temp (DEFAULT 158 degrees F)'
//                                     footerMessage=''
//                                 />
//                             </Grid>
//                         </Grid>

//                                     <Grid container spacing={3}>
//                                         <Grid item xs={12}>
//                                         <Field
//                                             component={renderTextField}
//                                             disabled={props.defaultDPTrailerTireAlarmHighTemp}
//                                             id='trailertirealarmsettingsdefaulthightempadjustvalue'
//                                             name='trailertirealarmsettingsdefaulthightempadjustvalue'
//                                             // objName='trailertirealarmsettingsdefaulthightempadjustvalue'
//                                             label='High Temp'
//                                             emptyText='High Temp'
//                                             footerMessage=''
//                                             locked={props.locked}
//                                         />
//                                         </Grid>
//                                     </Grid>
//  */}
//  <br />
//  <br />
//  <br />


// {/* a1 */}




