import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { CONTEXT_USER } from 'App';

import {
  Box,
  Button,
  colors,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.grey[50]
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 80,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto'
    }
  },
  content: {
    padding: theme.spacing(1, 2)
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center'
  }
}));

const SidebarFooter = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  /*** SIGNED IN USER DETAILS */
  const CONST_CONTEXT_USER = useContext(CONTEXT_USER);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box height="100%" display="flex" flexDirection="column">
        <Box p={2} m={2}>
          <div className={classes.actions}>
            <Button
              color="primary"
              component="a"
              href="/sign-out"
              variant="contained">
              Log Off
            </Button>
          </div>

          {CONST_CONTEXT_USER.role > 0 ? (
            <div className={classes.content}>
              <hr />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography align="center" variant="body2">
                    <Link href="/changelog" target="_blank">
                      ChangeLog
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ) : null}

{/* 
          <br />
          <br />
          <hr />
          <br />
          <br />

          <div className={classes.content}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" variant="body2">
                  {process.env.REACT_APP_APPVERSION}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" variant="body2">
                  {process.env.REACT_APP_DBCOREVERSION}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" variant="body2">
                  {process.env.REACT_APP_PLATFORMVERSION}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" variant="body2">
                  <Link href={process.env.REACT_APP_COMPANYURL} target="_blank">
                    {process.env.REACT_APP_COMPANYURLDESCRIPTION}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </div> 
*/}
        </Box>
      </Box>
    </div>
  );
};

SidebarFooter.propTypes = {
  className: PropTypes.string
};

export default SidebarFooter;
