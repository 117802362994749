import React from 'react';
import { Field } from 'react-final-form';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  renderSelectField,
  renderTextField,
  renderTextField_DATE
} from 'components/form-global-components/react-final-form-global-components';
import {
  DATA_BILLING,
  DATA_BILLING_TERM,
  OPTION_AFTER_EXPIRATION,
  OPTION_NO_YES
} from 'constants/action-constants';

/*** Make the card width = 100% --- START */
const useStyles = makeStyles((theme) => ({
  root: {
    /***
     * Make the card width = 100%
     * Add spacing around the top and bottom of the card
     */
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
/*** Make the card width = 100% --- END */

function SRFDisplayProgrammingDataBillingTruck(props) {
  /*** Make the card width = 100% --- START */
  const classes = useStyles();
  /*** Make the card width = 100% --- END */

  // console.log('77-17 - props = ', props);

  const renderBillingTermRate = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              component={renderSelectField}
              id="dptruckdatabillingterm"
              name="dptruckdatabillingterm"
              objName="dptruckdatabillingterm"
              label="Billing Term"
              footerMessage=""
              dataArray={DATA_BILLING_TERM}
              requireOnChangeMethod={false}
              locked={props.locked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              component={renderTextField}
              id="dptruckdatabillingrate"
              name="dptruckdatabillingrate"
              // objName="dptruckdatabillingrate"
              label="Billing Rate"
              emptyText="Billing Rate"
              footerMessage=""
              // requiredText={true}
              locked={props.locked}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return <>{renderBillingTermRate()}</>;
      case 2:
        return (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={renderSelectField}
                  id="dptruckcustsatexpire"
                  name="dptruckcustsatexpire"
                  objName="dptruckcustsatexpire"
                  label="Does Customer Sat expire"
                  footerMessage=""
                  dataArray={OPTION_NO_YES}
                  onChangeMethod={props.changeDPTruckCustSatExpireSelected}
                  locked={props.locked}
                />
              </Grid>
            </Grid>

            {props.DPTruckCustSatExpireSelected === 2 ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={renderTextField_DATE}
                      id="dptruckexpireswhen"
                      name="dptruckexpireswhen"
                      label="Expires When"
                      emptyText="Expires When"
                      footerMessage="Invalid Date"
                      // requiredText={true}
                      locked={props.locked}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={renderSelectField}
                      id="dptruckafterexpiration"
                      name="dptruckafterexpiration"
                      objName="dptruckafterexpiration"
                      label="What happens after expiration"
                      footerMessage=""
                      dataArray={OPTION_AFTER_EXPIRATION}
                      onChangeMethod={
                        props.changeDPTruckAfterExpirationSelected
                      }
                      locked={props.locked}
                    />
                  </Grid>
                </Grid>

                {props.DPTruckAfterExpirationSelected === 2 ? (
                  <>{renderBillingTermRate()}</>
                ) : null}
              </>
            ) : null}
          </>
        );
      case 3:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title="Truck Data Billing" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={renderSelectField}
                id="dptruckdatabilling"
                name="dptruckdatabilling"
                objName="dptruckdatabilling"
                label="Truck Data Billing"
                footerMessage=""
                dataArray={DATA_BILLING}
                onChangeMethod={props.changeDPTruckDataBillingSelected}
                locked={props.locked}
              />
            </Grid>
          </Grid>

          {renderSwitch(props.DPTruckDataBillingSelected)}
        </CardContent>
      </Card>
    </div>
  );
}

export default SRFDisplayProgrammingDataBillingTruck;

/***
 * Required property 
 * locked=bool

 * DPTruckDataBillingSelected=int
 * changeDPTruckDataBillingSelected=function
 * DPTruckCustSatExpireSelected=int
 * changeDPTruckCustSatExpireSelected=function
 * DPTruckAfterExpirationSelected=int
 * changeDPTruckAfterExpirationSelected=function

  <SRFDisplayProgrammingDataBillingTruck
    locked={true / false}
    
    DPTruckDataBillingSelected={1, 2, 3, or 4}
    changeDPTruckDataBillingSelected={props.changeDPTruckDataBillingSelected}
    DPTruckCustSatExpireSelected={1, 2, 3, or 4}
    changeDPTruckCustSatExpireSelected={props.changeDPTruckCustSatExpireSelected}
    DPTruckAfterExpirationSelected={1, 2, 3, or 4}
    changeDPTruckAfterExpirationSelected={props.changeDPTruckAfterExpirationSelected}
  />

*/
